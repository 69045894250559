import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import { getCohortProfiles } from "store/reducers/cohorts/services";
import CohortDataExport from "components/CohortDataExport";

import {
  getExports,
  createExport,
  getDownloadUrl
} from "store/reducers/dataExport/services";

const mapStateToProps = state => ({
  cohortProfiles: state.cohorts.cohortProfiles,
  cohortProfilesLoading: isLoading(state, "getCohortProfiles"),
  exportData: state.dataExport,
  organizationId: state.user.organizationId
});

const mapDispatchToProps = {
  getCohortProfiles,
  getExports,
  createExport,
  getDownloadUrl
};

export default connect(mapStateToProps, mapDispatchToProps)(CohortDataExport);
