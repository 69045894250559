import React from "react";
import "./flatLayout.scss";
import { useState } from "react";
import { initialBackgroundColor } from "const/colorCodes";

export const ColorContext = React.createContext(initialBackgroundColor);

const FlatLayout = ({ children }) => {
  const [color, setColor] = useState(initialBackgroundColor);
  const changeBgColor = React.useCallback(newColor => setColor(newColor), []);
  return (
    <ColorContext.Provider value={{ changeBgColor }}>
      <div style={{ backgroundColor: color }} className="flat-layout">
        {children}
      </div>
    </ColorContext.Provider>
  );
};

export default FlatLayout;
