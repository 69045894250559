import { error, success } from "react-notification-system-redux";
import * as api from "api/homeTest";
import * as actions from "./actions";
import i18n from "../../../translations";
import { ROLE_COUNTRY_ADMIN } from "const/roles";
import {checkContactDetails} from "utils/mappers";

export const getHomeTestProfilesList = countryCode => async (
  dispatch,
  getState
) => {
  const result = await dispatch(api.getHomeTestProfilesList(countryCode));
  dispatch(actions.setHomeProfiles(result.data));
  const state = getState();
  const { user } = state;
  if (result?.data?.length && user.roles.includes(ROLE_COUNTRY_ADMIN)) {
    const res = await dispatch(
      api.getHomeTestProfilesById(countryCode, result.data[0].testingProfileId)
    );
    dispatch(actions.setHomeTestingProfile(res.data));
  }
};

export const createHomeTestProfile = payload => async (dispatch, getState) => {
  const state = getState();
  const countryCode = state.user.countries[0] || state.user.countryCode;
  const { contactDetails } = payload;
  
  const inValid = checkContactDetails(contactDetails);

  if (inValid) {
    dispatch(
      error({
        title: i18n.t("error"),
        message: i18n.t("error_contact_info"),
        position: "tc"
      })
    );
    return false;
  }
  const result = await dispatch(
    api.createHomeTestProfile(countryCode.country, payload)
  );
  if (result && result.status === 200) {
    dispatch(actions.createHomeTestProfile(payload));
    dispatch(getHomeTestProfilesById(result.data.testingProfileId));

    return true;
  } else {
    dispatch(error({ message: i18n.t("error_generic") }));
    return false;
  }
};

export const getHomeTestProfilesById = id => async (dispatch, getState) => {
  const state = getState();
  const countryCode = state.user.countries[0] || state.user.countryCode;
  const result = await dispatch(
    api.getHomeTestProfilesById(countryCode.country, id)
  );
  dispatch(actions.setHomeTestingProfile(result.data));
};

export const updateHomeTestProfile = (id, payload) => async dispatch => {
  const { contactDetails } = payload;
  
  const inValid = checkContactDetails(contactDetails)

  if (inValid) {
    dispatch(
      error({
        title: i18n.t("error"),
        message: i18n.t("error_contact_info"),
        position: "tc"
      })
    );
    return false;
  }
  const result = await dispatch(api.updateHomeTestProfile(id, payload));
  if (result && result.status === 200) {
    dispatch(actions.updateHomeTestProfile(payload));
    const res = await dispatch(
      api.getHomeTestProfilesById(payload.countryCode, id)
    );
    dispatch(actions.setHomeTestingProfile(res.data));

    return true;
  } else {
    dispatch(error({ message: i18n.t("error_generic") }));
    return false;
  }
};

export const publishHomeTestProfile = (id, status) => async dispatch => {
  const result = await dispatch(api.publishHomeTestProfile(id, status));

  if (result?.status !== 200) return;

  dispatch(actions.publishHomeTestProfile(status));

  dispatch(
    success({
      title: i18n.t("success"),
      message: i18n.t("contents_published_s")
    })
  );
};

export const activateDeactivateHomeTestProfile = (
  id,
  active
) => async dispatch => {
  await dispatch(api.activateDeactivateHomeTestProfile(id, active));
  dispatch(actions.activateDeactivateHomeTestProfile(active));
};
