import React from "react";
import PageTitle from "components/PageTitle";
import FormGroup from "components/FormGroup";
import SelectOption from "components/SelectOption";
import EditDataElementForm from "components/EditDataElementForm";
import ConfirmPopup from "components/ConfirmPopup";
import Survey from "components/Survey";
import PropTypes from "prop-types";
import * as _ from "lodash";
import { formatI18String } from "utils/formatData";
import { SURVEY_FOR_TEST_TYPE,SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE, SURVEY_FOR_EXTENDED_PATIENT_PROFILE} from "const/surveyTypes";

import "react-datepicker/dist/react-datepicker.css";
import "./DataElements.scss";

import { useTranslation } from "react-i18next";

const DataElements = ({
  patientSurvey,
  organizationSurvey,
  testTypeSurvey,
  getPatientProfileSurvey,
  getOrganizationProfileSurvey,
  getTestTypeSurvey,
  patientSurveyIsLoading,
  organizationSurveyIsLoading,
  testTypeSurveyIsLoading,
  testTypeList,
  updateSurvey,
  surveyIsUpdating,
  createSurvey,
  deleteSurvey,
  uploadSurvey,
  surveyValidating
}) => {
  const { t } = useTranslation();
  const [selectedSurvey, setSelectedSurvey] = React.useState({});
  const [selectedTestType, setSelectedTestType] = React.useState(null);
  const [selectedDataElement, setSelectedDataElement] = React.useState(null);
  const [selectedSurveyType, setSelectedSurveyType] = React.useState("");
  const [action, setAction] = React.useState("");
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [confirmConfig, setConfirmConfig] = React.useState({});

  React.useEffect(() => {
    getPatientProfileSurvey();
    getOrganizationProfileSurvey();
  }, [getPatientProfileSurvey, getOrganizationProfileSurvey]);

  React.useEffect(() => {
    if (selectedTestType) {
      getTestTypeSurvey(selectedTestType);
    }
  }, [selectedTestType, getTestTypeSurvey]);

  const handleTestTypeChange = value => {
    setSelectedTestType(value.value);
  };

  const addAnswersMap = data => {
    const { questions } = data.survey;
    const nrOfQuestions = questions.length - 1;
    return {
      ...data,
      survey: {
        ...data.survey,
        questions: questions.map((question, i) => {
          return {
            ...question,
            answersMap: Object.assign(
              {},
              ...question.answers.map(answer => ({
                [answer["id"]]: i === nrOfQuestions ? null : questions[i + 1].id
              }))
            )
          };
        })
      }
    };
  };

  const onSubmit = async submittedData => {
    if (_.isEmpty(selectedSurvey) && action === "ADD") {
      const newSurvey = {
        surveyType: selectedSurveyType,
        scopeId: selectedTestType || null,
        survey: {
          //Im not sure where to get the languageId from
          languageId: "EN",
          questions: [{ ...submittedData }]
        }
      };
      let newSurveyWithAnswerMap = addAnswersMap(newSurvey);
      await createSurvey(newSurveyWithAnswerMap);
    } else {
      let updatedData = {
        ...selectedSurvey,
        survey: {
          ...selectedSurvey.survey,
          questions:
            action !== "ADD"
              ? selectedSurvey.survey.questions.map(question =>
                  question.id === submittedData.id
                    ? { ...submittedData }
                    : question
                )
              : [...selectedSurvey?.survey?.questions, { ...submittedData }]
        }
      };
      let updatedDataWithAnswerMap = addAnswersMap(updatedData);
      await updateSurvey(updatedDataWithAnswerMap);
    }
    setShowAddModal(false);

    setSelectedDataElement(null);
    setSelectedSurvey(null);
  };

  const onDuplicate = async data => {
    let updatedDataWithAnswerMap = addAnswersMap(data);
    await updateSurvey(updatedDataWithAnswerMap);
    setSelectedSurvey(null);
  };

  const onConfirmDeleteDataElement = React.useCallback(
    async (selectedSurvey, data) => {
      const updatedData = {
        ...selectedSurvey,
        survey: {
          ...selectedSurvey.survey,
          questions: selectedSurvey.survey.questions.filter(
            question => question.id !== data.id
          )
        }
      };
      if (!updatedData.survey.questions.length) {
        await deleteSurvey(updatedData);
      } else {
        if (updatedData.survey.questions[0].id !== "START") {
          updatedData.survey.questions[0].id = "START";
        }
        let updatedDataWithAnswerMap = addAnswersMap(updatedData);
        await updateSurvey(updatedDataWithAnswerMap);
      }
      setShowDeleteModal(false);
      setSelectedDataElement(null);
      setSelectedSurvey(null);
    },
    [updateSurvey, deleteSurvey]
  );

  const onDelete = React.useCallback(
    (survey, dataElement) => {
      setConfirmConfig({
        title: t("confirm_delete_action"),
        message: formatI18String(
          t("confirm_delete_name", { name: `'${dataElement.title}'` })
        ),
        onConfirm: () => onConfirmDeleteDataElement(survey, dataElement)
      });
      setShowDeleteModal(true);
    },
    [onConfirmDeleteDataElement, t]
  );

  const uploadImportedSurvey = async(importedSurvey, currentSurvey, surveyType) => {
    const uploaded = await uploadSurvey(importedSurvey, currentSurvey, surveyType, selectedTestType)
    return uploaded;
  }

  return (
    <>
      <PageTitle
        title={t("data_elements")}
        className="data-elements-page-title"
      />

      <Survey
        title={t("test_recipient_profile_data_elements")}
        data={patientSurvey}
        loading={patientSurveyIsLoading}
        setAction={action => setAction(action)}
        setShowAddModal={() => setShowAddModal(true)}
        selectSurvey={data => {
          setSelectedSurvey(data);
          setSelectedSurveyType(SURVEY_FOR_EXTENDED_PATIENT_PROFILE);
        }}
        selectDataElement={data => {
          setSelectedDataElement(data);
        }}
        duplicateDataElement={onDuplicate}
        deleteDataElement={data => onDelete(patientSurvey, data)}
        uploadImportedSurvey={uploadImportedSurvey}
        surveyValidating = {surveyValidating}
        surveyType={SURVEY_FOR_EXTENDED_PATIENT_PROFILE}
      />
      <Survey
        title={t("organization_profile_data_elements")}
        data={organizationSurvey}
        loading={organizationSurveyIsLoading}
        setAction={action => setAction(action)}
        setShowAddModal={() => setShowAddModal(true)}
        selectSurvey={data => {
          setSelectedSurvey(data);
          setSelectedSurveyType(SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE);
        }}
        selectDataElement={data => {
          setSelectedDataElement(data);
        }}
        duplicateDataElement={onDuplicate}
        deleteDataElement={data => onDelete(organizationSurvey, data)}
        uploadImportedSurvey={uploadImportedSurvey}
        surveyValidating = {surveyValidating}
        surveyType={SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE}
      />

      <PageTitle title={t("test_type")} />
      <FormGroup className="data-elements-select-testtype">
        <SelectOption
          isSearchable
          options={testTypeList}
          onChange={handleTestTypeChange}
          placeholder={t("select")}
        />
      </FormGroup>
      <div className="data-elements-devider"></div>
      {selectedTestType ? (
        <Survey
          title={t("test_recipient_survey_data_elements")}
          data={testTypeSurvey}
          loading={testTypeSurveyIsLoading}
          setAction={action => setAction(action)}
          setShowAddModal={() => setShowAddModal(true)}
          selectSurvey={data => {
            setSelectedSurvey(data);
            setSelectedSurveyType(SURVEY_FOR_TEST_TYPE);
          }}
          selectDataElement={data => {
            setSelectedDataElement(data);
          }}
          duplicateDataElement={onDuplicate}
          deleteDataElement={data => onDelete(testTypeSurvey, data)}
          uploadImportedSurvey={uploadImportedSurvey}
          surveyValidating = {surveyValidating}
          surveyType={SURVEY_FOR_TEST_TYPE}
        />
      ) : null}
      {showAddModal && (
        <EditDataElementForm
          onSubmit={onSubmit}
          loading={surveyIsUpdating}
          action={action}
          hide={() => {
            setShowAddModal(false);
            setSelectedDataElement(null);
          }}
          data={selectedSurvey}
          selectedDataElement={selectedDataElement}
        />
      )}
      {showDeleteModal && (
        <ConfirmPopup
          title={confirmConfig.title}
          message={confirmConfig.message}
          visible={showDeleteModal}
          cancel={() => setShowDeleteModal(false)}
          confirm={e => {
            e.preventDefault();
            confirmConfig.onConfirm();
          }}
          loading={surveyIsUpdating}
        />
      )}
    </>
  );
};

DataElements.propTypes = {
  patientSurvey: PropTypes.object,
  organizationSurvey: PropTypes.object,
  testTypeSurvey: PropTypes.object,
  patientSurveyIsLoading: PropTypes.bool,
  organizationSurveyIsLoading: PropTypes.bool,
  testTypeSurveyIsLoading: PropTypes.bool,
  testTypeList: PropTypes.array
};

export default DataElements;
