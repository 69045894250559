import React from "react";
import Amplify, { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import Spinner from "components/Spinner";
import Route from "components/Route";
import FlatLayout from "components/FlatLayout";
import Header from "components/Header";
import Notifications from "containers/Notifications.Container";
import awsConfig from "config/aws-config";
import { setLoggedUser, removeLoggedUser } from "store/reducers/user/services";
import { LAST_ACTIVE_TIME, LOGOUT_TIME } from "const/app";
import { clearAWSCache } from "utils/aws-tools";
import routes from "config/routes";

Amplify.configure(awsConfig);

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoadingStatus] = React.useState(true);

  React.useEffect(() => {
    const lastActive = parseInt(sessionStorage.getItem(LAST_ACTIVE_TIME));
    if (lastActive && Date.now() - lastActive <= LOGOUT_TIME) {
      Auth.currentAuthenticatedUser()
        .then(async user => await dispatch(setLoggedUser(user)))
        .catch(() => {})
        .finally(() => setLoadingStatus(false));
    } else {
      //clean possible session leftovers
      Auth.signOut({ global: true })
        .catch(() => {
          dispatch(removeLoggedUser());
        })
        .finally(() => {
          clearAWSCache();
          dispatch(removeLoggedUser());
          setLoadingStatus(false);
        });
    }
  }, [dispatch]);

  const renderSpinner = () => (
    <FlatLayout>
      <Spinner />
    </FlatLayout>
  );

  const renderApp = () => (
    <Router>
      <React.Fragment>
        <Header />
        <Switch>
          {Object.keys(routes).map(route => {
            const Component = routes[route].roles ? PrivateRoute : Route;
            return (
              <Component
                key={route}
                path={route}
                component={routes[route].component}
                layout={routes[route].layout}
                exact={routes[route].exact}
              />
            );
          })}
        </Switch>
      </React.Fragment>
    </Router>
  );

  //notifications get hidden on switching container so we want them always to be rendered
  return (
    <>
      {loading ? renderSpinner() : renderApp()}
      <Notifications />
    </>
  );
};

export default App;
