const awsConfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId:
      window.config.IDENTITY_POOL_ID || window.config.REACT_APP_IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: window.config.REGION || window.config.REACT_APP_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion:
      window.config.IDENTITY_POOL_REGION ||
      window.config.REACT_APP_IDENTITY_POOL_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: window.config.USER_POOL_ID || window.config.REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId:
      window.config.USER_POOL_WEB_CLIENT_ID ||
      window.config.REACT_APP_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: process.env.REACT_APP_MANDATORY_SIGN_IN,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: process.env.REACT_APP_AUTHENTICATION_FLOW_TYPE,

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    // oauth: {
    //   domain: 'ttp-dev-lambda.auth.us-west-2.amazoncognito.com',
    //   scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //   redirectSignIn: 'http://localhost:3000/',
    //   redirectSignOut: 'http://localhost:3000/',
    //   responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    // }
    //Store user data in SessionStorage so auth lives for session time only
    storage: sessionStorage
  }
};
export default awsConfig;
