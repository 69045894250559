import { useState, useEffect } from "react";
import i18n from "../translations";

function useLanguage() {
  const [flippedNameOrder, setFlippedNameOrder] = useState(false);
  const { language } = i18n;

  useEffect(() => {
    function checkLanguage() {
      if (language?.includes("zh") || language?.includes("ja")) {
        setFlippedNameOrder(true);
      }
    }
    checkLanguage();
  }, [language]);

  return { flippedNameOrder };
}
export default useLanguage;
