import React from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import SelectOption from "components/SelectOption";
import Switch from "components/Switch";
import Error from "components/Error";
import { ModalActions } from "components/Modal";
import "./AddAdminUserForm.scss";
import { useValidation } from "utils/useValidation";
import { noWhiteSpacesForRequiredFields } from "utils/FieldValidationRules";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/useLanguage";
import {
  ROLE_SUPER_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_DATA_ANALYST
} from "const/roles";

export default function AddAdminUserForm(props) {
  const {
    hide = () => {},
    adminId = null,
    firstName = "",
    lastName = "",
    email = "",
    loading,
    countries,
    roles
  } = props;

  const { t } = useTranslation();
  const { flippedNameOrder } = useLanguage();

  const [superAdminValue, setSuperAdminValue] = React.useState(
    roles?.find(role => role.roleId === ROLE_SUPER_ADMIN) ? true : false
  );
  const [countryAdminValue, setCountryAdminValue] = React.useState(
    roles?.find(role => role.roleId === ROLE_COUNTRY_ADMIN) ? true : false
  );
  const [dataAnalystValue, setDataAnalystValue] = React.useState(
    roles?.find(role => role.roleId === ROLE_COUNTRY_DATA_ANALYST)
      ? true
      : false
  );
  const { emailValidation, noRomanCharacter } = useValidation();

  const countryAdminRef = React.useRef(null);
  const dataAnalystRef = React.useRef(null);

  const countryOptions = countries
    ?.filter(({ serverId }) => serverId === window.config.REACT_APP_REGION)
    .map(({ countryName, countryCode }) => ({
      value: countryCode,
      label: countryName
    }));

  const {
    register,
    handleSubmit,
    formState,
    errors,
    setError,
    setValue,
    getValues,
    triggerValidation,
    control
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName,
      lastName,
      email,
      isSuperAdmin: superAdminValue,
      isCountryAdmin: countryAdminValue,
      isDataAnalyst: dataAnalystValue,
      countryAdmin:
        roles?.find(role => role.roleId === ROLE_COUNTRY_ADMIN)?.country || "",
      countryDataAnalyst:
        roles?.find(role => role.roleId === ROLE_COUNTRY_DATA_ANALYST)
          ?.country || ""
    }
  });

  const formatRequest = ({
    isSuperAdmin,
    isCountryAdmin,
    isDataAnalyst,
    countryAdmin,
    countryDataAnalyst,
    ...rest
  }) => ({
    ...rest,
    roles: [
      isSuperAdmin && { roleId: ROLE_SUPER_ADMIN, country: null },
      isCountryAdmin && {
        roleId: ROLE_COUNTRY_ADMIN,
        country: countryAdmin
      },
      isDataAnalyst && {
        roleId: ROLE_COUNTRY_DATA_ANALYST,
        country: countryDataAnalyst
      }
    ].filter(Boolean)
  });

  const hasRole = () => {
    const values = getValues();
    let isSuperAdDirty = formState.dirtyFields.has("isSuperAdmin");
    let isCountryAdDirty = formState.dirtyFields.has("isCountryAdmin");
    let isDataAnDirty = formState.dirtyFields.has("isDataAnalyst");

    if (adminId) {
      if (!isSuperAdDirty && !isCountryAdDirty && !isDataAnDirty) {
        return formState.dirty;
      } else {
        if (
          !values.isSuperAdmin &&
          !values.isCountryAdmin &&
          !values.isDataAnalyst
        ) {
          return false;
        }
        if (values.isCountryAdmin && values.isDataAnalyst) {
          return values.countryAdmin === "" || values.countryDataAnalyst === ""
            ? false
            : true;
        }
        if (values.isCountryAdmin) {
          return values.countryAdmin === "" ? false : true;
        }
        if (values.isDataAnalyst) {
          return values.countryDataAnalyst === "" ? false : true;
        }
        return true;
      }
    } else {
      if (isCountryAdDirty && isDataAnDirty) {
        return values.countryAdmin === "" || values.countryDataAnalyst === ""
          ? false
          : true;
      }
      if (isCountryAdDirty) {
        return values.countryAdmin === "" ? false : true;
      }
      if (isDataAnDirty) {
        return values.countryDataAnalyst === "" ? false : true;
      }
      if (isSuperAdDirty) {
        return true;
      }
      return false;
    }
  };

  const onSubmit = async params => {
    const request = formatRequest(params);
    if (formState.isValid) {
      const result = await props.onSubmit(request);
      if (result === "UsernameExistsException") {
        setError(
          "email",
          "required",
          t("health_care_organizations_users_add_modal_email_used")
        );
      } else if (result === "CredentialsError") {
        setError(
          "request",
          "required",
          t("health_care_organizations_users_add_modal_generic_error")
        );
      }
    }
  };

  return (
    <Modal
      visible={true}
      className="add-admin-user-form"
      data-testid="form-add-admin-user"
    >
      <Title>
        {adminId !== null
          ? t("edit_user")
          : t("health_care_organizations_users_add")}
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {flippedNameOrder ? (
          <>
            <FormGroup>
              <Label htmlFor="lastName" error={errors.lastName}>
                {t("last_name")}
              </Label>
              <Input
                type="text"
                name="lastName"
                error={errors.lastName}
                ref={register({
                  validate: noWhiteSpacesForRequiredFields,
                  required: t("form_field_is_required"),
                  maxLength: {
                    value: 255,
                    message: t("field_max_length", {
                      number_of_characters: 255
                    })
                  }
                })}
              />
              {errors.lastName && <Error>{errors.lastName.message}</Error>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="firstName" error={errors.firstName}>
                {t("first_name")}
              </Label>
              <Input
                type="text"
                name="firstName"
                error={errors.firstName}
                ref={register({
                  validate: noWhiteSpacesForRequiredFields,
                  required: t("form_field_is_required"),
                  maxLength: {
                    value: 255,
                    message: t("field_max_length", {
                      number_of_characters: 255
                    })
                  }
                })}
              />
              {errors.firstName && <Error>{errors.firstName.message}</Error>}
            </FormGroup>
          </>
        ) : (
          <>
            <FormGroup>
              <Label htmlFor="firstName" error={errors.firstName}>
                {t("first_name")}*
              </Label>
              <Input
                type="text"
                name="firstName"
                error={errors.firstName}
                ref={register({
                  validate: noWhiteSpacesForRequiredFields,
                  required: t("form_field_is_required"),
                  maxLength: {
                    value: 255,
                    message: t("field_max_length", {
                      number_of_characters: 255
                    })
                  }
                })}
              />
              {errors.firstName && <Error>{errors.firstName.message}</Error>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="lastName" error={errors.lastName}>
                {t("last_name")}*
              </Label>
              <Input
                type="text"
                name="lastName"
                error={errors.lastName}
                ref={register({
                  validate: noWhiteSpacesForRequiredFields,
                  required: t("form_field_is_required"),
                  maxLength: {
                    value: 255,
                    message: t("field_max_length", {
                      number_of_characters: 255
                    })
                  }
                })}
              />
              {errors.lastName && <Error>{errors.lastName.message}</Error>}
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Label htmlFor="email" error={errors.email}>
            {t("health_care_organizations_users_email")}*
          </Label>
          <Input
            type="text"
            name="email"
            error={errors.email}
            disabled={adminId !== null}
            onChange={noRomanCharacter}
            ref={register({
              validate: {
                noWhiteSpacesForRequiredFields,
                emailValidation
              },
              required: t("form_field_is_required"),
              maxLength: {
                value: 128,
                message: t("field_max_length", { number_of_characters: 128 })
              }
            })}
          />
          {errors.email && <Error>{errors.email.message}</Error>}
        </FormGroup>

        <div className="role-title">
          <Title>{t("super_admin")}</Title>
          <Switch
            ref={register}
            name="isSuperAdmin"
            handleToggle={() => {
              setSuperAdminValue(!superAdminValue);
              triggerValidation();
            }}
            isActive={superAdminValue}
            withoutLabel
            small
          />
        </div>

        <div className="role-title">
          <Title>{t("country_admin")}</Title>
          <Switch
            ref={register}
            name="isCountryAdmin"
            handleToggle={() => {
              if (countryAdminValue) {
                setCountryAdminValue(false);
                countryAdminRef.current.clearValue();
                setValue([{ countryAdmin: "" }]);
              } else {
                setCountryAdminValue(true);
              }
              triggerValidation();
            }}
            isActive={countryAdminValue}
            withoutLabel
            small
          />
        </div>

        <FormGroup>
          <Label>{t("health_care_organizations_add_modal_country")}</Label>
          <Controller
            control={control}
            name="countryAdmin"
            as={
              <SelectOption
                disabled={!countryAdminValue}
                ref={countryAdminRef}
                isSearchable
                options={countryOptions}
                placeholder={t("select")}
              />
            }
            onChange={([selected]) => {
              triggerValidation();
              return selected?.value;
            }}
          />
        </FormGroup>

        <div className="role-title">
          <Title>{t("data_analyst")}</Title>
          <Switch
            ref={register}
            name="isDataAnalyst"
            isActive={dataAnalystValue}
            handleToggle={() => {
              if (dataAnalystValue) {
                setDataAnalystValue(false);
                dataAnalystRef.current.clearValue();
                setValue([{ countryDataAnalyst: "" }]);
              } else {
                setDataAnalystValue(true);
              }
              triggerValidation();
            }}
            withoutLabel
            small
          />
        </div>

        <FormGroup>
          <Label>{t("health_care_organizations_add_modal_country")}</Label>

          <Controller
            control={control}
            name="countryDataAnalyst"
            as={
              <SelectOption
                disabled={!dataAnalystValue}
                ref={dataAnalystRef}
                isSearchable
                options={countryOptions}
                placeholder={t("select")}
              />
            }
            onChange={([selected]) => {
              triggerValidation();
              return selected?.value;
            }}
          />
        </FormGroup>

        <div className="fields-required">(*{t("required")})</div>

        <ModalActions>
          <Button
            variant="primary-full-width"
            className="margin-top--medium"
            type="submit"
            loading={loading}
            disabled={!formState.isValid || loading || !hasRole()}
          >
            {adminId !== null
              ? t("save")
              : t("health_care_organizations_add_modal_confirm")}
          </Button>
          <Button variant="cancel-text" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
