/* eslint-disable */
import React from "react";
import moment from "moment";
import Icon from "components/Icon";
import preval from "preval.macro";
import { ENV_COMMERCIAL } from "const/app";
import { formatName } from "utils/formatData";
import { fixture } from 'utils/fixture'
import "./about.scss";

const buildTimestamp = preval`module.exports = new Date().getTime();`;
const buildDate = moment.unix(buildTimestamp / 1000).format("YYYY-MM-DD");

const isCommercialEnv = window.config.REACT_APP_ENVIRONMENT === ENV_COMMERCIAL;
const title = formatName(fixture.project.name);

const About = ({ config, date = buildDate }) => (
  <>
    <div className="about-container" data-testid="about-container">
      <div className="block title">
        {title[0]}
        <span className="tm">{title[1]}</span>
        {title[2]}
        {!isCommercialEnv ? <span className="wip">(WIP)</span> : null}
      </div>
      <div className="block version">Version: 1.7</div>
      <div className="block lot">
        <Icon type="LOT" /> {`1.7.0.${window.config.REACT_APP_BUILD_NUMBER || 0}`}
      </div>
      {isCommercialEnv ? (
        <div className="block ref">
          <Icon type="REF" /> {config.refNumber_Portal}
        </div>
      ) : null}
      <div className="block icons">
        <Icon type="manual" />
        <Icon type="warning" />
      </div>
      <div className="block manufacturer">
        <Icon type="houseFull" />
        <span>{config.address}</span>
      </div>
      <div className="block manufactured">
        <Icon type="houseEmpty" /> {date}
      </div>
      {!isCommercialEnv ? (
        <div className="block purpose">
          <Icon type="warning" /> {config.purpose}
        </div>
      ) : null}
      <div className="block content">
        {isCommercialEnv ? config.content_commercial : config.content}
      </div>
    </div>
  </>
);

export default About;
