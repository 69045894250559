import { SET_SELECTED_ORGANIZATION } from "store/reducers/organizations/types";
import { LOGOUT_USER } from "store/reducers/user/types";
import {
  CREATE_ACCESS_PROFILE,
  DELETE_ACCESS_PROFILE,
  SET_ACCESS_PROFILES,
  UPDATE_ACCESS_PROFILES
} from "./types";

const initialState = [];

const accessProfiles = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACCESS_PROFILES: {
      const { accessProfiles } = payload;
      return [...(accessProfiles.data || [])];
    }
    case UPDATE_ACCESS_PROFILES: {
      const { accessProfileId, name, description } = payload;
      return state.map(item =>
        item.accessProfileId === accessProfileId
          ? { ...item, name, description, isEditing: false }
          : item
      );
    }

    case CREATE_ACCESS_PROFILE: {
      return [{ ...payload }, ...state];
    }

    case DELETE_ACCESS_PROFILE: {
      const newAccessProfiles = state.filter(
        profile => profile.accessProfileId !== payload.id
      );
      return newAccessProfiles;
    }

    case LOGOUT_USER:
    case SET_SELECTED_ORGANIZATION:
      return initialState;
    default:
      return state;
  }
};

export default accessProfiles;
