import phoneImage8 from "assets/images/qrPhone8.png";
import Button from "components/Button";
import EditableCell from "components/EditableCell";
import PageTitle from "components/PageTitle";
import Table from "components/Table";
import React from "react";
import "./TestProvider.scss";

import { formatMinutesAndSeconds } from "utils/formatData";
import { useTranslation } from "react-i18next";

const TestProvider = ({
  metaData,
  isEditing,
  updateTestingConfiguration,
  setTestingProfileDataForEdit,
  newMetaData
}) => {
  const { t } = useTranslation();
  const setIsEditing = isEditing.includes("testProvider") ? true : false;

  const contacts = [
    {
      id: "FirstRowProvider",
      type: "read_window",
      secondColumn: {
        label: "from",
        time: metaData?.resultTimer?.decisionInterval?.beginValidityInSeconds,
        formattedTime: formatMinutesAndSeconds(
          metaData?.resultTimer?.decisionInterval?.beginValidityInSeconds
        ),
        updateFunction: item => {
          let data = {
            ...newMetaData,
            resultTimer: {
              ...newMetaData?.resultTimer,
              decisionInterval: {
                ...newMetaData?.resultTimer.decisionInterval,
                beginValidityInSeconds: item
              }
            }
          };
          updateTestingConfiguration(data);
        },
        validationName: "readWindowFrom",

        toTime:
          newMetaData?.resultTimer?.decisionInterval?.endValidityInSeconds,
        fromTime:
          newMetaData?.resultTimer?.decisionInterval?.beginValidityInSeconds
      },
      thirdColumn: {
        label: "to",
        time: metaData?.resultTimer?.decisionInterval?.endValidityInSeconds,
        formattedTime: formatMinutesAndSeconds(
          metaData?.resultTimer?.decisionInterval?.endValidityInSeconds
        ),
        updateFunction: item => {
          let data = {
            ...newMetaData,
            resultTimer: {
              ...newMetaData?.resultTimer,
              decisionInterval: {
                ...newMetaData?.resultTimer.decisionInterval,
                endValidityInSeconds: item
              }
            }
          };
          updateTestingConfiguration(data);
        },
        validationName: "readWindowTo",
        toTime:
          newMetaData?.resultTimer?.decisionInterval?.endValidityInSeconds,
        fromTime:
          newMetaData?.resultTimer?.decisionInterval?.beginValidityInSeconds
      },
      fourthColumn: null,
      isEditing: setIsEditing
    },
    {
      id: "SecondRowProvider",
      type: "",
      secondColumn: t("before"),
      thirdColumn: {
        value: metaData?.resultTimer?.beforeValid?.headerText,
        updateFunction: item => {
          let data = {
            ...newMetaData,
            resultTimer: {
              ...newMetaData?.resultTimer,
              beforeValid: {
                ...newMetaData?.resultTimer.beforeValid,
                headerText: item
              }
            }
          };
          updateTestingConfiguration(data);
        },
        validationName: "beforeValidHeaderText"
      },
      fourthColumn: {
        value: metaData?.resultTimer?.beforeValid?.detailText,
        updateFunction: item => {
          let data = {
            ...newMetaData,
            resultTimer: {
              ...newMetaData?.resultTimer,
              beforeValid: {
                ...newMetaData?.resultTimer.beforeValid,
                detailText: item
              }
            }
          };
          updateTestingConfiguration(data);
        },
        validationName: "beforeValidDetailText"
      },
      isEditing: setIsEditing
    },
    {
      id: "ThirdRowProvider",
      type: "",
      secondColumn: t("during"),
      thirdColumn: {
        value: metaData?.resultTimer?.duringValid?.headerText,
        updateFunction: item => {
          let data = {
            ...newMetaData,
            resultTimer: {
              ...newMetaData?.resultTimer,
              duringValid: {
                ...newMetaData?.resultTimer.duringValid,
                headerText: item
              }
            }
          };

          updateTestingConfiguration(data);
        },

        validationName: "duringValidHeaderText"
      },
      fourthColumn: {
        value: metaData?.resultTimer?.duringValid?.detailText,
        updateFunction: item => {
          let data = {
            ...newMetaData,
            resultTimer: {
              ...newMetaData?.resultTimer,
              duringValid: {
                ...newMetaData?.resultTimer.duringValid,
                detailText: item
              }
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "duringValidDetailText"
      },
      isEditing: setIsEditing
    },
    {
      id: "FourthRowProvider",
      type: "",
      secondColumn: t("after"),
      thirdColumn: {
        value: metaData?.resultTimer?.afterValid?.headerText,
        updateFunction: item => {
          let data = {
            ...newMetaData,
            resultTimer: {
              ...newMetaData?.resultTimer,
              afterValid: {
                ...newMetaData?.resultTimer.afterValid,
                headerText: item
              }
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "afterValidHeaderText"
      },
      fourthColumn: {
        value: metaData?.resultTimer?.afterValid?.detailText,
        updateFunction: item => {
          let data = {
            ...newMetaData,
            resultTimer: {
              ...newMetaData?.resultTimer,
              afterValid: {
                ...newMetaData?.resultTimer.afterValid,
                detailText: item
              }
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "afterValidDetailText"
      },
      isEditing: setIsEditing
    },
    {
      id: "FifthRowProvider",
      type: "test_results",
      secondColumn: {
        value: metaData?.testOutcome?.values[0]?.title,
        id: metaData?.testOutcome?.values[0]?.id,
        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: [
                ...newMetaData.testOutcome.values.map(val =>
                  val.id === testId ? { ...val, title: item } : { ...val }
                )
              ]
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_0"
      },
      thirdColumn: {
        value: metaData?.testOutcome?.values[1]?.title,
        id: metaData?.testOutcome?.values[1]?.id,
        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: [
                ...newMetaData.testOutcome.values.map(val =>
                  val.id === testId ? { ...val, title: item } : { ...val }
                )
              ]
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_1"
      },
      fourthColumn: {
        value: metaData?.testOutcome?.values[2]?.title,
        id: metaData?.testOutcome?.values[2]?.id,
        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: [
                ...newMetaData.testOutcome.values.map(val =>
                  val.id === testId ? { ...val, title: item } : { ...val }
                )
              ]
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_2"
      },
      isEditing: setIsEditing
    }
  ];

  const contactColumns = React.useMemo(
    () => [
      {
        Header: () => null,
        accessor: "type",
        className: "provider-details-col1",

        Cell: ({ value, row: { original } }) => (
          <div className="header-content-row">
            <div>{value && t(value)}</div>
            {original.type === "read_window" ? (
              <span className="badge">1 </span>
            ) : original.type === "test_results" ? (
              <span className="badge">4</span>
            ) : null}
          </div>
        )
      },
      {
        Header: () => null,
        accessor: "secondColumn",
        className: "provider-details-col",
        Cell: props => {
          if (props.value === null) {
            return null;
          }
          if (props.value && props?.value?.label === "from")
            return (
              <div className="time-cell">
                {setIsEditing ? (
                  <EditableCell
                    inputType="time"
                    {...props}
                    value={props.value.time}
                  />
                ) : (
                  <p className="time-input">
                    {t("from")}: <span>HH :</span>
                    <span>MM :</span>
                    <span>SS</span> {props.value.formattedTime}
                  </p>
                )}
              </div>
            );
          if (props.row.original.type !== "test_results")
            return <p>{props.value}</p>;

          return (
            <EditableCell
              {...props}
              value={props?.value?.value || props.value}
            />
          );
        }
      },
      {
        Header: () => null,
        accessor: "thirdColumn",
        className: "provider-details-col",
        Cell: props => {
          if (props.row.original.type === "test_results")
            return (
              <EditableCell
                {...props}
                value={props?.value?.value || props.value}
              />
            );

          if (props?.value?.label === "to")
            return (
              <div className="time-cell">
                {setIsEditing ? (
                  <EditableCell
                    inputType="time"
                    {...props}
                    value={props.value.time}
                  />
                ) : (
                  <p className="time-input">
                    {t("to")}: <span>HH :</span>
                    <span>MM :</span>
                    <span>SS</span> {props.value.formattedTime}
                  </p>
                )}
              </div>
            );

          return (
            <EditableCell
              {...props}
              value={props?.value?.value || props.value}
              badgeValue={2}
            />
          );
        }
      },
      {
        Header: () => null,
        accessor: "fourthColumn",
        className: "provider-details-col",
        Cell: props => {
          if (props.value === null) {
            return null;
          }

          if (props.row.original.type === "test_results")
            return (
              <EditableCell
                {...props}
                value={props?.value?.value || props.value}
              />
            );

          return (
            <EditableCell
              {...props}
              value={props?.value?.value || props.value}
              badgeValue={3}
            />
          );
        }
      }
    ],
    [setIsEditing, t]
  );

  return (
    <div className="provider-container">
      <div className="container-left">
        <img src={phoneImage8} alt="phone" className="phone-image-8" />
      </div>
      <div className="container-right">
        <div className="title-top">
          <PageTitle title={t("test_provider")}></PageTitle>

          <Button
            variant="primary"
            className="header-add-button"
            disabled={setIsEditing}
            onClick={() => {
              setTestingProfileDataForEdit("ADD", "testProvider");
            }}
          >
            {t("edit")}
          </Button>
        </div>
        <Table
          columns={contactColumns}
          data={contacts}
          className="test-provider-table"
          Header={() => null}
        />
      </div>
    </div>
  );
};

export default TestProvider;
