import React from "react";
import SearchRecipientsContainer from "../../containers/SearchRecipients.Container";

const SearchRecipients = () => (
  <>
    <SearchRecipientsContainer location={"patients"}/>
  </>
);

export default SearchRecipients;
