import React from "react";
import Icon from "components/Icon";
import Table from "components/Table";
import Button from "components/Button";
import Spinner from "components/Spinner";
import PageTitle from "components/PageTitle";
import EditUserForm from "components/EditUserForm";
import ConfirmPopup from "components/ConfirmPopup";
import ResetUserPhoneNumberForm from "components/ResetUserPhoneNumberForm";
import { ROLE_TEST_PROVIDER_ADMIN, ROLE_EVENT_ADMIN, ROLE_ENTERPRISE_ADMIN, ROLE_SUPER_ADMIN } from "const/roles";
import { availableRoles } from "utils/roleManager";
import { sortIgnoreCase, sortByIsAdmin, sortByRole } from "utils/sortMethods";
import "./organizationUsers.scss";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/useLanguage";

const OrganizationUsers = ({
  users,
  loggedUser,
  rolesList,
  isSuperAdmin,
  userForm,
  selectedOrganization,
  getOrganizationUsers,
  updateOrganizationUser,
  deleteOrganizationUser,
  resetOrganizationUserPhoneNumber,
  organizationUserPhoneResetting,
  organizationUserRemoving,
  organizationsLoading,
  organizationUserCreating,
  organizationUserRolesSaving,
  organizationUsersLoading,
  organizationUserDeleting,
  createOrganizationUser,
  organizationUserSaving,
  showUserForm: dispatchShowUserForm,
  hideUserForm: dispatchHideUserForm
}) => {
  const { t } = useTranslation();
  const { flippedNameOrder } = useLanguage();
  const [showConfirm, setConfirmPopupStatus] = React.useState(false);
  const [userActionType, setUserActionType] = React.useState();
  const [currentUser, setCurrentUser] = React.useState({});
  const [confirmConfig, setConfirmConfig] = React.useState({});
  const [usersFetched, setUsersFetched] = React.useState(false);

  const userActionTypes = {
    RESET_PHONE: "RESET_PHONE",
    CREATE_USER: "CREATE_USER",
    EDIT_USER: "EDIT_USER"
  };

  const mapData = React.useCallback(
    users => {
      return users.map(user => ({
        ...user,
        isTestProviderAdmin: !!user.roles.find(
          r => r.roleId === ROLE_TEST_PROVIDER_ADMIN
        ),
        isEventAdmin: !!user.roles.find(r => r.roleId === ROLE_EVENT_ADMIN),
        isEnterpriseAdmin: !!user.roles.find(r => r.roleId === ROLE_ENTERPRISE_ADMIN),
        roles: user.roles
          .filter(
            r =>
              r.roleId !== ROLE_TEST_PROVIDER_ADMIN &&
              r.roleId !== ROLE_EVENT_ADMIN &&
              r.roleId !== ROLE_ENTERPRISE_ADMIN
          )
          .map(item => {
            const role = rolesList.find(r => r.value === item.roleId).label;
            return role || "";
          })
      }));
    },
    [rolesList]
  );

  React.useEffect(() => {
    if (isSuperAdmin && selectedOrganization) {
      if (!organizationsLoading) {
        getOrganizationUsers(selectedOrganization.organizationId);
      }
    }
  }, [
    organizationsLoading,
    loggedUser,
    getOrganizationUsers,
    isSuperAdmin,
    selectedOrganization
  ]);

  React.useEffect(() => {
    if (!isSuperAdmin && !usersFetched && !users.length) {
      getOrganizationUsers(selectedOrganization.organizationId);
      setUsersFetched(true)
    }
  }, [
    selectedOrganization,
    loggedUser,
    getOrganizationUsers,
    usersFetched,
    isSuperAdmin,
    users
  ]);

  const setUserForEdit = React.useCallback(
    (id, actionType) => {
      const user = users.find(u => u.id === id);
      setCurrentUser(user);
      dispatchShowUserForm();
      setUserActionType(actionType);
    },
    [users, dispatchShowUserForm]
  );

  const updateUser = React.useCallback(
    async ({ firstName, lastName, email, roles }) => {
      if (!currentUser.id) {
        return await createOrganizationUser(
          selectedOrganization.organizationId,
          firstName,
          lastName,
          email,
          roles
        );
      } else {
        let additionalRoles = currentUser.roles.filter(
          r =>
            r.roleId !== ROLE_TEST_PROVIDER_ADMIN &&
            r.roleId !== ROLE_EVENT_ADMIN &&
            r.roleId !== ROLE_ENTERPRISE_ADMIN
        )
        let roleListOfUser = [...roles, ...additionalRoles]
        await updateOrganizationUser(
          selectedOrganization.organizationId,
          firstName,
          lastName,
          currentUser.roles,
          loggedUser?.roles?.includes(ROLE_SUPER_ADMIN) 
            ? roleListOfUser.map(role => ({ roleId: role.roleId })) 
            : roles.map(role => ({ roleId: role.roleId })),
          currentUser.id,
          email
        );
      }
    },
    [
      createOrganizationUser,
      currentUser.id,
      currentUser.roles,
      selectedOrganization,
      updateOrganizationUser,
      loggedUser
    ]
  );

  const onConfirmDeleteUser = React.useCallback(
    id => {
      deleteOrganizationUser(selectedOrganization.organizationId, id).then(
        () => {
          hideConfirm();
        }
      );
    },
    [deleteOrganizationUser, selectedOrganization]
  );

  const deleteUser = React.useCallback(
    id => {
      const user = users.find(u => u.id === id);
      setCurrentUser(user);
      setConfirmConfig({
        title: t("confirm_delete_action"),
        message: t(`confirm_delete_name`, {
          name: `${user.firstName} ${user.lastName}`
        }),
        onConfirm: () => onConfirmDeleteUser(id)
      });
      setConfirmPopupStatus(true);
    },
    [onConfirmDeleteUser, users, t]
  );

  const hideUserForm = React.useCallback(() => {
    setCurrentUser({});
    dispatchHideUserForm();
  }, [dispatchHideUserForm]);

  const showAddUserForm = React.useCallback(() => {
    setCurrentUser({});
    setUserActionType(userActionTypes.CREATE_USER);
    dispatchShowUserForm();
  }, [dispatchShowUserForm, userActionTypes.CREATE_USER]);

  const onConfirmResetPhoneNumber = React.useCallback(
    async (id, phoneNumber) => {
      await resetOrganizationUserPhoneNumber(id, phoneNumber);
      hideUserForm();
    },
    [hideUserForm, resetOrganizationUserPhoneNumber]
  );

  const hideConfirm = () => {
    setCurrentUser({});
    setConfirmPopupStatus(false);
    setUserActionType();
  };

  const nameColumns = React.useMemo(
    () => [
      {
        Header: t("first_name"),
        accessor: "firstName",
        className: "name-column",
        sortType: sortIgnoreCase
      },
      {
        Header: t("last_name"),
        accessor: "lastName",
        className: "name-column",
        sortType: sortIgnoreCase
      }
    ],
    [t]
  );

  const nameColumnsFlipped = React.useMemo(
    () => [
      {
        Header: t("last_name"),
        accessor: "lastName",
        className: "name-column",
        sortType: sortIgnoreCase
      },
      {
        Header: t("first_name"),
        accessor: "firstName",
        className: "name-column",
        sortType: sortIgnoreCase
      }
    ],
    [t]
  );

  const userColumns = React.useMemo(
    () => [
      ...(flippedNameOrder ? nameColumnsFlipped : nameColumns),

      {
        Header: t("health_care_organizations_users_email"),
        accessor: "email",
        sortType: sortIgnoreCase
      },
      {
        Header: t("access_admin"),
        accessor: "isEventAdmin",
        className: "event-admin-column",
        sortType: sortByIsAdmin,
        Cell: ({ value }) => {
          return value ? <Icon type="check" /> : null;
        }
      },
      {
        Header: t("enterprise_admin"),
        accessor: "isEnterpriseAdmin",
        className: "enterprise-admin-column",
        sortType: sortByIsAdmin,
        Cell: ({ value }) => {
          return value ? <Icon type="check" /> : null;
        }
      },
      {
        Header: t("health_care_organizations_users_test_provider_admin"),
        accessor: "isTestProviderAdmin",
        className: "tp-admin-column",
        sortType: sortByIsAdmin,
        Cell: ({ value }) => {
          return value ? <Icon type="check" /> : null;
        }
      },
      {
        Header: t("roles"),
        sortType: sortByRole,
        className: "roles-column",
        accessor: item => {
          return item.roles.map(it => (it && it.role) || it);
        },
        Cell: ({ value }) => {
          return (
            <>
              {value.map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </>
          );
        }
      },
      {
        Header: t("health_care_organizations_actions"),
        className: "actions-column",
        Cell: ({ row }) => {
          return (
            <React.Fragment>
              <Button
                variant="row-action"
                title={t("health_care_organizations_actions_edit")}
                onClick={() => {
                  return setUserForEdit(
                    row.original.id,
                    userActionTypes.EDIT_USER
                  );
                }}
              >
                <Icon type="edit" />
              </Button>
              <Button
                variant="row-action"
                title={t("health_care_organizations_users_actions_reset_phone")}
                onClick={() =>
                  setUserForEdit(row.original.id, userActionTypes.RESET_PHONE)
                }
              >
                <Icon type="reset" />
              </Button>
              <Button
                variant="row-action"
                title={t("health_care_organizations_users_actions_delete")}
                onClick={() => deleteUser(row.original.id)}
              >
                <Icon type="trash" />
              </Button>
            </React.Fragment>
          );
        }
      }
    ],
    [
      deleteUser,
      setUserForEdit,
      userActionTypes.EDIT_USER,
      userActionTypes.RESET_PHONE,
      t,
      flippedNameOrder,
      nameColumns,
      nameColumnsFlipped
    ]
  );

  const noTestProviderAdmin = column => 
    !loggedUser?.roles?.includes(ROLE_TEST_PROVIDER_ADMIN) 
    ? column.accessor  !== "isTestProviderAdmin" 
    : column;

  const noEventAdmin = column => 
    !loggedUser?.roles?.includes(ROLE_EVENT_ADMIN)
    ? column.accessor  !== "isEventAdmin"
    : column;

  const noEnterpriseAdmin = column => 
    !loggedUser?.roles?.includes(ROLE_ENTERPRISE_ADMIN)
    ? column.accessor  !== "isEnterpriseAdmin"
    : column;
  
  const columns =  loggedUser?.roles?.includes(ROLE_SUPER_ADMIN) 
  ? userColumns 
  : userColumns 
    .filter(noTestProviderAdmin)
    .filter(noEventAdmin)
    .filter(noEnterpriseAdmin)
    
  if (!selectedOrganization) {
    return null;
  }

  return (
    <React.Fragment>
      {!organizationsLoading && !organizationUsersLoading && (
        <Button
          variant="primary"
          className="header-add-button"
          onClick={showAddUserForm}
        >
          {t("health_care_organizations_users_add")}
        </Button>
      )}
      <PageTitle title={t("health_care_organizations_users_title")} />
      <br />
      {organizationUsersLoading ? (
        <Spinner marginTop="2" />
      ) : (
        <Table
          columns={columns}
          data={mapData(users)}
          globalFilter
          pagination
          pageSize={4}
        />
      )}
      <hr className="devider" />
      {userForm &&
        (userActionType === userActionTypes.EDIT_USER ||
          userActionType === userActionTypes.CREATE_USER) && (
          <EditUserForm
            hide={hideUserForm}
            onSubmit={updateUser}
            loading={
              organizationUserSaving ||
              organizationUserCreating ||
              organizationUserRolesSaving
            }
            rolesList={availableRoles(loggedUser.roles, rolesList)}
            {...currentUser}
          />
        )}
      {userForm && userActionType === userActionTypes.RESET_PHONE && (
        <ResetUserPhoneNumberForm
          hide={hideUserForm}
          onSubmitAction={onConfirmResetPhoneNumber}
          loading={organizationUserPhoneResetting}
          userId={currentUser.id}
        />
      )}
      {showConfirm && (
        <ConfirmPopup
          title={confirmConfig.title}
          message={confirmConfig.message}
          loading={organizationUserDeleting || organizationUserRemoving}
          visible={showConfirm}
          cancel={hideConfirm}
          confirm={confirmConfig.onConfirm}
        />
      )}
    </React.Fragment>
  );
};

export default OrganizationUsers;
