import React from "react";
import CountryExportContainer from "../../containers/CountryExport.Container";

const CountryExport = () => (
  <>
    <CountryExportContainer />
  </>
);

export default CountryExport;
