import React from "react";
import Label from "components/Label";
import FormGroup from "components/FormGroup";
import SelectOption from "components/SelectOption";
import Error from "components/Error";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

function InputSingleSelect({
  field,
  defaultValue,
  useForm: { errors, control }
}) {
  const { t } = useTranslation();

  return (
    <FormGroup key={field.id}>
      <Label htmlFor={field.id}>
        {field.title}
        {field.required && "*"}
      </Label>
      <Controller
        as={
          <SelectOption
            isSearchable
            options={field.answers.map(item => ({
              label: item.value,
              value: item.id
            }))}
            placeholder={t("select")}
          />
        }
        onChange={([selected]) => {
          // React Select return object instead of value for selection
          return selected.value;
        }}
        rules={{
          required: field.required && t("form_field_is_required")
        }}
        name={field.id}
        control={control}
        defaultValue={defaultValue}
      />

      {errors[field.id] && <Error>{errors[field.id].message}</Error>}
    </FormGroup>
  );
}

export default InputSingleSelect;
