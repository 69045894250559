import AWS from "aws-sdk";
import config from "config/aws-config";
import { APP_ID } from "const/app";
import {
  ROLE_TEST_PROVIDER_ADMIN,
  ROLE_EVENT_ADMIN,
  ROLE_DATA_EXPORT_TEST_RESULTS,
  ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII,
  ROLE_DATA_EXPORT_ACCESS_EVENTS,
  ROLE_TEST_PROVIDER,
  ROLE_SUPER_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_DATA_ANALYST,
  ROLE_TEST_RECIPIENT_MANAGER,
  ROLE_ENTERPRISE_ADMIN,
  ROLE_ENTERPRISE_MANAGER,
  ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII,
  ROLE_ENTERPRISE_DATA_ANALYST
} from "const/roles";

const ADMING_GROUP = "Admins";

const createCognitoISP = jwtToken => {
  AWS.config.update({
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.Auth.identityPoolId,
      Logins: {
        [`cognito-idp.${config.Auth.region}.amazonaws.com/${config.Auth.userPoolId}`]: jwtToken
      }
    }),
    region: config.Auth.region
  });
  return new AWS.CognitoIdentityServiceProvider();
};

//const mapRolesAttribute = roles => ({
//  Name: "custom:roles",
//  Value: roles.join(",")
//});

const addUserToAdmins = (email, jwtToken) => {
  return new Promise((resolve, reject) => {
    createCognitoISP(jwtToken).adminAddUserToGroup(
      {
        UserPoolId: config.Auth.userPoolId,
        Username: email,
        GroupName: ADMING_GROUP
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        return resolve(data);
      }
    );
  });
};

const removeUserFromAdmins = (email, jwtToken) => {
  return new Promise((resolve, reject) => {
    createCognitoISP(jwtToken).adminRemoveUserFromGroup(
      {
        UserPoolId: config.Auth.userPoolId,
        Username: email,
        GroupName: ADMING_GROUP
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        return resolve(data);
      }
    );
  });
};

const updateUserAttributes = (username, attributes, jwtToken) => {
  return new Promise((resolve, reject) => {
    createCognitoISP(jwtToken).adminUpdateUserAttributes(
      {
        UserPoolId: config.Auth.userPoolId,
        Username: username,
        UserAttributes: attributes
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        return resolve(data);
      }
    );
  });
};

export const updatePhoneNumber = (username, phone_number, jwtToken) => {
  const attributes = [
    {
      Name: "phone_number",
      Value: phone_number
    }
  ];
  return updateUserAttributes(username, attributes, jwtToken);
};

const hasAdminRole = role => role.roleId === ROLE_TEST_PROVIDER_ADMIN;

const checkRolesChanged = (existingRoles, newRoles) =>
  existingRoles
    .map(r => r.roleId)
    .sort()
    .join() !==
  newRoles
    .map(r => r.roleId)
    .sort()
    .join();

const checkAdminRoleAdded = (existingRoles, newRoles) =>
  !existingRoles.find(hasAdminRole) && newRoles.find(hasAdminRole);

const checkAdminRoleRemoved = (existingRoles, newRoles) =>
  existingRoles.find(hasAdminRole) && !newRoles.find(hasAdminRole);

const getAdminRoleChangeAction = (existingRoles, newRoles) => {
  if (checkAdminRoleAdded(existingRoles, newRoles)) {
    return addUserToAdmins;
  } else if (checkAdminRoleRemoved(existingRoles, newRoles)) {
    return removeUserFromAdmins;
  }
};

export const updateUserRoles = async (
  username,
  jwtToken,
  existingRoles,
  newRoles
) => {
  if (checkRolesChanged(existingRoles, newRoles)) {
    const updateAdminStatus = getAdminRoleChangeAction(existingRoles, newRoles);
    if (updateAdminStatus) {
      return updateAdminStatus(username, jwtToken);
    } else {
      return Promise.resolve();
    }
  }
};

const adminRoles = [
  ROLE_TEST_PROVIDER_ADMIN,
  ROLE_EVENT_ADMIN,
  ROLE_DATA_EXPORT_TEST_RESULTS,
  ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII,
  ROLE_DATA_EXPORT_ACCESS_EVENTS,
  ROLE_SUPER_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_DATA_ANALYST
];

const enterpriseRoles = [
  ROLE_ENTERPRISE_ADMIN,
  ROLE_ENTERPRISE_MANAGER,
  ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII,
  ROLE_ENTERPRISE_DATA_ANALYST
]

export const createUser = (email, roles, jwtToken) => {
  return new Promise((resolve, reject) => {
    createCognitoISP(jwtToken).adminCreateUser(
      {
        UserPoolId: config.Auth.userPoolId,
        Username: email,
        UserAttributes: [
          {
            Name: "email",
            Value: email
          }
        ],

        ClientMetadata: {
          applicationId: APP_ID,
          userRole: roles.find(
            role => role.roleId === ROLE_TEST_RECIPIENT_MANAGER
          )
            ? ROLE_TEST_RECIPIENT_MANAGER
            :roles.some(role => enterpriseRoles.includes(role.roleId))
            ? ROLE_ENTERPRISE_ADMIN
            : roles.some(role => adminRoles.includes(role.roleId))
            ? ROLE_TEST_PROVIDER_ADMIN
            : ROLE_TEST_PROVIDER,
          locale: "en_us"
        }
      },
      (err, result) => {
        if (err) {
          return reject(err);
        }

        const user = result.User;

        const verifiedEmailAttribure = {
          Name: "email_verified",
          Value: "true"
        };

        return updateUserAttributes(
          email,
          [verifiedEmailAttribure],
          jwtToken
        ).then(() => {
          if (roles.find(role => role.roleId === ROLE_TEST_PROVIDER_ADMIN)) {
            return addUserToAdmins(email, jwtToken).then(() => {
              return resolve(user);
            });
          }
          return resolve(user);
        });
        //TODO: catch needed?
      }
    );
  });
};

export const resetPassword = (email, jwtToken) => {
  return new Promise((resolve, reject) => {
    createCognitoISP(jwtToken).adminSetUserPassword(
      {
        UserPoolId: config.Auth.userPoolId,
        Username: email,
        Password: "Pa$$W0rD",
        Permanent: false
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        return resolve(data);
      }
    );
  });
};

export const deleteUser = (email, jwtToken) => {
  return new Promise((resolve, reject) => {
    createCognitoISP(jwtToken).adminDeleteUser(
      {
        UserPoolId: config.Auth.userPoolId,
        Username: email
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        return resolve(data);
      }
    );
  });
};

export const clearAWSCache = () => {
  try {
    if (AWS.config.credentials) {
      AWS.config.credentials.clearCachedId();
    }
  } catch {}
};
