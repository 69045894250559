import React, { useRef } from "react";
import {
  Button,
  Icon,
  PageTitle,
  PatientContacts,
  PatientTestResults
} from "components";
import { initPrintView } from "utils/print";
import QrCode from "components/QrCode";
import { formatBirthDate } from "utils/formatData";
import { sha256 } from "js-sha256";
import "./patientDetails.scss";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/useLanguage";
import ComponentToPrint from "components/ComponentToPrint";
import { useReactToPrint } from 'react-to-print';

const PatientDetails = ({
  patient,
  getPatientProfileSurvey,
  countryPatientProfileSurvey,
  testTypeSurvey,
  testTypeSurveyIsLoading,
  getTestTypeSurvey,
  selectedPatientProfileSurvey,
  getTestResults,
  testResults,
  testResultsLoading,
  updatePatient,
  patientUpdating,
  saveTestTypeSurveyAnswers,
  testTypeSurveyIsSaving,
  countries,
  patientDeleted,
  deletedPatientData,
  isMember,
  cohorts
}) => {
  const { t } = useTranslation();
  const { flippedNameOrder } = useLanguage();
  const { profile } = patient;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  React.useEffect(initPrintView, []);

  const fullPhoneNumber =
    profile?.phoneNumber?.countryCode + profile?.phoneNumber?.number;

  const payload = {
    phoneNumber: fullPhoneNumber,
    zipCode: profile?.zipCode,
    firstName: profile?.firstName,
    userName: patient.username,
    dob: patient.profile?.dateOfBirth,
    countryOfResidence: patient.configuration?.country,
    patientId: patient.patientId,
    lastName: patient.profile?.lastName
  };

  const base64Payload = Buffer.from(JSON.stringify(payload)).toString("base64");

  let signature;
  patient.secretSalt
    ? (signature = sha256(base64Payload + patient.secretSalt))
    : (signature = "error");

  const lastname = patientDeleted
    ? deletedPatientData?.lastName
    : profile.lastName;
  const firstname = patientDeleted
    ? deletedPatientData?.firstName
    : profile.firstName;

  const title = `${
    flippedNameOrder ? `${lastname} ${firstname}` : `${firstname} ${lastname}`
  } ${patientDeleted ? `(${t("success_user_deleted")})` : ""}`;

  const deletedUser = deletedPatientData && {
    ...deletedPatientData,
    profile: deletedPatientData.patientProfile,
    configuration: {
      country: deletedPatientData.patientCountryOfResidence
    }
  };
  return (
    <div className="patient-details">
      <PageTitle title={title} />
      <hr className="devider" />

      <div className="information-container">
        <ComponentToPrint ref={componentRef}>
          <QrCode
            code={`{"qr-code-type": "patient", "version": "1","payload": "${base64Payload}","signature":"${signature}"}`}
            type={"testRecipient"}
            isPrint={true}
            data={{
              patientName: flippedNameOrder
                ? `${lastname} ${firstname}`
                : `${firstname} ${lastname}`,
              patientDateOfBirth: patientDeleted
                ? formatBirthDate(deletedPatientData.patientProfile.dob)
                : formatBirthDate(profile.dateOfBirth)
            }}
          ></QrCode>
        </ComponentToPrint>
        <div className="qr-code-contact-container">
          <div className="patient-details__qr-code">
            <QrCode
              code={`{"qr-code-type": "patient", "version": "1","payload": "${base64Payload}","signature":"${signature}"}`}
              onlyQrCode={true}
            />
            <div className="qr-actions">
              <Button
                data-testid="button-printer"
                variant="row-action"
                title={t("print")}
                onClick={e => {
                  e.preventDefault();
                  handlePrint();
                }}
              >
                <Icon type="printer" />
              </Button>
            </div>
          </div>

          <PatientContacts
            patient={patientDeleted ? deletedUser : patient}
            patientSurvey={selectedPatientProfileSurvey}
            countryPatientSurvey={countryPatientProfileSurvey}
            patientUpdating={patientUpdating}
            getPatientProfileSurvey={getPatientProfileSurvey}
            updatePatient={updatePatient}
            countries={countries}
            patientDeleted={patientDeleted}
            isMember={isMember}
          />
        </div>

        <PatientTestResults
          patient={patientDeleted ? deletedUser : patient}
          getTestResults={getTestResults}
          testResults={testResults}
          loading={testResultsLoading}
          countryPatientSurvey={countryPatientProfileSurvey}
          testTypeSurvey={testTypeSurvey}
          getTestTypeSurvey={getTestTypeSurvey}
          testTypeSurveyIsLoading={testTypeSurveyIsLoading}
          updatePatient={updatePatient}
          saveTestTypeSurveyAnswers={saveTestTypeSurveyAnswers}
          testTypeSurveyIsSaving={testTypeSurveyIsSaving}
          patientIsUpdating={patientUpdating}
          patientDeleted={patientDeleted}
          patientQRCode={`{"qr-code-type": "patient", "version": "1","payload": "${base64Payload}","signature":"${signature}"}`}
          isMember={isMember}
          cohorts={cohorts}
        />
      </div>

      <iframe
        title="ifmcontentstoprint"
        id="ifmcontentstoprint"
        style={{ visibility: "hidden", height: "10px" }}
      />
    </div>
  );
};

PatientDetails.propTypes = {};

export default PatientDetails;
