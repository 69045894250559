import {
  SET_PATIENT_PROFILE_SURVEY_DATA,
  SET_ORGANIZATION_PROFILE_SURVEY_DATA,
  SET_TEST_TYPE_SURVEY_DATA,
  SET_COHORT_PROFILE_SURVEY_DATA,
  UPDATE_PATIENT_PROFILE_SURVEY,
  UPDATE_ORGANIZATION_PROFILE_SURVEY,
  UPDATE_TEST_TYPE_SURVEY,
  UPDATE_COHORT_PROFILE_SURVEY,
  DELETE_PATIENT_PROFILE_SURVEY,
  DELETE_ORGANIZATION_PROFILE_SURVEY,
  DELETE_TEST_TYPE_SURVEY,
  DELETE_COHORT_PROFILE_SURVEY
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = {};

const surveyData = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PATIENT_PROFILE_SURVEY_DATA: {
      const { survey } = payload;
      return {
        ...state,
        patientProfileSurveyData: {
          ...survey
        }
      };
    }
    case SET_ORGANIZATION_PROFILE_SURVEY_DATA: {
      const { survey } = payload;
      return {
        ...state,
        organizationProfileSurveyData: {
          ...survey
        }
      };
    }
    case SET_TEST_TYPE_SURVEY_DATA: {
      const { survey } = payload;
      return {
        ...state,
        testTypeSurveyData: {
          ...survey
        }
      };
    }
    case SET_COHORT_PROFILE_SURVEY_DATA: {
      const { survey } = payload;
      return {
        ...state,
        cohortProfileSurveyData: {
          ...survey
        }
      };
    }
    case UPDATE_PATIENT_PROFILE_SURVEY: {
      const { patientProfileSurveyData } = state;
      const { survey } = payload;
      return {
        ...state,
        patientProfileSurveyData: {
          ...patientProfileSurveyData,
          survey: survey
        }
      };
    }
    case UPDATE_ORGANIZATION_PROFILE_SURVEY: {
      const { organizationProfileSurveyData } = state;
      const { survey } = payload;
      return {
        ...state,
        organizationProfileSurveyData: {
          ...organizationProfileSurveyData,
          survey: survey
        }
      };
    }
    case UPDATE_TEST_TYPE_SURVEY: {
      const { testTypeSurveyData } = state;
      const { survey } = payload;
      return {
        ...state,
        testTypeSurveyData: {
          ...testTypeSurveyData,
          survey: survey
        }
      };
    }
    case UPDATE_COHORT_PROFILE_SURVEY: {
      const { cohortProfileSurveyData } = state;
      const { survey } = payload;
      return {
        ...state,
        cohortProfileSurveyData: {
          ...cohortProfileSurveyData,
          survey: survey
        }
      };
    }
    case DELETE_PATIENT_PROFILE_SURVEY: {
      return {
        ...state,
        patientProfileSurveyData: {}
      };
    }
    case DELETE_ORGANIZATION_PROFILE_SURVEY: {
      return {
        ...state,
        organizationProfileSurveyData: {}
      };
    }
    case DELETE_TEST_TYPE_SURVEY: {
      return {
        ...state,
        testTypeSurveyData: {}
      };
    }
    case DELETE_COHORT_PROFILE_SURVEY: {
      return {
        ...state,
        cohortProfileSurveyData: {}
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default surveyData;
