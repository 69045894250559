import React from "react";
import Label from "components/Label";
import FormGroup from "components/FormGroup";
import Input from "components/Input";
import Error from "components/Error";
import { useTranslation } from "react-i18next";

function InputNumeric({ field, defaultValue, useForm: { errors, register } }) {
  const { t } = useTranslation();

  return (
    <FormGroup key={field.id}>
      <Label htmlFor={field.id} error={errors[field.id]}>
        {field.title}
        {field.required && "*"}
      </Label>
      <Input
        data-testid="numeric-input"
        type="number"
        step="any"
        name={field.id}
        error={errors[field.id]}
        ref={register({
          required: field.required && t("form_field_is_required"),
          maxLength: {
            value: 140,
            message: t("field_max_length", {
              number_of_characters: 140
            })
          }
        })}
        defaultValue={defaultValue}
      />
      {errors[field.id] && <Error>{errors[field.id].message}</Error>}
    </FormGroup>
  );
}

export default InputNumeric;
