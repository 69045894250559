/**
 * Used for description of private routes only
 *
 * Route format:
 * key – route path
 * value: {
 *   component
 *   layout
 *   roles – array of roles having acces to this route
 * }
 */

import {
  ROLE_SUPER_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_DATA_ANALYST,
  ROLE_TEST_PROVIDER_ADMIN,
  ROLE_EVERYONE,
  ROLE_EVENT_ADMIN,
  ROLE_DATA_EXPORT_TEST_RESULTS,
  ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII,
  ROLE_DATA_EXPORT_ACCESS_EVENTS,
  ROLE_TEST_RECIPIENT_MANAGER,
  ROLE_ENTERPRISE_ADMIN,
  ROLE_ENTERPRISE_DATA_ANALYST,
  ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII,
  ROLE_ENTERPRISE_MANAGER
} from "const/roles";

//TODO: this is nice option of defining config but it guarantees circular reference if any of underlying components includes logic dependent of routes
//Is there any better way? Or how to ensure correct components tree
import {
  Home,
  TestProfiles,
  Profile,
  About,
  Help,
  Users,
  Organizations,
  PageNotFound,
  AccessProfiles,
  TestDataExport,
  AccessDataExport,
  DataElements,
  HomeTestProfile,
  CountryExport,
  Patients,
  CountryManagement,
  SearchRecipients,
  CohortProfiles,
  CohortDataExport,
  Members,
  SearchMembers
} from "pages";
//TODO: refactor to pages
import {
  SignInForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  FlatLayout
} from "components";
import { DashboardLayout, SignOut } from "containers";

const routes = {
  "/sign-in": {
    component: SignInForm,
    layout: FlatLayout
  },
  "/sign-out": {
    component: SignOut,
    layout: FlatLayout
  },
  "/forgot-password": {
    component: ForgotPasswordForm,
    layout: FlatLayout
  },
  "/reset-password": {
    component: ResetPasswordForm,
    layout: FlatLayout
  },
  "/": {
    component: Home,
    layout: DashboardLayout,
    roles: [ROLE_EVERYONE],
    exact: true
  },
  "/test-profiles": {
    component: TestProfiles,
    layout: DashboardLayout,
    roles: [ROLE_TEST_PROVIDER_ADMIN],
    exact: true
  },
  "/cohort-profiles": {
    component: CohortProfiles,
    layout: DashboardLayout,
    roles: [ROLE_ENTERPRISE_ADMIN],
    exact: true
  },
  "/access-profiles": {
    component: AccessProfiles,
    layout: DashboardLayout,
    roles: [ROLE_EVENT_ADMIN],
    exact: true
  },
  "/users": {
    component: Users,
    layout: DashboardLayout,
    roles: [ROLE_TEST_PROVIDER_ADMIN, ROLE_EVENT_ADMIN, ROLE_ENTERPRISE_ADMIN]
  },
  "/organizations": {
    component: Organizations,
    layout: DashboardLayout,
    roles: [ROLE_SUPER_ADMIN]
  },
  "/countries": {
    component: CountryManagement,
    layout: DashboardLayout,
    roles: [ROLE_SUPER_ADMIN]
  },
  "/test-data-export": {
    component: TestDataExport,
    layout: DashboardLayout,
    roles: [
      ROLE_SUPER_ADMIN,
      ROLE_DATA_EXPORT_TEST_RESULTS,
      ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII
    ],
    exact: true
  },
  "/access-data-export": {
    component: AccessDataExport,
    layout: DashboardLayout,
    roles: [ROLE_SUPER_ADMIN, ROLE_DATA_EXPORT_ACCESS_EVENTS],
    exact: true
  },
  "/cohort-data-export": {
    component: CohortDataExport,
    layout: DashboardLayout,
    roles: [
      ROLE_ENTERPRISE_DATA_ANALYST,
      ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII
    ],
    exact: true
  },
  "/country-analyst-export": {
    component: CountryExport,
    layout: DashboardLayout,
    roles: [ROLE_COUNTRY_DATA_ANALYST],
    exact: true
  },
  "/data-elements": {
    component: DataElements,
    layout: DashboardLayout,
    roles: [ROLE_COUNTRY_ADMIN],
    exact: true
  },
  "/home-test-profile": {
    component: HomeTestProfile,
    layout: DashboardLayout,
    roles: [ROLE_COUNTRY_ADMIN],
    exact: true
  },
  "/test-recipients": {
    component: Patients,
    layout: DashboardLayout,
    roles: [ROLE_TEST_RECIPIENT_MANAGER],
    exact: true
  },
  "/search-test-recipients": {
    component: SearchRecipients,
    layout: DashboardLayout,
    roles: [ROLE_TEST_RECIPIENT_MANAGER],
    exact: true
  },
  "/members": {
    component: Members,
    layout: DashboardLayout,
    roles: [ROLE_ENTERPRISE_MANAGER],
    exact: true
  },
  "/search-members": {
    component: SearchMembers,
    layout: DashboardLayout,
    roles: [ROLE_ENTERPRISE_MANAGER],
    exact: true
  },
  "/about": {
    component: About,
    layout: DashboardLayout,
    roles: [ROLE_EVERYONE]
  },
  "/help": {
    component: Help,
    layout: DashboardLayout,
    roles: [ROLE_EVERYONE]
  },
  "/profile": {
    component: Profile,
    layout: DashboardLayout,
    roles: [ROLE_EVERYONE]
  },
  "*": {
    component: PageNotFound,
    layout: FlatLayout
  }
};

export { routes as default };
