import React from "react";
import CohortProfilesContainer from "../../containers/CohortProfiles.Container";

const CohortProfiles = () => (
  <>
    <CohortProfilesContainer />
  </>
);

export default CohortProfiles;
