import React from "react";
import PatientsContainer from "../../containers/Patients.Container";

const Members = () => (
  <>
    <PatientsContainer location={"members"}/>
  </>
);

export default Members;
