import phoneImage from "assets/images/qrPhone1.png";
import Button from "components/Button";
import ConfirmPopup from "components/ConfirmPopup";
import EditableCell from "components/EditableCell";
import FloatNavbar from "components/FloatNavbar";
import Icon from "components/Icon";
import PageTitle from "components/PageTitle";
import QrCodeDetails from "components/QrCodeDetails";
import Spinner from "components/Spinner";
import Table from "components/Table";
import { ROLE_TEST_PROVIDER_ADMIN } from "const/roles";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import React from "react";
import useSticky from "../../utils/useSticky";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import RouteLeavingGuard from "components/RouteLeavingGuard";
import { sortByIsAdmin, sortIgnoreCase } from "utils/sortMethods";

import TestProfileForm from "../TestProfileForm/TestProfileForm";
import { initPrintView } from "utils/print";
import "./TestProfiles.scss";
import { useTranslation } from "react-i18next";

const defaultColumn = {
  Cell: EditableCell
};

const TestProfiles = ({
  roles,
  qrCodes,
  getTestingProfiles,
  testingProfilesLoading,
  getTestingProfileDetails,
  qrCodeDetails,
  testingProfileDetailsLoading,
  updateMetaData,
  setTestingProfileForEdit,
  setTestingProfileDataForEdit,
  saveContactData,
  saveProfileData,
  cancelUpdate,
  createTestingProfile,
  testingProfileSaving,
  testingProfileUpdating,
  deleteTestingProfile,
  testingProfileDeleting,
  duplicateTestingProfile,
  fieldErrors,
  history,
  createInfinityIntegration
}) => {
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showUnsavedChangesModal, setshowUnsavedChangesModal] = React.useState(
    false
  );
  const [confirmConfig, setConfirmConfig] = React.useState({});
  const [unsavedConfirmConfig, setUnsavedConfirmConfig] = React.useState({});
  const [selectedTestProfile, setSelectedTestProfile] = React.useState({});
  const { isSticky, element } = useSticky();
  const isEditing = qrCodeDetails?.isEditing?.length ? true : false;

  React.useEffect(() => {
    getTestingProfiles();
  }, [getTestingProfiles]);

  React.useEffect(initPrintView, []);

  //this needs to be refactored with the form
  const checkIfEqual = () => {
    if (!qrCodeDetails.hasOwnProperty("newMetaData")) {
      return true;
    } else {
      return _.isEqual(
        qrCodeDetails.newMetaData,
        qrCodeDetails.originalMetaData
      );
    }
  };

  const isEqual = checkIfEqual();

  const addTestingProfile = React.useCallback(
    async ({ name, description, ...rest }) => {
      await createTestingProfile({ name, description, ...rest });
      hideAddTestingProfileForm();
    },

    [createTestingProfile]
  );

  const hideAddTestingProfileForm = () => {
    setShowAddModal(false);
  };

  const onConfirmDeleteTestProfile = React.useCallback(
    async id => {
      await deleteTestingProfile(id);
      setShowDeleteModal(false);
      setTestingProfileForEdit(null);
    },
    [deleteTestingProfile, setTestingProfileForEdit]
  );

  const onConfirmUnsavedChanges = React.useCallback(
    async row => {
      await cancelUpdate();
      setshowUnsavedChangesModal(false);
      setSelectedTestProfile(row);
      getTestingProfileDetails(row.testingProfileId);
    },
    [cancelUpdate, getTestingProfileDetails]
  );

  const unsavedConfig = React.useCallback(
    row => {
      setUnsavedConfirmConfig({
        title: t("unsaved_changes_title"),
        message: t("unsaved_changes_description"),
        onConfirm: () => onConfirmUnsavedChanges(row)
      });
      setshowUnsavedChangesModal(true);
    },
    [onConfirmUnsavedChanges, t]
  );

  const deleteTestProfile = React.useCallback(
    async row => {
      const profile = qrCodes.find(
        u => u.testingProfileId === row.testingProfileId
      );
      if (
        isEqual ||
        qrCodeDetails.qrCode === row.testingProfileId ||
        (isEqual && qrCodeDetails.qrCode === row.testingProfileId)
      ) {
        setConfirmConfig({
          title: t("confirm_delete_action"),
          message: t("confirm_delete_name", { name: `${profile.name}` }),
          onConfirm: () => onConfirmDeleteTestProfile(row.testingProfileId)
        });
        setShowDeleteModal(true);
      } else {
        unsavedConfig(row);
      }
    },
    [
      isEqual,
      onConfirmDeleteTestProfile,
      qrCodes,
      unsavedConfig,
      qrCodeDetails,
      t
    ]
  );

  const onDuplicate = React.useCallback(
    async row => {
      if (isEqual) {
        duplicateTestingProfile(row.testingProfileId);
      } else {
        unsavedConfig(row);
      }
    },
    [isEqual, duplicateTestingProfile, unsavedConfig]
  );

  const onSave = React.useCallback(() => {
    updateMetaData();
  }, [updateMetaData]);

  const onCancel = React.useCallback(() => {
    cancelUpdate();
  }, [cancelUpdate]);

  const update = React.useCallback(
    async (id, value) => {
      await saveProfileData(id, value);
    },
    [saveProfileData]
  );

  const onEdit = React.useCallback(
    async row => {
      if (_.isEmpty(qrCodeDetails) || isEqual) {
        setSelectedTestProfile(row);
        setTestingProfileForEdit(row.testingProfileId);
        await getTestingProfileDetails(row.testingProfileId);
        setTestingProfileDataForEdit("ADD", "profile");
      } else {
        if (qrCodeDetails.qrCode === row.testingProfileId) {
          setTestingProfileForEdit(row.testingProfileId);
          return;
        }
        unsavedConfig(row);
        return;
      }
    },
    [
      isEqual,
      setTestingProfileForEdit,
      setTestingProfileDataForEdit,
      getTestingProfileDetails,
      unsavedConfig,
      qrCodeDetails
    ]
  );

  const onRowClick = React.useCallback(
    async row => {
      if (_.isEmpty(qrCodeDetails) || isEqual) {
        setSelectedTestProfile(row);
        getTestingProfileDetails(row.testingProfileId);
        if (qrCodeDetails.qrCode !== row.testingProfileId) {
          setTestingProfileForEdit(null);
        } else {
          return;
        }
      } else {
        if (qrCodeDetails.qrCode === row.testingProfileId) {
          return;
        }
        unsavedConfig(row);
      }
    },
    [
      isEqual,
      setTestingProfileForEdit,
      qrCodeDetails,
      unsavedConfig,
      getTestingProfileDetails
    ]
  );

  const qrCodesColumns = [
    {
      Header: t("combo_test"),
      accessor: "isComboTestProfile",
      style: {
        minWidth: "50px"
      },
      sortType: sortByIsAdmin,
      Cell: ({ value }) => {
        return value ? <Icon type="check" /> : null;
      }
    },
    {
      Header: () => (
        <div className="header-content">
          <div>{t("qr_code")}</div> <span className="badge">1</span>
        </div>
      ),
      accessor: "qrCode",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="qr-codes__code-cell">
            <div className="qr-code__image">
              <QRCode
                value={row.original.testingProfileId}
                size={30}
                renderAs={"svg"}
                level={"H"}
                includeMargin={true}
              />
            </div>
          </div>
        );
      }
    },
    {
      Header: () => (
        <div className="header-content">
          <div>{t("name")}</div> <span className="badge">2</span>
        </div>
      ),
      accessor: "name",
      style: {
        width: "25%"
      },
      className: "qr-codes__name",
      sortType: sortIgnoreCase
    },
    {
      Header: t("description"),
      accessor: "description",
      style: {
        width: "65%",
        minWidth: "80px"
      },
      className: "qr-codes__description",
      disableSortBy: true
    },
    {
      Header: t("health_care_organizations_actions"),
      style: {
        minWidth: "60px"
      },
      className: "qr-codes__actions-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <React.Fragment>
            <Button
              variant="row-action"
              title={t("health_care_organizations_actions_edit")}
              onClick={e => {
                e.stopPropagation();
                onEdit(row.original);
              }}
            >
              <Icon type="edit" />
            </Button>
            {roles && roles.includes(ROLE_TEST_PROVIDER_ADMIN) && (
              <Button
                variant="row-action"
                title={t("duplicate")}
                onClick={e => {
                  e.stopPropagation();
                  onDuplicate(row.original);
                }}
              >
                <Icon type="duplicate" />
              </Button>
            )}
            <Button
              variant="row-action"
              title={t("health_care_organizations_users_actions_delete")}
              onClick={e => {
                e.stopPropagation();
                deleteTestProfile(row.original);
              }}
            >
              <Icon type="trash" />
            </Button>
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <div className="button-container">
        <Button
          variant="primary"
          className="button header-add-button"
          onClick={() => setShowAddModal(true)}
          disabled={isEditing}
          ref={element}
        >
          {t("create_test_profile")}
        </Button>
        <Button
          variant="secondary"
          className="button header-add-button"
          disabled={!isEditing}
          onClick={onCancel}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="primary"
          className="button header-add-button"
          disabled={
            !isEditing || Object.values(fieldErrors).filter(Boolean).length
          }
          onClick={onSave}
          loading={testingProfileUpdating}
        >
          {t("save")}
        </Button>
      </div>
      {isSticky && isEditing && (
        <FloatNavbar
          onSave={onSave}
          onCancel={onCancel}
          loading={testingProfileUpdating}
          saveDisabled={
            !isEditing || Object.values(fieldErrors).filter(Boolean).length
          }
          cancelDisabled={!isEditing}
        />
      )}
      {showAddModal && (
        <TestProfileForm
          onSubmit={addTestingProfile}
          loading={testingProfileSaving}
          hide={() => setShowAddModal(false)}
          type="testProfiles"
        />
      )}
      <PageTitle title={t("test_profiles")} />

      {testingProfilesLoading ? (
        <div className="profiles">
          <Spinner marginTop={15} />
        </div>
      ) : (
        <div className="table-container">
          <img src={phoneImage} alt="phone" className="phone-image-1" />
          <Table
            globalFilter
            pagination
            defaultColumn={defaultColumn}
            pageSize={10}
            columns={qrCodesColumns}
            data={qrCodes}
            selectedRowIndex={qrCodes.findIndex(item => {
              return (
                item.testingProfileId === selectedTestProfile?.testingProfileId
              );
            })}
            className="qr-codes-table"
            updateMyData={update}
            onRowClick={onRowClick}
          />
        </div>
      )}
      {showDeleteModal && (
        <ConfirmPopup
          title={confirmConfig.title}
          message={confirmConfig.message}
          visible={showDeleteModal}
          cancel={() => setShowDeleteModal(false)}
          confirm={confirmConfig.onConfirm}
          loading={testingProfileDeleting}
        />
      )}
      {showUnsavedChangesModal && (
        <ConfirmPopup
          title={unsavedConfirmConfig.title}
          message={unsavedConfirmConfig.message}
          visible={showUnsavedChangesModal}
          cancel={() => setshowUnsavedChangesModal(false)}
          confirm={unsavedConfirmConfig.onConfirm}
          loading={testingProfileSaving}
        />
      )}
      {testingProfileDetailsLoading ? (
        <Spinner marginTop={5} />
      ) : (
        qrCodeDetails.qrCode && (
          <>
            <PageTitle
              title={qrCodeDetails.metaData.name}
              className="qr-details-header"
            />
            <QrCodeDetails
              {...qrCodeDetails.metaData}
              createInfinityIntegration={createInfinityIntegration}
              selectedTestingProfile={qrCodeDetails}
              setTestingProfileDataForEdit={setTestingProfileDataForEdit}
              testingProfileUpdating={testingProfileUpdating}
              saveContactData={saveContactData}
            />
          </>
        )
      )}
      <iframe
        title="ifmcontentstoprint"
        id="ifmcontentstoprint"
        style={{ visibility: "hidden", height: "10px" }}
      />
      <RouteLeavingGuard
        when={!isEqual}
        navigate={path => history.push(path)}
      />
    </React.Fragment>
  );
};

TestProfiles.propTypes = {
  qrCodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  testingProfilesLoading: PropTypes.bool,
  qrCodeSaving: PropTypes.bool
};

export { TestProfiles };

export default withRouter(TestProfiles);
