import axios from "utils/Axios";
import { makeAxiosRequest } from "utils/requests";

export const getCohortProfiles = () => {
  return makeAxiosRequest("getCohortProfiles", {
    client: axios,
    method: "get",
    relativePath: "/cohorts"
  });
};

export const createCohortProfile = ({ name, description }) => {
  let contactDetails = {};
  return makeAxiosRequest("createCohortProfile", {
    client: axios,
    method: "post",
    relativePath: "/cohorts",
    data: { name, description, contactDetails }
  });
};

export const getCohortProfileData = id => {
  return makeAxiosRequest("getCohortProfileData", {
    client: axios,
    method: "get",
    relativePath: `/cohorts/${id}`
  });
};

export const updateCohortProfile = data => {
  return makeAxiosRequest("updateCohortProfile", {
    client: axios,
    method: "put",
    relativePath: `/cohorts/${data.cohortId}`,
    data: {
      name: data.name,
      description: data.description,
      contactDetails: data.contactDetails,
      properties: data.properties
    }
  });
};
