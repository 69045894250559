import React from "react";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import Form from "components/Form";
import Wrapper from "components/Wrapper";
import Title from "components/Title";
import Label from "components/Label";
import Input from "components/Input";
import Button from "components/Button";
import Link from "components/Link";
import Error from "components/Error";
import FormGroup from "components/FormGroup";
import { useValidation } from "utils/useValidation";
import "./forgotPasswordForm.scss";
import { ColorContext } from "components/FlatLayout/FlatLayout";
import { secondaryBackgroundColor } from "const/colorCodes";
import { APP_ID } from "const/app";
import { ROLE_TEST_PROVIDER } from "const/roles";
import { useTranslation } from "react-i18next";

function ForgotPasswordForm() {
  const { t } = useTranslation();
  const [username, setUsername] = React.useState("");
  const [redirect, setRedirectStatus] = React.useState(false);
  const [loading, setLoadingStatus] = React.useState(false);
  const { register, handleSubmit, formState, errors, setError } = useForm({
    mode: "onBlur"
  });
  const { emailValidation, noRomanCharacter } = useValidation();
  const { changeBgColor } = React.useContext(ColorContext);

  React.useEffect(() => {
    changeBgColor(secondaryBackgroundColor);
  }, [changeBgColor]);

  const onSubmit = ({ username }) => {
    if (formState.isValid) {
      setUsername(username);
      setLoadingStatus(true);

      Auth.forgotPassword(username, {
        applicationId: APP_ID,
        locale: "en_us",
        userRole: ROLE_TEST_PROVIDER
      })
        .then(() => {
          setRedirectStatus(true);
        })
        .catch(err => {
          setError("username", "invalid", "Email was not found");
          setLoadingStatus(false);
        });
    }
  };

  return (
    <Wrapper className="forgot-password-form">
      <Title className="no-margin">{t("forgot_password")}</Title>
      <p className="user-tip">{t("forgot_password_description")} </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="username" error={errors.username}>
            {t("email")}
          </Label>
          <Input
            type="text"
            name="username"
            error={errors.username}
            onChange={noRomanCharacter}
            ref={register({
              required: t("email_error_required"),
              validate: { emailValidation },
              maxLength: {
                value: 128,
                message: t("email_too_long")
              }
            })}
          />
          {errors.username && <Error>{errors.username.message}</Error>}
        </FormGroup>
        <Button
          variant="primary-full-width"
          className="margin-top--large"
          type="submit"
          loading={loading}
        >
          {t("send")}
        </Button>
        <Link to="/sign-in" className="margin-top sign-in-link">
          {t("sign_in")}
        </Link>
      </Form>
      {redirect && (
        <Redirect
          to={{
            pathname: "/reset-password",
            state: { username }
          }}
        />
      )}
    </Wrapper>
  );
}

export default ForgotPasswordForm;
