import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import AccessProfiles from "components/AccessProfiles";
import {
  getAccessProfiles,
  createAccessProfile,
  deleteAccessProfile
} from "store/reducers/accessProfiles/services";
import {
  duplicateAccessProfile,
  getAccessProfileDetails,
  updateMetaData,
  cancelUpdate
} from "store/reducers/accessProfileData/services";

const mapStateToProps = state => ({
  accessProfiles: state.accessProfiles,
  accessProfilesLoading: isLoading(state, "getAccessProfiles"),
  accessProfileUpdating: isLoading(state, "updateAccessProfile"),
  accessProfileDetails: state.accessProfileData,
  accessProfileDetailsLoading: isLoading(state, "getAccessProfileDetails"),
  roles: state.user.roles,
  accessProfileSaving: isLoading(state, "createAccessProfile"),
  accessProfileDeleting: isLoading(state, "deleteAccessProfile"),
  fieldErrors: state?.accessProfileData?.metaData?.fieldErrors || {},
  defaultTestTypeList: state.config.testTypes
});

const mapDispatchToProps = {
  getAccessProfiles,
  createAccessProfile,
  deleteAccessProfile,
  duplicateAccessProfile,
  getAccessProfileDetails,
  updateMetaData,
  cancelUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessProfiles);
