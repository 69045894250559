import {
  SET_CONFIG,
  SET_ABOUT_CONFIG,
  SET_HTTP_ERROR,
  SET_DEFAULT_TEST_CONFIG,
  SET_TEST_TYPE_LIST,
  SET_COUNTRIES
} from "./types";

export const setConfig = data => ({
  type: SET_CONFIG,
  payload: {
    data
  }
});

export const setHttpError = (status, error) => ({
  type: SET_HTTP_ERROR,
  payload: {
    status,
    error
  }
});

export const setAboutConfig = data => ({
  type: SET_ABOUT_CONFIG,
  payload: data
});

export const setDefaultTestConfig = data => ({
  type: SET_DEFAULT_TEST_CONFIG,
  payload: data
});

export const setTestTypeList = data => ({
  type: SET_TEST_TYPE_LIST,
  payload: data
});

export const setCountries = data => ({
  type: SET_COUNTRIES,
  payload: data
});
