import React from "react";
import PropTypes from "prop-types";
import PageTitle from "components/PageTitle";
import Table from "components/Table";
import Button from "components/Button";
import EditContactForm from "components/EditContactForm";
import Spinner from "components/Spinner";
import "./organizationContacts.scss";
import { useTranslation } from "react-i18next";
import { DATE, DATETIME} from "const/surveyTypes";
import {formatDate, formatDateTimeNumeric} from "utils/formatData";

const OrganizationContacts = ({
  institutionPhoneNumber,
  institutionAddress,
  institutionWebLink,
  subTitle,
  institutionName,
  contactUpdating,
  updateContacts,
  organizationExtendedProfile,
  questions,
  getOrganizationProfileSurvey,
  organizationSurveyLoading
}) => {
  const { t } = useTranslation();
  const [channelForm, setChannelFormStatus] = React.useState(false);

  React.useEffect(() => {
    getOrganizationProfileSurvey();
  }, [getOrganizationProfileSurvey]);

  const contacts =
    questions && Object.keys(questions).length
      ? [
          {
            id: "institutionAddress",
            type: t("email"),
            data: institutionAddress
          },
          {
            id: "institutionWebLink",
            type: t("website"),
            data: institutionWebLink
          },
          {
            id: "institutionPhoneNumber",
            type: t("phone_number"),
            data: institutionPhoneNumber
          },
          ...questions?.survey?.questions?.map(item => {
            let found = organizationExtendedProfile?.surveyAnswers.find(
              it => it.id === item.id
            );
            let foundHasValues = found?.answers
              ?.map(ans => ans?.value)
              .filter(Boolean).length;
            if (organizationExtendedProfile?.surveyId === questions.surveyId)
              return {
                id: item.id,
                type: item.title,
                data:
                  found && foundHasValues ? (
                    found.answers.map(answer => (
                      <p key={answer.id}>{
                        answer.type === DATE 
                          ? formatDate(answer.value) 
                          : answer.type === DATETIME
                          ? formatDateTimeNumeric(answer.value)
                          : answer.value
                        }</p>
                    ))
                  ) : (
                    <p style={{ color: "red" }} key={item.id}>
                      {item.hint}
                    </p>
                  )
              };
            return {
              id: item.id,
              type: item.title,
              data: (
                <p style={{ color: "red" }} key={item.id}>
                  {item.hint}
                </p>
              )
            };
          })
        ]
      : [
          {
            id: "institutionAddress",
            type: t("email"),
            data: institutionAddress
          },
          {
            id: "institutionWebLink",
            type: t("website"),
            data: institutionWebLink
          },
          {
            id: "institutionPhoneNumber",
            type: t("phone_number"),
            data: institutionPhoneNumber
          }
        ];

  const update = React.useCallback(
    async (
      institutionPhoneNumber,
      institutionAddress,
      institutionWebLink,
      response,
      institutionName
    ) => {
      await updateContacts(
        institutionPhoneNumber,
        institutionAddress,
        institutionWebLink,
        response,
        institutionName
      );
      setChannelFormStatus(false);
    },
    [updateContacts]
  );

  const contactColumns = React.useMemo(
    () => [
      {
        className: "channels",
        accessor: "type"
      },
      {
        accessor: "data"
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <Button
        variant="primary"
        className="header-add-button"
        onClick={() => {
          setChannelFormStatus(true);
        }}
      >
        {t("edit")}
      </Button>
      <PageTitle title={t("admin_organization_information")} />
      <br />
      {organizationSurveyLoading ? (
        <Spinner marginTop="2" />
      ) : (
        <Table
          columns={contactColumns}
          data={contacts}
          className="organization-contacts-table"
          disabledSort={true}
        />
      )}
      {channelForm && (
        <EditContactForm
          hide={() => setChannelFormStatus(false)}
          onSubmit={update}
          loading={contactUpdating}
          institutionAddress={institutionAddress}
          institutionWebLink={institutionWebLink}
          institutionPhoneNumber={institutionPhoneNumber}
          institutionName={institutionName}
          additionalFields={questions}
          organizationExtendedProfile={organizationExtendedProfile}
        />
      )}
    </React.Fragment>
  );
};

OrganizationContacts.propTypes = {
  institutionAddress: PropTypes.string,
  institutionWebLink: PropTypes.string,
  institutionPhoneNumber: PropTypes.string,
  contactUpdating: PropTypes.bool,
  updateContacts: PropTypes.func.isRequired
};

export default OrganizationContacts;
