import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const backendOptions = {
  loadPath: "/translations/{{lng}}.json"
};

const chineseLanguages = ["zh-HK", "zh-MO", "zh-TW", "zh_HK", "zh_MO", "zh_TW"];

const gbLanguages = [
  "en-GB",
  "en-SG",
  "en-PW",
  "en-AU",
  "en_GB",
  "en_SG",
  "en_PW",
  "en_AU"
];

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    // see https://www.i18next.com/principles/fallback
    fallbackLng: code => {
      if (!code || code === "en") return ["en"];
      const fallbacks = [code];

      // We maintain en-US and en-GB. Some regions will prefer en-GB.
      if (code.startsWith("en-") && gbLanguages.includes(code)) {
        fallbacks.push("en_GB");
      }
      //We maintain zh-TW but only for traditional Chinese
      if (
        (code.startsWith("zh") && code.includes("Hant")) ||
        chineseLanguages.includes(code)
      ) {
        fallbacks.push("zh_TW");
      }
      // add pure lang
      const langPart = code.split("-")[0];
      if (langPart !== code) fallbacks.push(langPart);

      // finally, default language
      fallbacks.push("en");
      return fallbacks;
    },
    backend: backendOptions,
    react: { useSuspense: true },
    load: "currentOnly"
  });

export default i18n;
