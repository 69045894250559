import React from "react";
import Label from "components/Label";
import FormGroup from "components/FormGroup";
import Error from "components/Error";
import Icon from "components/Icon";
import { getDateFormatString, languageMapping } from "utils/formatData";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "const/app";

function InputDate({ field, useForm: { errors, control, triggerValidation }, defaultValue, type }) {
  const { t } = useTranslation();
  return (
    <FormGroup key={field.id}>
      <Label htmlFor={field.id} error={errors[field.id]}>
        {field.title}
        {field.required && "*"}
      </Label>
      <Icon type="calendar" />
      <Controller
        as={ReactDatePicker}
        control={control}
        valueName="selected"
        onChange={([selected]) => selected}
        onBlur={() => triggerValidation(field.id)}
        name={field.id}
        className={
          errors[field.id] ? "date-picker--error" : "date-picker"
        }
        placeholderText={getDateFormatString(LANGUAGE)}
        autoComplete="off"
        locale={languageMapping[LANGUAGE] || LANGUAGE}
        dateFormat={type === "DATE" ? "P" : "Pp"}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        defaultValue={defaultValue && new Date(defaultValue)}
        rules={{
          required: field.required && t("form_field_is_required")
        }}
        showTimeSelect={type !== "DATE"}
      />
      { errors[field.id] && <Error>{ errors[field.id].message}</Error>}
    </FormGroup>
  );
}

export default InputDate;
