import React from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import Error from "components/Error";
import { ModalActions } from "components/Modal";
import { useValidation } from "utils/useValidation";
import { useTranslation } from "react-i18next";
import { registerLocale } from "react-datepicker";
import { LANGUAGE } from "const/app";
import { languageMapping } from "utils/formatData";
import {
  SINGLE_ANSWER, 
  MULTI_ANSWERS,
  BUTTON,
  DATE,
  DATETIME
} from "const/surveyTypes";
import moment from "moment";
import {formatDateString} from "utils/formatData"
import { mapFieldsToInputs } from "utils/mapFieldsToInput";

registerLocale(LANGUAGE, languageMapping?.[LANGUAGE]);

export default function EditContactForm(props) {
  const {
    hide,
    loading,
    institutionPhoneNumber,
    institutionAddress,
    institutionWebLink,
    additionalFields,
    organizationExtendedProfile,
    institutionName
  } = props;
  const { t } = useTranslation();
  const myUseForm = useForm({
    mode: "onChange",
    defaultValues: {
      institutionPhoneNumber,
      institutionAddress,
      institutionWebLink
    }
  });
  const { register, handleSubmit, formState, errors } = myUseForm;

  const {
    emailValidation,
    phoneNumberValidation,
    noRomanCharacter,
    numbersOnlyIgnoreFirstCharacter
  } = useValidation();

  const { isValid } = formState;

  const onSubmit = ({
    institutionPhoneNumber,
    institutionAddress,
    institutionWebLink,
    ...rest
  }) => {
    let answers = Object.keys(rest).map(key => {
      let question = additionalFields.survey.questions.find(q => q.id === key);
      let answer;

      if (question?.type === SINGLE_ANSWER) {
        answer = question.answers.find(ans => ans.id === rest[key]);
      } else if (question?.type === MULTI_ANSWERS) {
        answer = question.answers.filter(ans => rest[key]?.includes(ans.id));
      } else answer = question?.answers[0];

      return {
        id: question.id,
        fieldName: question.fieldName,
        title: question.title,
        answers: Array.isArray(rest[key])
          ? rest[key].map(arr => answer.find(ans => ans.id === arr))
          : answer?.type === BUTTON
          ? [{ ...answer }]
          : [{ ...answer, value: answer?.type === DATE || answer?.type === DATETIME ? moment(rest[key]).format(formatDateString(answer.type)) : rest[key] }]
      };
    });

    let response = {
      ...organizationExtendedProfile,
      surveyAnswers: answers,
      surveyId: additionalFields.surveyId,
      surveyVersion: additionalFields.surveyVersion
    };
    
    //if there are no additional fields/request fails, send the param as null
    let extendedProfile = Object.keys(additionalFields).length
      ? response
      : null;

    if (isValid) {
      props.onSubmit(
        institutionPhoneNumber,
        institutionAddress,
        institutionWebLink,
        extendedProfile,
        institutionName
      );
    }
  };

  return (
    <Modal visible={true}>
      <Title>{t("admin_update_contact")}</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="institutionAddress" error={errors.institutionAddress}>
            {t("admin_update_contact_email")}*
          </Label>
          <Input
            type="text"
            name="institutionAddress"
            error={errors.institutionAddress}
            onChange={noRomanCharacter}
            ref={register({
              required: t("form_field_is_required"),
              validate: { emailValidation },
              maxLength: {
                value: 128,
                message: t("field_max_length", { number_of_characters: 128 })
              }
            })}
          />
          {errors.institutionAddress && (
            <Error>{errors.institutionAddress.message}</Error>
          )}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="institutionWebLink" error={errors.institutionWebLink}>
            {t("website")}*
          </Label>
          <Input
            type="text"
            name="institutionWebLink"
            error={errors.institutionWebLink}
            ref={register({
              required: t("form_field_is_required"),
              maxLength: {
                value: 128,
                message: t("field_max_length", { number_of_characters: 128 })
              }
            })}
          />
          {errors.institutionWebLink && (
            <Error>{errors.institutionWebLink.message}</Error>
          )}
        </FormGroup>
        <FormGroup>
          <Label
            htmlFor="institutionPhoneNumber"
            error={errors.institutionPhoneNumber}
          >
            {t("phone_number")}*
          </Label>
          <Input
            type="text"
            name="institutionPhoneNumber"
            error={errors.institutionPhoneNumber}
            onChange={noRomanCharacter}
            ref={register({
              required: t("user_profile_phone_number_invalid"),
              validate: {
                numbersOnlyIgnoreFirstCharacter,
                phoneNumberValidation
              },
              minLength: {
                value: 3,
                message: t("user_profile_phone_number_invalid")
              },
              maxLength: {
                value: 18,
                message: t("user_profile_phone_number_invalid")
              }
            })}
          />
          {errors.institutionPhoneNumber && (
            <Error>{errors.institutionPhoneNumber.message}</Error>
          )}
        </FormGroup>

        {additionalFields?.survey?.questions?.map(field => mapFieldsToInputs(field, organizationExtendedProfile, additionalFields, myUseForm))}
        <ModalActions>
          <Button
            variant="primary-full-width"
            className="margin-top--medium"
            type="submit"
            loading={loading}
            disabled={!formState.isValid || !formState.dirty || loading}
          >
            {t("save")}
          </Button>
          <Button variant="cancel-text" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
