import React from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import Error from "components/Error";
import { ModalActions } from "components/Modal";
import { noWhiteSpacesForRequiredFields } from "utils/FieldValidationRules";
import SelectOption from "components/SelectOption";
import { useTranslation } from "react-i18next";
import { checkIfTestTypeAlreadyUsed } from "utils/mappers";

import "./TestProfileForm.scss";

export default function TestProfileForm(props) {
  const {
    hide = () => {},
    name = "",
    description = "",
    loading,
    type,
    testType,
    testTypeList,
    isCombo,
    alreadyAddedTestTypes
  } = props;
  const { t } = useTranslation();
  const [comboEnabled, setComboEnabled] = React.useState(false);

  const labTypeList = testTypeList?.filter(
    ({ value }) => !value.includes("HOME_TEST")
  );

  const title =
    type === "testProfiles"
      ? t("add_test_profile")
      : type === "accessProfiles"
      ? t("add_access_profile")
      : t("add_test_type");

  const { register, handleSubmit, formState, errors, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      name,
      description,
      combo: type === "testProfiles" ? comboEnabled : null,
      testType: type !== "testProfiles" ? testType : null
    }
  });

  const onSubmit = params => {
    if (formState.isValid) {
      props.onSubmit({ ...params });
    }
  };

  const handleTestTypeChange = value => {
    setValue("testType", value.value, true);
  };

  React.useEffect(() => {
    if (type === "testTypes") {
      register(
        { name: "testType" },
        {
          validate: v => {
            return (v && v.length > 0) || t("label_copy_3");
          }
        }
      );
    }
  }, [register, type, t]);

  if (type === "accessProfiles") {
    return (
      <Modal visible={true} className="add">
        <Title>{title}</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label htmlFor="name">{t("name")}</Label>
            <Input
              type="text"
              name="name"
              error={errors.name}
              ref={register({
                validate: noWhiteSpacesForRequiredFields,
                required: t("form_field_is_required"),
                maxLength: {
                  value: 128,
                  message: t("field_max_length", { number_of_characters: 128 })
                }
              })}
            />
            {errors.name && <Error>{errors.name.message}</Error>}
          </FormGroup>
          <FormGroup>
            <Label error={errors.description} htmlFor="description">
              {t("description")}
            </Label>
            <Input
              type="text"
              name="description"
              error={errors.description}
              ref={register({
                validate: noWhiteSpacesForRequiredFields,
                required: t("form_field_is_required"),
                maxLength: {
                  value: 512,
                  message: t("field_max_length", { number_of_characters: 512 })
                }
              })}
            />
            {errors.description && <Error>{errors.description.message}</Error>}
          </FormGroup>
          <ModalActions>
            <Button
              variant="primary-full-width"
              className="margin-top"
              type="submit"
              loading={loading}
              disabled={!formState.isValid || loading}
            >
              {t("add_access_profile")}
            </Button>
            <Button variant="cancel-text" type="button" onClick={hide}>
              {t("cancel")}
            </Button>
          </ModalActions>
        </Form>
      </Modal>
    );
  }

  return (
    <Modal visible={true} className="add">
      <Title>{title}</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label error={errors.name} htmlFor="name">
            {type === "testProfiles" || type === "accessProfiles"
              ? t("name")
              : t("test_name")}
          </Label>
          <Input
            type="text"
            name="name"
            error={errors.name}
            ref={register(
              type === "testProfiles"
                ? {
                    validate: noWhiteSpacesForRequiredFields,
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 128,
                      message: t("field_max_length", {
                        number_of_characters: 128
                      })
                    }
                  }
                : {
                    validate: noWhiteSpacesForRequiredFields,
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 36,
                      message: t("field_max_length", {
                        number_of_characters: 36
                      })
                    }
                  }
            )}
          />
          {errors.name && <Error>{errors.name.message}</Error>}
        </FormGroup>
        <FormGroup>
          <Label error={errors.description} htmlFor="description">
            {t("description")}
          </Label>
          <Input
            type="text"
            name="description"
            error={errors.description}
            ref={register(
              type === "testProfiles"
                ? {
                    validate: noWhiteSpacesForRequiredFields,
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 255,
                      message: t("field_max_length", {
                        number_of_characters: 255
                      })
                    }
                  }
                : {
                    validate: noWhiteSpacesForRequiredFields,
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 512,
                      message: t("field_max_length", {
                        number_of_characters: 512
                      })
                    }
                  }
            )}
          />
          {errors.description && <Error>{errors.description.message}</Error>}
        </FormGroup>
        {type === "testTypes" ? (
          <FormGroup>
            <Label error={errors.testType}>{t("test_type")}</Label>
            <SelectOption
              isSearchable
              options={labTypeList}
              onChange={handleTestTypeChange}
              defaultValue={testType}
              error={errors.testType}
              placeholder={t("select_test_type")}
              isOptionDisabled={option => {
                if(isCombo) {
                  return checkIfTestTypeAlreadyUsed(option.value, alreadyAddedTestTypes)
                }
              }}
            />
            {errors.testType && <Error>{errors.testType.message}</Error>}
          </FormGroup>
        ) : null}
        
        {type === "testProfiles" ? (
          <FormGroup>
            <div className="form-checkbox-conatainer">
              <input
                ref={register}
                checked={comboEnabled}
                name="combo"
                onClick={e => e.stopPropagation()}
                onChange={() => {
                  setComboEnabled(!comboEnabled)
                }}
                type="checkbox"
              />
              <label
                className="combo-label"
                htmlFor="combo"
              >{t("combo_test_label")}</label>
            </div>
          </FormGroup>
        ) : null}
        <ModalActions>
          <Button
            variant="primary-full-width"
            className="margin-top--medium"
            type="submit"
            loading={loading}
            disabled={!formState.isValid || loading}
          >
            {type === "testProfiles"
              ? t("add_test_profile")
              : type === "testTypes"
              ? t("add_test_type")
              : t("add_access_profile")}
          </Button>
          <Button variant="cancel-text" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
