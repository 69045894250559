import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import Form from "components/Form";
import Wrapper from "components/Wrapper";
import Title from "components/Title";
import Label from "components/Label";
import Input from "components/Input";
import Button from "components/Button";
import Error from "components/Error";
import FormGroup from "components/FormGroup";
import "./resetPasswordForm.scss";
import { ColorContext } from "components/FlatLayout/FlatLayout";
import { secondaryBackgroundColor } from "const/colorCodes";
import { useTranslation } from "react-i18next";
import { useValidation } from "utils/useValidation";

function ResetPasswordForm({ location }) {
  const password = React.useRef({});
  const [redirect, setRedirectStatus] = React.useState(false);
  const [loading, setLoadingStatus] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState,
    errors,
    setError,
    watch
  } = useForm({
    mode: "onBlur"
  });
  password.current = watch("password", "");
  const { t } = useTranslation();
  const { noRomanCharacter } = useValidation();

  const { changeBgColor } = React.useContext(ColorContext);

  React.useEffect(() => {
    changeBgColor(secondaryBackgroundColor);
  }, [changeBgColor]);

  const onSubmit = ({ password, code }) => {
    if (formState.isValid) {
      let username = location.state.username;

      setLoadingStatus(true);

      Auth.forgotPasswordSubmit(username.trim(), code.trim(), password.trim())
        .then(() => {
          setRedirectStatus(true);
        })
        .catch(err => {
          setError("code", "invalid", err.message);
          setLoadingStatus(false);
        });
    }
  };

  return (
    <Wrapper className="reset-password-form">
      <Title>{t("reset_password")}</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="code" error={errors.code}>
            {t("verification_code")}
          </Label>
          <Input
            name="code"
            error={errors.code}
            ref={register({
              required: t("form_field_is_required"),
              minLength: {
                value: 6,
                message: t("verification_too_short")
              }
            })}
            onChange={noRomanCharacter}
          />
          {errors.code && <Error>{errors.code.message}</Error>}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="password" error={errors.password}>
            {t("password")}
          </Label>
          <Input
            name="password"
            type="password"
            error={errors.password}
            onChange={noRomanCharacter}
            ref={register({
              required: t("password_error_required"),
              minLength: {
                value: 8,
                message: t("password_error_too_short", {
                  number_of_characters: 8
                })
              },
              validate: {
                hasLowerCase: value =>
                  RegExp(/[a-z]/g).test(value) ||
                  t("password_error_no_lowercase"),
                hasUpperCase: value =>
                  RegExp(/[A-Z]/g).test(value) ||
                  t("password_error_no_uppercase"),
                hasDigits: value =>
                  RegExp(/\d/g).test(value) || t("password_error_no_digits")
              }
            })}
          />
          {errors.password && <Error>{errors.password.message}</Error>}
          <p className="user-tip-header">{t("password_requirements")}</p>
          <p className="user-tip-list">{t("password_criteria")}</p>
        </FormGroup>

        <FormGroup>
          <Label htmlFor="confirmPassword" error={errors.confirmPassword}>
            {t("password_repeat")}
          </Label>
          <Input
            type="password"
            name="confirmPassword"
            error={errors.confirmPassword}
            onChange={noRomanCharacter}
            ref={register({
              validate: value =>
                value === password.current || t("password_do_not_match")
            })}
          />
          {errors.confirmPassword && (
            <Error>{errors.confirmPassword.message}</Error>
          )}
        </FormGroup>
        <Button
          type="submit"
          className="button--primary"
          loading={loading}
          disabled={!formState.isValid}
        >
          {t("confirm")}
        </Button>
      </Form>
      {redirect && <Redirect to={{ pathname: "/sign-in" }} />}
    </Wrapper>
  );
}

export default withRouter(ResetPasswordForm);
