import React from "react";
import classNames from "classnames";
import Button from "../Button";
import "./dropdown.scss";

class Dropdown extends React.Component {
  state = {
    isOpen: false
  };

  dropdownMenuRef = React.createRef();

  showMenu = event => {
    event.preventDefault();

    this.setState({ isOpen: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  };

  closeMenu = event => {
    if (!this.dropdownMenuRef.current.contains(event.target)) {
      this.setState({ isOpen: false }, () => {
        document.removeEventListener("click", this.closeMenu);
      });
    }
  };

  render() {
    const { isOpen } = this.state;
    const { toggleComponent, children, id } = this.props;

    return (
      <div
        id={id}
        className={classNames("dropdown", { "dropdown--open": isOpen })}
      >
        <Button className="dropdown_toggle" onClick={this.showMenu}>
          {toggleComponent}
        </Button>
        {isOpen && (
          <div className="dropdown_items" ref={this.dropdownMenuRef}>
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default Dropdown;
