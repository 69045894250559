import {
  SET_HOME_PROFILES,
  UPDATE_HOME_TEST_PROFILE,
  CREATE_HOME_TEST_PROFILE,
  PUBLISH_HOME_PROFILE,
  SET_HOME_PROFILE,
  ACTIVATE_DEACTIVATE_HOME_PROFILE
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = {};

const qrCodes = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HOME_PROFILES: {
      // const { qrCodes } = payload;
      return { ...state, ...payload };
    }

    case CREATE_HOME_TEST_PROFILE: {
      return { ...state, homeTestProfile: payload, profiles: [payload] };
    }

    case SET_HOME_PROFILE: {
      return { ...state, homeTestProfile: { ...payload } };
    }

    case UPDATE_HOME_TEST_PROFILE: {
      return { ...state, homeTestProfile: payload };
    }

    case PUBLISH_HOME_PROFILE: {
      return {
        ...state,
        homeTestProfile: { ...state.homeTestProfile, status: payload }
      };
    }

    case ACTIVATE_DEACTIVATE_HOME_PROFILE: {
      return {
        ...state,
        homeTestProfile: { ...state.homeTestProfile, active: payload }
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default qrCodes;
