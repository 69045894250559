export const SURVEY_FOR_TEST_TYPE = "SURVEY_FOR_TEST_TYPE";
export const SURVEY_FOR_EXTENDED_PATIENT_PROFILE = "SURVEY_FOR_EXTENDED_PATIENT_PROFILE";
export const SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE = "SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE";
export const SURVEY_FOR_TEST_SHARING_WITH_COHORT = "SURVEY_FOR_TEST_SHARING_WITH_COHORT";

export const SINGLE_ANSWER = "SINGLE_ANSWER";
export const MULTI_ANSWERS = "MULTI_ANSWERS";
export const SINGLE_ANSWER_TEXT = "SINGLE_ANSWER_TEXT";

export const FREE_TEXT = "FREE_TEXT";
export const NUMERIC = "NUMERIC";
export const BUTTON = "BUTTON";
export const CHECKBOX = "CHECKBOX";
export const DATE = "DATE";
export const DATETIME = "DATETIME";

export const QUESTIONTYPES = [
  {
    value: "SINGLE_ANSWER",
    name: "SINGLE_ANSWER",
    label: "Single select",
    type: BUTTON
  },
  {
    value: "MULTI_ANSWERS",
    name: "MULTI_ANSWERS",
    label: "Multiple answer",
    type: CHECKBOX
  },
  {
    value: "SINGLE_ANSWER_TEXT",
    name: "SINGLE_ANSWER_TEXT",
    label: "Free text",
    type: FREE_TEXT
  },
  {
    value: "SINGLE_ANSWER_TEXT_NUMERIC",
    name: "SINGLE_ANSWER_TEXT",
    label: "Numeric field",
    type: NUMERIC
  },
  {
    value: "SINGLE_ANSWER_TEXT_DATE",
    name: "SINGLE_ANSWER_TEXT",
    label: "Date",
    type: DATE
  },
  {
    value: "SINGLE_ANSWER_TEXT_DATETIME",
    name: "SINGLE_ANSWER_TEXT",
    label: "Date Time",
    type: DATETIME
  },
];

export const YES = {
  value: "true",
  label: "Yes",
  booleanValue: true
};

export const NO = {
  value: "false",
  label: "No",
  booleanValue: false
};
