import { error, warning, success } from "react-notification-system-redux";
import {
  getConfig,
  getAboutConfig,
  getTestTypeList,
  getCountries
} from "store/reducers/config/services";
import * as api from "api/user";
import * as actions from "./actions";
import { updateAuthToken } from "api/config";
import i18n from "../../../translations";

export const getUserData = () => async dispatch => {
  const result = await dispatch(api.getUserData());
  if (result && result.status === 200) {
    dispatch(actions.setUserData(result.data || {}));
    return result.data;
  }
};

export const updateSession = user => dispatch => {
  updateAuthToken(user.idToken.jwtToken);
  dispatch(actions.updateUserSession(user));
};

export const setLoggedUser = user => async dispatch => {
  if (user) {
    updateAuthToken(user.signInUserSession.idToken.jwtToken);
    dispatch(actions.loginSuccess(user));
    //TODO: this somehow hides exceptions in reducer code
    const res = await dispatch(getConfig());
    if (res && res.status === 200) {
      await dispatch(getAboutConfig());
      await dispatch(getTestTypeList());
      await dispatch(getUserData());
      await dispatch(getCountries());
    }
  } else {
    dispatch(
      error({
        message: i18n.t("error_login")
      })
    );
  }
};

export const updateContacts = (
  institutionPhoneNumber,
  institutionAddress,
  institutionWebLink,
  extendedProfile,
  institutionName
) => async (dispatch, getState) => {
  const result = await dispatch(
    api.updateContacts(
      institutionPhoneNumber,
      institutionAddress,
      institutionWebLink,
      extendedProfile,
      institutionName
    )
  );
  if (result && result.status === 200) {
    dispatch(
      actions.updateContacts(
        institutionPhoneNumber,
        institutionAddress,
        institutionWebLink,
        extendedProfile,
        institutionName
      )
    );
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("the_organization_inf"),
        position: "tc"
      })
    );
  } else {
    dispatch(error({ message: i18n.t("error_generic") }));
  }
};

export const removeLoggedUser = reason => dispatch => {
  sessionStorage.clear();
  updateAuthToken();
  dispatch(actions.logoutUser());

  if (reason === "timer") {
    dispatch(
      warning({
        title: i18n.t("warning_session_expired"),
        message: i18n.t("warning_session_expired_description"),
        position: "tc",
        autoDismiss: 60
      })
    );
  } else if (reason === "server") {
    dispatch(
      warning({
        title: i18n.t("warning_session_invalid"),
        message: i18n.t("warning_session_invalid_description"),
        position: "tc",
        autoDismiss: 60
      })
    );
  }
};

export const updateProfile = (
  firstName,
  lastName,
  phone,
  id
) => async dispatch => {
  await dispatch(api.updateProfile(firstName, lastName, phone, id));
  dispatch(actions.setUserData({ firstName, lastName }));
};
