import { Button } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { success } from "react-notification-system-redux";
import "./InfinityIntegration.scss";

export default function InfinityIntegration({
  integrationId,
  createInfinityIntegration,
  testingProfileId,
  disabled
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const enableInfinityIntegration = () =>
    createInfinityIntegration(testingProfileId);
  const copyIntegrationIdToClipboard = async code => {
    await navigator.clipboard.writeText(code);
    dispatch(
      success({
        title: t("success"),
        message: t("connection_code_copied"),
        position: "tc"
      })
    );
  };
  const IntegrationButton = ({
    onClick,
    buttonText,
    buttonTitle,
    integrationId,
    buttonDisabled
  }) => (
    <div className="integration-button">
      {integrationId && <div className="integration-id">{integrationId}</div>}

      <Button
        data-testid="button-action"
        variant="primary"
        className={`enable-button ${integrationId && "copy-button"}`}
        onClick={onClick}
        disabled={buttonDisabled}
        title={buttonTitle}
      >
        {buttonText}
      </Button>
    </div>
  );

  return (
    <div className="infinity-integration">
      <div className="column">
        {t("connect_external_data")}
        <div className="subtitle">{t("enable_connection")}</div>
      </div>
      <div className="column">
        {integrationId ? (
          <IntegrationButton
            onClick={() => copyIntegrationIdToClipboard(integrationId)}
            buttonText={t("copy")}
            buttonTitle={t("copy_to_clipboard")}
            integrationId={integrationId}
          />
        ) : (
          <IntegrationButton
            onClick={() => {
              enableInfinityIntegration(testingProfileId);
            }}
            buttonText={t("enable")}
            buttonDisabled={disabled}
          />
        )}
      </div>
    </div>
  );
}
