import {
  SET_HOME_PROFILES,
  CREATE_HOME_TEST_PROFILE,
  UPDATE_HOME_TEST_PROFILE,
  SET_HOME_PROFILE,
  PUBLISH_HOME_PROFILE,
  ACTIVATE_DEACTIVATE_HOME_PROFILE
} from "./types";

export const setHomeProfiles = profiles => ({
  type: SET_HOME_PROFILES,
  payload: {
    profiles
  }
});

export const setHomeTestingProfile = profileId => ({
  type: SET_HOME_PROFILE,
  payload: {
    ...profileId
  }
});

export const createHomeTestProfile = payload => ({
  type: CREATE_HOME_TEST_PROFILE,
  payload
});

export const updateHomeTestProfile = payload => {
  return {
    type: UPDATE_HOME_TEST_PROFILE,
    payload
  };
};

export const publishHomeTestProfile = payload => {
  return {
    type: PUBLISH_HOME_PROFILE,
    payload
  };
};

export const activateDeactivateHomeTestProfile = payload => {
  return {
    type: ACTIVATE_DEACTIVATE_HOME_PROFILE,
    payload
  };
};
