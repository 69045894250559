import React from "react";
import { useTranslation } from "react-i18next";

import "./Switch.scss";

const Switch = React.forwardRef(
  (
    {
      isActive,
      handleToggle,
      onColor,
      disabled,
      withoutLabel,
      small,
      name = "switchName"
    },
    forwardRef
  ) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState();

    React.useEffect(() => {
      setValue(isActive);
    }, [isActive]);

    return (
      <div className={`switch-container ${small && "small-size"}`}>
        {withoutLabel || <span>{t("off")}</span>}
        <input
          ref={forwardRef}
          name={name}
          checked={isActive}
          value={value}
          onChange={handleToggle}
          className="switch-checkbox"
          id={name}
          type="checkbox"
          disabled={disabled}
        />
        <label
          style={{ background: isActive && onColor }}
          className="switch-label"
          htmlFor={name}
        >
          <span className={`switch-button`} />
        </label>
        {withoutLabel || <span>{t("on")}</span>}
      </div>
    );
  }
);

export default Switch;
