import React from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import Error from "components/Error";
import { ModalActions } from "components/Modal";
import { noWhiteSpacesForRequiredFields } from "utils/FieldValidationRules";
import { useTranslation } from "react-i18next";

export default function CohortProfileForm(props) {
  const { hide = () => {}, name = "", description = "", loading } = props;
  const { t } = useTranslation();

  const { register, handleSubmit, formState, errors } = useForm({
    mode: "onChange",
    defaultValues: {
      name,
      description
    }
  });

  const onSubmit = params => {
    if (formState.isValid) {
      props.onSubmit({ ...params });
    }
  };

  return (
    <Modal visible={true} className="add">
      <Title>{t("add_cohort")}</Title>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="my-form">
        <FormGroup>
          <Label error={errors.name} htmlFor="name">
            {t("name")}
          </Label>
          <Input
            data-testid="input-name"
            type="text"
            name="name"
            error={errors.name}
            ref={register({
              validate: noWhiteSpacesForRequiredFields,
              required: t("form_field_is_required"),
              maxLength: {
                value: 128,
                message: t("field_max_length", {
                  number_of_characters: 128
                })
              }
            })}
          />
          {errors.name && <Error>{errors.name.message}</Error>}
        </FormGroup>
        <FormGroup>
          <Label error={errors.description} htmlFor="description">
            {t("description")}
          </Label>
          <Input
            data-testid="input-description"
            type="text"
            name="description"
            error={errors.description}
            ref={register({
              validate: noWhiteSpacesForRequiredFields,
              required: t("form_field_is_required"),
              maxLength: {
                value: 255,
                message: t("field_max_length", {
                  number_of_characters: 255
                })
              }
            })}
          />
          {errors.description && <Error>{errors.description.message}</Error>}
        </FormGroup>
        <ModalActions>
          <Button
            data-testid="btn-add-cohort"
            variant="primary-full-width"
            className="margin-top--medium"
            type="submit"
            loading={loading}
            disabled={!formState.isValid || loading}
          >
            {t("add_cohort")}
          </Button>
          <Button variant="cancel-text" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
