import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Spinner from "components/Spinner";
import "./button.scss";

const Button = React.forwardRef(
  (
    { variant, onClick, children, disabled, loading, className, ...props },
    ref
  ) => (
    <button
      disabled={disabled || loading}
      className={classNames("button", className, {
        "button--disabled": disabled,
        "button--loading": loading,
        [`button--${variant}`]: variant
      })}
      onClick={onClick}
      {...props}
      ref={ref}
    >
      {loading && <Spinner />}
      <span className="button_text">{children}</span>
    </button>
  )
);

Button.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "primary-large",
    "secondary",
    "secondary-large",
    "row-action",
    "primary-full-width",
    "secondary-full-width",
    "cancel-text",
    "primary-confirm",
    "secondary-confirm",
    "outlined",
    "outlined-text",
    "text"
  ])
};

Button.displayName = "Button";

export default Button;
