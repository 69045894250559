import React from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import PageTitle from "components/PageTitle";
import Spinner from "components/Spinner";
import Table from "components/Table";
import PropTypes from "prop-types";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import Label from "components/Label";
import SelectOption from "components/SelectOption";
import { useForm, Controller } from "react-hook-form";
import Error from "components/Error";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { subMonths, addMonths } from "date-fns";
import { LANGUAGE } from "const/app";
import moment from "moment-timezone";
import { sortIgnoreCase, sortTimestamp } from "utils/sortMethods";

import "react-datepicker/dist/react-datepicker.css";

import {
  EXPORT_TEST_RESULTS_FOR_GOVERNMENT,
  STATUS_NEW,
  STATUS_STARTED,
  STATUS_COMPLETED,
  STATUS_ERROR,
  STATUS_IN_PROGRESS,
  STATUS_TIMED_OUT
} from "const/exportTypes";
import { getDateFormatString, languageMapping } from "utils/formatData";
import { useTranslation } from "react-i18next";

registerLocale(LANGUAGE, languageMapping?.[LANGUAGE]);

const CountryExport = ({
  exportData,
  getExports,
  createExport,
  getDownloadUrl,
  testTypes,
  countryCode,
  testTypesLoading
}) => {
  const { t } = useTranslation();

  const SELECT_ALL_VALUE = "*";
  const [allIsSelected, setAllIsSelected] = React.useState(false);

  const {
    handleSubmit,
    errors,
    getValues,
    setValue,
    reset,
    formState,
    control,
    watch,
    setError,
    clearError
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const selectRef = React.useRef(null);

  let exportsInProgress = React.useMemo(
    () =>
      exportData
        .filter(data => data.jobType === EXPORT_TEST_RESULTS_FOR_GOVERNMENT)
        .filter(
          item =>
            item.statusCode === STATUS_IN_PROGRESS ||
            item.statusCode === STATUS_NEW ||
            item.statusCode === STATUS_STARTED
        ).length,
    [exportData]
  );
  React.useEffect(() => {
    if (!window.navigator.onLine) return;
  }, []);

  React.useEffect(() => {
    getExports();

    const interval = setInterval(() => {
      getExports();
    }, 10000);

    if (!exportsInProgress) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [getExports, exportsInProgress]);

  React.useEffect(() => {
    watch("testTypes");
  }, [watch]);

  const compareDates = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d2 < d1;
  };

  const onSubmit = async formData => {
    if (formData?.testTypes?.includes(SELECT_ALL_VALUE)) {
      formData.testTypes = testTypes.map(option => option.value);
    }
    let start = moment(formData.dateFrom)
      .startOf("day")
      .valueOf();
    let end = moment(formData.dateTo)
      .endOf("day")
      .valueOf();
    let userTimeZone = moment.tz.guess();

    let requestsArray = formData.testTypes.map(testType => {
      let request = {
        organizationIdList: null,
        exportType: EXPORT_TEST_RESULTS_FOR_GOVERNMENT,
        intervalStart: start,
        intervalEnd: end,
        countryCode: countryCode,
        testTypeList: [testType],
        userTimeZone
      };
      return createExport(request);
    });

    await Promise.all(requestsArray);

    setValue([
      { testTypes: null },
      { dateFrom: undefined },
      { dateTo: undefined }
    ]);
    reset();
    setAllIsSelected(false);
  };

  const triggerDownload = React.useCallback( async exportId => {
    const file = await getDownloadUrl(exportId);
    window.open(file.data?.downloadUrl, "_self");
  }, [getDownloadUrl]);

  const triggerRetry = React.useCallback( async rowData => {
    let userTimeZone = moment.tz.guess();
    const {
      organizationIdList,
      intervalStart,
      intervalEnd,
      testTypeList,
      countryCode
    } = rowData.jobConfig;

    let response = {
      organizationIdList,
      exportType: EXPORT_TEST_RESULTS_FOR_GOVERNMENT,
      intervalStart,
      intervalEnd,
      testTypeList,
      userTimeZone,
      countryCode
    };
    await createExport(response);
    return;
  }, [createExport]);

  const formatDateRange = React.useCallback((dateFrom, dateTo) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const fromDate = new Date(dateFrom).toLocaleDateString(LANGUAGE, options);
    const toDate = new Date(dateTo).toLocaleDateString(LANGUAGE, options);
    return `${fromDate} - ${toDate}`;
  }, []);

  const formatStartDate = React.useCallback(date => {
    const dateTime = new Date(date);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: false };
    const startDate = dateTime.toLocaleDateString(LANGUAGE, options);
    const startTime = dateTime.toLocaleTimeString(LANGUAGE, timeOptions);

    return `${startDate} ${startTime}`;
  }, []);

  const data = exportData.filter(
    data => data.jobType === EXPORT_TEST_RESULTS_FOR_GOVERNMENT
  ).map((item) => ({
    ...item, 
    testTypeName: testTypes.length &&
    testTypes.find(
      t => t.value === item.jobConfig?.testTypeList?.[0]
    )?.label,
    status: item.statusCode === STATUS_ERROR || !!item.error
      ? t("export_not_successful")
      : item.statusCode === STATUS_COMPLETED
      ? t("export_ready")
      : item.statusCode === STATUS_TIMED_OUT
      ? t("export_too_big")
      : t("export_in_progress")
  }));

  const countryDataExportColumns = React.useMemo(
    () => [
    {
      Header: t("test_data_organization_date_range"),
      accessor: "dateRange",
      disableSortBy: true,
      className: "column-date-range",
      Cell: ({ row }) =>
        formatDateRange(
          row.original.jobConfig?.intervalStart,
          row.original.jobConfig?.intervalEnd
        )
    },
    {
      Header: t("test_type"),
      accessor: "testTypeName",
      sortType: sortIgnoreCase
    },
    {
      Header: t("test_data_organization_job_started"),
      accessor: "createdAt",
      sortType: sortTimestamp,
      className: "column-job-started",
      Cell: ({ value }) => formatStartDate(value)
    },
    {
      Header: t("test_data_organization_processing_status"),
      accessor: "status",
      sortType: sortIgnoreCase,
      Cell: ({ row, value }) => {
        return (
          <div className="status-container">
            <p>{value}</p>
            {row.original.statusCode === STATUS_COMPLETED ? (
              <Icon type="readyToDownload" />
            ) : row.original.statusCode === STATUS_ERROR ||
                row.original.statusCode === STATUS_TIMED_OUT ||
                !!row.original.error ? (
              <Icon type="errorAlert" />
            ) : null}
          </div>
        );
      }
    },
    {
      Header: t("test_data_organization_actions"),
      className: "qr-codes__actions-column column-actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <React.Fragment>
            {row.original.statusCode === STATUS_COMPLETED ? (
              <Button
                variant="row-action"
                title={t("test_data_organization_actions_download")}
                onClick={() => triggerDownload(row.original.exportId)}
              >
                <Icon type="download" />
              </Button>
            ) : row.original.statusCode === STATUS_ERROR ||
              !!row.original.error ? (
              <Button
                variant="row-action"
                title={t("test_data_organization_actions_retry")}
                onClick={() => triggerRetry(row.original)}
              >
                <Icon type="refresh" />
              </Button>
            ) : null}
          </React.Fragment>
        );
      }
    }
  ], [formatDateRange, formatStartDate, triggerDownload, triggerRetry, t]);

  return testTypesLoading ? (
    <Spinner marginTop={15} />
  ) : (
    <React.Fragment>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="data-export__form-container"
      >
        <div className="button-container">
          <Button
            variant="primary"
            className="button header-add-button"
            type="submit"
            disabled={!formState.isValid}
          >
            {t("test_data_organization_export_data")}
          </Button>
        </div>
        <PageTitle title={t("data_export_nav")} />
        <div className="data-export__form">
          <div className="dates-container">
            <FormGroup>
              <Label htmlFor="dateFrom" error={errors.dateFrom}>
                {t("test_data_date_from")}
              </Label>
              <Icon type="calendar" />

              <Controller
                as={ReactDatePicker}
                control={control}
                valueName="selected" // DateSelect value's name is selected
                onChange={([selected]) => {
                  return selected;
                }}
                minDate={subMonths(watch("dateTo"), 3)}
                name="dateFrom"
                className={
                  errors.dateFrom ? "date-picker--error" : "date-picker"
                }
                placeholderText={getDateFormatString(LANGUAGE)}
                autoComplete="off"
                locale={languageMapping[LANGUAGE] || LANGUAGE}
                dateFormat="P"
                rules={{
                  required: t("form_field_is_required"),
                  validate: value => {
                    return (
                      !compareDates(value, getValues("dateTo")) ||
                      t("test_data_date_from_less_than_to_date")
                    );
                  }
                }}
              />
              {errors.dateFrom && <Error>{errors.dateFrom.message}</Error>}
            </FormGroup>
            <div className="separator">-</div>
            <FormGroup>
              <Label htmlFor="dateTo" error={errors.dateTo}>
                {t("to")}
              </Label>
              <Icon type="calendar" />

              <Controller
                as={ReactDatePicker}
                control={control}
                valueName="selected" // DateSelect value's name is selected
                onChange={([selected]) => {
                  const { dateFrom } = getValues();
                  if (compareDates(dateFrom, selected)) {
                    setError(
                      "dateFrom",
                      "notMatch",
                      t("test_data_date_from_less_than_to_date")
                    );
                  } else {
                    clearError("dateFrom");
                    reset("dateFrom", dateFrom);
                  }
                  return selected;
                }}
                maxDate={addMonths(watch("dateFrom"), 3)}
                name="dateTo"
                className={errors.dateTo ? "date-picker--error" : "date-picker"}
                placeholderText={getDateFormatString(LANGUAGE)}
                autoComplete="off"
                locale={languageMapping[LANGUAGE] || LANGUAGE}
                dateFormat="P"
                rules={{
                  required: t("form_field_is_required")
                }}
              />
              {errors.dateTo && <Error>{errors.dateTo.message}</Error>}
            </FormGroup>
          </div>
          <FormGroup>
            <Label htmlFor="testTypes" error={errors.testTypes}>
              {t("test_type")}
            </Label>

            <Controller
              as={
                <SelectOption
                  isSearchable
                  isClearable
                  isMulti
                  allowSelectAll={true}
                  closeMenuOnSelect={false}
                  allIsSelected={allIsSelected}
                  options={testTypes}
                  error={errors.testTypes}
                  placeholder={t("select_test_type")}
                  ref={selectRef}
                />
              }
              control={control}
              onChange={([selected, meta]) => {
                // React Select return object instead of value for selection
                if (selected && selected[selected.length - 1]?.value === "*") {
                  setAllIsSelected(true);
                }
                if (allIsSelected && meta.action === "clear") {
                  setAllIsSelected(false);
                }
                if (allIsSelected && meta.action === "remove-value") {
                  let data = selected
                    ?.filter(item => item.value !== meta.removedValue.value)
                    .map(item => item.value);
                  setAllIsSelected(false);

                  return data;
                }
                return selected?.length ? selected?.map(item => item.value) : null;
              }}
              rules={{ required: t("form_field_is_required") }}
              name="testTypes"
            />
            {errors.testTypes && <Error>{errors.testTypes.message}</Error>}
          </FormGroup>
        </div>
      </Form>
      <div className="data-export__table-container">
        <Table
          pagination
          globalFilter
          pageSize={10}
          columns={countryDataExportColumns}
          data={data}
          className={"data-export__table"}
          withSortIcons={true}
        />
      </div>
    </React.Fragment>
  );
};

CountryExport.propTypes = {
  testTypes: PropTypes.arrayOf(PropTypes.object),
  exportData: PropTypes.arrayOf(PropTypes.object)
};

export default CountryExport;
