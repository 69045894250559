import axios from "utils/Axios";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";
import { makeOrganizationsData, makeUsersData } from "utils/mockData";

export const getOrganizations = () => {
  return makeAxiosRequest("getOrganizations", {
    client: axios,
    method: "get",
    relativePath: "/admin/organizations"
  });
};

export const getMockedOrganizations = () => {
  return makeRequest("getOrganizations", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeOrganizationsData(5);
            resolve({ data: { data: mockData } });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const createOrganization = (institutionName, reference, countryCode) => {
  return makeAxiosRequest("createOrganization", {
    client: axios,
    method: "post",
    relativePath: "/admin/organizations",
    data: {
      institutionName,
      properties: {
        sapNumber: reference,
        countryCode
      }
    }
  });
};

export const adminUpdateOrganizationName = (
  institutionName,
  tenantId,
  reference,
  countryCode
) => {
  return makeAxiosRequest("updateOrganization", {
    client: axios,
    method: "put",
    relativePath: `/admin/organizations/${tenantId}`,
    data: {
      institutionName,
      properties: {
        sapNumber: reference,
        countryCode
      }
    }
  });
};

export const updateOrganization = (
  institutionName,
  city,
  country,
  institutionPhoneNumber,
  institutionAddress,
  institutionWebLink
) => {
  return makeAxiosRequest("updateOrganization", {
    client: axios,
    method: "put",
    relativePath: `/admin/organizations`,
    data: {
      institutionName,
      city,
      country,
      institutionPhoneNumber,
      institutionAddress,
      institutionWebLink
    }
  });
};

export const getOrganizationUsers = tenantId => {
  return makeAxiosRequest("getOrganizationUsers", {
    client: axios,
    method: "get",
    relativePath: tenantId ? `/admin/organizations/${tenantId}/hcp` : "/hcp"
  });
};

export const getMockedOrganizationUsers = organizationId => {
  return makeRequest("getOrganizationUsers", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeUsersData(6);
            resolve({ data: { data: mockData, status: 200 } });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const updateOrganizationUser = (
  organizationId,
  firstName,
  lastName,
  roles,
  dataOwnerId
) => {
  return makeAxiosRequest("updateOrganizationUser", {
    client: axios,
    method: "put",
    relativePath: organizationId
      ? `/admin/organizations/${organizationId}/hcp/${dataOwnerId}`
      : `/hcp/${dataOwnerId}`,
    data: {
      firstName,
      lastName,
      roles
    }
  });
};

export const updateMockedOrganizationUser = (organizationId, data) => {
  return makeRequest("updateOrganizationUser", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({ data: true });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const createOrganizationUser = (
  organizationId,
  firstName,
  lastName,
  email,
  roles,
  hcpId
) => {
  return makeAxiosRequest("createOrganizationUser", {
    client: axios,
    method: "post",
    relativePath: organizationId
      ? `/admin/organizations/${organizationId}/hcp`
      : "/hcp",
    data: {
      hcpId,
      firstName,
      lastName,
      phoneNumber: "null",
      email,
      roles
    }
  });
};

export const createMockedOrganizationUser = (
  organizationId,
  firstName,
  lastName,
  roles,
  dataOwnerId
) => {
  return makeRequest("createOrganizationUser", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({ data: true });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const deleteOrganizationUser = (organizationId, id) => {
  return makeAxiosRequest("deleteOrganizationUser", {
    client: axios,
    method: "delete",
    relativePath: organizationId
      ? `/admin/organizations/${organizationId}/hcp/${id}`
      : `/hcp/${id}`
  });
};

export const deleteMockedOrganizationUser = (organizationId, id) => {
  return makeRequest("deleteOrganizationUser", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({ data: true });
          }, 1000);
        });
      }
    },
    query: ""
  });
};
