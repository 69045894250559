import React from "react";
import Label from "components/Label";
import ReactDatePicker from "react-datepicker";
import FormGroup from "components/FormGroup";
import Error from "components/Error";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "const/app";
import Icon from "components/Icon";
import { getDateFormatString, languageMapping } from "utils/formatData";
import "./InputBirthDay.scss";
import moment from "moment";

function InputBirthDay({
  name = "dateOfBirth",
  useForm,
  label = "date_of_birth_label",
  required,
  type
}) {
  const { t } = useTranslation();
  const { control, errors, triggerValidation } = useForm;

  return (
    <div className="dates-container">
      <FormGroup>
        <Label htmlFor={name} error={errors[name]}>
          {`${t(label)} ${required && "*"}`}
        </Label>
        <Icon type="calendar" />
        <Controller
          as={
            <ReactDatePicker
              customInput={<input data-testid="select-birth-day" />}
            />
          }
          control={control}
          valueName="selected"
          onChange={([selected]) => selected}
          onBlur={() => triggerValidation(name)}
          name={name}
          className={errors[name] ? "date-picker--error" : "date-picker"}
          placeholderText={getDateFormatString(LANGUAGE)}
          autoComplete="off"
          locale={languageMapping[LANGUAGE] || LANGUAGE}
          dateFormat="P"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          maxDate={moment().toDate()}
          openToDate={
            type === "ADD"
              ? moment()
                  .subtract(18, "years")
                  .toDate()
              : null
          }
          rules={{
            ...(required && { required: t("form_field_is_required") })
          }}
        />

        {errors[name] && <Error>{errors[name].message}</Error>}
      </FormGroup>
    </div>
  );
}

export default InputBirthDay;
