import React from "react";
import { Title, Button, Modal } from "components";
import { ModalActions } from "components/Modal";
import { useTranslation } from "react-i18next";

import "./patientConsent.scss";

function PatientConsent({ hide = () => {}, onConsentSubmit, loading, flow }) {
  const { t } = useTranslation();

  return (
    <Modal visible={true}>
      <div className="patient-consent" data-testid="consent-modal">
        <Title>{t("recipient_consent_title")}</Title>
        <div className="patient-consent-content">
          {flow === "ADD"
            ? t("recipient_profile_consent_body")
            : t("recipient_consent_body_import")}
        </div>
        <ModalActions>
          <Button
            variant="primary-full-width"
            type="submit"
            className="margin-top--medium"
            onClick={onConsentSubmit}
            loading={loading}
          >
            {flow === "ADD"
              ? t("create_profile_button")
              : t("import_profile_button")}
          </Button>
          <Button variant="cancel-text" type="button" onClick={hide}>
            {t("decline_button")}
          </Button>
        </ModalActions>
      </div>
    </Modal>
  );
}

export default PatientConsent;
