import React from "react";
import { useForm } from "react-hook-form";
import { Modal, Button, Form, Title } from "components";
import moment from "moment";
import {formatDateString} from "utils/formatData"
import { ModalActions } from "components/Modal";
import { useTranslation } from "react-i18next";
import {
  SINGLE_ANSWER, 
  MULTI_ANSWERS,
  BUTTON,
  DATE,
  DATETIME
} from "const/surveyTypes";
import { mapFieldsToInputs } from "utils/mapFieldsToInput";

export default function PatientSurveyForm(props) {
  const {
    hide,
    loading,
    extendedProfile,
    onSurveySubmit,
    answers,
    name = "PATIENT"
  } = props;
  const { t } = useTranslation();
  const myUseForm = useForm({
    mode: "onChange"
  });
  const { handleSubmit, formState } = myUseForm;

  const { isValid } = formState;

  const onSubmit = params => {
    let answers = Object.keys(params).map(key => {
      let question = extendedProfile.find(q => q.id === key);
      let answer;

      if (question?.type === SINGLE_ANSWER) {
        answer = question.answers.find(ans => ans.id === params[key]);
      } else if (question?.type === MULTI_ANSWERS) {
        answer = question.answers.filter(ans => params[key]?.includes(ans.id));
      } else answer = question?.answers[0];

      return {
        id: question.id,
        fieldName: question.fieldName,
        title: question.title,
        answers: Array.isArray(params[key])
          ? params[key].map(arr => answer.find(ans => ans.id === arr))
          : answer?.type === BUTTON
          ? [{ ...answer }]
          : [{ ...answer, 
            value: 
            answer?.type === DATE || answer?.type === DATETIME ? moment(params[key]).format(formatDateString(answer.type)) : params[key] }]
      };
    });

    let response = {
      surveyAnswers: answers
    };

    //if there are no additional fields/request fails, send the param as null
    let profile = Object.keys(extendedProfile).length ? response : null;

    if (isValid) {
      onSurveySubmit(profile);
    }
  };

  return (
    <Modal visible={true}>
      <Title>
        {name === "PATIENT"
          ? t("patient_information_title")
          : t("test_survey_title")}
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {extendedProfile?.map(field => mapFieldsToInputs(field, extendedProfile, answers, myUseForm))}
        <ModalActions>
          <Button
            variant="primary-full-width"
            className="margin-top--medium"
            type="submit"
            loading={loading}
            disabled={!formState.isValid}
          >
            {t("save")}
          </Button>
          <Button variant="cancel-text" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
