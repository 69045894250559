import { ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_DATA_ANALYST } from "const/roles";

export const getLoggedUser = state => state.user;

export const getLoggedUserSession = state =>
  getLoggedUser(state).signInUserSession;

export const getLoggedUserIdToken = state => {
  const session = getLoggedUserSession(state);
  return session ? session.idToken : null;
};

export const getLoggedUserAccessToken = state => {
  const session = getLoggedUserSession(state);
  return session ? session.accessToken : null;
};

export const getCountriesOfUser = state => getLoggedUser(state).countries;

export const getLoggedAdminUserCountry = state => {
  const countries = getCountriesOfUser(state);
  return countries?.length
    ? countries?.find(c => c.role === ROLE_COUNTRY_ADMIN).country
    : null;
};

export const getLoggedAnalystUserCountry = state => {
  const countries = getCountriesOfUser(state);
  return countries.length
    ? countries.find(c => c.role === ROLE_COUNTRY_DATA_ANALYST).country
    : null;
};
