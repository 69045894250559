import React from "react";
import { Link } from "react-router-dom";
//TODO: trying import {} from "containers" causes circular dependency
import LoggedUser from "containers/LoggedUser.Container";
import Icon from "components/Icon";
import "./header.scss";
import { formatName } from "utils/formatData";
import { fixture } from 'utils/fixture'

const Header = () => {
  let name = formatName(fixture.project.name);
  return (
    <div>
      <div className="mobileHide">
        <header className="header" role="banner">
          <div className="header_left">
            <Link to="/">
              <h2>
                {name[0]}
                <span className="tm">{name[1]}</span>
                {name[2]}
              </h2>
            </Link>
          </div>
          <div className="header_right">
            <LoggedUser />
            <Icon type="logo" />
          </div>
        </header>
      </div>

      <div className="mobileShow">
        <header className="header" role="banner">
          <div className="header_left">
            <Link to="/">
              <h2>
                {name[0]}
                <span className="tm">{name[1]}</span>
                {name[2]}
              </h2>
            </Link>
          </div>
          <div className="header_center">
            <Icon type="logo" />
          </div>
          <div className="header_right">
            <div className="header_right_login">
              <LoggedUser />
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
