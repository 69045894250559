import React from "react";
import PageTitle from "components/PageTitle";
import UpdateProfileForm from "containers/UpdateProfileForm.Container";
import { useTranslation } from "react-i18next";
// import UpdatePasswordForm from "containers/UpdatePasswordForm";

const Home = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <PageTitle
        title={t("user_profile_title")}
        subtitle={t("user_profile_title_description")}
      />
      <UpdateProfileForm />
      {/* <UpdatePasswordForm /> */}
    </React.Fragment>
  );
};

export default Home;
