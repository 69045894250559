const countries = [
  {
    name: "Afghanistan",
    isoCodeAlpha2: "AF",
    isoCodeAlpha3: "AFG",
    phonePrefix: "+93"
  },
  {
    name: "Albania",
    isoCodeAlpha2: "AL",
    isoCodeAlpha3: "ALB",
    phonePrefix: "+355"
  },
  {
    name: "Algeria",
    isoCodeAlpha2: "DZ",
    isoCodeAlpha3: "DZA",
    phonePrefix: "+213"
  },
  {
    name: "American Samoa",
    isoCodeAlpha2: "AS",
    isoCodeAlpha3: "ASM",
    phonePrefix: "+1-684"
  },
  {
    name: "Andorra",
    isoCodeAlpha2: "AD",
    isoCodeAlpha3: "AND",
    phonePrefix: "+376"
  },
  {
    name: "Angola",
    isoCodeAlpha2: "AO",
    isoCodeAlpha3: "AGO",
    phonePrefix: "+244"
  },
  {
    name: "Anguilla",
    isoCodeAlpha2: "AI",
    isoCodeAlpha3: "AIA",
    phonePrefix: "+1-264"
  },
  {
    name: "Antarctica",
    isoCodeAlpha2: "AQ",
    isoCodeAlpha3: "ATA",
    phonePrefix: "+672"
  },
  {
    name: "Antigua and Barbuda",
    isoCodeAlpha2: "AG",
    isoCodeAlpha3: "ATG",
    phonePrefix: "+1-268"
  },
  {
    name: "Argentina",
    isoCodeAlpha2: "AR",
    isoCodeAlpha3: "ARG",
    phonePrefix: "+54"
  },
  {
    name: "Armenia",
    isoCodeAlpha2: "AM",
    isoCodeAlpha3: "ARM",
    phonePrefix: "+374"
  },
  {
    name: "Aruba",
    isoCodeAlpha2: "AW",
    isoCodeAlpha3: "ABW",
    phonePrefix: "+297"
  },
  {
    name: "Australia",
    isoCodeAlpha2: "AU",
    isoCodeAlpha3: "AUS",
    phonePrefix: "+61"
  },
  {
    name: "Austria",
    isoCodeAlpha2: "AT",
    isoCodeAlpha3: "AUT",
    phonePrefix: "+43"
  },
  {
    name: "Azerbaijan",
    isoCodeAlpha2: "AZ",
    isoCodeAlpha3: "AZE",
    phonePrefix: "+994"
  },
  {
    name: "Bahamas",
    isoCodeAlpha2: "BS",
    isoCodeAlpha3: "BHS",
    phonePrefix: "+1-242"
  },
  {
    name: "Bahrain",
    isoCodeAlpha2: "BH",
    isoCodeAlpha3: "BHR",
    phonePrefix: "+973"
  },
  {
    name: "Bangladesh",
    isoCodeAlpha2: "BD",
    isoCodeAlpha3: "BGD",
    phonePrefix: "+880"
  },
  {
    name: "Barbados",
    isoCodeAlpha2: "BB",
    isoCodeAlpha3: "BRB",
    phonePrefix: "+1-246"
  },
  {
    name: "Belarus",
    isoCodeAlpha2: "BY",
    isoCodeAlpha3: "BLR",
    phonePrefix: "+375"
  },
  {
    name: "Belgium",
    isoCodeAlpha2: "BE",
    isoCodeAlpha3: "BEL",
    phonePrefix: "+32"
  },
  {
    name: "Belize",
    isoCodeAlpha2: "BZ",
    isoCodeAlpha3: "BLZ",
    phonePrefix: "+501"
  },
  {
    name: "Benin",
    isoCodeAlpha2: "BJ",
    isoCodeAlpha3: "BEN",
    phonePrefix: "+229"
  },
  {
    name: "Bermuda",
    isoCodeAlpha2: "BM",
    isoCodeAlpha3: "BMU",
    phonePrefix: "+1-441"
  },
  {
    name: "Bhutan",
    isoCodeAlpha2: "BT",
    isoCodeAlpha3: "BTN",
    phonePrefix: "+975"
  },
  {
    name: "Bolivia",
    isoCodeAlpha2: "BO",
    isoCodeAlpha3: "BOL",
    phonePrefix: "+591"
  },
  {
    name: "Bosnia and Herzegovina",
    isoCodeAlpha2: "BA",
    isoCodeAlpha3: "BIH",
    phonePrefix: "+387"
  },
  {
    name: "Botswana",
    isoCodeAlpha2: "BW",
    isoCodeAlpha3: "BWA",
    phonePrefix: "+267"
  },
  {
    name: "Brazil",
    isoCodeAlpha2: "BR",
    isoCodeAlpha3: "BRA",
    phonePrefix: "+55"
  },
  {
    name: "British Virgin Islands",
    isoCodeAlpha2: "VG",
    isoCodeAlpha3: "VGB",
    phonePrefix: "+1-284"
  },
  {
    name: "Brunei",
    isoCodeAlpha2: "BN",
    isoCodeAlpha3: "BRN",
    phonePrefix: "+673"
  },
  {
    name: "Bulgaria",
    isoCodeAlpha2: "BG",
    isoCodeAlpha3: "BGR",
    phonePrefix: "+359"
  },
  {
    name: "Burkina Faso",
    isoCodeAlpha2: "BF",
    isoCodeAlpha3: "BFA",
    phonePrefix: "+226"
  },
  {
    name: "Burundi",
    isoCodeAlpha2: "BI",
    isoCodeAlpha3: "BDI",
    phonePrefix: "+257"
  },
  {
    name: "Cambodia",
    isoCodeAlpha2: "KH",
    isoCodeAlpha3: "KHM",
    phonePrefix: "+855"
  },
  {
    name: "Cameroon",
    isoCodeAlpha2: "CM",
    isoCodeAlpha3: "CMR",
    phonePrefix: "+237"
  },
  {
    name: "Canada",
    isoCodeAlpha2: "CA",
    isoCodeAlpha3: "CAN",
    phonePrefix: "+1"
  },
  {
    name: "Cape Verde",
    isoCodeAlpha2: "CV",
    isoCodeAlpha3: "CPV",
    phonePrefix: "+238"
  },
  {
    name: "Cayman Islands",
    isoCodeAlpha2: "KY",
    isoCodeAlpha3: "CYM",
    phonePrefix: "+1-345"
  },
  {
    name: "Central African Republic",
    isoCodeAlpha2: "CF",
    isoCodeAlpha3: "CAF",
    phonePrefix: "+236"
  },
  {
    name: "Chad",
    isoCodeAlpha2: "TD",
    isoCodeAlpha3: "TCD",
    phonePrefix: "+235"
  },
  {
    name: "Chile",
    isoCodeAlpha2: "CL",
    isoCodeAlpha3: "CHL",
    phonePrefix: "+56"
  },
  {
    name: "China",
    isoCodeAlpha2: "CN",
    isoCodeAlpha3: "CHN",
    phonePrefix: "+86"
  },
  {
    name: "Christmas Island",
    isoCodeAlpha2: "CX",
    isoCodeAlpha3: "CXR",
    phonePrefix: "+61"
  },
  {
    name: "Cocos Islands",
    isoCodeAlpha2: "CC",
    isoCodeAlpha3: "CCK",
    phonePrefix: "+61"
  },
  {
    name: "Colombia",
    isoCodeAlpha2: "CO",
    isoCodeAlpha3: "COL",
    phonePrefix: "+57"
  },
  {
    name: "Comoros",
    isoCodeAlpha2: "KM",
    isoCodeAlpha3: "COM",
    phonePrefix: "+269"
  },
  {
    name: "Republic of the Congo",
    isoCodeAlpha2: "CG",
    isoCodeAlpha3: "COG",
    phonePrefix: "+242"
  },
  {
    name: "DR Congo",
    isoCodeAlpha2: "CD",
    isoCodeAlpha3: "COD",
    phonePrefix: "+243"
  },
  {
    name: "Cook Islands",
    isoCodeAlpha2: "CK",
    isoCodeAlpha3: "COK",
    phonePrefix: "+682"
  },
  {
    name: "Costa Rica",
    isoCodeAlpha2: "CR",
    isoCodeAlpha3: "CRI",
    phonePrefix: "+506"
  },
  {
    name: "Croatia",
    isoCodeAlpha2: "HR",
    isoCodeAlpha3: "HRV",
    phonePrefix: "+385"
  },
  {
    name: "Cuba",
    isoCodeAlpha2: "CU",
    isoCodeAlpha3: "CUB",
    phonePrefix: "+53"
  },
  {
    name: "Curacao",
    isoCodeAlpha2: "CW",
    isoCodeAlpha3: "CUW",
    phonePrefix: "+599"
  },
  {
    name: "Cyprus",
    isoCodeAlpha2: "CY",
    isoCodeAlpha3: "CYP",
    phonePrefix: "+357"
  },
  {
    name: "Czech Republic",
    isoCodeAlpha2: "CZ",
    isoCodeAlpha3: "CZE",
    phonePrefix: "+420"
  },
  {
    name: "Denmark",
    isoCodeAlpha2: "DK",
    isoCodeAlpha3: "DNK",
    phonePrefix: "+45"
  },
  {
    name: "Djibouti",
    isoCodeAlpha2: "DJ",
    isoCodeAlpha3: "DJI",
    phonePrefix: "+253"
  },
  {
    name: "Dominica",
    isoCodeAlpha2: "DM",
    isoCodeAlpha3: "DMA",
    phonePrefix: "+1-767"
  },
  {
    name: "Dominican Republic",
    isoCodeAlpha2: "DO",
    isoCodeAlpha3: "DOM",
    phonePrefix: "+1-809"
  },
  {
    name: "East Timor",
    isoCodeAlpha2: "TL",
    isoCodeAlpha3: "TLS",
    phonePrefix: "+670"
  },
  {
    name: "Ecuador",
    isoCodeAlpha2: "EC",
    isoCodeAlpha3: "ECU",
    phonePrefix: "+593"
  },
  {
    name: "Egypt",
    isoCodeAlpha2: "EG",
    isoCodeAlpha3: "EGY",
    phonePrefix: "+20"
  },
  {
    name: "El Salvador",
    isoCodeAlpha2: "SV",
    isoCodeAlpha3: "SLV",
    phonePrefix: "+503"
  },
  {
    name: "Equatorial Guinea",
    isoCodeAlpha2: "GQ",
    isoCodeAlpha3: "GNQ",
    phonePrefix: "+240"
  },
  {
    name: "Eritrea",
    isoCodeAlpha2: "ER",
    isoCodeAlpha3: "ERI",
    phonePrefix: "+291"
  },
  {
    name: "Estonia",
    isoCodeAlpha2: "EE",
    isoCodeAlpha3: "EST",
    phonePrefix: "+372"
  },
  {
    name: "Ethiopia",
    isoCodeAlpha2: "ET",
    isoCodeAlpha3: "ETH",
    phonePrefix: "+251"
  },
  {
    name: "Falkland Islands",
    isoCodeAlpha2: "FK",
    isoCodeAlpha3: "FLK",
    phonePrefix: "+500"
  },
  {
    name: "Faroe Islands",
    isoCodeAlpha2: "FO",
    isoCodeAlpha3: "FRO",
    phonePrefix: "+298"
  },
  {
    name: "Fiji",
    isoCodeAlpha2: "FJ",
    isoCodeAlpha3: "FJI",
    phonePrefix: "+679"
  },
  {
    name: "Finland",
    isoCodeAlpha2: "FI",
    isoCodeAlpha3: "FIN",
    phonePrefix: "+358"
  },
  {
    name: "France",
    isoCodeAlpha2: "FR",
    isoCodeAlpha3: "FRA",
    phonePrefix: "+33"
  },
  {
    name: "French Polynesia",
    isoCodeAlpha2: "PF",
    isoCodeAlpha3: "PYF",
    phonePrefix: "+689"
  },
  {
    name: "Gabon",
    isoCodeAlpha2: "GA",
    isoCodeAlpha3: "GAB",
    phonePrefix: "+241"
  },
  {
    name: "Gambia",
    isoCodeAlpha2: "GM",
    isoCodeAlpha3: "GMB",
    phonePrefix: "+220"
  },
  {
    name: "Georgia",
    isoCodeAlpha2: "GE",
    isoCodeAlpha3: "GEO",
    phonePrefix: "+995"
  },
  {
    name: "Germany",
    isoCodeAlpha2: "DE",
    isoCodeAlpha3: "DEU",
    phonePrefix: "+49"
  },
  {
    name: "Ghana",
    isoCodeAlpha2: "GH",
    isoCodeAlpha3: "GHA",
    phonePrefix: "+233"
  },
  {
    name: "Gibraltar",
    isoCodeAlpha2: "GI",
    isoCodeAlpha3: "GIB",
    phonePrefix: "+350"
  },
  {
    name: "Greece",
    isoCodeAlpha2: "GR",
    isoCodeAlpha3: "GRC",
    phonePrefix: "+30"
  },
  {
    name: "Greenland",
    isoCodeAlpha2: "GL",
    isoCodeAlpha3: "GRL",
    phonePrefix: "+299"
  },
  {
    name: "Grenada",
    isoCodeAlpha2: "GD",
    isoCodeAlpha3: "GRD",
    phonePrefix: "+1-473"
  },
  {
    name: "Guam",
    isoCodeAlpha2: "GU",
    isoCodeAlpha3: "GUM",
    phonePrefix: "+1-671"
  },
  {
    name: "Guatemala",
    isoCodeAlpha2: "GT",
    isoCodeAlpha3: "GTM",
    phonePrefix: "+502"
  },
  {
    name: "Guinea",
    isoCodeAlpha2: "GN",
    isoCodeAlpha3: "GIN",
    phonePrefix: "+224"
  },
  {
    name: "Guinea-Bissau",
    isoCodeAlpha2: "GW",
    isoCodeAlpha3: "GNB",
    phonePrefix: "+245"
  },
  {
    name: "Guyana",
    isoCodeAlpha2: "GY",
    isoCodeAlpha3: "GUY",
    phonePrefix: "+592"
  },
  {
    name: "Haiti",
    isoCodeAlpha2: "HT",
    isoCodeAlpha3: "HTI",
    phonePrefix: "+509"
  },
  {
    name: "Honduras",
    isoCodeAlpha2: "HN",
    isoCodeAlpha3: "HND",
    phonePrefix: "+504"
  },
  {
    name: "Hong Kong",
    isoCodeAlpha2: "HK",
    isoCodeAlpha3: "HKG",
    phonePrefix: "+852"
  },
  {
    name: "Hungary",
    isoCodeAlpha2: "HU",
    isoCodeAlpha3: "HUN",
    phonePrefix: "+36"
  },
  {
    name: "Iceland",
    isoCodeAlpha2: "IS",
    isoCodeAlpha3: "ISL",
    phonePrefix: "+354"
  },
  {
    name: "India",
    isoCodeAlpha2: "IN",
    isoCodeAlpha3: "IND",
    phonePrefix: "+91"
  },
  {
    name: "Indonesia",
    isoCodeAlpha2: "ID",
    isoCodeAlpha3: "IDN",
    phonePrefix: "+62"
  },
  {
    name: "Iran",
    isoCodeAlpha2: "IR",
    isoCodeAlpha3: "IRN",
    phonePrefix: "+98"
  },
  {
    name: "Iraq",
    isoCodeAlpha2: "IQ",
    isoCodeAlpha3: "IRQ",
    phonePrefix: "+964"
  },
  {
    name: "Ireland",
    isoCodeAlpha2: "IE",
    isoCodeAlpha3: "IRL",
    phonePrefix: "+353"
  },
  {
    name: "Israel",
    isoCodeAlpha2: "IL",
    isoCodeAlpha3: "ISR",
    phonePrefix: "+972"
  },
  {
    name: "Italy",
    isoCodeAlpha2: "IT",
    isoCodeAlpha3: "ITA",
    phonePrefix: "+39"
  },
  {
    name: "Ivory Coast",
    isoCodeAlpha2: "CI",
    isoCodeAlpha3: "CIV",
    phonePrefix: "+225"
  },
  {
    name: "Jamaica",
    isoCodeAlpha2: "JM",
    isoCodeAlpha3: "JAM",
    phonePrefix: "+1-876"
  },
  {
    name: "Japan",
    isoCodeAlpha2: "JP",
    isoCodeAlpha3: "JPN",
    phonePrefix: "+81"
  },
  {
    name: "Jordan",
    isoCodeAlpha2: "JO",
    isoCodeAlpha3: "JOR",
    phonePrefix: "+962"
  },
  {
    name: "Kazakhstan",
    isoCodeAlpha2: "KZ",
    isoCodeAlpha3: "KAZ",
    phonePrefix: "+7"
  },
  {
    name: "Kenya",
    isoCodeAlpha2: "KE",
    isoCodeAlpha3: "KEN",
    phonePrefix: "+254"
  },
  {
    name: "Kiribati",
    isoCodeAlpha2: "KI",
    isoCodeAlpha3: "KIR",
    phonePrefix: "+686"
  },
  {
    name: "Kosovo",
    isoCodeAlpha2: "XK",
    isoCodeAlpha3: "XKX",
    phonePrefix: "+383"
  },
  {
    name: "Kuwait",
    isoCodeAlpha2: "KW",
    isoCodeAlpha3: "KWT",
    phonePrefix: "+965"
  },
  {
    name: "Kyrgyzstan",
    isoCodeAlpha2: "KG",
    isoCodeAlpha3: "KGZ",
    phonePrefix: "+996"
  },
  {
    name: "Laos",
    isoCodeAlpha2: "LA",
    isoCodeAlpha3: "LAO",
    phonePrefix: "+856"
  },
  {
    name: "Latvia",
    isoCodeAlpha2: "LV",
    isoCodeAlpha3: "LVA",
    phonePrefix: "+371"
  },
  {
    name: "Lebanon",
    isoCodeAlpha2: "LB",
    isoCodeAlpha3: "LBN",
    phonePrefix: "+961"
  },
  {
    name: "Lesotho",
    isoCodeAlpha2: "LS",
    isoCodeAlpha3: "LSO",
    phonePrefix: "+266"
  },
  {
    name: "Liberia",
    isoCodeAlpha2: "LR",
    isoCodeAlpha3: "LBR",
    phonePrefix: "+231"
  },
  {
    name: "Libya",
    isoCodeAlpha2: "LY",
    isoCodeAlpha3: "LBY",
    phonePrefix: "+218"
  },
  {
    name: "Liechtenstein",
    isoCodeAlpha2: "LI",
    isoCodeAlpha3: "LIE",
    phonePrefix: "+423"
  },
  {
    name: "Lithuania",
    isoCodeAlpha2: "LT",
    isoCodeAlpha3: "LTU",
    phonePrefix: "+370"
  },
  {
    name: "Luxembourg",
    isoCodeAlpha2: "LU",
    isoCodeAlpha3: "LUX",
    phonePrefix: "+352"
  },
  {
    name: "Macau",
    isoCodeAlpha2: "MO",
    isoCodeAlpha3: "MAC",
    phonePrefix: "+853"
  },
  {
    name: "Macedonia",
    isoCodeAlpha2: "MK",
    isoCodeAlpha3: "MKD",
    phonePrefix: "+389"
  },
  {
    name: "Madagascar",
    isoCodeAlpha2: "MG",
    isoCodeAlpha3: "MDG",
    phonePrefix: "+261"
  },
  {
    name: "Malawi",
    isoCodeAlpha2: "MW",
    isoCodeAlpha3: "MWI",
    phonePrefix: "+265"
  },
  {
    name: "Malaysia",
    isoCodeAlpha2: "MY",
    isoCodeAlpha3: "MYS",
    phonePrefix: "+60"
  },
  {
    name: "Maldives",
    isoCodeAlpha2: "MV",
    isoCodeAlpha3: "MDV",
    phonePrefix: "+960"
  },
  {
    name: "Mali",
    isoCodeAlpha2: "ML",
    isoCodeAlpha3: "MLI",
    phonePrefix: "+223"
  },
  {
    name: "Malta",
    isoCodeAlpha2: "MT",
    isoCodeAlpha3: "MLT",
    phonePrefix: "+356"
  },
  {
    name: "Marshall Islands",
    isoCodeAlpha2: "MH",
    isoCodeAlpha3: "MHL",
    phonePrefix: "+692"
  },
  {
    name: "Mauritania",
    isoCodeAlpha2: "MR",
    isoCodeAlpha3: "MRT",
    phonePrefix: "+222"
  },
  {
    name: "Mauritius",
    isoCodeAlpha2: "MU",
    isoCodeAlpha3: "MUS",
    phonePrefix: "+230"
  },
  {
    name: "Mayotte",
    isoCodeAlpha2: "YT",
    isoCodeAlpha3: "MYT",
    phonePrefix: "+262"
  },
  {
    name: "Mexico",
    isoCodeAlpha2: "MX",
    isoCodeAlpha3: "MEX",
    phonePrefix: "+52"
  },
  {
    name: "Micronesia",
    isoCodeAlpha2: "FM",
    isoCodeAlpha3: "FSM",
    phonePrefix: "+691"
  },
  {
    name: "Moldova",
    isoCodeAlpha2: "MD",
    isoCodeAlpha3: "MDA",
    phonePrefix: "+373"
  },
  {
    name: "Monaco",
    isoCodeAlpha2: "MC",
    isoCodeAlpha3: "MCO",
    phonePrefix: "+377"
  },
  {
    name: "Mongolia",
    isoCodeAlpha2: "MN",
    isoCodeAlpha3: "MNG",
    phonePrefix: "+976"
  },
  {
    name: "Montenegro",
    isoCodeAlpha2: "ME",
    isoCodeAlpha3: "MNE",
    phonePrefix: "+382"
  },
  {
    name: "Montserrat",
    isoCodeAlpha2: "MS",
    isoCodeAlpha3: "MSR",
    phonePrefix: "+1-664"
  },
  {
    name: "Morocco",
    isoCodeAlpha2: "MA",
    isoCodeAlpha3: "MAR",
    phonePrefix: "+212"
  },
  {
    name: "Mozambique",
    isoCodeAlpha2: "MZ",
    isoCodeAlpha3: "MOZ",
    phonePrefix: "+258"
  },
  {
    name: "Myanmar",
    isoCodeAlpha2: "MM",
    isoCodeAlpha3: "MMR",
    phonePrefix: "+95"
  },
  {
    name: "Namibia",
    isoCodeAlpha2: "NA",
    isoCodeAlpha3: "NAM",
    phonePrefix: "+264"
  },
  {
    name: "Nauru",
    isoCodeAlpha2: "NR",
    isoCodeAlpha3: "NRU",
    phonePrefix: "+674"
  },
  {
    name: "Nepal",
    isoCodeAlpha2: "NP",
    isoCodeAlpha3: "NPL",
    phonePrefix: "+977"
  },
  {
    name: "Netherlands",
    isoCodeAlpha2: "NL",
    isoCodeAlpha3: "NLD",
    phonePrefix: "+31"
  },
  {
    name: "Netherlands Antilles",
    isoCodeAlpha2: "AN",
    isoCodeAlpha3: "ANT",
    phonePrefix: "+599"
  },
  {
    name: "New Caledonia",
    isoCodeAlpha2: "NC",
    isoCodeAlpha3: "NCL",
    phonePrefix: "+687"
  },
  {
    name: "New Zealand",
    isoCodeAlpha2: "NZ",
    isoCodeAlpha3: "NZL",
    phonePrefix: "+64"
  },
  {
    name: "Nicaragua",
    isoCodeAlpha2: "NI",
    isoCodeAlpha3: "NIC",
    phonePrefix: "+505"
  },
  {
    name: "Niger",
    isoCodeAlpha2: "NE",
    isoCodeAlpha3: "NER",
    phonePrefix: "+227"
  },
  {
    name: "Nigeria",
    isoCodeAlpha2: "NG",
    isoCodeAlpha3: "NGA",
    phonePrefix: "+234"
  },
  {
    name: "Niue",
    isoCodeAlpha2: "NU",
    isoCodeAlpha3: "NIU",
    phonePrefix: "+683"
  },
  {
    name: "Northern Mariana Islands",
    isoCodeAlpha2: "MP",
    isoCodeAlpha3: "MNP",
    phonePrefix: "+1-670"
  },
  {
    name: "North Korea",
    isoCodeAlpha2: "KP",
    isoCodeAlpha3: "PRK",
    phonePrefix: "+850"
  },
  {
    name: "Norway",
    isoCodeAlpha2: "NO",
    isoCodeAlpha3: "NOR",
    phonePrefix: "+47"
  },
  {
    name: "Oman",
    isoCodeAlpha2: "OM",
    isoCodeAlpha3: "OMN",
    phonePrefix: "+968"
  },
  {
    name: "Pakistan",
    isoCodeAlpha2: "PK",
    isoCodeAlpha3: "PAK",
    phonePrefix: "+92"
  },
  {
    name: "Palau",
    isoCodeAlpha2: "PW",
    isoCodeAlpha3: "PLW",
    phonePrefix: "+680"
  },
  {
    name: "Palestine",
    isoCodeAlpha2: "PS",
    isoCodeAlpha3: "PSE",
    phonePrefix: "+970"
  },
  {
    name: "Panama",
    isoCodeAlpha2: "PA",
    isoCodeAlpha3: "PAN",
    phonePrefix: "+507"
  },
  {
    name: "Papua New Guinea",
    isoCodeAlpha2: "PG",
    isoCodeAlpha3: "PNG",
    phonePrefix: "+675"
  },
  {
    name: "Paraguay",
    isoCodeAlpha2: "PY",
    isoCodeAlpha3: "PRY",
    phonePrefix: "+595"
  },
  {
    name: "Peru",
    isoCodeAlpha2: "PE",
    isoCodeAlpha3: "PER",
    phonePrefix: "+51"
  },
  {
    name: "Philippines",
    isoCodeAlpha2: "PH",
    isoCodeAlpha3: "PHL",
    phonePrefix: "+63"
  },
  {
    name: "Pitcairn",
    isoCodeAlpha2: "PN",
    isoCodeAlpha3: "PCN",
    phonePrefix: "+64"
  },
  {
    name: "Poland",
    isoCodeAlpha2: "PL",
    isoCodeAlpha3: "POL",
    phonePrefix: "+48"
  },
  {
    name: "Portugal",
    isoCodeAlpha2: "PT",
    isoCodeAlpha3: "PRT",
    phonePrefix: "+351"
  },
  {
    name: "Puerto Rico",
    isoCodeAlpha2: "PR",
    isoCodeAlpha3: "PRI",
    phonePrefix: "+1-787"
  },
  {
    name: "Qatar",
    isoCodeAlpha2: "QA",
    isoCodeAlpha3: "QAT",
    phonePrefix: "+974"
  },
  {
    name: "Reunion",
    isoCodeAlpha2: "RE",
    isoCodeAlpha3: "REU",
    phonePrefix: "+262"
  },
  {
    name: "Romania",
    isoCodeAlpha2: "RO",
    isoCodeAlpha3: "ROU",
    phonePrefix: "+40"
  },
  {
    name: "Russia",
    isoCodeAlpha2: "RU",
    isoCodeAlpha3: "RUS",
    phonePrefix: "+7"
  },
  {
    name: "Rwanda",
    isoCodeAlpha2: "RW",
    isoCodeAlpha3: "RWA",
    phonePrefix: "+250"
  },
  {
    name: "Saint Barthelemy",
    isoCodeAlpha2: "BL",
    isoCodeAlpha3: "BLM",
    phonePrefix: "+590"
  },
  {
    name: "Samoa",
    isoCodeAlpha2: "WS",
    isoCodeAlpha3: "WSM",
    phonePrefix: "+685"
  },
  {
    name: "San Marino",
    isoCodeAlpha2: "SM",
    isoCodeAlpha3: "SMR",
    phonePrefix: "+378"
  },
  {
    name: "Sao Tome and Principe",
    isoCodeAlpha2: "ST",
    isoCodeAlpha3: "STP",
    phonePrefix: "+239"
  },
  {
    name: "Saudi Arabia",
    isoCodeAlpha2: "SA",
    isoCodeAlpha3: "SAU",
    phonePrefix: "+966"
  },
  {
    name: "Senegal",
    isoCodeAlpha2: "SN",
    isoCodeAlpha3: "SEN",
    phonePrefix: "+221"
  },
  {
    name: "Serbia",
    isoCodeAlpha2: "RS",
    isoCodeAlpha3: "SRB",
    phonePrefix: "+381"
  },
  {
    name: "Seychelles",
    isoCodeAlpha2: "SC",
    isoCodeAlpha3: "SYC",
    phonePrefix: "+248"
  },
  {
    name: "Sierra Leone",
    isoCodeAlpha2: "SL",
    isoCodeAlpha3: "SLE",
    phonePrefix: "+232"
  },
  {
    name: "Singapore",
    isoCodeAlpha2: "SG",
    isoCodeAlpha3: "SGP",
    phonePrefix: "+65"
  },
  {
    name: "Sint Maarten",
    isoCodeAlpha2: "SX",
    isoCodeAlpha3: "SXM",
    phonePrefix: "+1-721"
  },
  {
    name: "Slovakia",
    isoCodeAlpha2: "SK",
    isoCodeAlpha3: "SVK",
    phonePrefix: "+421"
  },
  {
    name: "Slovenia",
    isoCodeAlpha2: "SI",
    isoCodeAlpha3: "SVN",
    phonePrefix: "+386"
  },
  {
    name: "Solomon Islands",
    isoCodeAlpha2: "SB",
    isoCodeAlpha3: "SLB",
    phonePrefix: "+677"
  },
  {
    name: "Somalia",
    isoCodeAlpha2: "SO",
    isoCodeAlpha3: "SOM",
    phonePrefix: "+252"
  },
  {
    name: "South Africa",
    isoCodeAlpha2: "ZA",
    isoCodeAlpha3: "ZAF",
    phonePrefix: "+27"
  },
  {
    name: "South Korea",
    isoCodeAlpha2: "KR",
    isoCodeAlpha3: "KOR",
    phonePrefix: "+82"
  },
  {
    name: "South Sudan",
    isoCodeAlpha2: "SS",
    isoCodeAlpha3: "SSD",
    phonePrefix: "+211"
  },
  {
    name: "Spain",
    isoCodeAlpha2: "ES",
    isoCodeAlpha3: "ESP",
    phonePrefix: "+34"
  },
  {
    name: "Sri Lanka",
    isoCodeAlpha2: "LK",
    isoCodeAlpha3: "LKA",
    phonePrefix: "+94"
  },
  {
    name: "Saint Helena",
    isoCodeAlpha2: "SH",
    isoCodeAlpha3: "SHN",
    phonePrefix: "+290"
  },
  {
    name: "Saint Kitts and Nevis",
    isoCodeAlpha2: "KN",
    isoCodeAlpha3: "KNA",
    phonePrefix: "+1-869"
  },
  {
    name: "Saint Lucia",
    isoCodeAlpha2: "LC",
    isoCodeAlpha3: "LCA",
    phonePrefix: "+1-758"
  },
  {
    name: "Saint Martin",
    isoCodeAlpha2: "MF",
    isoCodeAlpha3: "MAF",
    phonePrefix: "+590"
  },
  {
    name: "Saint Pierre and Miquelon",
    isoCodeAlpha2: "PM",
    isoCodeAlpha3: "SPM",
    phonePrefix: "+508"
  },
  {
    name: "Saint Vincent Grenadines",
    isoCodeAlpha2: "VC",
    isoCodeAlpha3: "VCT",
    phonePrefix: "+1-784"
  },
  {
    name: "Sudan",
    isoCodeAlpha2: "SD",
    isoCodeAlpha3: "SDN",
    phonePrefix: "+249"
  },
  {
    name: "Suriname",
    isoCodeAlpha2: "SR",
    isoCodeAlpha3: "SUR",
    phonePrefix: "+597"
  },
  {
    name: "Svalbard and Jan Mayen",
    isoCodeAlpha2: "SJ",
    isoCodeAlpha3: "SJM",
    phonePrefix: "+47"
  },
  {
    name: "Swaziland",
    isoCodeAlpha2: "SZ",
    isoCodeAlpha3: "SWZ",
    phonePrefix: "+268"
  },
  {
    name: "Sweden",
    isoCodeAlpha2: "SE",
    isoCodeAlpha3: "SWE",
    phonePrefix: "+46"
  },
  {
    name: "Switzerland",
    isoCodeAlpha2: "CH",
    isoCodeAlpha3: "CHE",
    phonePrefix: "+41"
  },
  {
    name: "Syria",
    isoCodeAlpha2: "SY",
    isoCodeAlpha3: "SYR",
    phonePrefix: "+963"
  },
  {
    name: "Taiwan",
    isoCodeAlpha2: "TW",
    isoCodeAlpha3: "TWN",
    phonePrefix: "+886"
  },
  {
    name: "Tajikistan",
    isoCodeAlpha2: "TJ",
    isoCodeAlpha3: "TJK",
    phonePrefix: "+992"
  },
  {
    name: "Tanzania",
    isoCodeAlpha2: "TZ",
    isoCodeAlpha3: "TZA",
    phonePrefix: "+255"
  },
  {
    name: "Thailand",
    isoCodeAlpha2: "TH",
    isoCodeAlpha3: "THA",
    phonePrefix: "+66"
  },
  {
    name: "Togo",
    isoCodeAlpha2: "TG",
    isoCodeAlpha3: "TGO",
    phonePrefix: "+228"
  },
  {
    name: "Tokelau",
    isoCodeAlpha2: "TK",
    isoCodeAlpha3: "TKL",
    phonePrefix: "+690"
  },
  {
    name: "Tonga",
    isoCodeAlpha2: "TO",
    isoCodeAlpha3: "TON",
    phonePrefix: "+676"
  },
  {
    name: "Trinidad and Tobago",
    isoCodeAlpha2: "TT",
    isoCodeAlpha3: "TTO",
    phonePrefix: "+1-868"
  },
  {
    name: "Tunisia",
    isoCodeAlpha2: "TN",
    isoCodeAlpha3: "TUN",
    phonePrefix: "+216"
  },
  {
    name: "Turkey",
    isoCodeAlpha2: "TR",
    isoCodeAlpha3: "TUR",
    phonePrefix: "+90"
  },
  {
    name: "Turkmenistan",
    isoCodeAlpha2: "TM",
    isoCodeAlpha3: "TKM",
    phonePrefix: "+993"
  },
  {
    name: "Turks and Caicos Islands",
    isoCodeAlpha2: "TC",
    isoCodeAlpha3: "TCA",
    phonePrefix: "+1-649"
  },
  {
    name: "Tuvalu",
    isoCodeAlpha2: "TV",
    isoCodeAlpha3: "TUV",
    phonePrefix: "+688"
  },
  {
    name: "United Arab Emirates",
    isoCodeAlpha2: "AE",
    isoCodeAlpha3: "ARE",
    phonePrefix: "+971"
  },
  {
    name: "Uganda",
    isoCodeAlpha2: "UG",
    isoCodeAlpha3: "UGA",
    phonePrefix: "+256"
  },
  {
    name: "United Kingdom",
    isoCodeAlpha2: "GB",
    isoCodeAlpha3: "GBR",
    phonePrefix: "+44"
  },
  {
    name: "Ukraine",
    isoCodeAlpha2: "UA",
    isoCodeAlpha3: "UKR",
    phonePrefix: "+380"
  },
  {
    name: "Uruguay",
    isoCodeAlpha2: "UY",
    isoCodeAlpha3: "URY",
    phonePrefix: "+598"
  },
  {
    name: "United States",
    isoCodeAlpha2: "US",
    isoCodeAlpha3: "USA",
    phonePrefix: "+1"
  },
  {
    name: "Uzbekistan",
    isoCodeAlpha2: "UZ",
    isoCodeAlpha3: "UZB",
    phonePrefix: "+998"
  },
  {
    name: "Vanuatu",
    isoCodeAlpha2: "VU",
    isoCodeAlpha3: "VUT",
    phonePrefix: "+678"
  },
  {
    name: "Vatican",
    isoCodeAlpha2: "VA",
    isoCodeAlpha3: "VAT",
    phonePrefix: "+379"
  },
  {
    name: "Venezuela",
    isoCodeAlpha2: "VE",
    isoCodeAlpha3: "VEN",
    phonePrefix: "+58"
  },
  {
    name: "Vietnam",
    isoCodeAlpha2: "VN",
    isoCodeAlpha3: "VNM",
    phonePrefix: "+84"
  },
  {
    name: "U.S. Virgin Islands",
    isoCodeAlpha2: "VI",
    isoCodeAlpha3: "VIR",
    phonePrefix: "+1-340"
  },
  {
    name: "Wallis and Futuna",
    isoCodeAlpha2: "WF",
    isoCodeAlpha3: "WLF",
    phonePrefix: "+681"
  },
  {
    name: "Western Sahara",
    isoCodeAlpha2: "EH",
    isoCodeAlpha3: "ESH",
    phonePrefix: "+212"
  },
  {
    name: "Yemen",
    isoCodeAlpha2: "YE",
    isoCodeAlpha3: "YEM",
    phonePrefix: "+967"
  },
  {
    name: "Zambia",
    isoCodeAlpha2: "ZM",
    isoCodeAlpha3: "ZMB",
    phonePrefix: "+260"
  },
  {
    name: "Zimbabwe",
    isoCodeAlpha2: "ZW",
    isoCodeAlpha3: "ZWE",
    phonePrefix: "+263"
  }
];

export default countries;
