import React, { useEffect, useRef, useState } from "react";
import { Button, PageTitle, Table, Spinner, Icon } from "components";
import moment from "moment";
import { checkIfSurveyIsAnswered } from "utils/mappers";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/useLanguage";
import { formatDate, formatDateTime, formatBirthDate } from "utils/formatData";
import PatientSurveyForm from "components/PatientSurveyForm";
import QrCode from "components/QrCode";
import ComponentToPrint from "components/ComponentToPrint";
import { useReactToPrint } from 'react-to-print';
import "./patientTestResults.scss";

const PatientTestResults = ({
  patient,
  loading,
  testResults,
  getTestTypeSurvey,
  testTypeSurveyIsLoading,
  countryPatientSurvey,
  patientIsUpdating,
  updatePatient,
  saveTestTypeSurveyAnswers,
  testTypeSurveyIsSaving,
  patientDeleted,
  patientQRCode,
  isMember,
  cohorts
}) => {
  const { t } = useTranslation();
  const { flippedNameOrder } = useLanguage();
  const [surveyFormStatus, setSurveyFormStatus] = React.useState(false);
  const [selectedTest, setSelectedTest] = React.useState(null);
  const [surveyModalConfig, setSurveyModalConfig] = React.useState({});
  const [newPatient, setNewPatient] = React.useState(patient);
  const [countrySurveyAnswered, setCountrySurveyAnswered] = React.useState(
    null
  );
  const [surveyChanged, setSurveyChanged] = React.useState(false);
  const [QRCodeData, setQrCodeData] = useState(null);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (QRCodeData) { handlePrint() }
  }, [QRCodeData, handlePrint])

  React.useEffect(() => {
    if (countryPatientSurvey) {
      if (
        countryPatientSurvey?.surveyId !== patient.extendedProfile?.surveyId
      ) {
        setSurveyChanged(true);
        setCountrySurveyAnswered(
          checkIfSurveyIsAnswered(null, countryPatientSurvey?.survey)
        );
      } else {
        setCountrySurveyAnswered(
          checkIfSurveyIsAnswered(patient, countryPatientSurvey?.survey)
        );
      }
    }
  }, [patient, countryPatientSurvey]);

  const clearFlow = React.useCallback(() => {
    setSurveyFormStatus(false);
    setSelectedTest(null);
  }, []);

  const hideSurveyForm = React.useCallback(() => {
    setSurveyFormStatus(false);
  }, []);

  const hideAndPrintSurveyForm = React.useCallback(
    async (params, testTypeSurvey, selectedTest) => {
      let questionary = {
        surveyId: testTypeSurvey.surveyId,
        surveyAnswers: params?.surveyAnswers,
        surveyVersion: testTypeSurvey.surveyVersion,
        surveyChecksum: testTypeSurvey.surveyChecksum
      };
      let result = await saveTestTypeSurveyAnswers(
        selectedTest.testId,
        questionary
      );
      setSurveyFormStatus(false);
      if (result) {
        setQrCodeData(selectedTest);
      }
      clearFlow();
    },
    [setQrCodeData, clearFlow, saveTestTypeSurveyAnswers]
  );

  const getTestSurvey = React.useCallback(
    selectedTest => {
      (async () => {
        setQrCodeData(null)
        let result = await getTestTypeSurvey(
          selectedTest.testType,
          patient.configuration.country
        );
        if (result) {
          setSurveyModalConfig({
            hide: hideSurveyForm,
            extendedProfile: result.survey.questions,
            onSurveySubmit: params =>
              hideAndPrintSurveyForm(params, result, selectedTest),
            answers: [],
            name: "TEST"
          });
          setSurveyFormStatus(true);
        } else {
          setQrCodeData(selectedTest);
        }
      })();
    },
    [
      patient,
      getTestTypeSurvey,
      hideSurveyForm,
      hideAndPrintSurveyForm,
      setQrCodeData
    ]
  );

  const displaySurveys = row => {
    if (
      (countryPatientSurvey && !countrySurveyAnswered) ||
      (surveyChanged && !countrySurveyAnswered)
    ) {
      //open unanswered patient survey
      setSurveyModalConfig({
        hide: hideSurveyForm,
        extendedProfile: countryPatientSurvey.survey.questions,
        onSurveySubmit: params => onPatientSurveySubmit(params, row),
        answers: surveyChanged
          ? []
          : patient.extendedProfile?.surveyAnswers || [],
        name: "PATIENT"
      });
      setSurveyFormStatus(true);
    } else if (row.original.isSurveyEnabled && !row.original.isSurveyAnswered) {
      getTestSurvey(row.original);
    } else {
      setQrCodeData(row.original);
    }
  };

  const onPatientSurveySubmit = React.useCallback(
    async (params, row) => {
      let questionary = {
        surveyId: countryPatientSurvey.surveyId,
        surveyAnswers: params?.surveyAnswers,
        surveyVersion: countryPatientSurvey.surveyVersion,
        surveyChecksum: countryPatientSurvey.surveyChecksum
      };
      setNewPatient({
        ...newPatient,
        extendedProfile: questionary
      });
      let patientRequest = patientDeleted
        ? {
            ...newPatient,
            extendedProfile: questionary,
            profile: {
              ...newPatient.profile,
              dateOfBirth: moment(newPatient.profile.dob).format("YYYY-MM-DD"),
              phoneNumber: null
            }
          }
        : { ...newPatient, extendedProfile: questionary };
      await updatePatient(newPatient.patientId, patientRequest);

      setCountrySurveyAnswered(true);
      if (row.original.isSurveyEnabled && !row.original.isSurveyAnswered) {
        getTestSurvey(row.original);
      } else {
        setQrCodeData(row.original);
      }
      hideSurveyForm();
    },
    [
      newPatient,
      countryPatientSurvey,
      getTestSurvey,
      hideSurveyForm,
      setQrCodeData,
      updatePatient,
      patientDeleted
    ]
  );

  const data = testResults?.length ? testResults : [];

  const formatResult = res => {
    const { serverTime } = patient;
    const { testResult, testStatus, testConfigurationItem } = res;
    const values = testConfigurationItem.testConfiguration.testOutcome.values;
    if (testStatus === "STATUS_PENDING") {
      return testConfigurationItem.testConfiguration.pendingState?.activeValue;
    } else if (!testResult) {
      return "";
    } else {
      return serverTime < res?.testResultExpirationTime
        ? values?.find(value => value.id === testResult)?.activeValue
        : values?.find(value => value.id === testResult)?.expiredValue;
    }
  };

  const decode = str => {
    let buff = Buffer.from(str, "base64");
    return buff.toString("ascii");

  };

  const formattedPatientData = data.map(item => {
    return {
      ...item,
      date: formatDate(item.registrationTime),
      testName: item.testConfigurationItem.testConfiguration.testTypeName,
      testResult: formatResult(item),
      testConfigId: (item.originalTestKitId && decode(item.originalTestKitId))|| item.testId
    };
  });

  const formattedMemberData = data
    .reduce((list, result) => {
      const resultInList = {
        ...result,
        date: formatDate(result.registrationTime),
        testName: result.testConfigurationItem.testConfiguration.testTypeName,
        testResult: formatResult(result),
        testConfigId: (result.originalTestKitId && decode(result.originalTestKitId)) || result.testId,
        cohortNames: cohorts?.find(cohort => cohort.cohortId === result.cohortId)?.name,
        cohortIds: [result.cohortId]
      }
      const alreadyExist = list.find(l => l.testConfigId === resultInList.testConfigId && l.testType === resultInList.testType)
      if(alreadyExist) {
        alreadyExist["cohortIds"].push(resultInList.cohortId);
        alreadyExist["cohortNames"] = alreadyExist.cohortIds.map(c => cohorts.find(cohort => cohort.cohortId === c)?.name).join(", ")
        return [...list]
      } else {
        return [...list, resultInList]
      }
    }, [])

  const cohortColumn = [
    {
      Header: t("cohort_profile_label"),
      accessor: "cohortNames"
    },
  ]

  const testResultsColumns = [
    {
      Header: t("date"),
      accessor: "date",
      style: {"minWidth": "75px"},
    },
    {
      Header: t("test_name"),
      accessor: "testName"
    },
    {
      Header: t("test_type"),
      accessor: "testType"
    },
    {
      Header: t("sample_id"),
      accessor: "testConfigId",
      className: "column-sample-id"
    },
    ...(isMember ? cohortColumn : []),
    {
      Header: t("result"),
      accessor: "testResult"
    },
    {
      Header: t("health_care_organizations_actions"),
      style: {"minWidth": "70px"},
      Cell: ({ row }) => {
        return (
          <React.Fragment>
            <Button
              variant="row-action"
              title={t("print")}
              onClick={() => {
                setSelectedTest(row.original);
                if(isMember) {
                  setQrCodeData(row.original);
                } else {
                  displaySurveys(row);
                }
              }}
            >
              {testTypeSurveyIsLoading &&
              selectedTest?.testId === row.original.testId ? (
                <Spinner />
              ) : (
                <Icon type="print" />
              )}
            </Button>
          </React.Fragment>
        );
      }
    }
  ];

  const { profile } = patient;

  return (
    <>
      <ComponentToPrint ref={componentRef}>
        <QrCode
          code={`${patientQRCode}`}
          type={"certificate"}
          isPrint={true}
          data={{
            name: flippedNameOrder
              ? `${profile?.lastName} ${profile?.firstName}`
              : `${profile?.firstName} ${profile?.lastName}`,
            dateOfBirth: patientDeleted
              ? formatBirthDate(profile?.dob)
              : formatBirthDate(profile?.dateOfBirth),
            valid:
              QRCodeData?.testResultExpirationTime &&
              formatDateTime(QRCodeData?.testResultExpirationTime),
            tested: QRCodeData?.recordedTime && formatDateTime(QRCodeData?.recordedTime),
            testName: QRCodeData?.testName,
            testType: QRCodeData?.testType,
            sampleId: QRCodeData?.testConfigId,
            result: QRCodeData?.testResult
          }}
        ></QrCode>
      </ComponentToPrint>
      <div className="patient-test-results">
        <PageTitle title={t("test_results")} />
        {loading ? (
          <Spinner marginTop="2" />
        ) : (
          <Table
            columns={testResultsColumns}
            data={isMember ? formattedMemberData : formattedPatientData}
            className="patient-test-results-table"
            disabledSort={true}
            pagination
            pageSize={5}
          />
        )}
      </div>
      {surveyFormStatus && (
        <PatientSurveyForm
          hide={surveyModalConfig.hide}
          extendedProfile={surveyModalConfig.extendedProfile}
          onSurveySubmit={surveyModalConfig.onSurveySubmit}
          loading={testTypeSurveyIsSaving || patientIsUpdating}
          answers={surveyModalConfig.answers}
          name={surveyModalConfig.name}
        />
      )}
    </>
  );
};

PatientTestResults.propTypes = {};

export default PatientTestResults;
