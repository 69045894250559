import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import Spinner from "components/Spinner";
import PageTitle from "components/PageTitle";
import Table from "components/Table";
import Icon from "components/Icon";
import Button from "components/Button";
import OrganizationForm from "./OrganizationForm";
import "./organizations.scss";
import { sortIgnoreCase } from "utils/sortMethods";

import { useTranslation } from "react-i18next";

const Organizations = ({
  countries,
  clearState,
  organizations,
  getOrganizations,
  organizationSaving,
  createOrganization,
  organizationCreating,
  selectedOrganization,
  organizationsLoading,
  setSelectedOrganization,
  adminUpdateOrganizationName
}) => {
  const [organizationForm, setOrganizationFormStatus] = React.useState(false);
  const [currentOrganization, setCurrentOrganization] = React.useState({});
  const [organizationsFetched, setOrganizationsFetched] = React.useState(false);

  const { t } = useTranslation();

  const fetchOrganizations = React.useCallback(() => {
    getOrganizations();
  }, [getOrganizations]);

  React.useEffect(() => {
    if(!organizationsFetched && !organizations.length) {
      fetchOrganizations();
      setOrganizationsFetched(true)
    }
    return () => {
      clearState();
    };
  }, [clearState, fetchOrganizations, organizationsFetched, organizations]);

  const editOrganization = organizationId => {
    const organization = organizations.find(
      o => o.organizationId === organizationId
    );
    setCurrentOrganization(organization);
    setOrganizationFormStatus(true);
  };

  const updateOrganizationData = React.useCallback(
    async ({ institutionName, reference, countryCode }) => {
      if (currentOrganization.organizationId) {
        await adminUpdateOrganizationName(
          institutionName,
          currentOrganization.organizationId,
          reference,
          countryCode
        );
      } else {
        await createOrganization(institutionName, reference, countryCode);
      }
      hideOrganizationForm();
    },
    [
      createOrganization,
      currentOrganization.organizationId,
      adminUpdateOrganizationName
    ]
  );

  const hideOrganizationForm = () => {
    setCurrentOrganization({});
    setOrganizationFormStatus(false);
  };
  const organizationColumns = [
    {
      Header: t("health_care_organizations_view_users"),
      className: "view-users-column",
      Cell: ({ row }) => (
        <div
          className={classNames("radio-button", {
            "radio-button--selected":
              selectedOrganization &&
              row.original.organizationId ===
                selectedOrganization.organizationId
          })}
        />
      )
    },
    {
      Header: t("health_care_organizations_organization_name"),
      accessor: "institutionName",
      className: "institutionName-column",
      sortType: sortIgnoreCase
    },
    {
      Header: t("health_care_organizations_actions"),
      className: "actions-column",
      Cell: ({ row }) => {
        return (
          <React.Fragment>
            <Button
              variant="row-action"
              title={t("edit")}
              onClick={e => {
                e.stopPropagation();
                editOrganization(row.original.organizationId);
              }}
            >
              <Icon type="edit" />
            </Button>
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      {!organizationsLoading && (
        <Button
          variant="primary"
          className="header-add-button"
          onClick={() => setOrganizationFormStatus(true)}
        >
          {t("health_care_organizations_add")}
        </Button>
      )}
      <PageTitle
        title={t("health_care_organizations_title")}
        subtitle={t("health_care_organizations_description")}
      />
      {organizationsLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={organizationColumns}
          data={organizations}
          onRowSelect={setSelectedOrganization}
          idSelector="organizationId"
          defaultSelect
          globalFilter
          pagination
          pageSize={10}
          className="organization-table"
        />
      )}
      {organizationForm && (
        <OrganizationForm
          hide={hideOrganizationForm}
          onSubmit={updateOrganizationData}
          countries={countries}
          loading={organizationSaving || organizationCreating}
          {...currentOrganization}
        />
      )}
    </React.Fragment>
  );
};

Organizations.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withRouter(Organizations);
