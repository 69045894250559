import React from "react";
import PatientsContainer from "../../containers/Patients.Container";

const Patients = () => (
  <>
    <PatientsContainer location={"patients"}/>
  </>
);

export default Patients;
