import React from "react";
import AccessProfilesContainer from "../../containers/AccessProfiles.Container";

const AccessProfiles = () => (
  <>
    <AccessProfilesContainer />
  </>
);

export default AccessProfiles;
