import React from "react";
import { Prompt, useHistory } from "react-router-dom";
import ConfirmPopup from "components/ConfirmPopup";
import { useTranslation } from "react-i18next";

const RouteLeavingGuard = ({ when, navigate }) => {
  const { t } = useTranslation();
  const [showUnsavedChangesModal, setshowUnsavedChangesModal] = React.useState(
    false
  );
  const [unsavedConfirmConfig, setUnsavedConfirmConfig] = React.useState({});
  const [nextLocation, setNextLocation] = React.useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);
  const { location } = useHistory();

  React.useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      navigate(nextLocation.pathname);
    }
  }, [confirmedNavigation, nextLocation, navigate]);

  const handleConfirmNavigationClick = React.useCallback(
    nextLocation => {
      if (nextLocation) {
        setConfirmedNavigation(true);
        navigate(nextLocation.pathname);
      }
    },
    [navigate]
  );

  const handleBlockedNavigation = nextLocation => {
    const notSamePathname = location?.pathname !== nextLocation?.pathname;
    if (!confirmedNavigation && notSamePathname) {
      setUnsavedConfirmConfig({
        title: t("unsaved_changes_title"),
        message: t("unsaved_changes_description"),
        onConfirm: () => handleConfirmNavigationClick(nextLocation)
      });
      setshowUnsavedChangesModal(true);
      setNextLocation(nextLocation);
      return false;
    }
    return true;
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmPopup
        title={unsavedConfirmConfig.title}
        message={unsavedConfirmConfig.message || ""}
        visible={showUnsavedChangesModal}
        cancel={() => setshowUnsavedChangesModal(false)}
        confirm={unsavedConfirmConfig.onConfirm || (() => {})}
      />
    </>
  );
};

export default RouteLeavingGuard;
