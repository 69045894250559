import React from "react";
import {
  InputSingleSelect,
  InputMultiSelect,
  InputText,
  InputNumeric,
  InputDate
} from "components/FormComponents";

import {
  SINGLE_ANSWER, 
  MULTI_ANSWERS,
  NUMERIC,
  DATE, 
  DATETIME
} from "const/surveyTypes";

export const mapFieldsToInputs = (field, extendedProfile, answers, myUseForm) => {

  let question =
    extendedProfile?.surveyId === answers?.surveyId
      ? extendedProfile?.surveyAnswers?.find(
          item => item.id === field.id
        )
      : field;

  const isAnswered =
    extendedProfile?.surveyId === answers?.surveyId &&
    extendedProfile?.surveyAnswers?.find(item => item.id === field.id);

  switch (field.type) {
    case SINGLE_ANSWER:
      return (
        <InputSingleSelect
          key={question?.id || field.id}
          useForm={myUseForm}
          field={field}
          defaultValue={
            isAnswered ? question?.answers?.map(item => item.id)[0] : null
          }
        />
      );
    case MULTI_ANSWERS:
      return (
        <InputMultiSelect
          key={question?.id || field.id}
          useForm={myUseForm}
          field={field}
          defaultValue={
            isAnswered ? question?.answers?.map(item => item?.id) : null
          }
        />
      );

    default:
      let answerTypes = field.answers.map(item => item.type);
      if (answerTypes.every(item => item === NUMERIC))
        return (
          <InputNumeric
            key={question?.id || field.id}
            useForm={myUseForm}
            field={field}
            defaultValue={isAnswered ? question?.answers?.[0]?.value : null}
          />
        );
      else if (answerTypes.every(item => item === DATE || item === DATETIME)) 
        return (
          <div className="dates-container" key={field.id}>
            <InputDate 
              key={question?.id || field.id}
              useForm={myUseForm}
              field={field}
              defaultValue={isAnswered ? question?.answers?.[0]?.value : null}
              type={field?.answers?.[0]?.type}
              />
          </div>
        );  
      else
        return (
          <InputText
            key={question?.id || field.id}
            useForm={myUseForm}
            field={field}
            defaultValue={isAnswered ? question?.answers?.[0]?.value : null}
          />
        );
  }
};