export const importStyles = () => {
  const styles = document.styleSheets;
  let cssString = "";

  for (let i = 0, countI = styles.length; i < countI; ++i) {
    if (styles[i].cssRules) {
      let cssRules = styles[i].cssRules;

      for (let j = 0, countJ = cssRules.length; j < countJ; ++j) {
        cssString += cssRules[j].cssText;
      }
    } else {
      cssString += styles[i].cssText;
    }
  }

  const style = document.createElement("style");
  style.type = "text/css";

  try {
    style.innerHTML = cssString;
  } catch {
    style.styleSheet.cssText = cssString;
  }

  return style;
};

export const initPrintView = () => {
  const iframe = document.getElementById("ifmcontentstoprint");
  if (iframe && !iframe.contentWindow.document.body.innerHTML) {
    const iframeDocument = iframe.contentWindow.document;
    const styles = importStyles();
    iframeDocument.head.appendChild(styles);
  }
};
