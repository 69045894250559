import {
  SET_ADMIN_USERS,
  UPDATE_ADMIN_USER,
  CREATE_ADMIN_USER,
  DELETE_ADMIN_USER,
  SHOW_ADMIN_USER_FORM,
  HIDE_ADMIN_USER_FORM
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = { users: [], isCreatingAdminUser: false };

const adminUsers = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ADMIN_USERS: {
      const { users } = payload;
      return { ...state, users: [...users] };
    }
    case CREATE_ADMIN_USER: {
      return { ...state, users: [{ ...payload }, ...state.users] };
    }
    case UPDATE_ADMIN_USER: {
      const { firstName, lastName, roles, adminId, email } = payload;
      return {
        ...state,
        users: state.users.map(user =>
          user.adminId === adminId
            ? { ...user, firstName, lastName, roles, email }
            : user
        )
      };
    }
    case DELETE_ADMIN_USER: {
      const newUsers = state.users.filter(u => u.adminId !== payload.adminId);
      return {
        ...state,
        users: newUsers
      };
    }
    case SHOW_ADMIN_USER_FORM: {
      return {
        ...state,
        showAdminUserForm: true
      };
    }
    case HIDE_ADMIN_USER_FORM: {
      return {
        ...state,
        showAdminUserForm: false
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default adminUsers;
