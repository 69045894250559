import React from "react";
import PageTitle from "components/PageTitle";
import "./HomeTestDetails.scss";
import { useTranslation } from "react-i18next";
import homeTest_1 from "assets/images/homeTest_1.png";
import homeTest_2 from "assets/images/homeTest_2.png";
import Icon from "components/Icon";
import Button from "components/Button";
import Error from "components/Error";
import { getDays, getHours } from "utils/formatData";
import { noWhiteSpacesForRequiredFields } from "utils/FieldValidationRules";
import { Controller, useFormContext } from "react-hook-form";
import { HomeProfileEditingContext } from "components/HomeTestProfile/HomeTestProfile";

const HomeTestDetails = ({ testProfile, institutionName }) => {
  const { t } = useTranslation();
  const { register, formState, errors, control } = useFormContext();
  const context = React.useContext(HomeProfileEditingContext);
  const data = testProfile;

  const isEditing = context?.currentlyEditing?.sections?.includes(
    "testDetails"
  );
  const { dirtyFields } = formState;

  return (
    <>
      <div className="title-top">
        <PageTitle title={testProfile.testTypeName}></PageTitle>
        <Button
          variant="primary"
          className="header-add-button"
          onClick={() => {
            context.setCurrentlyEditing(prevState => {
              return {
                sections: [...prevState.sections, "testDetails"]
              };
            });
          }}
          disabled={isEditing}
        >
          {t("edit")}
        </Button>
      </div>
      <div className="results-container">
        <img src={homeTest_1} alt="phone" className="phone-image-9" />

        <div className="results-table">
          <div className="row-1">
            <div className="cell">
              <strong>{t("record_result")}</strong>{" "}
              <span className="badge">1 </span>
            </div>
            <div className="cell">
              {isEditing ? (
                <textarea
                  rows={2}
                  className="table-cell-input"
                  style={{
                    backgroundColor: formState.dirtyFields.has(
                      `testConfiguration[0].testOutcome.values[0].title`
                    )
                      ? "#fffcda"
                      : ""
                  }}
                  ref={register({
                    validate: noWhiteSpacesForRequiredFields,
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 17,
                      message: t("field_max_length", {
                        number_of_characters: 17
                      })
                    }
                  })}
                  name={`testConfiguration[0].testOutcome.values[0].title`}
                />
              ) : (
                <span>{testProfile.testOutcome.values[0].title}</span>
              )}
              {errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                ?.title && (
                <Error>
                  {
                    errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                      ?.title.message
                  }
                </Error>
              )}
            </div>
            <div className="cell">
              {isEditing ? (
                <textarea
                  rows={2}
                  className="table-cell-input"
                  style={{
                    backgroundColor: formState.dirtyFields.has(
                      `testConfiguration[0].testOutcome.values[1].title`
                    )
                      ? "#fffcda"
                      : ""
                  }}
                  ref={register({
                    validate: noWhiteSpacesForRequiredFields,
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 17,
                      message: t("field_max_length", {
                        number_of_characters: 17
                      })
                    }
                  })}
                  name={`testConfiguration[0].testOutcome.values[1].title`}
                />
              ) : (
                <span>{testProfile.testOutcome.values[1].title}</span>
              )}
              {errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                ?.title && (
                <Error>
                  {
                    errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                      ?.title.message
                  }
                </Error>
              )}
            </div>
            <div className="cell">
              {isEditing ? (
                <textarea
                  rows={2}
                  className="table-cell-input"
                  style={{
                    backgroundColor: formState.dirtyFields.has(
                      `testConfiguration[0].testOutcome.values[2].title`
                    )
                      ? "#fffcda"
                      : ""
                  }}
                  ref={register({
                    validate: noWhiteSpacesForRequiredFields,
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 17,
                      message: t("field_max_length", {
                        number_of_characters: 17
                      })
                    }
                  })}
                  name={`testConfiguration[0].testOutcome.values[2].title`}
                />
              ) : (
                <span>{testProfile.testOutcome.values[2].title}</span>
              )}
              {errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                ?.title && (
                <Error>
                  {
                    errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                      ?.title.message
                  }
                </Error>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="recipient-container">
        <div className="container-left">
          <img src={homeTest_2} alt="phone" className="phone-image-9" />
        </div>
        <div className="container-right">
          <div className="title-container">
            <PageTitle title={t("test_recipient")}></PageTitle>
          </div>
          <div className="recipient-table">
            <div className="row-1">
              <div className="cell">
                <strong>{t("test_results")}</strong>{" "}
                <span className="badge">1 </span>
              </div>
              <div className="cell">
                <Icon type="homeTestResultNegative" />
                <div className="wrapp-information">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[0].activeValue`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 17,
                          message: t("field_max_length", {
                            number_of_characters: 17
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[0].activeValue`}
                    />
                  ) : (
                    <span>{data?.testOutcome?.values?.[0].activeValue}</span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                    ?.activeValue && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                          ?.activeValue?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
              <div className="cell">
                <div className="textarea-container">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[0].activeValueDescription`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 1024,
                          message: t("field_max_length", {
                            number_of_characters: 1024
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[0].activeValueDescription`}
                    />
                  ) : (
                    <span className="description">
                      {data?.testOutcome?.values?.[0].activeValueDescription}
                    </span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                    ?.activeValueDescription && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                          ?.activeValueDescription?.message
                      }
                    </Error>
                  )}
                </div>
                <span className="badge">3 </span>
              </div>
            </div>
            <div className="row-2">
              <div className="cell"></div>
              <div className="cell">
                <Icon type="homeTestResultNegativeExpired" />
                <div className="wrapp-information">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[0].expiredValue`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 17,
                          message: t("field_max_length", {
                            number_of_characters: 17
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[0].expiredValue`}
                    />
                  ) : (
                    <span>{data?.testOutcome?.values?.[0].expiredValue}</span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                    ?.expiredValue && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                          ?.expiredValue?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
              <div className="cell">
                <div className="textarea-container">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[0].expiredValueDescription`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 1024,
                          message: t("field_max_length", {
                            number_of_characters: 1024
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[0].expiredValueDescription`}
                    />
                  ) : (
                    <span className="description">
                      {data?.testOutcome?.values?.[0].expiredValueDescription}
                    </span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                    ?.expiredValueDescription && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                          ?.expiredValueDescription?.message
                      }
                    </Error>
                  )}
                </div>
                <span className="badge">3 </span>
              </div>
            </div>
            <div className="row-3">
              <div className="cell"></div>
              <div className="cell">
                <Icon type="homeTestResultPositive" />
                <div className="wrapp-information">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[1].activeValue`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 17,
                          message: t("field_max_length", {
                            number_of_characters: 17
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[1].activeValue`}
                    />
                  ) : (
                    <span>{data?.testOutcome?.values?.[1].activeValue}</span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                    ?.activeValue && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                          ?.activeValue?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
              <div className="cell">
                <div className="textarea-container">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[1].activeValueDescription`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 1024,
                          message: t("field_max_length", {
                            number_of_characters: 1024
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[1].activeValueDescription`}
                    />
                  ) : (
                    <span className="description">
                      {data?.testOutcome?.values?.[1].activeValueDescription}
                    </span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                    ?.activeValueDescription && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                          ?.activeValueDescription?.message
                      }
                    </Error>
                  )}
                </div>
                <span className="badge">3 </span>
              </div>
            </div>
            <div className="row-4">
              <div className="cell"></div>
              <div className="cell">
                <Icon type="homeTestResultPositiveExpired" />
                <div className="wrapp-information">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[1].expiredValue`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 17,
                          message: t("field_max_length", {
                            number_of_characters: 17
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[1].expiredValue`}
                    />
                  ) : (
                    <span>{data?.testOutcome?.values?.[1].expiredValue}</span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                    ?.expiredValue && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                          ?.expiredValue?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
              <div className="cell">
                <div className="textarea-container">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[1].expiredValueDescription`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 1024,
                          message: t("field_max_length", {
                            number_of_characters: 1024
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[1].expiredValueDescription`}
                    />
                  ) : (
                    <span className="description">
                      {data?.testOutcome?.values?.[1].expiredValueDescription}
                    </span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                    ?.expiredValueDescription && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                          ?.expiredValueDescription?.message
                      }
                    </Error>
                  )}
                </div>
                <span className="badge">3 </span>
              </div>
            </div>

            <div className="row-5">
              <div className="cell"></div>
              <div className="cell">
                <Icon type="homeTestResultInvalid" />
                <div className="wrapp-information">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[2].activeValue`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 17,
                          message: t("field_max_length", {
                            number_of_characters: 17
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[2].activeValue`}
                    />
                  ) : (
                    <span>{data?.testOutcome?.values?.[2].activeValue}</span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                    ?.activeValue && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                          ?.activeValue?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
              <div className="cell">
                <div className="textarea-container">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[2].activeValueDescription`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 1024,
                          message: t("field_max_length", {
                            number_of_characters: 1024
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[2].activeValueDescription`}
                    />
                  ) : (
                    <span className="description">
                      {data?.testOutcome?.values?.[2].activeValueDescription}
                    </span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                    ?.activeValueDescription && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                          ?.activeValueDescription?.message
                      }
                    </Error>
                  )}
                </div>
                <span className="badge">3 </span>
              </div>
            </div>
            <div className="row-6">
              <div className="cell"></div>
              <div className="cell">
                <Icon type="homeTestResultInvalidExpired" />
                <div className="wrapp-information">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[2].expiredValue`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 17,
                          message: t("field_max_length", {
                            number_of_characters: 17
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[2].expiredValue`}
                    />
                  ) : (
                    <span>{data?.testOutcome?.values?.[2].expiredValue}</span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                    ?.expiredValue && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                          ?.expiredValue?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
              <div className="cell">
                <div className="textarea-container">
                  {isEditing ? (
                    <textarea
                      rows={2}
                      className="table-cell-input"
                      style={{
                        backgroundColor: dirtyFields?.has(
                          `testConfiguration[0].testOutcome.values[2].expiredValueDescription`
                        )
                          ? "#fffcda"
                          : ""
                      }}
                      ref={register({
                        validate: noWhiteSpacesForRequiredFields,
                        required: t("form_field_is_required"),
                        maxLength: {
                          value: 1024,
                          message: t("field_max_length", {
                            number_of_characters: 1024
                          })
                        }
                      })}
                      name={`testConfiguration[0].testOutcome.values[2].expiredValueDescription`}
                    />
                  ) : (
                    <span className="description">
                      {data?.testOutcome?.values?.[2].expiredValueDescription}
                    </span>
                  )}
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                    ?.expiredValueDescription && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                          ?.expiredValueDescription?.message
                      }
                    </Error>
                  )}
                </div>
                <span className="badge">3 </span>
              </div>
            </div>

            <div className="row-8">
              <div className="cell">
                <strong>{t("expiration")} </strong>{" "}
                <span className="badge">2 </span>
              </div>
              <div className="cell">
                <Icon type="testResult_negative" />
                <div className="day-hours-field">
                  <div>
                    <span>
                      <strong>{t("days")}</strong>
                    </span>

                    {isEditing ? (
                      // Controller is used in order to convert string to number
                      <Controller
                        name={`testConfiguration[0].testOutcome.values[0].days`}
                        control={control}
                        type="number"
                        as="input"
                        style={{
                          backgroundColor: dirtyFields?.has(
                            `testConfiguration[0].testOutcome.values[0].days`
                          )
                            ? "#fffcda"
                            : ""
                        }}
                        rules={{
                          min: {
                            value: 0,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 999
                            })
                          },
                          max: {
                            value: 999,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 999
                            })
                          }
                        }}
                        onChange={([event]) => parseInt(event.target.value)}
                      />
                    ) : (
                      <span>
                        {getDays(
                          data?.testOutcome?.values?.[0]
                            .validityIntervalInSeconds
                        )}
                      </span>
                    )}
                  </div>

                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                    ?.days && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                          ?.days?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
              <div className="cell">
                <div className="day-hours-field">
                  <div>
                    <span>
                      <strong>{t("hours")}</strong>
                    </span>
                    {isEditing ? (
                      <Controller
                        name={`testConfiguration[0].testOutcome.values[0].hours`}
                        control={control}
                        type="number"
                        as="input"
                        style={{
                          backgroundColor: dirtyFields?.has(
                            `testConfiguration[0].testOutcome.values[0].hours`
                          )
                            ? "#fffcda"
                            : ""
                        }}
                        rules={{
                          min: {
                            value: 0,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 23
                            })
                          },
                          max: {
                            value: 23,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 23
                            })
                          }
                        }}
                        onChange={([event]) => parseInt(event.target.value)}
                      />
                    ) : (
                      <span>
                        {getHours(
                          data?.testOutcome?.values?.[0]
                            .validityIntervalInSeconds
                        )}
                      </span>
                    )}
                  </div>

                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                    ?.hours && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[0]
                          ?.hours?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
            </div>
            <div className="row-9">
              <div className="cell" />
              <div className="cell">
                <Icon type="testResult_positive" />
                <div className="day-hours-field">
                  <div>
                    <span>
                      <strong>{t("days")}</strong>
                    </span>
                    {isEditing ? (
                      <Controller
                        name={`testConfiguration[0].testOutcome.values[1].days`}
                        control={control}
                        type="number"
                        as="input"
                        style={{
                          backgroundColor: dirtyFields?.has(
                            `testConfiguration[0].testOutcome.values[1].days`
                          )
                            ? "#fffcda"
                            : ""
                        }}
                        rules={{
                          min: {
                            value: 0,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 999
                            })
                          },
                          max: {
                            value: 999,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 999
                            })
                          }
                        }}
                        onChange={([event]) => parseInt(event.target.value)}
                      />
                    ) : (
                      <span>
                        {getDays(
                          data?.testOutcome?.values?.[1]
                            .validityIntervalInSeconds
                        )}
                      </span>
                    )}
                  </div>
                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                    ?.days && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                          ?.days?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
              <div className="cell">
                <div className="day-hours-field">
                  <div>
                    <span>
                      <strong>{t("hours")}</strong>
                    </span>
                    {isEditing ? (
                      <Controller
                        name={`testConfiguration[0].testOutcome.values[1].hours`}
                        control={control}
                        type="number"
                        as="input"
                        style={{
                          backgroundColor: dirtyFields?.has(
                            `testConfiguration[0].testOutcome.values[1].hours`
                          )
                            ? "#fffcda"
                            : ""
                        }}
                        rules={{
                          min: {
                            value: 0,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 23
                            })
                          },
                          max: {
                            value: 23,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 23
                            })
                          }
                        }}
                        onChange={([event]) => parseInt(event.target.value)}
                      />
                    ) : (
                      <span>
                        {getHours(
                          data?.testOutcome?.values?.[1]
                            .validityIntervalInSeconds
                        )}
                      </span>
                    )}
                  </div>

                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                    ?.hours && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[1]
                          ?.hours?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
            </div>
            <div className="row-10">
              <div className="cell" />
              <div className="cell">
                <Icon type="testResult_invalid" />
                <div className="day-hours-field">
                  <div>
                    <span>
                      <strong>{t("days")}</strong>
                    </span>
                    {isEditing ? (
                      <Controller
                        name={`testConfiguration[0].testOutcome.values[2].days`}
                        control={control}
                        type="number"
                        as="input"
                        style={{
                          backgroundColor: dirtyFields?.has(
                            `testConfiguration[0].testOutcome.values[2].days`
                          )
                            ? "#fffcda"
                            : ""
                        }}
                        rules={{
                          min: {
                            value: 0,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 999
                            })
                          },
                          max: {
                            value: 999,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 999
                            })
                          }
                        }}
                        onChange={([event]) => parseInt(event.target.value)}
                      />
                    ) : (
                      <span>
                        {getDays(
                          data?.testOutcome?.values?.[2]
                            .validityIntervalInSeconds
                        )}
                      </span>
                    )}
                  </div>

                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                    ?.days && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                          ?.days?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
              <div className="cell">
                <div className="day-hours-field">
                  <div>
                    <span>
                      <strong>{t("hours")}</strong>
                    </span>
                    {isEditing ? (
                      <Controller
                        name={`testConfiguration[0].testOutcome.values[2].hours`}
                        control={control}
                        type="number"
                        as="input"
                        style={{
                          backgroundColor: dirtyFields?.has(
                            `testConfiguration[0].testOutcome.values[2].hours`
                          )
                            ? "#fffcda"
                            : ""
                        }}
                        rules={{
                          min: {
                            value: 0,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 23
                            })
                          },
                          max: {
                            value: 23,
                            message: t("time_before_validation", {
                              min: 0,
                              max: 23
                            })
                          }
                        }}
                        onChange={([event]) => parseInt(event.target.value)}
                      />
                    ) : (
                      <span>
                        {getHours(
                          data?.testOutcome?.values?.[2]
                            .validityIntervalInSeconds
                        )}
                      </span>
                    )}
                  </div>

                  {errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                    ?.hours && (
                    <Error>
                      {
                        errors?.testConfiguration?.[0]?.testOutcome?.values?.[2]
                          ?.hours?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
            </div>
            <div className="row-11">
              <div className="cell">
                <strong>{t("test_org_facility_name")}</strong>{" "}
                <span className="badge">4 </span>
              </div>
              <div className="cell">
                <p>{institutionName}</p>
                <i>({t("test_org_facility_name_description")})</i>
              </div>
              <div className="cell"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeTestDetails;
