import axios from "utils/Axios";
import { makeQrCodesData } from "utils/mockData";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";

export const getTestingProfiles = () => {
  return makeAxiosRequest("getTestingProfiles", {
    client: axios,
    method: "get",
    relativePath: `/testing-profiles`
  });
};

export const getMockedTestingProfiles = () => {
  return makeRequest("getTestingProfiles", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeQrCodesData(20);
            resolve({ data: { TenantSurveyList: mockData } });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const getTestingProfileDetails = id => {
  return makeAxiosRequest("getTestingProfileDetails", {
    client: axios,
    method: "get",
    relativePath: `/testing-profiles/${id}`
  });
};

export const updateTestingProfile = ({
  name,
  description,
  contactDetails,
  testConfiguration,
  testingProfileId
}) => {
  return makeAxiosRequest("updateTestingProfile", {
    client: axios,
    method: "put",
    relativePath: `/testing-profiles/${testingProfileId}`,
    data: {
      name,
      description,
      contactDetails,
      testConfiguration
    }
  });
};

export const updateMockedTestingProfile = ({ qrCode, metaData }) => {
  return makeRequest("updateTestingProfile", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              data: { status: 200 }
            });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const createTestingProfile = ({ name, description, ...rest }) => {
  return makeAxiosRequest("createTestingProfile", {
    client: axios,
    method: "post",
    relativePath: "/testing-profiles",
    data: { name, description, ...rest }
  });
};

export const deleteTestingProfile = id => {
  return makeAxiosRequest("deleteTestingProfile", {
    client: axios,
    method: "delete",
    relativePath: `/testing-profiles/${id}`
  });
};

export const createInfinityIntegration = testingProfileId => {
  return makeAxiosRequest("createInfinityIntegration", {
    client: axios,
    method: "post",
    relativePath: `/testing-profiles/${testingProfileId}/integration-id`
  });
};

export const enableComboTest = testingProfileId => {
  return makeAxiosRequest("enableComboTest", {
    client: axios,
    method: "put",
    relativePath: `/testing-profiles/${testingProfileId}/enable-combo-test`
  });
};
