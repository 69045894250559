import * as locale from "date-fns/locale";
import { LANGUAGE } from "const/app";
import countries from "const/countries";
import {
  DATE
} from "const/surveyTypes";
import moment from "moment";

export const getHours = seconds => {
  const hours = Math.floor(+seconds / (60 * 60)) % 24;
  return hours;
};

export const getDays = seconds => {
  const days = Math.floor(+seconds / (60 * 60 * 24));
  return days;
};

export const formatMinutesAndSeconds = time => {
  const hours = Math.floor(time / (60 * 60));
  const minutes = Math.floor((time % (60 * 60)) / 60);
  const seconds = Math.floor((time % (60 * 60)) % 60);

  return `${hours < 10 ? "0" + hours : hours} : ${
    minutes < 10 ? "0" + minutes : minutes
  } : ${seconds < 10 ? "0" + seconds : seconds}`;
};

export const formatName = name => {
  let formattedName = [];
  if (name?.length) {
    let array = name.split(" ");
    formattedName[0] = array[0].substring(0, 6);
    formattedName[1] = array[0].charAt(array[0].length - 1) + " ";
    formattedName[2] = " " + array[1];
  }
  return formattedName;
};

export const getDateFormatString = locale => {
  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());

  return formatObj
    .map(obj => {
      switch (obj.type) {
        case "day":
          return "DD";
        case "month":
          return "MM";
        case "year":
          return "YYYY";
        default:
          return obj.value;
      }
    })
    .join("");
};

export const hoursToMilliseconds = number => {
  return number * 60 * 60 * 1000;
};

export const millisecondsToHours = number => {
  return number / 60 / 60 / 1000;
};

export const languageMapping = {
  af: locale["af"],
  "ar-DZ": locale["arDZ"],
  "ar-MA": locale["arMA"],
  "ar-SA": locale["arSA"],
  az: locale["az"],
  be: locale["be"],
  bg: locale["bg"],
  bn: locale["bn"],
  ca: locale["ca"],
  cs: locale["cs"],
  cy: locale["cy"],
  da: locale["da"],
  de: locale["de"],
  el: locale["el"],
  "en-AU": locale["enAU"],
  "en-CA": locale["enCA"],
  "en-GB": locale["enGB"],
  "en-IN": locale["enIN"],
  "en-NZ": locale["enNZ"],
  "en-US": locale["enUS"],
  "en-ZA": locale["enZA"],
  eo: locale["eo"],
  es: locale["es"],
  et: locale["et"],
  eu: locale["eu"],
  "fa-IR": locale["faIR"],
  fi: locale["fi"],
  fr: locale["fr"],
  "fr-CA": locale["frCA"],
  "fr-CH": locale["frCH"],
  gd: locale["gd"],
  gl: locale["gl"],
  gu: locale["gu"],
  he: locale["he"],
  hi: locale["hi"],
  hr: locale["hr"],
  hu: locale["hu"],
  hy: locale["hy"],
  id: locale["id"],
  is: locale["is"],
  it: locale["it"],
  ja: locale["ja"],
  ka: locale["ka"],
  kk: locale["kk"],
  kn: locale["kn"],
  ko: locale["ko"],
  lb: locale["lb"],
  lt: locale["lt"],
  lv: locale["lv"],
  mk: locale["mk"],
  ms: locale["ms"],
  mt: locale["mt"],
  nb: locale["nb"],
  nl: locale["nl"],
  "nl-BE": locale["nlBE"],
  nn: locale["nn"],
  pl: locale["pl"],
  pt: locale["pt"],
  "pt-BR": locale["ptBR"],
  ro: locale["ro"],
  ru: locale["ru"],
  sk: locale["sk"],
  sl: locale["sl"],
  sr: locale["sr"],
  "sr-Latn": locale["srLatn"],
  sv: locale["sv"],
  ta: locale["ta"],
  te: locale["te"],
  th: locale["th"],
  tr: locale["tr"],
  ug: locale["ug"],
  uk: locale["uk"],
  uz: locale["uz"],
  vi: locale["vi"],
  "zh-CN": locale["zhCN"],
  "zh-TW": locale["zhTW"]
};

export const formatDate = date => {
  let dateValue;
  if (typeof date === "string") {
    dateValue = moment(date, "YYYY-MM-DD").toDate();
  } else {
    dateValue = new Date(date);
  }
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const formattedDate = dateValue.toLocaleDateString(LANGUAGE, options);
  return formattedDate;
};

export const formatDateTime = date => {
  const dateValue = new Date(date);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };
  const formattedDate = dateValue.toLocaleDateString(LANGUAGE, options);
  return formattedDate;
};

export const formatDateTimeNumeric = date => {
  const dateValue = new Date(date);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };
  const formattedDate = dateValue.toLocaleDateString(LANGUAGE, options);
  return formattedDate;
};

export const formatBirthDate = date => {
  let dateValue;
  if (typeof date === "string") {
    dateValue = moment(date, "YYYY-MM-DD").toDate();
  } else {
    dateValue = new Date(date);
  }
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = dateValue.toLocaleDateString(LANGUAGE, options);
  return formattedDate;
};

export const formatPhone = phone => {
  return phone ? `${phone.countryCode}${phone.number}` : "";
};

export const formatCountry = (code, countries) => {
  return (
    countries?.find(country => country?.countryCode === code)?.countryName || ""
  );
};

export const formatResult = (test, serverTime) => {
  const { testResult, testStatus, registrationTime } = test;
  const values =
    test?.testConfigurationItem?.testConfiguration?.testOutcome?.values;
  if(testStatus === "STATUS_DRAFT") {
    return "STATUS DRAFT"
  } else if (testStatus === "STATUS_PENDING") {
    return test?.testConfigurationItem?.testConfiguration?.pendingState
      ?.activeValue;
  } else if (!testResult) {
    return "";
  } else {
    let value = values?.find(value => value.id === test.testResult);
    return serverTime <
      registrationTime + value?.validityIntervalInSeconds * 1000
      ? value?.activeValue
      : value?.expiredValue;
  }
};

export const getPhoneCountry = code =>
  countries.find(country => country.phonePrefix === code)?.isoCodeAlpha2;

export const formatI18String = str => {
  return str
    .replace(/&#39;/g, "'")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#x2F;/g, "/")
    .replace(/&amp;/g, "&");
};

export const getSeconds = ({days, hours}) => {
  let daysInSeconds = days ? (days * 24 * 60 * 60) : 0;
  let hoursInSeconds =  hours ? (hours * 60 * 60) : 0;
  return daysInSeconds + hoursInSeconds;
};

export const formatDateString = (type) => {
  return type === DATE ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss"
}
