import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  getOrganizationUsers,
  updateOrganizationUser,
  deleteOrganizationUser,
  createOrganizationUser,
  resetOrganizationUserPhoneNumber,
  showUserForm,
  hideUserForm
} from "store/reducers/organizations/services";
import { getTestingProfiles } from "store/reducers/qrCodes/services";
import OrganizationUsers from "components/OrganizationUsers";
import { ROLE_SUPER_ADMIN } from "const/roles";

const mapStateToProps = (state, ownProps) => ({
  users: state.organizations.users,
  loggedUser: state.user,
  qrCodes: state.qrCodes,
  rolesList: state.config.roles || [],
  isSuperAdmin: state.user.roles.includes(ROLE_SUPER_ADMIN),
  qrCodesList: state.qrCodes || [],
  userForm: state.organizations.showUserForm,
  selectedOrganization:
    ownProps.selectedOrganization || state.organizations.selectedOrganization,
  organizationsLoading: isLoading(state, "getOrganizations"),
  organizationUsersLoading: isLoading(state, "getOrganizationUsers"),
  organizationUserRemoving: isLoading(state, "awsDeleteUser"),
  organizationUserCreating:
    isLoading(state, "awsCreateUser") ||
    isLoading(state, "createOrganizationUser"),
  organizationUserPhoneResetting: isLoading(state, "awsUpdatePhoneNumber"),
  organizationUserRolesSaving: isLoading(state, "awsUpdateUserRoles"),
  organizationUserSaving: isLoading(state, "updateOrganizationUser"),
  organizationUserDeleting: isLoading(state, "deleteOrganizationUser"),
  testingProfilesLoading: isLoading(state, "getTestingProfiles")
});

const mapDispatchToProps = {
  getOrganizationUsers,
  getTestingProfiles,
  updateOrganizationUser,
  deleteOrganizationUser,
  createOrganizationUser,
  resetOrganizationUserPhoneNumber,
  showUserForm,
  hideUserForm
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationUsers);
