import { success, error } from "react-notification-system-redux";
import * as api from "api/qrCodes";
import * as actions from "./actions";
import { clearQrCodeData } from "../qrCodeData/actions";
import i18n from "../../../translations";

export const getTestingProfiles = tenantId => async (dispatch, getState) => {
  const state = getState();
  if (state.qrCodeData) {
    dispatch(clearQrCodeData());
  }
  const result = await dispatch(
    api.getTestingProfiles(tenantId || state.user.tenantId)
  );
  dispatch(actions.setQrCodes(result.data));
  return result.data;
};

export const setTestingProfileForEdit = id => dispatch => {
  dispatch(actions.setTestingProfileForEdit(id));
};

export const createTestingProfile = ({
  name,
  description,
  combo,
  ...rest
}) => async dispatch => {
  const result = await dispatch(
    api.createTestingProfile({ name, description, ...rest })
  );
  if (result && result.status === 200) {
    const { testingProfileId } = result.data;
    if(combo) {
      const enabled = await dispatch(api.enableComboTest(testingProfileId))
      if(enabled && enabled.status !== 200) {
        dispatch(
          error({
            title: i18n.t("error_title"),
            message: i18n.t("error"),
            position: "tc"
          })
        );
        return false;
      }  
    }
    dispatch(
      actions.createTestingProfile({
        name,
        description,
        testingProfileId,
        isComboTestProfile: combo,
        ...rest
      })
    );
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_test_profile_created"),
        position: "tc"
      })
    );
    return true;
  } else {
    return false;
  }
};

export const deleteTestingProfile = id => async (dispatch, getState) => {
  const state = getState();
  const { qrCode } = state.qrCodeData;
  const result = await dispatch(api.deleteTestingProfile(id));
  if (result && result.status === 200) {
    dispatch(actions.deleteTestingProfile(id));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_test_profile_deleted"),
        position: "tc"
      })
    );
    if (qrCode === id) {
      dispatch(clearQrCodeData());
    }
  }
};
