import {
  LOGIN_SUCCESS,
  LOGOUT_USER,
  UPDATE_USER_ATTRIBUTES,
  UPDATE_USER_SESSION,
  SET_USER_DATA,
  UPDATE_CONTACTS
} from "./types";

export const updateContacts = (
  institutionPhoneNumber,
  institutionAddress,
  institutionWebLink,
  extendedProfile,
  institutionName
) => ({
  type: UPDATE_CONTACTS,
  payload: {
    institutionPhoneNumber,
    institutionAddress,
    institutionWebLink,
    institutionName,
    organizationExtendedProfile: extendedProfile
  }
});

export const loginSuccess = user => ({
  type: LOGIN_SUCCESS,
  payload: {
    user
  }
});

export const logoutUser = () => ({
  type: LOGOUT_USER
});

export const updateUserAttributes = attributes => ({
  type: UPDATE_USER_ATTRIBUTES,
  payload: {
    attributes
  }
});

export const updateUserSession = signInUserSession => ({
  type: UPDATE_USER_SESSION,
  payload: {
    signInUserSession
  }
});

export const setUserData = data => ({
  type: SET_USER_DATA,
  payload: data
});
