import { ROLE_EVERYONE, rolesToAdd, rolesBlacklist } from "const/roles";

export const detectUserRoles = (roles = []) => {
  const resultRoles = new Set();
  const countries = new Set();
  roles.forEach(({ roleId, country } = {}) => {
    roleId && resultRoles.add(roleId);
    country && countries.add({ role: roleId, country: country });
  });
  return {
    roles: Array.from(resultRoles),
    countries: Array.from(countries)
  };
};
//TODO: fix circular dependency: config/routes -> DashboardLatour -> NavBar -> roleManager
let routes;
import("config/routes").then(m => {
  routes = m.default;
});

export const hasAccessToRoute = (route, roles) => {
  if (!routes[route] || !routes[route].roles) {
    return false;
  }
  if (!!roles) {
    if (routes[route].roles.indexOf(ROLE_EVERYONE) > -1) {
      return true;
    } else {
      return !!roles.find(role => routes[route].roles.indexOf(role) > -1);
    }
  } else {
    return false;
  }
};

export const availableRoles = (userRoles, rolesList) => {
  let roles = new Set();
  userRoles.forEach(role => {
    if (rolesToAdd[role]) {
      rolesToAdd[role].forEach(item => {
        let r = rolesList.find(i => item === i.value);
        if (r !== undefined) {
          roles.add(r);
        }
      });
    } else {
      return;
    }
  });
  return Array.from(roles).sort((a, b) => {
    return a.position - b.position;
  });
};

export const isRoleDisabled = (value, selected) => 
  rolesBlacklist[value] && selected.some(s => s.roleId === rolesBlacklist[value]) ? true : false;
