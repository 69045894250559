import React from "react";
import { Error, FormGroup, Input, Label, PageTitle } from "components";
import { useFormContext } from "react-hook-form";
import { millisecondsToHours } from "utils/formatData";

import "./DecisionValidity.scss";
import { useTranslation } from "react-i18next";

const DecisionValidity = ({ duration }) => {
  const { t } = useTranslation();
  const { register, errors, formState, setValue } = useFormContext();

  const onInputChange = e => {
    let { value } = e.target;
    let parsed = parseInt(value);
    if (isNaN(parsed)) {
      parsed = 0;
    }
    if (value === "") {
      parsed = null;
    }
    setValue("duration", parsed);
  };

  return (
    <div className="decision-validity">
      <PageTitle
        title={t("reentry")}
        subtitle={t("reentry_description")}
      ></PageTitle>
      <div className="decision-validity--input-field">
        <FormGroup>
          <Label htmlFor="duration" error={errors?.duration}>
            {t("time_window")} ({t("hours")})
          </Label>
          <div className="days-hours-picker">
            <Input
              type="number"
              name="duration"
              placeholder={t("hours")}
              min="0"
              max="8760"
              onInput={onInputChange}
              style={
                formState.dirtyFields.has(`duration`)
                  ? { backgroundColor: "#fffcda" }
                  : null
              }
              ref={register({
                required: t("form_field_is_required"),
                min: {
                  value: 0,
                  message: t("time_window_min", { hours: 0 })
                },
                max: {
                  value: 8760,
                  message: t("time_window_max", { hours: 8760 })
                }
              })}
              defaultValue={
                Number.isNaN(duration) ? "" : millisecondsToHours(duration)
              }
              error={errors.duration}
              className="day-hours-time-input"
            />
          </div>
          {errors.duration && <Error>{errors.duration.message}</Error>}
        </FormGroup>
      </div>
    </div>
  );
};

export default DecisionValidity;
