import {
  CLEAR_QR_CODE_DATA,
  CREATE_COPY_OF_METADATA,
  CREATE_TEST_TYPE,
  DELETE_COPY_OF_METADATA,
  DELETE_TEST_TYPE,
  SAVE_CONTACT_DATA,
  SET_QR_CODE_DATA,
  SET_TESTING_PROFILE_DATA_FOR_EDIT,
  SET_TEST_TYPE_FOR_EDIT,
  SAVE_PROFILE_DATA,
  UPDATE_TESTING_CONFIGURATION,
  UPDATE_TESTING_PROFILE,
  SAVE_TEST_TYPE_DATA,
  UPDATE_TEST_CONFIG,
  FIELD_ERRORS,
  SET_INFINITY_INTEGRATION,
  SAVE_CHECKBOX_DATA,
  RESET_PROVIDER_FIELDS
} from "./types";

export const setFieldErrors = data => ({
  type: FIELD_ERRORS,
  payload: data
});

export const setQrCodeData = (qrCodeData, qrCode) => ({
  type: SET_QR_CODE_DATA,
  payload: {
    qrCodeData,
    qrCode
  }
});

export const createCopyOfMetaData = () => ({
  type: CREATE_COPY_OF_METADATA
});

export const deleteCopyOfMetaData = () => ({
  type: DELETE_COPY_OF_METADATA
});

export const createTestType = data => ({
  type: CREATE_TEST_TYPE,
  payload: data
});

export const deleteTestType = id => ({
  type: DELETE_TEST_TYPE,
  payload: id
});

export const updateTestingProfile = metaData => ({
  type: UPDATE_TESTING_PROFILE,
  payload: metaData
});

export const updateTestConfig = testConfiguration => ({
  type: UPDATE_TEST_CONFIG,
  payload: testConfiguration
});

export const updateTestingConfiguration = metaData => ({
  type: UPDATE_TESTING_CONFIGURATION,
  payload: metaData
});

export const setTestTypeForEdit = testTypeId => ({
  type: SET_TEST_TYPE_FOR_EDIT,
  payload: {
    testTypeId
  }
});

export const setTestingProfileDataForEdit = (type, name) => ({
  type: SET_TESTING_PROFILE_DATA_FOR_EDIT,
  payload: {
    type,
    name
  }
});

export const saveContactData = (id, value) => ({
  type: SAVE_CONTACT_DATA,
  payload: {
    id,
    value
  }
});

export const saveProfileData = (id, value) => ({
  type: SAVE_PROFILE_DATA,
  payload: {
    id,
    value
  }
});

export const saveTestTypeData = (testTypeId, id, value) => ({
  type: SAVE_TEST_TYPE_DATA,
  payload: {
    testTypeId,
    id,
    value
  }
});

export const saveCheckboxData = (testTypeId, id, value) => ({
  type: SAVE_CHECKBOX_DATA,
  payload: {
    testTypeId,
    id,
    value
  }
});

export const clearQrCodeData = () => ({
  type: CLEAR_QR_CODE_DATA
});

export const setInfinityIntegration = integrationId => ({
  type: SET_INFINITY_INTEGRATION,
  payload: {
    integrationId
  }
});

export const resetProviderFields = id => ({
  type: RESET_PROVIDER_FIELDS,
  payload: {
    id
  }
});
