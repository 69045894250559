import { combineReducers } from "redux";
import user from "./user";
import users from "./users";
import requests from "./requests";
import organizations from "./organizations";
import qrCodes from "./qrCodes";
import qrCodeData from "./qrCodeData";
import config from "./config";
import accessProfiles from "./accessProfiles";
import accessProfileData from "./accessProfileData";
import dataExport from "./dataExport";
import surveys from "./surveys";
import homeTest from "./homeTest";
import patients from "./patients";
import cohorts from "./cohorts";
import adminUsers from "./adminUsers";
import { reducer as notifications } from "react-notification-system-redux";

const reducers = combineReducers({
  config,
  user,
  users,
  organizations,
  qrCodes,
  qrCodeData,
  notifications,
  requests,
  accessProfiles,
  accessProfileData,
  dataExport,
  surveys,
  homeTest,
  patients,
  cohorts,
  adminUsers
});

export default reducers;
