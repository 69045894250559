import {
  SET_PATIENTS,
  SET_MEMBERS,
  SET_SELECTED_PATIENT,
  UPDATE_PATIENTS,
  UPDATE_PATIENT,
  SET_TEST_RESULTS,
  SHOW_PATIENT_FORM,
  HIDE_PATIENT_FORM,
  SET_FILTERED_PATIENTS,
  CREATE_PATIENT,
  SET_SELECTED_PATIENT_SURVEY,
  SET_SURVEY_ANSWERED,
  CLEAR_FILTERED_PATIENTS,
  CLEAR_PATIENTLIST
} from "./types";

export const setPatients = (patients, countries) => ({
  type: SET_PATIENTS,
  payload: {
    patients,
    countries
  }
});
export const setMembers = (members, countries) => ({
  type: SET_MEMBERS,
  payload: {
    members,
    countries
  }
});

export const setSelectedPatient = patientData => ({
  type: SET_SELECTED_PATIENT,
  payload: {
    patientData
  }
});

export const setSelectedPatientSurvey = survey => ({
  type: SET_SELECTED_PATIENT_SURVEY,
  payload: {
    survey
  }
});

export const createPatient = (patient, countries) => ({
  type: CREATE_PATIENT,
  payload: {
    patient,
    countries
  }
});

export const setFilteredPatients = patientList => ({
  type: SET_FILTERED_PATIENTS,
  payload: {
    patientList
  }
});

export const clearFilteredPatient = () => ({
  type: CLEAR_FILTERED_PATIENTS
});

export const clearPatientList = () => ({
  type: CLEAR_PATIENTLIST
});

export const updatePatients = (id, patient) => ({
  type: UPDATE_PATIENTS,
  payload: {
    id: id,
    patient
  }
});

export const updatePatient = patient => ({
  type: UPDATE_PATIENT,
  payload: {
    patient
  }
});

export const setTestResults = ({ serverTime, testResults }) => ({
  type: SET_TEST_RESULTS,
  payload: {
    serverTime,
    testResults
  }
});

export const setSurveyAnswered = id => ({
  type: SET_SURVEY_ANSWERED,
  payload: id
});

export const showPatientForm = () => ({ type: SHOW_PATIENT_FORM });

export const hidePatientForm = () => ({ type: HIDE_PATIENT_FORM });
