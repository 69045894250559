import React from "react";
import useLanguage from "utils/useLanguage";
import { sortIgnoreCase } from "utils/sortMethods";
import TextColumnFilter from "components/Table/addons/TextColumnFilter";
import { useTranslation } from "react-i18next";
import { Spinner, Table } from "components";
import { formatDate, formatResult } from "utils/formatData";
import "./DataTable.scss";

const DataTable = ({
  loadingData,
  data: results,
  onRowClick,
  selectedPatient,
  nextToken,
  serverTime,
  membersSearch,
  cohortProfiles
}) => {
  const { flippedNameOrder } = useLanguage();
  const { t } = useTranslation();

  const data = results.map(item => {
    return {
      ...item,
      testType: item.testConfigurationItem?.testConfiguration?.testTypeName,
      result: serverTime && formatResult(item, serverTime),
      date: formatDate(item.registrationTime),
      firstName: item.patientProfile?.firstName,
      lastName: item.patientProfile?.lastName,
      testProfile: item.testConfigurationItem?.testProfile?.testProfileName,
      cohortName: cohortProfiles.find(profile => profile.cohortId ===  item.cohortId)?.name
    };
  });

  const nameColumns = [
    {
      Header: t("first_name_cap"),
      accessor: "firstName",
      Filter: TextColumnFilter,
      sortType: sortIgnoreCase
    },
    {
      Header: t("last_name_cap"),
      accessor: "lastName",
      Filter: TextColumnFilter,
      fallsortType: sortIgnoreCase
    }
  ];

  const nameColumnsFlipped = [
    {
      Header: t("last_name_cap"),
      accessor: "lastName",
      Filter: TextColumnFilter,
      fallsortType: sortIgnoreCase
    },
    {
      Header: t("first_name_cap"),
      accessor: "firstName",
      Filter: TextColumnFilter,
      sortType: sortIgnoreCase
    }
  ];

  const resultsColumns = [
    ...(flippedNameOrder ? nameColumnsFlipped : nameColumns),

    {
      Header: t("date"),
      accessor: "date",
      Filter: TextColumnFilter
    },
    (membersSearch 
      ? {
        Header: t("cohort_profile_label"),
        accessor: "cohortName",
        Filter: TextColumnFilter,
        sortType: sortIgnoreCase
      }
      : 
      {
        Header: t("test_profile"),
        accessor: "testProfile",
        Filter: TextColumnFilter,
        sortType: sortIgnoreCase
      }
    ),
    {
      Header: t("test_type"),
      accessor: "testType",
      Filter: TextColumnFilter,
      sortType: sortIgnoreCase
    },
    {
      Header: t("result"),
      accessor: "result",
      Filter: TextColumnFilter,
      sortType: sortIgnoreCase
    }
  ];

  return (
    <div className="search-table">
      {loadingData && nextToken ? (
        <Spinner marginTop={15} />
      ) : (
        <Table
          removeNumTotal
          pagination
          defaultPageSize={25}
          className="patients-table"
          columns={resultsColumns}
          data={data}
          withSortIcons={true}
          showPageSizeOptions={true}
          pageSizeOptions={[25, 50, 75]}
          emptyTableInfo={t("enter_search_criteria")}
          selectedRowIndex={results.findIndex((item, e) => {
            return (
              selectedPatient.testId && item.testId === selectedPatient?.testId
            );
          })}
          onRowClick={e => onRowClick(e)}
        />
      )}
    </div>
  );
};

export default DataTable;
