import React from "react";
import classNames from "classnames";
import { SelectOption, Icon } from "components";

// Filter UI for selecting options from a list
// May be used with icludesSome built-in filtering method
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, filterSettings = {} }
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return Array.from(options).map(v => ({ value: v, label: v }));
  }, [id, preFilteredRows]);

  const [visible, toggleFilterDropdown] = React.useState(false);

  const hideDropdown = React.useCallback(() => {
    toggleFilterDropdown(false);
  }, [toggleFilterDropdown]);

  //TODO: table sorting handler blocks propagation so this code is not reachable
  React.useEffect(() => {
    if (visible) {
      document.addEventListener("click", hideDropdown);
      return () => {
        document.removeEventListener("click", hideDropdown);
      };
    }
  }, [visible, hideDropdown]);

  const onFilterChange = React.useCallback(
    values => {
      if (filterSettings.multi) {
        setFilter(values && values.map(v => v.value));
      } else {
        setFilter(values && [values.value]);
      }
    },
    [filterSettings, setFilter]
  );

  return (
    <div
      className={classNames({ "select-filter": true, active: !!filterValue })}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <Icon
        type="filter"
        onClick={() => {
          toggleFilterDropdown(!visible);
        }}
      />
      <div
        className={classNames({
          "select-filter_dropdown-container": true,
          visible
        })}
      >
        <SelectOption
          className="select-filter_dropdown"
          options={filterSettings.options || options}
          isMulti={filterSettings.multi}
          value={filterValue}
          onChange={onFilterChange}
        />
      </div>
    </div>
  );
}

export default SelectColumnFilter;
