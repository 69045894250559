import React from "react";
import DataElementsContainer from "../../containers/DataElements.Container";

const DataElements = () => (
  <>
    <DataElementsContainer />
  </>
);

export default DataElements;
