//import { success } from "react-notification-system-redux";
import * as api from "api/countryManagement";
import * as actions from "./actions";
import * as AwsApi from "api/aws";
import { getLoggedUserIdToken } from "selectors/loggedUser";
import { getSelectedAdminUser } from "selectors/adminUsers";
import { error, success } from "react-notification-system-redux";
import i18n from "../../../translations";

export const getAdminUsers = () => async (dispatch, getState) => {
  const result = await dispatch(api.getAdminUsers());
  dispatch(actions.setAdminUsers(result.data));
  return result;
};

export const createAdminUser = request => async (dispatch, getState) => {
  const state = getState();

  const idToken = getLoggedUserIdToken(state);

  if (idToken) {
    const awsUser = await dispatch(
      AwsApi.createUser(request.email, request.roles, idToken.jwtToken)
    );

    if (awsUser) {
      if (awsUser instanceof Error) {
        return awsUser.code;
      } else {
        const result = await dispatch(
          api.createAdminUser({ ...request, adminId: awsUser.Username })
        );
        if (result?.status !== 200) {
          await dispatch(AwsApi.deleteUser(awsUser.Username, idToken.jwtToken));
          return false;
        } else {
          dispatch(
            actions.createAdminUser({ ...request, adminId: awsUser.Username })
          );
          await dispatch(actions.hideAdminUserForm());
          dispatch(
            success({
              title: i18n.t("success"),
              message: i18n.t("success_user_created"),
              position: "tc"
            })
          );
        }
      }
    } else {
      dispatch(error({ message: i18n.t("error_create_user") }));
    }
  }
};

export const updateAdminUser = (user, request) => async (
  dispatch,
  getState
) => {
  const adminId = user.adminId;
  const { firstName, lastName, roles, email } = request;
  const oldRoles = user.roles;

  const result = await dispatch(
    api.updateAdminUser(adminId, firstName, lastName, roles)
  );
  if (result && result.status === 200) {
    const state = getState();
    const idToken = getLoggedUserIdToken(state);

    if (idToken) {
      dispatch(
        await AwsApi.updateUserRoles(adminId, idToken.jwtToken, oldRoles, roles)
      );
      dispatch(
        actions.updateAdminUser(firstName, lastName, roles, adminId, email)
      );
      dispatch(actions.hideAdminUserForm());
      dispatch(
        success({
          title: i18n.t("success"),
          message: i18n.t("success_user_updated"),
          position: "tc"
        })
      );
    }
  } else {
    dispatch(actions.hideAdminUserForm());
  }
};

export const deleteAdminUser = adminId => async (dispatch, getState) => {
  const state = getState();
  const idToken = getLoggedUserIdToken(state);

  if (idToken) {
    await dispatch(AwsApi.deleteUser(adminId, idToken.jwtToken));
    await dispatch(api.deleteAdminUser(adminId));
    dispatch(actions.deleteAdminUser(adminId));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_user_deleted"),
        position: "tc"
      })
    );
  }
};

export const resetAdminUserPhoneNumber = (userId, phoneNumber) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const user = getSelectedAdminUser(state, userId);
  const idToken = getLoggedUserIdToken(state);

  if (idToken) {
    await dispatch(
      AwsApi.updatePhoneNumber(user.email, phoneNumber, idToken.jwtToken)
    );
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_phone_updated"),
        position: "tc"
      })
    );
  }
};

export const showAdminUserForm = () => dispatch =>
  dispatch(actions.showAdminUserForm());
export const hideAdminUserForm = () => dispatch =>
  dispatch(actions.hideAdminUserForm());
