export const APP_ID = "com.roche.pass";
export const AUTH_USER_TOKEN_KEY = "ReactAmplify.TokenKey";
export const LOGOUT_TIME = 3_600_000;
export const LAST_ACTIVE_TIME = "_lastActiveTime_";

export const ENV_CLINICAL = "CLINICAL";
export const ENV_COMMERCIAL = "COMMERCIAL";

export const CSV_DELIMITER = ",";

export const LANGUAGE =
  window.navigator.browserLanguage || window.navigator.language || "en";

export const HOME_TEST_VALUE = "SARS_COV_2_RAPID_ANTIGEN_HOME_TEST";

export const EXPORT_TIMEOUT_LIMIT = 180000;
