import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import { updateContacts } from "store/reducers/user/services";
import OrganizationContacts from "components/OrganizationContacts";
import { getOrganizationProfileSurvey } from "store/reducers/surveys/services";

const mapStateToProps = state => {
  const {
    institutionPhoneNumber,
    institutionAddress,
    institutionWebLink,
    organizationExtendedProfile,
    institutionName
  } = state.user;

  return {
    institutionPhoneNumber,
    institutionAddress,
    institutionWebLink,
    organizationExtendedProfile,
    institutionName,
    contactUpdating: isLoading(state, "updateContacts"),
    questions: state?.surveys?.organizationProfileSurveyData,
    organizationSurveyLoading: isLoading(state, "getOrganizationProfileSurvey")
  };
};

const mapDispatchToProps = {
  updateContacts,
  getOrganizationProfileSurvey
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationContacts);
