import { makeAWSRequest } from "utils/requests";
import * as awsTools from "utils/aws-tools";

export const createUser = (email, roles, jwtToken) =>
  makeAWSRequest("awsCreateUser", () =>
    awsTools.createUser(email, roles, jwtToken)
  );

export const updateUserRoles = (username, jwtToken, existingRoles, newRoles) =>
  makeAWSRequest("awsUpdateUserRoles", () =>
    awsTools.updateUserRoles(username, jwtToken, existingRoles, newRoles)
  );

export const deleteUser = (username, jwtToken) =>
  makeAWSRequest("awsDeleteUser", () =>
    awsTools.deleteUser(username, jwtToken)
  );

export const updatePhoneNumber = (username, phoneNumber, jwtToken) =>
  makeAWSRequest("awsUpdatePhoneNumber", () =>
    awsTools.updatePhoneNumber(username, phoneNumber, jwtToken)
  );
