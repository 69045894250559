import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import "./selectOption.scss";
import { useTranslation } from "react-i18next";

const SelectOption = React.forwardRef(
  (
    {
      options = [],
      value,
      defaultValue,
      isSearchable = false,
      isClearable = false,
      isMulti = false,
      allowSelectAll = false,
      allIsSelected = false,
      inline,
      error,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    const allOption = {
      label: t("select_all") || "Select All",
      value: "*"
    };

    let selected;
    let defaultSelected;
    let allSelected;
    let customOptions = props.customOptions || false;
    let typeSelect =
      props.placeholder &&
      props.placeholder === t("test_data_organization_placeholder");

    if (allowSelectAll) {
      allSelected = options?.map(option => option);
    }
    if (isMulti) {
      selected = value && value.map(v => options.find(o => o.value === v));
      defaultSelected =
        defaultValue && defaultValue.map(v => options.find(o => o.value === v));
    } else {
      selected = options.find(o => o.value === value);
      defaultSelected = options.find(o => o.value === defaultValue);
    }
    const styles = {
      container: style => ({
        ...style,
        display: "inline-block",
        minWidth: "102px",
        width: props.fullWidth ? "100%" : "auto"
      }),
      control: style => ({
        ...style,
        border: !error ? "1px solid #646464" : "1px solid  #ef4242",
        borderRight: inline ? "0" : "1px solid #646464",
        borderRadius: inline ? "4px 0 0 4px" : "4px",
        boxShadow: "none",
        minHeight: "auto",
        padding: "7px 0",
        fontSize: "16px",
        backgroundColor: props.isChanged ? " #fffcda" : style.backgroundColor,
        "&:hover": {
          borderColor: !error ? "#646464" : "#ef4242"
        }
      }),
      menu: style => ({
        ...style,
        margin: "0",
        borderRadius: "2px",
        width: customOptions ? "328px" : "100%",
        zIndex: "2"
      }),
      indicatorsContainer: style => ({
        ...style,
        flexDirection: "row-reverse"
      }),
      indicatorSeparator: style => ({ ...style, display: "none" }),
      singleValue: style => ({
        ...style,
        position: "static",
        maxWidth: "340px",
        transform: "none",
        marginLeft: 0
      }),
      dropdownIndicator: style =>
        typeSelect
          ? { display: "none" }
          : {
              ...style,
              "& svg": { display: "none" }
            },
      loadingIndicator: style => ({ ...style, color: "#4a4a4a" }),
      option: style => ({
        ...style,
        whiteSpace: "nowrap",
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }),
      placeholder: style =>
        typeSelect
          ? { ...style, color: "#d1d1d1", maxWidth: "auto", marginLeft: 0 }
          : {
              ...style,
              position: "static",
              color: "#4a4a4a",
              maxWidth: "auto",
              transform: "none",
              marginLeft: 0
            },
      multiValue: style => ({
        ...style,
        background: "#e3f2ff",
        borderRadius: "10px",
        maxWidth: "230px"
      }),
      valueContainer: style => ({ ...style, padding: "3px 8px", display: "flex" })
    };

    const CustomOption = props => {
      if (customOptions) {
        return (
          <components.Option {...props}>
            <div className="custom-option">
              <p>{props.data.name}</p>
              <p>{props.data.preFix}</p>
            </div>
          </components.Option>
        );
      } else {
        return <components.Option {...props}></components.Option>;
      }
    };

    const CustomSingleValue = props => {
      const { innerProps, innerRef } = props;
      return (
        <div ref={innerRef} {...innerProps} style={{ maxWidth: "98%" }}>
          {props.data.shortLabel || props.data.label}
        </div>
      );
    };

    const CustomMultiValuLabel = props => {
      const { innerProps, innerRef } = props;
      return (
        <div ref={innerRef} {...innerProps}>
          {props.data.shortLabel || props.data.label}
        </div>
      );
    };
    return (
      <Select
        {...props}
        classNamePrefix="select-option"
        isClearable={isClearable}
        isSearchable={isSearchable}
        isMulti={isMulti}
        isDisabled={props.disabled}
        allowSelectAll={allowSelectAll}
        styles={styles}
        options={
          allowSelectAll
            ? allIsSelected
              ? []
              : [allOption, ...options]
            : options
        }
        value={allIsSelected ? allSelected : selected}
        defaultValue={defaultSelected}
        ref={ref}
        components={{
          SingleValue: CustomSingleValue,
          Option: CustomOption,
          MultiValueLabel: CustomMultiValuLabel
        }}
      />
    );
  }
);

SelectOption.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    })
  ),
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  inline: PropTypes.bool
};

SelectOption.displayName = "SelectOption";

export default SelectOption;
