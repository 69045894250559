import {
  CLEAR_ACCESS_PROFILE_DATA,
  SET_ACCESS_PROFILE_DATA,
  UPDATE_ACCESS_PROFILE,
  SET_ACCESS_PROFILE_FOR_EDIT
} from "./types";

export const setAccessProfileData = (accessProfileData, accessProfileId) => ({
  type: SET_ACCESS_PROFILE_DATA,
  payload: {
    accessProfileData,
    accessProfileId
  }
});

export const updateAccessProfile = metaData => ({
  type: UPDATE_ACCESS_PROFILE,
  payload: metaData
});

export const clearAccessProfileData = () => ({
  type: CLEAR_ACCESS_PROFILE_DATA
});

export const isEditingAccessProfile = isEditing => ({
  type: SET_ACCESS_PROFILE_FOR_EDIT,
  payload: isEditing
});
