import React from "react";
import { Input, PageTitle, Table, Button } from "components";
import { useFormContext } from "react-hook-form";
import { CohortEditingContext } from "components/CohortProfiles/CohortProfiles";
import {getDays} from "utils/formatData"
import EditableCell from "components/EditableCell";

import { useTranslation } from "react-i18next";
import "./DataRetention.scss";

const defaultColumn = {
  Cell: EditableCell
};
const defaultPeriod = 31536000;

const DataRetention = ({ properties }) => {
  const {retentionPeriod} = properties || {};
  const { t } = useTranslation();

  const context = React.useContext(CohortEditingContext);
  const isEditing = context?.currentlyEditing?.sections?.includes(
    "data-retention"
  );

  const { setValue } = useFormContext();
  
  const onInputChange = React.useCallback((e, name)=> {
    let { value } = e.target;
    let newValue = value.replace(".", "")
    setValue(`${name}`, newValue)
  }, [setValue]);

  const data = [{
    days: getDays(retentionPeriod  ? retentionPeriod: defaultPeriod),
  }]

  const columns = React.useMemo(
    () => [
      {
        accessor: "name",
        Cell: () => <p>{t("data_retention_duration")}</p>
      },
      {
        accessor: "days",
        Cell: ({ value }) => {
          const { register, errors, formState } = useFormContext();
        return (
          <div className="input-container">
            <div className="input-name">{t("days")}</div> 
            {isEditing 
              ? <>
              <Input
                  type="number"
                  name="properties.days"
                  placeholder={t("days")}
                  min="0"
                  max="365"
                  onInput={(e) => onInputChange(e, "properties.days")}
                  style={
                    formState.dirtyFields.has(`days`)
                      ? { backgroundColor: "#fffcda" }
                      : null
                  }
                  ref={register({
                    min: {
                      value: 0
                    },
                    max: {
                      value: 365
                    }
                  })}
                  defaultValue={value}
                  error={errors.properties?.days}
                />
              </>
              : <p className="text-value">{value}</p>
            }
          </div>
        )
      }
      }
    ],
    [t, isEditing, onInputChange]
  );

  return (
    <div className="data-retention">
      <div className="title-container">
        <PageTitle title={t("data_retention_title")}></PageTitle>
        <Button
          variant="primary"
          className="header-add-button"
          onClick={e => {
            e.preventDefault();
            context.setCurrentlyEditing(prevState => {
              return {
                ...prevState,
                sections: [...prevState.sections, "data-retention"]
              };
            });
          }}
          disabled={isEditing}
        >
          {t("edit")}
        </Button>
      </div>
      <div className="table-container">
        <Table
          columns={columns}
          data={data}
          className={"table-data-retention"}
          defaultColumn={defaultColumn}
          disabledSort={true}
        />
        <div className="default-note">
          {t("data_retention_default")}
        </div>
      </div>
      
    </div>
  );
};

export default DataRetention;
