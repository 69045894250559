import React from "react";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import Form from "components/Form";
import Wrapper from "components/Wrapper";
import Title from "components/Title";
import Label from "components/Label";
import Input from "components/Input";
import Button from "components/Button";
import Error from "components/Error";
import FormGroup from "components/FormGroup";
import { useTranslation } from "react-i18next";

function ConfirmEmailForm({ username }) {
  const [loading, setLoadingStatus] = React.useState(false);
  const [redirect, setRedirectStatus] = React.useState(false);
  const [confirmationCode, setConfirmationCode] = React.useState("");
  const { register, handleSubmit, formState, errors, setError } = useForm({
    mode: "onBlur"
  });
  const { t } = useTranslation();

  const onSubmit = () => {
    if (formState.isValid) {
      setLoadingStatus(true);

      Auth.confirmSignUp(username, confirmationCode)
        .then(() => setRedirectStatus(true))
        .catch((err = {}) => {
          setError(
            "confirmationCode",
            "invalid",
            err.message || t("error_unknown")
          );
          setLoadingStatus(false);
        });
    }
  };

  const onPaste = event => {
    event.preventDefault();

    let code = event.clipboardData.getData("Text").trim();

    setConfirmationCode(code);

    // regex to check if string is numbers only
    const reg = new RegExp("^[0-9]+$");

    if (reg.test(code) && code.length === 6) {
      setLoadingStatus(true);

      //TODO: better to call onSubmit() but it isolates code value from state in closure
      Auth.confirmSignUp(username, code)
        .then(() => setRedirectStatus(true))
        .catch((err = {}) => {
          setError(
            "confirmationCode",
            "invalid",
            err.message || t("error_unknown")
          );
          setLoadingStatus(false);
        });
    }
  };

  const onChange = event => {
    setConfirmationCode(event.currentTarget.value);
  };

  return (
    <Wrapper>
      <Title>Email confirmation</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="confirmationCode" error={errors.confirmationCode}>
            Confirmation Code
          </Label>
          <Input
            type="text"
            name="confirmationCode"
            onChange={onChange}
            onPaste={onPaste}
            error={errors.confirmationCode}
            value={confirmationCode}
            ref={register({
              minLength: {
                value: 6,
                message: t("confirmation_code_error")
              },
              required: "Confirmation code is required"
            })}
          />
          {errors.confirmationCode && (
            <Error>{errors.confirmationCode.message}</Error>
          )}
        </FormGroup>
        <Button type="submit" className="button--primary" loading={loading}>
          Confirm Email
        </Button>
      </Form>
      {redirect && <Redirect to={{ pathname: "/" }} />}
    </Wrapper>
  );
}

export default ConfirmEmailForm;
