export const sortNumbers = (rowA, rowB, columnId) => {
  if (
    !rowA ||
    !rowB ||
    rowA.original[`${columnId}Value`] === rowB.original[`${columnId}Value`]
  ) {
    return 0;
  }
  const itemA = rowA.original[`${columnId}Value`];
  const itemB = rowB.original[`${columnId}Value`];
  return itemA > itemB ? 1 : -1;
};

export const sortIgnoreCase = (rowA, rowB, columnId) => {
  if (!rowA || !rowB || rowA.original[columnId] === rowB.original[columnId]) {
    return 0;
  }
  const itemA = rowA.original[columnId]?.trim().toUpperCase();
  const itemB = rowB.original[columnId]?.trim().toUpperCase();
  return itemA > itemB ? 1 : -1;
};

export const sortByRole = (rowA, rowB) => {
  if (!rowA || !rowB) {
    return 0;
  }
  const itemA = rowA.original.roles;
  const itemB = rowB.original.roles;
  return itemA === itemB ? 0 : itemA > itemB ? -1 : 1;
};

export const sortByIsAdmin = (rowA, rowB, columnId) => {
  if (!rowA || !rowB) {
    return 0;
  }
  const itemA = rowA.original[columnId];
  const itemB = rowB.original[columnId];
  return itemA === itemB ? 0 : itemA > itemB ? -1 : 1;
};

export const sortAmount = (rowA, rowB, columnId) => {
  const columnCoreId = columnId.replace("Description", "");
  if (!rowA || !rowB) {
    return 0;
  }
  const itemsA = rowA.original[columnCoreId]
    ? rowA.original[columnCoreId].length
    : 1000;
  const itemsB = rowB.original[columnCoreId]
    ? rowB.original[columnCoreId].length
    : 1000;
  return itemsA === itemsB ? 0 : itemsA > itemsB ? 1 : -1;
};

export const sortRiskFactors = (rowA, rowB, columnId) => {
  if (!rowA || !rowB) {
    return 0;
  }
  const itemsA = rowA.original.riskFactors || [];
  const itemsB = rowB.original.riskFactors || [];
  return itemsA.length === itemsB.length
    ? itemsA.join("").localeCompare(itemsB.join(""))
    : itemsA.length - itemsB.length;
};

export const sortComments = (rowA, rowB) => {
  if (!rowA || !rowB) {
    return 0;
  }
  const itemA = rowA.original?.comment?.text;
  const itemB = rowB.original?.comment?.text;
  return itemA || itemB
    ? !itemA
      ? 1
      : !itemB
      ? -1
      : itemA.toLowerCase().localeCompare(itemB.toLowerCase())
    : 0;
};

export const sortDate = (rowA, rowB, columnId) => {
  if (!rowA || !rowB) {
    return 0;
  }
  const itemA =
    columnId === "latestTestDateTime"
      ? rowA.original?.reviewStatus?.dateTime
      : rowA.original?.sensorLastSyncTime;
  const itemB =
    columnId === "latestTestDateTime"
      ? rowB.original?.reviewStatus?.dateTime
      : rowB.original?.sensorLastSyncTime;
  return itemA === itemB ? 0 : itemA > itemB ? 1 : -1;
};

export const sortDateOfBirth = (rowA, rowB) =>
  new Date(rowA.original?.patientProfile?.profile?.dateOfBirth) -
  new Date(rowB.original?.patientProfile?.profile?.dateOfBirth);

export const sortTimestamp = (rowA, rowB) =>
new Date(rowA.original?.createdAt) -
new Date(rowB.original?.createdAt);
