//Roche administrator
export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
//Country administrator
export const ROLE_COUNTRY_ADMIN = "ROLE_COUNTRY_ADMIN";
//Country data analyst
export const ROLE_COUNTRY_DATA_ANALYST = "ROLE_COUNTRY_DATA_ANALYST";
//Organization administrator
export const ROLE_TEST_PROVIDER_ADMIN = "ROLE_TEST_PROVIDER_ADMIN";
//Test Provider
export const ROLE_TEST_PROVIDER = "ROLE_TEST_PROVIDER";
//Event Admin
export const ROLE_EVENT_ADMIN = "ROLE_EVENT_ADMIN";
//Access Agent
export const ROLE_ACCESS_AGENT = "ROLE_ACCESS_AGENT";
//Access Agent Supervisor
export const ROLE_ACCESS_AGENT_SUPERVISOR = "ROLE_ACCESS_AGENT_SUPERVISOR";
//Test Data Analyst wthithout PII
export const ROLE_DATA_EXPORT_TEST_RESULTS = "ROLE_DATA_EXPORT_TEST_RESULTS";
//Test Data Analyst wthith PII
export const ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII =
  "ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII";
//Access Data Analyst wthithout PII
export const ROLE_DATA_EXPORT_ACCESS_EVENTS = "ROLE_DATA_EXPORT_ACCESS_EVENTS";
//Test recipient manager
export const ROLE_TEST_RECIPIENT_MANAGER = "ROLE_TEST_RECIPIENT_MANAGER";
//Enterprise Admin
export const ROLE_ENTERPRISE_ADMIN = "ROLE_ENTERPRISE_ADMIN";
//Enterprise Manager
export const ROLE_ENTERPRISE_MANAGER = "ROLE_ENTERPRISE_MANAGER";
//Enterprise Data Analyst with PII
export const ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII = "ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII";
//Entreprises Data Analyst without PII
export const ROLE_ENTERPRISE_DATA_ANALYST = "ROLE_ENTERPRISE_DATA_ANALYST";
//Any logged user
export const ROLE_EVERYONE = "ROLE_EVERYONE";

export const rolesToAdd = {
  ROLE_TEST_PROVIDER_ADMIN: [
    ROLE_TEST_PROVIDER_ADMIN,
    ROLE_TEST_PROVIDER,
    ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII,
    ROLE_DATA_EXPORT_TEST_RESULTS,
    ROLE_TEST_RECIPIENT_MANAGER
  ],

  ROLE_ENTERPRISE_ADMIN: [
    ROLE_ENTERPRISE_ADMIN,
    ROLE_ENTERPRISE_DATA_ANALYST,
    ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII,
    ROLE_ENTERPRISE_MANAGER
  ],

  ROLE_EVENT_ADMIN: [
    ROLE_EVENT_ADMIN,
    ROLE_ACCESS_AGENT,
    ROLE_ACCESS_AGENT_SUPERVISOR,
    ROLE_DATA_EXPORT_ACCESS_EVENTS
  ],
  
  ROLE_SUPER_ADMIN: [ROLE_EVENT_ADMIN, ROLE_TEST_PROVIDER_ADMIN, ROLE_ENTERPRISE_ADMIN]
};

export const rolesBlacklist = {
  ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII: "ROLE_DATA_EXPORT_TEST_RESULTS",
  ROLE_DATA_EXPORT_TEST_RESULTS: "ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII",
  ROLE_ENTERPRISE_DATA_ANALYST: "ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII",
  ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII: "ROLE_ENTERPRISE_DATA_ANALYST",
};
