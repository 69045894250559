import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import CountryManagement from "components/CountryManagement";
import {
  getAdminUsers,
  createAdminUser,
  updateAdminUser,
  hideAdminUserForm,
  showAdminUserForm,
  deleteAdminUser,
  resetAdminUserPhoneNumber
} from "store/reducers/adminUsers/services";

const mapStateToProps = state => ({
  adminUsers: state.adminUsers,
  countries: state.config.countries,
  selectedAdminUser: state.adminUsers.selectedAdminUser,
  loggedUser: state.user,
  adminsLoading: isLoading(state, "getAdminUsers"),
  adminUserSaving: isLoading(state, "updateAdminUser"),
  adminUserDeleting: isLoading(state, "deleteAdminUser"),
  adminUserCreating:
    isLoading(state, "awsCreateUser") || isLoading(state, "createAdminUser"),
  adminUserForm: state.adminUsers.showAdminUserForm,
  adminUserPhoneResetting: isLoading(state, "awsUpdatePhoneNumber")
});

const mapDispatchToProps = {
  getAdminUsers,
  createAdminUser,
  updateAdminUser,
  deleteAdminUser,
  hideAdminUserForm,
  showAdminUserForm,
  resetAdminUserPhoneNumber
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryManagement);
