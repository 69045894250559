import React from "react";
import { withRouter } from "react-router-dom";
import Text from "../Text";
import useLanguage from "utils/useLanguage";
import "./loggedUser.scss";
import {
  ROLE_SUPER_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_DATA_ANALYST
} from "const/roles";
import { formatCountry } from "utils/formatData";
import { useTranslation } from "react-i18next";

const LoggedUser = ({ user, countries }) => {
  const { flippedNameOrder } = useLanguage();
  const isSuperAdmin = user?.roles?.includes(ROLE_SUPER_ADMIN);
  const isCountryAdmin = user?.roles?.includes(ROLE_COUNTRY_ADMIN);
  const countryOfCountryAdmin =
    (isCountryAdmin &&
      formatCountry(
        user.countries?.find(c => c.role === ROLE_COUNTRY_ADMIN).country,
        countries
      )) ||
    "";
  const isDataAnalyst = user?.roles?.includes(ROLE_COUNTRY_DATA_ANALYST);
  const countryOfDataAnalyst =
    (isDataAnalyst &&
      formatCountry(
        user.countries?.find(c => c.role === ROLE_COUNTRY_DATA_ANALYST).country,
        countries
      )) ||
    "";
  const { t } = useTranslation();

  let name = flippedNameOrder
    ? `${user.lastName} ${user.firstName}`
    : `${user.firstName} ${user.lastName}`;
  if (user.roles) {
    return (
      <div className="loggedUser">
        <Text as="span">{name}</Text>
        <Text as="span" info>
          {user.institutionName}
          {isCountryAdmin &&
            `${t("country_admin")}, (${countryOfCountryAdmin})${
              isSuperAdmin || isDataAnalyst ? ";" : ""
            } `}
          {isDataAnalyst &&
            `${t("data_analyst")}, (${countryOfDataAnalyst})${
              isSuperAdmin ? ";" : ""
            } `}
          {isSuperAdmin && t("super_admin")}
        </Text>
      </div>
    );
  }

  return null;
};

export default withRouter(LoggedUser);
