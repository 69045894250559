import React from "react";
import classNames from "classnames";
import { Input } from "components";
import { useTranslation } from "react-i18next";

function TextColumnFilter({ column: { filterValue, setFilter } }) {

  const { t } = useTranslation();

  return (
    <div
      className={classNames({ "text-filter": true, active: !!filterValue })}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <div
        className={classNames({
          "text-filter_container": true
        })}
      >
        <Input
          onChange={e => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder={t("search")}
        />
      </div>
    </div>
  );
}

export default TextColumnFilter;
