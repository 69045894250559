import React from "react";
import TestDataExportContainer from "../../containers/TestDataExport.Container";

const TestDataExport = () => (
  <>
    <TestDataExportContainer />
  </>
);

export default TestDataExport;
