import * as api from "api/config";
import * as actions from "./actions";

export const getConfig = () => async dispatch => {
  const result = await dispatch(api.getConfig());
  if (result && result.status === 200) {
    const { data } = result || {};
    dispatch(actions.setConfig(data || {}));
  }
  return result;
};

export const getAboutConfig = () => async dispatch => {
  const result = await dispatch(api.getAboutConfig());
  if (result && result.status === 200) {
    const data = result.data || {};
    dispatch(actions.setAboutConfig(data));
  }
  return result;
};

export const getDefaultTestConfig = () => async dispatch => {
  const result = await dispatch(api.getDefaultTestConfig());
  if (result && result.status === 200) {
    const data = result.data || {};
    dispatch(actions.setDefaultTestConfig(data));
  }
  return result;
};

export const getTestTypeList = () => async dispatch => {
  const result = await dispatch(api.getTestTypeList());
  if (result && result.status === 200) {
    const data = result.data || {};
    dispatch(actions.setTestTypeList(data));
  }
  return result;
};

export const getCountries = () => async dispatch => {
  const result = await dispatch(api.getCountries());
  if (result && result.status === 200) {
    const data = result.data || {};
    dispatch(actions.setCountries(data));
  }
  return result;
};
