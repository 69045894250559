import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import CountryExport from "components/CountryExport";
import {
  getExports,
  createExport,
  getDownloadUrl
} from "store/reducers/dataExport/services";
import { getLoggedAnalystUserCountry } from "selectors/loggedUser";

const mapStateToProps = state => ({
  exportData: state.dataExport,
  organizationId: state.user.organizationId,
  testTypes: state.config.testTypes,
  accessProfilesLoading: isLoading(state, "getTestTypeList"),
  countryCode: getLoggedAnalystUserCountry(state)
});

const mapDispatchToProps = {
  getExports,
  createExport,
  getDownloadUrl
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryExport);
