import React from "react";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import Form from "components/Form";
import Wrapper from "components/Wrapper";
import InputGroup from "components/InputGroup";
import SelectOption from "components/SelectOption";
import Title from "components/Title";
import Label from "components/Label";
import Input from "components/Input";
import Button from "components/Button";
import Link from "components/Link";
import Error from "components/Error";
import FormGroup from "components/FormGroup";
import { useValidation } from "utils/useValidation";
import countries from "const/countries";
import { ColorContext } from "components/FlatLayout/FlatLayout";
import { secondaryBackgroundColor } from "const/colorCodes";
import "./createProfileForm.scss";
import { useTranslation } from "react-i18next";

function CreateProfileForm({
  userInProgress,
  onProfileCreateSuccess,
  onProfileCreateError
}) {
  const phonePrefixes = countries
    .reduce((list, country) => {
      const itemInList = list.find(item => item.name === country.name);

      if (!itemInList) {
        const option = {
          name: country.name,
          value: country.phonePrefix,
          preFix: country.phonePrefix.substring(1),
          label: `${country.name} \n ${country.phonePrefix.substring(1)}`,
          shortLabel: country.phonePrefix
        };

        return [...list, option];
      }

      itemInList.name = `${itemInList.name}, ${country.name}`;
      return list;
    }, [])
    .sort((a, b) => a.name - b.name);

  const { t } = useTranslation();
  const newPassword = React.useRef({});
  const [loading, setLoading] = React.useState(false);
  const [phonePrefix, setPhonePrefix] = React.useState("+1");
  const { register, handleSubmit, formState, errors, watch } = useForm({
    mode: "onChange"
  });
  const { numbersOnly, noRomanCharacter } = useValidation();

  const { changeBgColor } = React.useContext(ColorContext);

  React.useEffect(() => {
    changeBgColor(secondaryBackgroundColor);
    return () => {
      setLoading(false);
    };
  }, [changeBgColor]);

  newPassword.current = watch("newPassword", "");

  const handleError = err => {
    setLoading(false);
    console.error(err.message);
  };

  const onSubmit = ({ newPassword, phone_number }) => {
    if (formState.isValid) {
      setLoading(true);
      const attributes = {
        phone_number: `${
          phonePrefix.value ? phonePrefix.value.replace("-", "") : phonePrefix
        }${phone_number}`
      };

      Auth.completeNewPassword(userInProgress, newPassword, attributes)
        .then(user => {
          Auth.verifyCurrentUserAttribute("phone_number")
            .then(() => {
              Auth.enableSMS(user)
                .then(onProfileCreateSuccess)
                .catch(handleError);
            })
            .catch(handleError);
        })
        .catch(err => {
          if (err && err.code === "NotAuthorizedException") {
            onProfileCreateError();
          } else {
            setLoading(false);
          }
        });
    }
  };

  const filterPhonePrefixOptions = ({ value, data }, inputValue) => {
    return (
      // e.g. "+40"
      value.startsWith(inputValue) ||
      // e.g. "40"
      value.substring(1).startsWith(inputValue) ||
      // e.g. "ROMANIA"
      data.name.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  };

  return (
    <Wrapper className="sign-in-form">
      <Title>{t("complete_user_registration")}</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="phone_number" error={errors.phone_number}>
            {t("phone_number")}
          </Label>
          <InputGroup>
            <SelectOption
              inline
              isSearchable
              defaultValue={phonePrefix}
              onChange={setPhonePrefix}
              filterOption={filterPhonePrefixOptions}
              options={phonePrefixes}
              placeholder={t("select")}
              customOptions={true}
            />
            <Input
              type="text"
              name="phone_number"
              error={errors.phone_number}
              onChange={noRomanCharacter}
              ref={register({
                required: t("phone_number_invalid"),
                validate: { numbersOnly },
                maxLength: {
                  value: 12,
                  message: t("phone_number_invalid")
                }
              })}
            />
          </InputGroup>
          {errors.phone_number && <Error>{errors.phone_number.message}</Error>}
          <p className="user-tip-header">{t("phone_number_2FA_description")}</p>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="newPassword" error={errors.newPassword}>
            {t("password")}
          </Label>
          <Input
            name="newPassword"
            type="password"
            autoComplete="new-password"
            error={errors.newPassword}
            onChange={noRomanCharacter}
            ref={register({
              required: t("password_error_required"),
              minLength: {
                value: 8,
                message: t("password_error_too_short", {
                  number_of_characters: 8
                })
              },
              validate: {
                hasLowerCase: value =>
                  RegExp(/[a-z]/g).test(value) ||
                  t("password_error_no_lowercase"),
                hasUpperCase: value =>
                  RegExp(/[A-Z]/g).test(value) ||
                  t("password_error_no_uppercase"),
                hasDigits: value =>
                  RegExp(/\d/g).test(value) || t("password_error_no_digits")
              }
            })}
          />
          {errors.newPassword && <Error>{errors.newPassword.message}</Error>}
          <p className="user-tip-header">{t("password_requirements")}</p>
          <ul className="user-tip-list">
            {t("password_requirements_description")
              .split("\n")
              .map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
          </ul>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="confirmPassword" error={errors.confirmPassword}>
            {t("password_repeat")}
          </Label>
          <Input
            type="password"
            name="confirmPassword"
            error={errors.confirmPassword}
            onChange={noRomanCharacter}
            ref={register({
              required: t("password_error_required"),
              validate: value =>
                value === newPassword.current ||
                t("password_error_not_matching")
            })}
          />
          {errors.confirmPassword && (
            <Error>{errors.confirmPassword.message}</Error>
          )}
        </FormGroup>
        <Button
          variant="primary-full-width"
          className="margin-top--medium"
          disabled={loading}
          type="submit"
          loading={loading}
        >
          {t("submit")}
        </Button>
      </Form>
      <Link to="/" className="margin-top sign-in-link">
        {t("cancel")}
      </Link>
    </Wrapper>
  );
}

export default CreateProfileForm;
