import axios from "utils/Axios";
import { makeAccessProfiles, makeAccessProfileDetails } from "utils/mockData";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";

export const getAccessProfiles = () => {
  return makeAxiosRequest("getAccessProfiles", {
    client: axios,
    method: "get",
    relativePath: `/access-profiles`
  });
};

export const getMockedAccessProfiles = () => {
  return makeRequest("getAccessProfiles", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeAccessProfiles(20);
            resolve({ data: { accessProfiles: mockData } });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const getAccessProfileDetails = id => {
  return makeAxiosRequest("getAccessProfileDetails", {
    client: axios,
    method: "get",
    relativePath: `/access-profiles/${id}`
  });
};

export const getMockedAccessProfileDetails = () => {
  return makeRequest("getAccessProfileDetails", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeAccessProfileDetails();
            resolve({ data: { status: 200, data: mockData } });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const createAccessProfile = ({ name, description, ...rest }) => {
  return makeAxiosRequest("createAccessProfile", {
    client: axios,
    method: "post",
    relativePath: "/access-profiles",
    data: { name, description, ...rest }
  });
};

export const createMockedAccessProfile = ({ name, description, ...rest }) => {
  return makeRequest("createAccessProfile", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              data: { status: 200, data: { accessProfileId: "12345678" } }
            });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const deleteAccessProfile = id => {
  return makeAxiosRequest("deleteAccessProfile", {
    client: axios,
    method: "delete",
    relativePath: `/access-profiles/${id}`
  });
};

export const deleteMockedAccessProfile = id => {
  return makeRequest("deleteAccessProfile", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({ data: { status: 200 } });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const updateAccessProfile = ({
  name,
  description,
  accessProfileId,
  ...rest
}) => {
  return makeAxiosRequest("updateAccessProfile", {
    client: axios,
    method: "put",
    relativePath: `/access-profiles/${accessProfileId}`,
    data: {
      name,
      description,
      ...rest
    }
  });
};

export const updateMockedAccessProfile = id => {
  return makeRequest("updateAccessProfile", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({ data: { status: 200 } });
          }, 1000);
        });
      }
    },
    query: ""
  });
};
