import React from "react";
import OrganizationContacts from "containers/OrganizationContacts.Container";
import OrganizationUsersContainer from "containers/OrganizationUsers.Container";

const Users = () => (
  <React.Fragment>
    <OrganizationUsersContainer selectedOrganization={{}} />
    <OrganizationContacts />
  </React.Fragment>
);

export default Users;
