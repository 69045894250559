import { connect } from "react-redux";
import UpdateProfileForm from "components/UpdateProfileForm";
import {
  updateUserSession,
  updateUserAttributes
} from "store/reducers/user/actions";
import { updateProfile } from "store/reducers/user/services";

const mapStateToProps = ({ user }) => ({
  ...user
});

const mapDispatchToProps = {
  updateUserSession,
  updateUserAttributes,
  updateProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfileForm);
