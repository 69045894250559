import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  useTable,
  useFilters,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useExpanded
} from "react-table";
import { GlobalFilter, Pagination, TextColumnFilter } from "./addons";
import { useTranslation } from "react-i18next";
import { Icon } from "components";
import "./table.scss";

function Table({
  columns,
  data,
  onRowClick,
  onRowSelect,
  globalFilter,
  className,
  selectedRowIndex,
  idSelector = "id",
  pagination = true,
  defaultSelect = false,
  withSortIcons = false,
  defaultColumn,
  updateMyData,
  disabledSort,
  showPageSizeOptions = false,
  pageSizeOptions,
  defaultPageSize,
  pageSize = defaultPageSize || 10,
  emptyTableInfo = "",
  removeNumTotal
}) {
  const filterTypes = React.useMemo(
    () => ({
      textColumn: TextColumnFilter
    }),
    []
  );
  const { t } = useTranslation();
  const skipPageResetRef = React.useRef();
  const [updatedPageSize, updatePageSize] = React.useState(
    defaultPageSize || pageSize
  );

  const [selectedRow, selectRow] = React.useState(
    defaultSelect ? (data.length > 0 ? data[0][idSelector] : null) : null
  );

  React.useEffect(() => {
    if (defaultSelect && selectedRow) {
      onRowSelect && onRowSelect(data.find(r => r[idSelector] === selectedRow));
    }
    skipPageResetRef.current = true;
  }, [data, defaultSelect, idSelector, onRowSelect, selectedRow]);

  React.useEffect(() => {
    if (
      selectedRow !== null &&
      !data.find(r => r[idSelector] === selectedRow)
    ) {
      selectRow(null);
      onRowSelect && onRowSelect(null);
    }
    skipPageResetRef.current = true;
  }, [selectedRow, data, idSelector, onRowSelect]);

  const handleRowClick = row => {
    if (onRowClick) {
      onRowClick(row);
    }
    if (onRowSelect) {
      selectRow(row[idSelector]);
      onRowSelect(row);
    }
  };

  const tableConfig = [
    {
      columns,
      data,
      defaultColumn,
      updateMyData,
      className,
      filterTypes,
      initialState: {
        pageSize: updatedPageSize
      },
      autoResetPage: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetGlobalFilter: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      disableSortBy: disabledSort
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  ];

  if (!pagination) {
    tableConfig.splice(-1);
  }
  if (!globalFilter) {
    tableConfig.splice(2, 1);
  }

  const showPagination = pagination && data?.length >= pageSize;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
    visibleColumns,
    setGlobalFilter,
    globalFilteredRows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize
  } = useTable.apply(window, tableConfig);

  const paginationProps = {
    totalRecords: (globalFilter ? globalFilteredRows : rows).length,
    pageIndex: state.pageIndex,
    pageSize: updatedPageSize,
    pageCount,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    gotoPage,
    removeNumTotal
  };

  const currentRows = pagination
    ? page
    : globalFilter
    ? globalFilteredRows
    : rows;

  const patients = className === "patients-table";
  const exports = className === "data-export__table";
  const survey = className === "survey__table";

  const handlePageSizeClick = num => {
    setPageSize(num);
    updatePageSize(num);
  };

  return (
    <div className={classNames("table", className)}>
      {showPageSizeOptions && (
        <div className="full-top-bar">
          <div className="page-size-options">
            <p>{t("list_per_page")}</p>
            {pageSizeOptions.map((item, index) => (
              <div
                key={index}
                className={
                  updatedPageSize === item ? "option active" : "option"
                }
                onClick={() => handlePageSizeClick(item)}
              >
                {item}
              </div>
            ))}
          </div>
          {(globalFilter || showPagination) && (
            <div className="top-bar">
              {globalFilter && (
                <GlobalFilter
                  value={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              )}
              {showPagination && <Pagination {...paginationProps} />}
            </div>
          )}
        </div>
      )}
      {!showPageSizeOptions && (globalFilter || showPagination) && (
        <div className="top-bar">
          {globalFilter && (
            <GlobalFilter
              value={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
          {showPagination && <Pagination {...paginationProps} />}
        </div>
      )}

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={classNames({
                "tr--aligned-top": [patients]
              })}
            >
              {headerGroup.headers.map(column => {
                return (
                  <th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: null })
                    )}
                    className={
                      !withSortIcons
                        ? classNames({
                            "tr--sorted-desc":
                              column.isSorted && column.isSortedDesc,
                            "tr--sorted-asc":
                              column.isSorted && !column.isSortedDesc,
                            "header_flex": survey && column.className === "column-answers",
                            [`col-background-${column.colBackground}`]: column.colBackground
                          })
                        : classNames({
                            [`col-background-${column.colBackground}`]: column.colBackground,
                            "header_flex": survey && column.className === "column-answers"
                          })
                    }
                    style={column.style}
                  >
                    <div
                      className={
                        patients || exports  ? "header_filterGroup" : "header_group"
                      }
                    >
                      <div>
                        <div className="header-title">
                          {column.render("Header")}
                          {withSortIcons && column.canSort ? (
                            <div className="sorting-icons">
                              <Icon
                                type="sortAsc"
                                className={classNames({
                                  active:
                                    column.isSorted && !column.isSortedDesc
                                })}
                              />
                              <Icon
                                type="sortDesc"
                                className={classNames({
                                  active: column.isSorted && column.isSortedDesc
                                })}
                              />
                            </div>
                          ) : null}
                        </div>

                        {column.subHeader && (
                          <span className="sub-header">{column.subHeader}</span>
                        )}
                        <div>
                          {column.canFilter && column.Filter
                            ? column.render("Filter")
                            : null}
                        </div>
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {currentRows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps(row.original.props || {})}
                onClick={() => {
                  handleRowClick(row.original);
                }}
                className={classNames(
                  {
                    "tr--clickable": onRowClick || onRowSelect,
                    //"tr--aligned-top": patients,
                    "tr--selected": selectedRowIndex === row.index
                  },
                  (row.original.props || {}).className,
                  ...((row.original.props || {}).classNames || [])
                )}
              >
                {row.original.customRow ? (
                  <>
                    <td colSpan={visibleColumns.length}>
                      {row.original.render(row)}
                    </td>
                  </>
                ) : (
                  row.cells.map((cell, i) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: classNames(cell.column.className, {
                            [`col-background-${cell.column.colBackground}`]: cell
                              .column.colBackground
                          })
                        })}
                      >
                        {cell.render("Cell", {})}
                      </td>
                    );
                  })
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {patients && !data.length ? (
        <div className="empty-table-info-box">
          <div className="info-content">{emptyTableInfo}</div>
          <hr />
        </div>
      ) : patients && !currentRows.length ? (
        <div className="empty-table-info-box">
          <div className="info-content">{t("nothing_found")}</div>{" "}
        </div>
      ) : null}
      <div className="bottom-details">
        {showPageSizeOptions && (
          <div className="page-size-options">
            <p>{t("list_per_page")}</p>
            {pageSizeOptions.map((item, index) => (
              <div
                key={index}
                className={
                  updatedPageSize === item ? "option active" : "option"
                }
                onClick={() => handlePageSizeClick(item)}
              >
                {item}
              </div>
            ))}
          </div>
        )}
        {showPagination && (
          <div className="bottom-bar">
            <Pagination {...paginationProps} />
          </div>
        )}
      </div>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  selectedRowIndex: PropTypes.number,
  onRowClick: PropTypes.func,
  globalFilter: PropTypes.bool,
  pagination: PropTypes.bool,
  pageSize: PropTypes.number,
  qrCodeData: PropTypes.object
};

Table.defaultProps = {
  qrCodeData: {}
};

export default Table;
