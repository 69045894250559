import {
  SET_ADMIN_USERS,
  CREATE_ADMIN_USER,
  UPDATE_ADMIN_USER,
  DELETE_ADMIN_USER,
  SHOW_ADMIN_USER_FORM,
  HIDE_ADMIN_USER_FORM
} from "./types";

export const setAdminUsers = users => ({
  type: SET_ADMIN_USERS,
  payload: {
    users
  }
});

export const createAdminUser = ({
  firstName,
  lastName,
  email,
  roles,
  adminId
}) => ({
  type: CREATE_ADMIN_USER,
  payload: {
    firstName,
    lastName,
    email,
    roles,
    adminId
  }
});

export const updateAdminUser = (
  firstName,
  lastName,
  roles,
  adminId,
  email
) => ({
  type: UPDATE_ADMIN_USER,
  payload: {
    firstName,
    lastName,
    roles,
    adminId,
    email
  }
});

export const deleteAdminUser = adminId => ({
  type: DELETE_ADMIN_USER,
  payload: {
    adminId
  }
});

export const showAdminUserForm = () => ({ type: SHOW_ADMIN_USER_FORM });
export const hideAdminUserForm = () => ({ type: HIDE_ADMIN_USER_FORM });
