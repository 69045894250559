import {
  SET_ORGANIZATIONS,
  CREATE_ORGANIZATION,
  SET_ORGANIZATION_USERS,
  CREATE_ORGANIZATION_USER,
  UPDATE_ORGANIZATION_USER,
  DELETE_ORGANIZATION_USER,
  SET_SELECTED_ORGANIZATION,
  ADMIN_UPDATE_ORGANIZATION,
  SHOW_USER_FORM,
  HIDE_USER_FORM
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = { users: [], organizations: [] };

const organizations = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ORGANIZATIONS: {
      const { organizations } = payload;
      return {
        ...state,
        organizations
      };
    }
    case ADMIN_UPDATE_ORGANIZATION: {
      const {
        institutionName,
        organizationId,
        reference,
        countryCode
      } = payload;
      return {
        ...state,
        organizations: state.organizations.map(organization =>
          organization.organizationId === organizationId
            ? {
                ...organization,
                institutionName,
                properties: {
                  sapNumber: reference,
                  countryCode
                }
              }
            : organization
        )
      };
    }
    case CREATE_ORGANIZATION: {
      const {
        institutionName,
        organizationId,
        reference,
        countryCode
      } = payload;
      return {
        ...state,
        organizations: [
          ...state.organizations,
          {
            institutionName,
            organizationId,
            properties: {
              sapNumber: reference,
              countryCode
            }
          }
        ]
      };
    }
    case SET_ORGANIZATION_USERS: {
      const { users } = payload;
      return {
        ...state,
        users: users.reduce((r, u) => {
          if (u) {
            return [
              ...r,
              {
                ...u,
                id: u.hcpId
              }
            ];
          }
          return r;
        }, [])
      };
    }
    case CREATE_ORGANIZATION_USER: {
      return {
        ...state,
        users: [payload, ...state.users]
      };
    }
    case UPDATE_ORGANIZATION_USER: {
      const { firstName, lastName, roles, id, email } = payload;
      return {
        ...state,
        users: state.users.map(user =>
          user.id === id ? { ...user, firstName, lastName, roles, email } : user
        )
      };
    }
    case DELETE_ORGANIZATION_USER: {
      const newUsers = state.users.filter(u => u.id !== payload.id);
      return {
        ...state,
        users: newUsers
      };
    }
    case SET_SELECTED_ORGANIZATION: {
      const { organization } = payload;
      return {
        ...state,
        selectedOrganization: organization
      };
    }
    case SHOW_USER_FORM: {
      return {
        ...state,
        showUserForm: true
      };
    }
    case HIDE_USER_FORM: {
      return {
        ...state,
        showUserForm: false
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default organizations;
