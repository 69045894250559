import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import DataElements from "components/DataElements";
import {
  getPatientProfileSurvey,
  getOrganizationProfileSurvey,
  getTestTypeSurvey,
  updateSurvey,
  createSurvey,
  deleteSurvey,
  uploadSurvey
} from "store/reducers/surveys/services";

const mapStateToProps = state => ({
  patientSurvey: state.surveys.patientProfileSurveyData,
  organizationSurvey: state.surveys.organizationProfileSurveyData,
  testTypeSurvey: state.surveys.testTypeSurveyData,
  patientSurveyIsLoading: isLoading(state, "getPatientProfileSurveyData"),
  organizationSurveyIsLoading: isLoading(
    state,
    "getOrganizationProfileSurveyData"
  ),
  surveyIsUpdating: isLoading(state, "updateSurvey"),
  testTypeSurveyIsUpdating: isLoading(state, "updateTestTypeSurvey"),
  testTypeList: state.config.testTypes,
  surveyValidating: isLoading(state, "validateSurvey")
});

const mapDispatchToProps = {
  getPatientProfileSurvey,
  getOrganizationProfileSurvey,
  getTestTypeSurvey,
  updateSurvey,
  createSurvey,
  deleteSurvey,
  uploadSurvey
};

export default connect(mapStateToProps, mapDispatchToProps)(DataElements);
