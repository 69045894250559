import TestProfileContacts from "components/TestProfileContacts";
import TestTypesContainer from "../../containers/TestTypes.Container";
import Button from "components/Button";
import Icon from "components/Icon";
import QrCode from "components/QrCode";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import "./qrCodeDetails.scss";
import { useTranslation } from "react-i18next";
import InfinityIntegration from "components/InfinityIntegration";
import ComponentToPrint from "components/ComponentToPrint";
import { useReactToPrint } from 'react-to-print';

const QrCodeDetails = ({
  contactDetails,
  testingProfileId: qrCode,
  testingProfileUpdating,
  setTestingProfileForEdit,
  setTestingProfileDataForEdit,
  saveContactData,
  selectedTestingProfile,
  createInfinityIntegration,
  ...metaData
}) => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return qrCode ? (
    <div className="qr-code-details">
      <ComponentToPrint ref={componentRef}>
        <QrCode
          code={`{"qr-code-type": "configuration","payload": "${qrCode}"}`}
          type={"testProfile"}
          isPrint={true}
          data={{
            ...contactDetails,
            title: metaData.name
          }}
        ></QrCode>
      </ComponentToPrint>
      <div className="contact-information">
        <div className="qr-code-details__qr-code">
          <QrCode
            code={`{"qr-code-type": "configuration","payload": "${qrCode}"}`}
            onlyQrCode={true}
          />
          <div className="qr-actions">
            <Button
              data-testid="button-printer"
              variant="row-action"
              title={t("print")}
              onClick={(e) => {
                e.preventDefault();
                handlePrint();
              }}
            >
              <Icon type="printer" />
            </Button>
          </div>
        </div>
        <div className="qr-summary">
          <InfinityIntegration
            integrationId={metaData?.integrationId}
            testingProfileId={qrCode}
            disabled={selectedTestingProfile?.isEditing?.length || false}
            createInfinityIntegration={createInfinityIntegration}
          />
          <TestProfileContacts
            testingProfileUpdating={testingProfileUpdating}
            selectedTestingProfile={selectedTestingProfile}
            setTestingProfileDataForEdit={setTestingProfileDataForEdit}
            saveContactData={saveContactData}
            {...contactDetails}
            {...metaData}
          />
        </div>
      </div>
      <div className="test-types">
        <TestTypesContainer />
      </div>
    </div>
  ) : null;
};

QrCodeDetails.propTypes = {
  metaData: PropTypes.object,
  qrCode: PropTypes.string
};

export default QrCodeDetails;
