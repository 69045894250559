import React from "react";
import classNames from "classnames";
import "./input.scss";

const Input = React.forwardRef(
  ({ className, disabled, error, ...props }, ref) => (
    <input
      disabled={disabled}
      className={classNames(
        "input",
        { "input--error": error },
        { "input--disabled": disabled },
        className
      )}
      {...props}
      ref={ref}
    />
  )
);

Input.displayName = "Input";

export default Input;
