import * as actions from "./actions";
import * as api from "api/cohorts";
import { success } from "react-notification-system-redux";
import i18n from "../../../translations";

export const getCohortProfiles = () => async dispatch => {
  const result = await dispatch(api.getCohortProfiles());
  if (result && result.status === 200) {
    const cohortProfiles = result.data;
    dispatch(actions.setCohorts(cohortProfiles));
  }
};

export const createCohortProfile = ({
  name,
  description
}) => async dispatch => {
  const result = await dispatch(api.createCohortProfile({ name, description }));
  if (result && result.status === 200) {
    const cohortProfile = result.data;
    dispatch(actions.createCohortProfile(cohortProfile));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_cohort_created"),
        position: "tc"
      })
    );
    return true;
  } else {
    return false;
  }
};

export const getCohortProfileData = id => async dispatch => {
  const result = await dispatch(api.getCohortProfileData(id));

  if (result && result.status === 200) {
    const cohort = result.data;
    dispatch(actions.setSelectedCohortProfile(cohort));
  }
};

export const updateCohortProfile = payload => async (dispatch, getState) => {
  const result = await dispatch(api.updateCohortProfile(payload));
  if (result && result.status === 200) {
    const { cohortId, shortId, name, description, contactDetails, properties } = payload;
    dispatch(
      actions.updateCohortProfiles({ cohortId, shortId, name, description, properties })
    );
    let cohortProfileData = {
      cohortId,
      shortId,
      name,
      description,
      contactDetails,
      properties
    };
    dispatch(actions.updateCohortProfileData(cohortProfileData));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_cohort_updated"),
        position: "tc"
      })
    );
    return true;
  } else {
    return false;
  }
};

export const clearSelectedCohort = () => dispatch => {
  dispatch(actions.setSelectedCohortProfile({}));
};
