import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Text from "components/Text";
import "./pageTitle.scss";

const PageTitle = ({ title, subtitle, children, backLink, className }) => {
  return (
    <div className={classNames("page-title", className)}>
      {backLink && (
        <>
          <Link className="back-link" to={backLink.path}>
            {backLink.text}
          </Link>
          <br />
        </>
      )}
      <Text as="h1">{title}</Text>
      {children}
      {subtitle && <Text>{subtitle}</Text>}
    </div>
  );
};

export default PageTitle;
