import phoneImage from "assets/images/cohortPhone.png";
import Button from "components/Button";
import ConfirmPopup from "components/ConfirmPopup";
import Icon from "components/Icon";
import PageTitle from "components/PageTitle";
import CohortDetails from "components/CohortDetails";
import Spinner from "components/Spinner";
import Table from "components/Table";
import PropTypes from "prop-types";
import Error from "components/Error";
import QRCode from "qrcode.react";
import React from "react";
import { sortIgnoreCase } from "utils/sortMethods";
import CohortProfileForm from "../CohortProfileForm/CohortProfileForm";
import { useTranslation } from "react-i18next";
import { initPrintView } from "utils/print";
import { useForm, FormContext, useFormContext } from "react-hook-form";
import { validationRules } from "utils/FieldValidationRules";
import FloatNavbar from "components/FloatNavbar";
import { withRouter } from "react-router-dom";
import RouteLeavingGuard from "components/RouteLeavingGuard";
import useSticky from "../../utils/useSticky";
import { DataRetention, CohortProfileSurvey } from "components";
import {getSeconds} from "utils/formatData";
import { SURVEY_FOR_TEST_SHARING_WITH_COHORT } from "const/surveyTypes";

import "./cohortProfiles.scss";

export const CohortEditingContext = React.createContext([]);

const defaultColumn = {
  Cell: ({ row, value, column }) => {
    const { register, formState, errors } = useFormContext();
    const { dirtyFields } = formState;
    const context = React.useContext(CohortEditingContext);
    if (
      context?.currentlyEditing?.selectedCohort === row.original.cohortId &&
      context?.currentlyEditing?.isEditing
    ) {
      return (
        <>
          <textarea
            rows={2}
            name={column.id}
            ref={register(validationRules[column.id])}
            style={{
              backgroundColor: dirtyFields.has(column.id) ? "#fffcda" : ""
            }}
            className="table-cell-input"
            defaultValue={value}
          />
          {errors?.[column?.id] && (
            <Error>{errors?.[column?.id]?.message}</Error>
          )}
        </>
      );
    }
    return <p>{value}</p>;
  }
};

const CohortProfiles = ({
  cohortProfiles,
  getCohortProfiles,
  cohortProfilesLoading,
  createCohortProfile,
  cohortProfileCreating,
  getCohortProfileData,
  cohortProfileData,
  cohortProfileDataLoading,
  history,
  updateCohortProfile,
  cohortProfileUpdating,
  clearSelectedCohort,
  organizationId,
  getCohortProfileSurvey,
  cohortProfileSurvey,
  cohortSurveyIsLoading,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  clearCohortSurvey,
  uploadSurvey,
  surveyValidating
}) => {
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showUnsavedChangesModal, setshowUnsavedChangesModal] = React.useState(
    false
  );
  const [unsavedConfirmConfig, setUnsavedConfirmConfig] = React.useState({});
  const [selectedCohortProfile, setSelectedCohortProfile] = React.useState({});
  const [canLeavePage, setCanLeavePage] = React.useState(true);

  React.useEffect(() => {
    getCohortProfiles();
    return () => {
      clearSelectedCohort();
    };
  }, [getCohortProfiles, clearSelectedCohort]);

  React.useEffect(initPrintView, []);

  const initialEditingState = React.useMemo(
    () => ({
      selectedCohort: "",
      isEditing: false,
      sections: []
    }),
    []
  );
  const [currentlyEditing, setCurrentlyEditing] = React.useState(
    initialEditingState
  );
  const { isSticky, element } = useSticky();

  const methods = useForm({
    mode: "onChange"
  });
  const { formState, reset, handleSubmit } = methods;

  React.useEffect(() => {
    setCanLeavePage(!formState.dirty);
  }, [formState]);

  const onSubmit = async data => {
    let updatedData = {
      ...cohortProfileData,
      ...data,
      ...(data.properties && {properties: {retentionPeriod: getSeconds(data.properties)}}),
      cohortId: cohortProfileData.cohortId
    };
    let result = await updateCohortProfile(updatedData);
    if (result) {
      setCurrentlyEditing(prevState => ({
        ...prevState,
        isEditing: false,
        sections: []
      }));
    }
    resetForm();
  };

  const resetForm = React.useCallback(() => {
    reset();
    setCurrentlyEditing(initialEditingState);
  }, [initialEditingState, reset]);

  const onCancel = React.useCallback(() => {
    resetForm();
  }, [resetForm]);

  const addCohortProfile = React.useCallback(
    async ({ name, description }) => {
      await createCohortProfile({ name, description });
      hideAddCohortProfileForm();
    },

    [createCohortProfile]
  );

  const hideAddCohortProfileForm = () => {
    setShowAddModal(false);
  };

  const onConfirmUnsavedChanges = React.useCallback(
    async id => {
      setshowUnsavedChangesModal(false);
      let selected = cohortProfiles.find(p => p.cohortId === id);
      setSelectedCohortProfile(selected);
      resetForm();
      getCohortProfileData(id);
      setCurrentlyEditing(prevState => ({
        ...prevState,
        selectedCohort: id,
        isEditing: false
      }));
    },
    [getCohortProfileData, resetForm, cohortProfiles]
  );

  const unsavedConfig = React.useCallback(
    id => {
      setUnsavedConfirmConfig({
        title: t("unsaved_changes_title"),
        message: t("unsaved_changes_description"),
        onConfirm: () => onConfirmUnsavedChanges(id)
      });
      setshowUnsavedChangesModal(true);
      return false;
    },
    [onConfirmUnsavedChanges, t]
  );

  const onEdit = React.useCallback(
    async e => {
      if (formState.dirty) {
        if (e.cohortId !== cohortProfileData.cohortId) {
          unsavedConfig(e.cohortId);
          return;
        } else {
          setCurrentlyEditing(prevState => ({
            ...prevState,
            selectedCohort: e.cohortId,
            isEditing: true
          }));
          return;
        }
      } else {
        if (e.cohortId !== cohortProfileData.cohortId) {
          getCohortProfileData(e.cohortId);
          setSelectedCohortProfile(e);
        }
        setCurrentlyEditing(prevState => ({
          ...prevState,
          selectedCohort: e.cohortId,
          isEditing: true
        }));
      }
    },
    [cohortProfileData, unsavedConfig, getCohortProfileData, formState]
  );

  const onRowClick = React.useCallback(
    async e => {
      if (!formState.dirty) {
        if (cohortProfileData.cohortId !== e.cohortId) {
          setSelectedCohortProfile(e);
          setCurrentlyEditing(prevState => ({
            ...prevState,
            selectedCohort: e.cohortId,
            isEditing: false,
            sections: []
          }));
          await getCohortProfileData(e.cohortId);
          return;
        } else {
          return;
        }
      } else {
        if (cohortProfileData.cohortId === e.cohortId) {
          return;
        } else {
          unsavedConfig(e.cohortId);
          return;
        }
      }
    },
    [cohortProfileData, formState, getCohortProfileData, unsavedConfig]
  );

  const uploadImportedSurvey = async(importedSurvey, currentSurvey, surveyType) => {
    uploadSurvey(importedSurvey, currentSurvey, surveyType, cohortProfileData.cohortId)
  }

  const cohortColumns = [
    {
      Header: () => (
        <div className="header-content">
          <div>{t("qr_code")}</div> <span className="badge">1</span>
        </div>
      ),
      accessor: "cohortId",
      disableSortBy: true,
      Cell: ({ row }) => {
        return row.original.cohortId ? (
          <div className="qr-codes__code-cell">
            <div className="qr-code__image">
              <QRCode
                value={row.original.cohortId}
                size={30}
                renderAs={"svg"}
                level={"H"}
                includeMargin={true}
              />
            </div>
          </div>
        ) : null;
      }
    },
    {
      Header: () => (
        <div className="header-content">
          <div>{t("name")}</div> <span className="badge">2</span>
        </div>
      ),
      accessor: "name",
      style: {
        width: "25%"
      },
      className: "qr-codes__name",
      sortType: sortIgnoreCase
    },
    {
      Header: t("description"),
      accessor: "description",
      style: {
        width: "65%",
        minWidth: "80px"
      },
      className: "qr-codes__description",
      disableSortBy: true
    },
    {
      Header: t("health_care_organizations_actions"),
      style: {
        minWidth: "60px"
      },
      className: "qr-codes__actions-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <React.Fragment>
            <Button
              data-testid="edit-button"
              variant="row-action"
              title={t("health_care_organizations_actions_edit")}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onEdit(row.original);
              }}
            >
              <Icon type="edit" />
            </Button>
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <>
      <CohortEditingContext.Provider
        value={{ currentlyEditing, setCurrentlyEditing }}
      >
        <FormContext {...methods}>
          <form className="cohorts">
            <div className="button-container">
              <Button
                variant="primary"
                className="button header-add-button"
                onClick={e => {
                  e.preventDefault();
                  setShowAddModal(true);
                }}
                disabled={formState.dirty}
                ref={element}
              >
                {t("create_cohort_button")}
              </Button>
              <Button
                type="button"
                variant="secondary"
                className="button header-add-button"
                disabled={!formState.dirty}
                onClick={onCancel}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="primary"
                className="button header-add-button"
                disabled={!formState.isValid || !formState.dirty}
                onClick={handleSubmit(onSubmit)}
                loading={cohortProfileUpdating}
              >
                {t("save")}
              </Button>
            </div>
            {isSticky && formState.dirty && (
              <FloatNavbar
                onSave={handleSubmit(onSubmit)}
                onCancel={e => {
                  e.preventDefault();
                  onCancel();
                }}
                loading={cohortProfileUpdating}
                saveDisabled={!formState.isValid || !formState.dirty}
              />
            )}

            <PageTitle title={t("cohort_profiles")} />

            {cohortProfilesLoading ? (
              <Spinner marginTop={15} />
            ) : (
              <div className="table-container">
                <img src={phoneImage} alt="phone" className="phone-image-1" />
                <Table
                  globalFilter
                  pagination
                  defaultColumn={defaultColumn}
                  pageSize={10}
                  columns={cohortColumns}
                  data={cohortProfiles}
                  selectedRowIndex={cohortProfiles.findIndex(item => {
                    return item.cohortId === selectedCohortProfile?.cohortId;
                  })}
                  className="access-profiles-table"
                  onRowClick={onRowClick}
                />
              </div>
            )}

            {showUnsavedChangesModal && (
              <ConfirmPopup
                title={unsavedConfirmConfig.title}
                message={unsavedConfirmConfig.message}
                visible={showUnsavedChangesModal}
                cancel={() => setshowUnsavedChangesModal(false)}
                confirm={unsavedConfirmConfig.onConfirm}
                //loading={accessProfileSaving}
              />
            )}
            {cohortProfileDataLoading ? (
              <Spinner marginTop={5} />
            ) : (
              cohortProfileData?.cohortId && (
                <>
                  <PageTitle title={cohortProfileData.name} />
                  <CohortDetails
                    cohort={cohortProfileData}
                    contactDetails={cohortProfileData.contactDetails}
                    organizationId={organizationId}
                  />
                  <DataRetention properties={cohortProfileData.properties}/> 
                </>
              )
            )}
          </form>
        </FormContext>
        {cohortProfileData?.cohortId && (
          <div className="cohort-survey-container">
            <CohortProfileSurvey 
              getCohortProfileSurvey={getCohortProfileSurvey}
              survey={cohortProfileSurvey}
              loading={cohortSurveyIsLoading}
              disabled={formState.dirty}
              createSurvey={createSurvey}
              updateSurvey={updateSurvey}
              deleteSurvey={deleteSurvey}
              cohortId={cohortProfileData?.cohortId}
              clearCohortSurvey={clearCohortSurvey}
              uploadImportedSurvey={uploadImportedSurvey}
              surveyValidating = {surveyValidating}
              surveyType={SURVEY_FOR_TEST_SHARING_WITH_COHORT}
            />
          </div>
        )}
        {showAddModal && (
          <CohortProfileForm
            onSubmit={addCohortProfile}
            loading={cohortProfileCreating}
            hide={() => setShowAddModal(false)}
          />
        )}
        <iframe
          title="ifmcontentstoprint"
          id="ifmcontentstoprint"
          style={{ visibility: "hidden", height: "10px" }}
        />
        <RouteLeavingGuard
          when={!canLeavePage}
          navigate={path => history.push(path)}
        />
      </CohortEditingContext.Provider>
    </>
  );
};

CohortProfiles.propTypes = {
  cohortProfiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  cohortProfilesLoading: PropTypes.bool,
  qrCodeSaving: PropTypes.bool,
  cohortProfileCreating: PropTypes.bool,
  cohortProfilelsLoading: PropTypes.bool
};

export default withRouter(CohortProfiles);
