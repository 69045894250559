import { connect } from "react-redux";
import { removeLoggedUser } from "store/reducers/user/services";
import DashboardLayout from "components/DashboardLayout";

const mapStateToProps = ({ user }) => ({
  user
});

const mapDispatchToProps = {
  removeLoggedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
