import { ERROR, PENDING, DONE } from "../const/requestStatusTypes";

export const getRequest = (state, requestName) => state.requests[requestName];

export const getRequestStatus = (state, requestName) => {
  const request = getRequest(state, requestName);

  if (request) {
    return request.status;
  }
};

export const getRequestErrorMessage = (state, requestName) => {
  const request = getRequest(state, requestName);

  if (request) {
    return request.message;
  }
};

export const isLoading = (state, requestName) =>
  getRequestStatus(state, requestName) === PENDING;

export const isDone = (state, requestName) =>
  getRequestStatus(state, requestName) === DONE;

export const isError = (state, requestName) =>
  getRequestStatus(state, requestName) === ERROR;
