import React from "react";
import OrganizationsContainer from "containers/Organizations.Container";
import OrganizationUsersContainer from "containers/OrganizationUsers.Container";

const Organizations = () => (
  <React.Fragment>
    <OrganizationsContainer />
    <OrganizationUsersContainer />
  </React.Fragment>
);

export default Organizations;
