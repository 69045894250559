import { connect } from "react-redux";
import HomeTestProfile from "components/HomeTestProfile";
import { getDefaultTestConfig } from "store/reducers/config/services";
import { isLoading } from "selectors/request";
import {
  getHomeTestProfilesList,
  createHomeTestProfile,
  updateHomeTestProfile,
  publishHomeTestProfile,
  activateDeactivateHomeTestProfile
} from "store/reducers/homeTest/services";
import { getLoggedAdminUserCountry } from "selectors/loggedUser";

const mapStateToProps = state => ({
  defaultTestProfile: state.config.defaultTestConfig,
  testProfile: state.homeTest.homeTestProfile,
  testProfiles: state.homeTest.profiles,
  config: state.config,
  countryCode: getLoggedAdminUserCountry(state),
  testTypes: state.config.testTypes,
  homeTestSaving: isLoading(state, "updateHomeTestProfile"),
  homeTestPublishing: isLoading(state, "publishHomeTestProfile")
});

const mapDispatchToProps = {
  getDefaultTestConfig,
  getHomeTestProfilesList,
  createHomeTestProfile,
  updateHomeTestProfile,
  publishHomeTestProfile,
  activateDeactivateHomeTestProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeTestProfile);
