import { error } from "react-notification-system-redux";
import { removeLoggedUser } from "store/reducers/user/services";
import { updateRequestStatus } from "store/reducers/requests/actions";
import { DONE, PENDING, ERROR } from "const/requestStatusTypes";
import i18n from "../translations";

const handleError = () =>
  error({
    title: i18n.t("error"),
    message: i18n.t("error_network"),
    position: "tc"
  });

const makeRequest = (syncName, { client, query }) => async dispatch => {
  dispatch(updateRequestStatus(syncName, PENDING));

  try {
    const { data } = await client.query(query);
    setTimeout(() => dispatch(updateRequestStatus(syncName, DONE)), 0);

    return data;
  } catch ({ message }) {
    dispatch(updateRequestStatus(syncName, ERROR, message));
    dispatch(handleError());
  }
};

export const makeAWSRequest = (syncName, apiCall) => async dispatch => {
  dispatch(updateRequestStatus(syncName, PENDING));

  try {
    const data = await apiCall();
    dispatch(updateRequestStatus(syncName, DONE));
    return data;
  } catch (error) {
    dispatch(updateRequestStatus(syncName, ERROR, error.message));
    return error;
  }
};

export const makeSecurityGuardRequest = (
  syncName,
  { client, query }
) => async dispatch => {
  dispatch(updateRequestStatus(syncName, PENDING));

  try {
    const { data } = await client.query(query);
    setTimeout(() => dispatch(updateRequestStatus(syncName, DONE)), 0);

    return data;
  } catch ({ message }) {
    dispatch(updateRequestStatus(syncName, ERROR, message));
    if (!message.includes("more than one")) {
      dispatch(handleError());
    } else {
      return { errors: message };
    }
  }
};

export const makeAxiosRequest = (
  syncName,
  { client, method, relativePath, data, options = {}, signal = {} }
) => async dispatch => {
  dispatch(updateRequestStatus(syncName, PENDING));
  try {
    const response = await client[method](
      relativePath,
      method === "get" || method === "delete"
        ? { params: data, ...options }
        : data,
      options,
      signal,
    );

    //timeout to reset loading status AFTER the data is provided
    setTimeout(() => dispatch(updateRequestStatus(syncName, DONE)), 0);
    if(signal.aborted) {
      return "request cancelled"
    }
    return response;
  } catch (e) {
    dispatch(updateRequestStatus(syncName, ERROR, e.message));
    if (e.message.match(/code\s401/)) {
      dispatch(removeLoggedUser("server"));
    }
    if (e.message.match(/code\s412/)) {
      return e;
    } 
    if (e?.response?.data?.path === "/surveys/validate" && e?.response?.data?.status === 400) {
      return e;
    }
    if (e?.response?.data?.path.includes("/admin/organizations") && e?.response?.data?.status === 412) {
      return e;
    }
    else if (!e.message.match(/code\s404/)) {
      dispatch(handleError());
    }
    return e;
  }
};

export default makeRequest;
