import React from "react";
import AboutContainer from "containers/About.Container";

const About = () => (
  <>
    <AboutContainer />
  </>
);

export default About;
