import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  getFilteredPatientList,
  clearFilteredPatient,
  getSelectedPatientData,
  clearState,
  clearSelectedPatient
} from "store/reducers/patients/services";
import { getTestingProfiles } from "store/reducers/qrCodes/services";
import { getCohortProfiles } from "store/reducers/cohorts/services";
import SearchRecipients from "components/SearchRecipients";

const mapStateToProps = (state, ownProps) => ({
  patients: state.patients,
  testTypes: state.config.testTypes,
  filteredPatients: state.patients?.filteredPatientList,
  filteredPatientIsLoading: isLoading(state, "getFilteredPatientList"),
  serverTime: state.patients?.serverTime,
  testProfiles: state.qrCodes,
  testingProfilesLoading: isLoading(state, "getTestingProfiles"),
  selectedPatientData: state.patients.selectedPatientData,
  patientIsLoading: isLoading(state, "getPatientData"),
  surveyIsLoading:
    isLoading(state, "getPatientProfileSurveyData") ||
    isLoading(state, "getSurveyById") ||
    isLoading(state, "getSignature"),
  membersSearch: ownProps.location === "members",
  cohortProfiles: state.cohorts.cohortProfiles,
  cohortProfilesLoading: isLoading(state, "getCohortProfiles")
});

const mapDispatchToProps = {
  getFilteredPatientList,
  getTestingProfiles,
  clearFilteredPatient,
  getSelectedPatientData,
  clearState,
  clearSelectedPatient,
  getCohortProfiles
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchRecipients);
