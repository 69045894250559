import Button from "components/Button";
import Error from "components/Error";
import { HomeProfileEditingContext } from "components/HomeTestProfile/HomeTestProfile";
import PageTitle from "components/PageTitle";
import SelectOption from "components/SelectOption";
import Table from "components/Table";
import { HOME_TEST_VALUE } from "const/app";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { validationRules } from "utils/FieldValidationRules";
import "./HomeTestTypes.scss";

const defaultColumn = {
  Cell: ({ value, column }) => {
    const { register, formState, errors, control } = useFormContext();
    const { dirtyFields } = formState;
    const { t } = useTranslation();
    const testTypes = useSelector(state => state.config.testTypes);
    const availableTestTypes = testTypes.filter(
      test => test.value === HOME_TEST_VALUE
    );
    const testTypeName = testTypes.find(test => test.value === HOME_TEST_VALUE);

    const context = React.useContext(HomeProfileEditingContext);

    if (context?.currentlyEditing.sections?.includes("testTypes")) {
      if (column.id === "testType")
        return (
          <Controller
            as={<SelectOption fullWidth />}
            options={availableTestTypes}
            placeholder={t("select")}
            defaultValue={HOME_TEST_VALUE}
            control={control}
            onChange={([selected]) => {
              return selected?.value;
            }}
            rules={{ required: t("form_field_is_required") }}
            name={`testConfiguration[0].${column.id}`}
          />
        );
      if(column.id !== "isReportingEnable" && column.id !== "isSurveySkippable")
        return (
          <div>
            <textarea
              rows={2}
              name={`testConfiguration[0].${column.id}`}
              ref={register(validationRules[column.id])}
              style={{
                backgroundColor: dirtyFields.has(
                  `testConfiguration[0].${column.id}`
                )
                  ? "#fffcda"
                  : ""
              }}
              className="table-cell-input"
              defaultValue={value}
              onClick={e => e.stopPropagation()}
            />
            {errors?.testConfiguration?.[0]?.[column.id] && (
              <Error>{errors?.testConfiguration[0]?.[column.id]?.message}</Error>
            )}
          </div>
        );
    }
    if (column.id === "isReportingEnable" || column.id === "isSurveySkippable") {
      const handleCheckboxChange = () => {
        context.setCurrentlyEditing(prevState => {
          if(!prevState.sections?.includes(`${column.id}-checkbox`)) {
            return {
              sections: [...prevState.sections, `${column.id}-checkbox`]
            };
          } else {
            return {
              sections: prevState.sections.filter(i => i !== `${column.id}-checkbox`)
            };
          }
        });
      }
      return (
        <div className="form-checkbox-conatainer">    
          <input
            ref={register}
            name={`testConfiguration[0].${column.id}`}
            defaultChecked={value}
            onClick={e => e.stopPropagation()}
            onChange={(e) => handleCheckboxChange(e)}
            type="checkbox"
          />
        </div>
      )
    }
    return (
      <div className="cell-badge">
        <p>{column.id === "testType" ? testTypeName?.label : value}</p>
      </div>
    );
  }
};

const HomeTestTypes = ({ testProfile }) => {
  
  const { t } = useTranslation();
  const context = React.useContext(HomeProfileEditingContext);

  const { testConfiguration } = testProfile;
  const isEditing = context?.currentlyEditing?.sections?.includes("testTypes");

  const testTypesColumns = [
    {
      Header: t("enable_external_report"),
      accessor: "isReportingEnable",
      id: "isReportingEnable",
      style: {
        width: "100px"
      }
    },
    {
      Header: t("enable_optional"),
      accessor: "isSurveySkippable",
      id: "isSurveySkippable",
      style: {
        width: "110px"
      }
    },
    {
      Header: t("test_name"),
      accessor: "testTypeName",
      id: "testTypeName",
      style: {
        width: "auto",
        minWidth: "80px"
      }
    },
    {
      Header: t("test_type"),
      accessor: "testType",
      id: "testType",
      style: {
        width: "25%"
      }
    },

    {
      Header: t("test_description"),
      accessor: "testTypeDescription",
      id: "testTypeDescription",
      style: {
        width: "30%"
      }
    }
  ];

  return (
    <>
      <div className="title-top home-test-type-title">
        <PageTitle title={t("test")}></PageTitle>

        <Button
          variant="primary"
          className="header-add-button"
          onClick={() => {
            context.setCurrentlyEditing(prevState => {
              return {
                sections: [...prevState.sections, "testTypes"]
              };
            });
          }}
          disabled={isEditing}
        >
          {t("edit")}
        </Button>
      </div>
      <hr className="devider" />
      <div className="home-test-type">
        {testConfiguration?.length && (
          <Table
            columns={testTypesColumns}
            data={testProfile.testConfiguration}
            className={"home-test-profile"}
            defaultColumn={defaultColumn}
            disabledSort={true}
          />
        )}
      </div>
    </>
  );
};

export default HomeTestTypes;
