import React from "react";
import { PageTitle, Spinner, Table, Button, ConfirmPopup } from "components";
import PatientDetailsContainer from "../../containers/PatientDetails.Container";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/useLanguage";
import TextColumnFilter from "../Table/addons/TextColumnFilter";
import { sortIgnoreCase, sortDateOfBirth } from "utils/sortMethods";
import { getPhoneCountry } from "utils/formatData";
import { checkIfSurveyIsAnswered } from "utils/mappers";
import * as _ from "lodash";
import moment from "moment";
import AddPatientForm from "components/AddPatientForm";
import PatientConsent from "components/PatientConsent";
import PatientExistsModal from "components/PatientExistsModal";
import PatientSurveyForm from "components/PatientSurveyForm";
import {TEST_RESULTS, SHARED_TEST_RESULTS} from "const/testResults";
import "./Patients.scss";

const patientObject = {
  profile: {},
  configuration: {},
  extendedProfile: {},
  consentAcceptedAt: null
};

const Patients = ({
  getPatients,
  clearState,
  patients,
  patientsLoading,
  getSelectedPatientData,
  selectedPatientData,
  discoveredPatientsLoading,
  patientIsCreating,
  showPatientForm,
  hidePatientForm,
  isAddingPatient,
  getPatientProfileSurvey,
  patientProfileSurvey,
  searchSinglePatient,
  createPatient,
  filteredPatients,
  linkPatient,
  patientIsLoading,
  surveyIsLoading,
  membersPage,
  hasBothManagerRoles,
  getCohortProfiles,
  getMembers,
  cohortProfiles,
  clearPatientList
}) => {
  const { t } = useTranslation();
  const { flippedNameOrder } = useLanguage();
  const [flow, setFlow] = React.useState(null);
  const [patientConsentStatus, setPatientConsentStatus] = React.useState(false);
  const [patientExisting, setPatientExisting] = React.useState(false);
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [surveyFormStatus, setSurveyFormStatus] = React.useState(false);
  const [confirmConfig, setConfirmConfig] = React.useState({});
  const [newPatient, setNewPatient] = React.useState(patientObject);
  const [emailError, setEmailError] = React.useState(false);
  const [selectedPatient, setSelectedPatient] = React.useState({});
  const [listFetched, setListFetched] = React.useState(false);
  const [cohortProfilesFetched, setCohortProfilesFetched] = React.useState(false);

  React.useEffect(() => {
    if(membersPage && !cohortProfiles.length && !cohortProfilesFetched) {
      getCohortProfiles();
      setCohortProfilesFetched(true)
    }
  }, [membersPage, cohortProfiles, getCohortProfiles, cohortProfilesFetched])

  React.useEffect(() => {
    const controller = new AbortController();
    if(hasBothManagerRoles) {  
      if(membersPage) {  
        getMembers(controller)
      } else {
          getPatients(controller)
      }
    } 
    return () => {
      if(hasBothManagerRoles) {
        controller.abort()
        clearPatientList()
      }
      clearState();
    };
  },[clearPatientList, clearState, hasBothManagerRoles, getMembers, getPatients, membersPage])
  
  React.useEffect(() => {
    if(!hasBothManagerRoles && !patients.length && !listFetched) {
      if(membersPage) {
        getMembers()  
      } else {
        getPatients()
      }
      setListFetched(true)
    }   
  },[
    getPatients,
    clearState,  
    getMembers,  
    hasBothManagerRoles, 
    membersPage,
    clearPatientList,
    listFetched,
    patients
  ]);

  const mapData = React.useCallback(
    patients => {
      return patients?.map(member => ({
        ...member,
        cohortNames: cohortProfiles.length ? member.cohortIds?.map(c => cohortProfiles.find(cohort => cohort.cohortId === c)?.name).join(", ") : ""
      }));
    },
    [cohortProfiles]
  );

  const nameColumns = React.useMemo(
    () => [
      {
        Header: t("first_name_cap"),
        accessor: "firstName",
        Filter: TextColumnFilter,
        sortType: sortIgnoreCase
      },
      {
        Header: t("last_name_cap"),
        accessor: "lastName",
        Filter: TextColumnFilter,
        sortType: sortIgnoreCase
      }
    ],
    [t]
  );

  const nameColumnsFlipped = React.useMemo(
    () => [
      {
        Header: t("last_name_cap"),
        accessor: "lastName",
        Filter: TextColumnFilter,
        sortType: sortIgnoreCase
      },
      {
        Header: t("first_name_cap"),
        accessor: "firstName",
        Filter: TextColumnFilter,
        sortType: sortIgnoreCase
      }
    ],
    [t]
  );

  const cohortColumn = React.useMemo(
    () => [
      {
        Header: t("cohort_profile_label"),
        accessor: "cohortNames",
        Filter: TextColumnFilter,
        sortType: sortIgnoreCase
      },
    ],[t]
  )


  const patientsColumns = React.useMemo(
    () => [
      ...(flippedNameOrder ? nameColumnsFlipped : nameColumns),

      {
        Header: t("date_of_birth_label"),
        accessor: "dateOfBirth",
        Filter: TextColumnFilter,
        sortType: sortDateOfBirth
      },
      {
        Header: t("phone_number"),
        accessor: "phoneNumber",
        Filter: TextColumnFilter
      },
      {
        Header: t("email_address"),
        accessor: "email",
        Filter: TextColumnFilter,
        sortType: sortIgnoreCase
      },
      ...(membersPage ? cohortColumn : []),
      {
        Header: t("zip_code"),
        accessor: "zipCode",
        Filter: TextColumnFilter,
        sortType: sortIgnoreCase
      },
      {
        //FIXME: fix translation key!!! later...
        Header: t("health_care_organizations_add_modal_country"),
        accessor: "country",
        Filter: TextColumnFilter
      }
    ],
    [flippedNameOrder, nameColumns, nameColumnsFlipped, cohortColumn, membersPage, t]
  );

  //On cancel everything needs to be reset
  const cancelFlow = React.useCallback(() => {
    setFlow(null);
    setPatientConsentStatus(false);
    hidePatientForm();
    setSurveyFormStatus(false);
    setShowWarningModal(false);
    setPatientExisting(false);
    setNewPatient(patientObject);
    setEmailError(false);
    setSelectedPatient({});
    clearState();
  }, [clearState, hidePatientForm, setFlow]);

  const sendRequest = React.useCallback(
    patient => {
      if (flow === "ADD") {
        (async () => {
          const result = await createPatient(patient);
          if (result === "duplicate") {
            setEmailError(true);
            showPatientForm();
            setPatientConsentStatus(false);
            setSurveyFormStatus(false);
          } else {
            cancelFlow();
          }
        })();
      } else {
        (async () => {
          await linkPatient(patient);
          cancelFlow();
        })();
      }
    },
    [cancelFlow, createPatient, flow, linkPatient, showPatientForm]
  );

  //Warning modal
  const onConfirmWarningModal = React.useCallback(() => {
    //Warning modal confirmed, account creation cancelled
    cancelFlow();
  }, [cancelFlow]);

  const onCancelWarningModal = React.useCallback(() => {
    //Go back to consent modal!
    setShowWarningModal(false);
    setPatientConsentStatus(true);
  }, []);

  //Consent modal
  const declinePatientConsent = React.useCallback(() => {
    setConfirmConfig({
      title: t("warning_title"),
      message: t("warning_content"),
      onConfirm: () => onConfirmWarningModal(),
      onCancel: () => onCancelWarningModal()
    });
    setPatientConsentStatus(false);
    setShowWarningModal(true);
  }, [onCancelWarningModal, onConfirmWarningModal, t]);

  const onConsentSubmit = React.useCallback(async () => {
    let timeStamp = moment().valueOf();
    setNewPatient({ ...newPatient, consentAcceptedAt: timeStamp });
    let patientRequest = { ...newPatient, consentAcceptedAt: timeStamp };
    if (!_.isEmpty(patientProfileSurvey)) {
      //There is a survey, open survey form
      if (
        (patientProfileSurvey.surveyId ===
          newPatient.extendedProfile.surveyId) &
        checkIfSurveyIsAnswered(newPatient, patientProfileSurvey.survey)
      ) {
        sendRequest(patientRequest);
      } else {
        setPatientConsentStatus(false);
        setSurveyFormStatus(true);
      }
    } else {
      //There is no survey, account can be created
      setSurveyFormStatus(false);
      sendRequest(patientRequest);
    }
  }, [patientProfileSurvey, newPatient, sendRequest]);

  //Add patient modal
  const onAddPatientSubmit = React.useCallback(
    async params => {
      const dateFormat = "YYYY-MM-DD";
      let searchPatient = {
        firstName: params.firstName,
        lastName: params.lastName,
        dateOfBirth: moment(params.dateOfBirth).format(dateFormat),
        country: params.country,
        ...(params.email.length ? { email: params.email } : null),
        ...(params.phoneNumber.number.length
          ? { phoneNumber: { ...params.phoneNumber } }
          : null)
      };
      setNewPatient({
        ...newPatient,
        profile: {
          ...newPatient.profile,
          firstName: params.firstName,
          lastName: params.lastName,
          dateOfBirth: moment(params.dateOfBirth).format(dateFormat),
          ...(params.phoneNumber.number.length && {
            phoneNumber: {
              ...params.phoneNumber,
              country: getPhoneCountry(params.phoneNumber.countryCode)
            }
          }),
          zipCode: params.zipCode
        },
        configuration: {
          ...newPatient.configuration,
          country: params.country,
          email: params.email
        }
      });
      //Look for existing accounts by sending the searchPatient object:
      let foundPatients = await searchSinglePatient(searchPatient);

      if (foundPatients) {
        // There are discovered accounts
        setPatientExisting(true);
      } else {
        // There are no similar accounts,
        await getPatientProfileSurvey(params.country);
        setPatientConsentStatus(true);
      }
      hidePatientForm();
    },
    [getPatientProfileSurvey, hidePatientForm, searchSinglePatient, newPatient]
  );

  //Survey modal
  const onSurveySubmit = React.useCallback(
    params => {
      let questionary = {
        surveyId: patientProfileSurvey.surveyId,
        surveyAnswers: params?.surveyAnswers,
        surveyVersion: patientProfileSurvey.surveyVersion,
        surveyChecksum: patientProfileSurvey.surveyChecksum
      };
      setNewPatient({
        ...newPatient,
        extendedProfile: questionary
      });
      let patientRequest = { ...newPatient, extendedProfile: questionary };
      sendRequest(patientRequest);
    },
    [newPatient, patientProfileSurvey, sendRequest]
  );

  const hideSurveyForm = () => {
    setSurveyFormStatus(false);
    setPatientConsentStatus(true);
  };

  //Exists patient modal
  const onCreateNewAccount = React.useCallback(() => {
    if (filteredPatients.length) {
      let emailExist = filteredPatients.find(
        item => item.configuration?.email === newPatient.configuration?.email
      );
      if (newPatient.configuration.email.length && emailExist) {
        setPatientExisting(false);
        showPatientForm();
        setEmailError(true);
        return;
      }
    }
    getPatientProfileSurvey(newPatient.configuration.country);
    setPatientExisting(false);
    setPatientConsentStatus(true);
    clearState();
  }, [
    getPatientProfileSurvey,
    newPatient,
    clearState,
    filteredPatients,
    showPatientForm
  ]);

  const onLinkAccount = React.useCallback(
    params => {
      //api to link existing account to organization need: id, consentAcceptedAt
      setFlow("LINK");
      setNewPatient({ ...newPatient, ...params });
      getPatientProfileSurvey(params.configuration.country);
      setPatientExisting(false);
      setPatientConsentStatus(true);
    },
    [setFlow, getPatientProfileSurvey, newPatient]
  );

  const onRowClick = React.useCallback(
    async e => {
      setSelectedPatient(e);
      membersPage 
      ? getSelectedPatientData(
        null,
        e.patientId,
        null,
        SHARED_TEST_RESULTS
      )
      : getSelectedPatientData(
        e.patientProfile.configuration?.country,
        e.patientId,
        e.patientProfile.extendedProfile?.surveyId,
        TEST_RESULTS
      );
    },
    [getSelectedPatientData, membersPage]
  );

  return (
    <div className="patients-dashboard" data-testid="patients">
      {!membersPage ?
        <div className="button-container">
          <Button
            variant="primary"
            className="button header-add-button"
            onClick={() => {
              setFlow("ADD");
              showPatientForm();
            }}
          >
            {t("add")}
          </Button>
        </div>
        : null}
      <PageTitle title={membersPage ? t("member_list") : t("test_recipients_title")}></PageTitle>
      {patientsLoading ? (
        <Spinner marginTop={15} />
      ) : (
        <Table
          pagination
          defaultPageSize={25}
          columns={patientsColumns}
          data={membersPage ? mapData(patients) : patients}
          className="patients-table"
          withSortIcons={true}
          showPageSizeOptions={true}
          pageSizeOptions={[25, 50, 75]}
          emptyTableInfo={t("data_unavailable")}
          selectedRowIndex={patients.findIndex((item, e) => {
            return (
              selectedPatient.patientId &&
              item.patientId === selectedPatient?.patientId
            );
          })}
          onRowClick={onRowClick}
        />
      )}
      <div className="patient-details">
        {patientIsLoading || surveyIsLoading ? (
          <Spinner marginTop={0} />
        ) : (
          selectedPatientData.patientId && <PatientDetailsContainer isMember={membersPage} />
        )}
      </div>

      {isAddingPatient && (
        <AddPatientForm
          hide={cancelFlow}
          onSubmit={onAddPatientSubmit}
          patient={newPatient}
          emailError={emailError}
          loading={discoveredPatientsLoading}
          type={"ADD"}
        />
      )}

      {patientConsentStatus && (
        <PatientConsent
          hide={declinePatientConsent}
          onConsentSubmit={onConsentSubmit}
          loading={patientIsCreating}
          flow={flow}
        />
      )}

      {surveyFormStatus && (
        <PatientSurveyForm
          hide={hideSurveyForm}
          extendedProfile={patientProfileSurvey.survey.questions}
          onSurveySubmit={onSurveySubmit}
          loading={patientIsCreating}
          answers={newPatient?.extendedProfile.surveyAnswers}
        />
      )}

      {showWarningModal && (
        <ConfirmPopup
          title={confirmConfig.title}
          message={confirmConfig.message}
          visible={showWarningModal}
          cancel={() => confirmConfig.onCancel()}
          confirm={e => {
            e.preventDefault();
            confirmConfig.onConfirm();
          }}
          buttonText={{ confirm: "OK" }}
        />
      )}

      {patientExisting && (
        <PatientExistsModal
          onCreateNewAccount={onCreateNewAccount}
          filteredPatients={filteredPatients}
          onCancel={cancelFlow}
          onLinkAccount={onLinkAccount}
        />
      )}
    </div>
  );
  
};

export default Patients;
