import React from "react";
import { Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { Route } from "components";
import { validateToken } from "utils/authHelper";
import { hasAccessToRoute } from "utils/roleManager";
import { updateSession, removeLoggedUser } from "store/reducers/user/services";
import { AUTH_USER_TOKEN_KEY, LAST_ACTIVE_TIME, LOGOUT_TIME } from "const/app";
import { clearAWSCache } from "utils/aws-tools";

const PrivateRoute = props => {
  const { roles } = useSelector(state => state.user);
  const hasAccess = hasAccessToRoute(props.path, roles);
  const dispatch = useDispatch();
  const location = useLocation();
  const [validating, setValidationStatus] = React.useState(true);
  const [isValidToken, setValidity] = React.useState(false);

  const tokenIsValid = validateToken(
    sessionStorage.getItem(AUTH_USER_TOKEN_KEY)
  );

  React.useEffect(() => {
    let isLoggedIn = true;
    if (!tokenIsValid) {
      const lastActive = parseInt(sessionStorage.getItem(LAST_ACTIVE_TIME));
      if (Date.now() - lastActive <= LOGOUT_TIME) {
        Auth.currentAuthenticatedUser()
          .then(async user => {
            await dispatch(updateSession(user.signInUserSession));
            setValidity(true);
          })
          .catch(() => {
            dispatch(removeLoggedUser());
          })
          .finally(() => setValidationStatus(false));
      } else {
        Auth.signOut({ global: true })
          .catch(() => {})
          .finally(() => {
            if (isLoggedIn) {
              clearAWSCache();
              dispatch(removeLoggedUser());
              setValidity(false);
              setValidationStatus(false);
            }
          });
      }
    } else {
      setValidity(true);
      setValidationStatus(false);
    }

    return () => isLoggedIn = false

  }, [dispatch, location, tokenIsValid]);

  if (validating) {
    return null;
  }

  if (!isValidToken) {
    return <Redirect to={{ pathname: "/sign-in" }} />;
  }

  if (!hasAccess) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
