import React from "react";
import "./spinner.scss";

const Spinner = ({ marginTop = 0, marginBottom = 0 }) => (
  <div className="spinner" data-testid="spinner">
    <div
      className="spinner spinner-circle"
      style={{
        marginTop: `${marginTop}rem`,
        marginBottom: `${marginBottom}rem`
      }}
    ></div>
  </div>
);

export default Spinner;
