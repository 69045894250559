import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./polyfills";
import App from "./components/App";
import store from "./store";
import "styles/index.scss";
import "./translations/";
import { fixture } from'utils/fixture';
import { Spinner } from "components";

document.title = fixture.project.name;
ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
