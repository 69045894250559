export const SET_PATIENT_PROFILE_SURVEY_DATA =
  "SET_PATIENT_PROFILE_SURVEY_DATA";
export const SET_ORGANIZATION_PROFILE_SURVEY_DATA =
  "SET_ORGANIZATION_PROFILE_SURVEY_DATA";
export const SET_TEST_TYPE_SURVEY_DATA = "SET_TEST_TYPE_SURVEY_DATA";
export const SET_COHORT_PROFILE_SURVEY_DATA =
  "SET_COHORT_PROFILE_SURVEY_DATA";
export const UPDATE_PATIENT_PROFILE_SURVEY = "UPDATE_PATIENT_PROFILE_SURVEY";
export const UPDATE_ORGANIZATION_PROFILE_SURVEY =
  "UPDATE_ORGANIZATION_PROFILE_SURVEY";
export const UPDATE_TEST_TYPE_SURVEY = "UPDATE_TEST_TYPE_SURVEY";
export const UPDATE_COHORT_PROFILE_SURVEY = "UPDATE_COHORT_PROFILE_SURVEY";
export const DELETE_PATIENT_PROFILE_SURVEY = "DELETE_PATIENT_PROFILE_SURVEY";
export const DELETE_ORGANIZATION_PROFILE_SURVEY =
  "DELETE_ORGANIZATION_PROFILE_SURVE";
export const DELETE_TEST_TYPE_SURVEY = "DELETE_TEST_TYPE_SURVEY";
export const DELETE_COHORT_PROFILE_SURVEY = "DELETE_COHORT_PROFILE_SURVEY";
