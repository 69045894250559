import React from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { validateToken } from "utils/authHelper";
import { clearAWSCache } from "utils/aws-tools";
import { updateSession } from "store/reducers/user/services";
import { AUTH_USER_TOKEN_KEY, LOGOUT_TIME, LAST_ACTIVE_TIME } from "const/app";
import NavBar from "../NavBar";
import "./dashboardLayout.scss";

window.aaAuth = Auth;

const DashboardLayout = ({ children, user, removeLoggedUser, ...props }) => {
  const timer = React.useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const signOut = React.useCallback(() => {
    Auth.signOut({ global: true })
      .catch(() => {})
      .finally(() => {
        clearAWSCache();
        removeLoggedUser("timer");
        history.push("/sign-in");
      });
  }, [history, removeLoggedUser]);

  const onActivity = () => {
    sessionStorage.setItem(LAST_ACTIVE_TIME, Date.now());
    const tokenIsValid = validateToken(
      sessionStorage.getItem(AUTH_USER_TOKEN_KEY)
    );

    if (!tokenIsValid) {
      Auth.currentAuthenticatedUser()
        .then(async user => {
          user && (await dispatch(updateSession(user.signInUserSession)));
        })
        .catch((err = {}) => {
          removeLoggedUser();
        });
    }
  };

  return (
    <>
      <div className="dashboardLayout">
        {user.username ? (
          <NavBar
            {...props}
            removeLoggedUser={removeLoggedUser}
            isSuperAdmin={user.roles.includes("ROLE_SUPER_ADMIN")}
          />
        ) : null}
        <div className="dashboardLayout_right">{children}</div>
      </div>
      <IdleTimer
        ref={timer}
        onIdle={signOut}
        onAction={onActivity}
        debounce={250}
        timeout={LOGOUT_TIME}
      />
    </>
  );
};

export default DashboardLayout;
