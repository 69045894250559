import {
  SET_COHORTS,
  CREATE_COHORT,
  SET_SELECTED_COHORT,
  UPDATE_COHORTS,
  UPDATE_COHORT
} from "./types";

export const setCohorts = cohortProfiles => ({
  type: SET_COHORTS,
  payload: {
    cohortProfiles
  }
});

export const createCohortProfile = cohortProfile => ({
  type: CREATE_COHORT,
  payload: {
    cohortProfile
  }
});

export const setSelectedCohortProfile = cohort => ({
  type: SET_SELECTED_COHORT,
  payload: {
    cohort
  }
});
export const updateCohortProfiles = ({
  cohortId,
  shortId,
  name,
  description
}) => ({
  type: UPDATE_COHORTS,
  payload: {
    cohortId,
    shortId,
    name,
    description
  }
});

export const updateCohortProfileData = cohort => ({
  type: UPDATE_COHORT,
  payload: {
    cohort
  }
});
