import { LOGOUT_USER } from "store/reducers/user/types";
import {
  CLEAR_ACCESS_PROFILE_DATA,
  SET_ACCESS_PROFILE_DATA,
  UPDATE_ACCESS_PROFILE,
  SET_ACCESS_PROFILE_FOR_EDIT
} from "./types";
const initialState = {};

const accessProfileData = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACCESS_PROFILE_DATA: {
      const { accessProfileData, accessProfileId } = payload;
      const decisionTreeLogic = accessProfileData?.decisionTreeLogic
        ? { ...accessProfileData?.decisionTreeLogic }
        : {};
      return {
        accessProfileId,
        metaData: {
          ...accessProfileData,
          decisionTreeLogic: {
            ...decisionTreeLogic,
            children: decisionTreeLogic?.children?.map(item => item) || [{}]
          }
        },
        isEditing: []
      };
    }

    case UPDATE_ACCESS_PROFILE: {
      return {
        ...state,
        metaData: { ...payload },
        isEditing: []
      };
    }

    case SET_ACCESS_PROFILE_FOR_EDIT: {
      return {
        ...state,
        isEditing: payload ? [payload.isEditing] : []
      };
    }

    case CLEAR_ACCESS_PROFILE_DATA: {
      return initialState;
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default accessProfileData;
