import React from "react";
import classNames from "classnames";
import "./text.scss";

const Text = ({
  children,
  className,
  as: As = "span",
  bold,
  extraBold,
  info,
  gray
}) => (
  <As
    className={classNames(
      "text",
      As,
      className,
      { bold: bold },
      { extraBold: extraBold },
      { info: info },
      { gray: gray }
    )}
  >
    {children}
  </As>
);

export default Text;
