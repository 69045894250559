import React from "react";
import Label from "components/Label";
import Input from "components/Input";
import InputGroup from "components/InputGroup";
import FormGroup from "components/FormGroup";
import SelectOption from "components/SelectOption";
import Error from "components/Error";
import { useValidation } from "utils/useValidation";
import countries from "const/countries";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

function InputPhoneNumber({
  name = "phoneNumber",
  removePrefix,
  useForm: { register, errors, control, clearError, setError, getValues },
  label = "phone_number",
  required
}) {
  const { t } = useTranslation();
  const { numbersOnly, noRomanCharacter } = useValidation();
  const phonePrefixes = countries
    .reduce((list, country) => {
      const itemInList = list.find(item => item.name === country.name);

      if (!itemInList) {
        const option = {
          name: country.name,
          value: country.phonePrefix,
          preFix: country.phonePrefix.substring(1),
          label: `${country.name} \n ${country.phonePrefix.substring(1)}`,
          shortLabel: country.phonePrefix
        };

        return [...list, option];
      }
      itemInList.name = `${itemInList.name}, ${country.name}`;

      return list;
    }, [])
    .sort((a, b) => a.name - b.name);
  const selectRef = React.useRef(null);

  const filterPhonePrefixOptions = ({ value, data }, inputValue) =>
    // e.g. "+40"
    value.startsWith(inputValue) ||
    // e.g. "40"
    value.substring(1).startsWith(inputValue) ||
    // e.g. "ROMANIA"
    data.name.toLowerCase().startsWith(inputValue.toLowerCase());

  const setRequiredMessageFor = (fieldName, message) => setError( `${name}[${fieldName}]`, "required", t(`${ message || "form_field_is_required"}`) )

  const handleInputValueChange = event => {
    const phoneHasPrefix = getValues(`${name}[countryCode]`)?.length > 0;
    const numberHasvalue = event.target.value.length > 0;
    noRomanCharacter(event);
    numbersOnly(event);
    if (!phoneHasPrefix && numberHasvalue) setRequiredMessageFor('countryCode')
    if (phoneHasPrefix && !numberHasvalue) setRequiredMessageFor('number')
    if (!numberHasvalue && !phoneHasPrefix) clearError()
  };

  const handleSelectPrefix = event => {
    const numberHasvalue = getValues(`${name}[number]`).length > 0;
    const nullEvent = event === null;
    const onlyNumbers = numbersOnly(getValues(`${name}[number]`));
    clearError()
    if (onlyNumbers !== true) setRequiredMessageFor('number', 'phone_number_only_digits')
    if (event?.value && !numberHasvalue) setRequiredMessageFor('number')
    if (nullEvent && numberHasvalue) setRequiredMessageFor('countryCode')

    return event?.value
  };

  return (
    <FormGroup>
      <Label
        htmlFor={name["countryCode"]}
        error={errors[name]?.["number"] || errors[name]?.["countryCode"]}
      >
        {t(label)}
        {required && "*"}
      </Label>
      <InputGroup>
        {!removePrefix && (
          <Controller
            control={control}
            name={`${name}[countryCode]`}
            as={
              <SelectOption
                data-testid="select-country-code"
                inline
                isSearchable
                isClearable
                ref={selectRef}
                options={phonePrefixes}
                placeholder={t("select")}
                error={errors[name]?.["countryCode"]}
                filterOption={filterPhonePrefixOptions}
                customOptions={true}
              />
            }
            onChange={([selected]) => handleSelectPrefix(selected)}
          />
        )}
        <Input
          type="text"
          data-testid="phone-number-input"
          name={`${name}[number]`}
          error={errors?.[name]?.number}
          onChange={handleInputValueChange}
          ref={register({
            ...(required && { required: t("phone_number_invalid") }),
            validate: { numbersOnly },
            maxLength: {
              value: 12,
              message: t("phone_number_invalid")
            }
          })}
        />
      </InputGroup>
      {errors[name]?.["number"] && (
        <Error>{errors[name]?.["number"]?.message}</Error>
      )}
      {errors[name]?.["countryCode"] && (
        <Error>{errors[name]?.["countryCode"]?.message}</Error>
      )}
    </FormGroup>
  );
}

export default InputPhoneNumber;
