import React from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { success, error } from "react-notification-system-redux";
import { Form, Label, Input, Button, Error, FormGroup, Col } from "components";
import { useValidation } from "utils/useValidation";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/useLanguage";

function UpdatePersonalDataForm({
  firstName,
  lastName,
  email,
  id,
  updateUserSession,
  updateUserAttributes,
  updateProfile,
  attributes: { phone_number } = {}
}) {
  const { t } = useTranslation();
  const { flippedNameOrder } = useLanguage();
  const dispatch = useDispatch();
  const [loading, setLoadingStatus] = React.useState(false);
  const { register, handleSubmit, formState, errors, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName,
      lastName,
      phone_number,
      email
    }
  });
  const {
    emailValidation,
    startsWithPlus,
    numbersOnlyIgnoreFirstCharacter,
    noRomanCharacter
  } = useValidation();

  const onSubmit = async (
    { firstName, lastName, phone_number, email },
    event
  ) => {
    if (formState.isValid) {
      setLoadingStatus(true);
      const attributes = {
        phone_number
      };

      Auth.currentAuthenticatedUser().then(loggeddUser =>
        Auth.updateUserAttributes(loggeddUser, attributes)
          .then(() => {
            loggeddUser.refreshSession(
              loggeddUser.signInUserSession.refreshToken,
              async (err, data) => {
                if (err) {
                  console.error(err);
                  dispatch(
                    error({
                      title: t("error_update_profile_title"),
                      message: t("error_update_profile_refresh_description"),
                      position: "tc"
                    })
                  );
                } else {
                  await updateProfile(firstName, lastName, phone_number, id);
                  reset({
                    firstName,
                    lastName,
                    phone_number,
                    email
                  });
                  dispatch(
                    success({
                      title: t("error_update_profile_title"),
                      message: t("success_changes_applied"),
                      position: "tc"
                    })
                  );
                  updateUserSession(data);
                  updateUserAttributes(attributes);
                  setLoadingStatus(false);
                }
              }
            );
          })
          .catch(err => {
            dispatch(
              error({
                title: t("error_update_profile_title"),
                message: t("error_update_profile_description"),
                position: "tc"
              })
            );
            console.error(err);
            setLoadingStatus(false);
          })
      );
    }
  };

  return (
    <React.Fragment>
      <Col>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {flippedNameOrder ? (
            <>
              <FormGroup>
                <Label htmlFor="lastName" error={errors.lastName}>
                  {t("last_name")}
                </Label>
                <Input
                  type="text"
                  name="lastName"
                  error={errors.lastName}
                  ref={register({
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 255,
                      message: t("field_max_length", {
                        number_of_characters: 255
                      })
                    }
                  })}
                />
                {errors.lastName && <Error>{errors.lastName.message}</Error>}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="firstName" error={errors.firstName}>
                  {t("first_name")}
                </Label>
                <Input
                  type="text"
                  name="firstName"
                  error={errors.firstName}
                  ref={register({
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 255,
                      message: t("field_max_length", {
                        number_of_characters: 255
                      })
                    }
                  })}
                />
                {errors.firstName && <Error>{errors.firstName.message}</Error>}
              </FormGroup>
            </>
          ) : (
            <>
              <FormGroup>
                <Label htmlFor="firstName" error={errors.firstName}>
                  {t("first_name")}
                </Label>
                <Input
                  type="text"
                  name="firstName"
                  error={errors.firstName}
                  ref={register({
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 255,
                      message: t("field_max_length", {
                        number_of_characters: 255
                      })
                    }
                  })}
                />
                {errors.firstName && <Error>{errors.firstName.message}</Error>}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="lastName" error={errors.lastName}>
                  {t("last_name")}
                </Label>
                <Input
                  type="text"
                  name="lastName"
                  error={errors.lastName}
                  ref={register({
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: 255,
                      message: t("field_max_length", {
                        number_of_characters: 255
                      })
                    }
                  })}
                />
                {errors.lastName && <Error>{errors.lastName.message}</Error>}
              </FormGroup>
            </>
          )}
          <FormGroup>
            <Label htmlFor="email" error={errors.email}>
              {t("health_care_organizations_users_email")}
            </Label>
            <Input
              disabled
              type="text"
              name="email"
              error={errors.email}
              onChange={noRomanCharacter}
              ref={register({
                required: t("form_field_is_required"),
                validate: { emailValidation }
              })}
            />
            {errors.email && <Error>{errors.email.message}</Error>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="phone_number" error={errors.phone_number}>
              {t("phone_number")}
            </Label>
            <Input
              type="text"
              name="phone_number"
              error={errors.phone_number}
              ref={register({
                required: t("user_profile_phone_number_invalid"),
                validate: {
                  startsWithPlus,
                  numbersOnlyIgnoreFirstCharacter
                },
                minLength: {
                  value: 3,
                  message: t("user_profile_phone_number_invalid")
                },
                maxLength: {
                  value: 18,
                  message: t("user_profile_phone_number_invalid")
                }
              })}
            />
            {errors.phone_number && (
              <Error>{errors.phone_number.message}</Error>
            )}
          </FormGroup>
          <Button
            type="submit"
            className="button--primary"
            loading={loading}
            disabled={loading || !formState.isValid || !formState.dirty}
          >
            {t("user_profile_update_profile")}
          </Button>
        </Form>
      </Col>
    </React.Fragment>
  );
}

export default UpdatePersonalDataForm;
