import { error, success } from "react-notification-system-redux";
import { getLoggedUserIdToken } from "selectors/loggedUser";
import { getSelectedOrganizationUser } from "selectors/organizations";
import * as AwsApi from "api/aws";
import * as api from "api/organizations";
import * as actions from "./actions";
import i18n from "../../../translations";

export const getOrganizations = () => async dispatch => {
  const results = await dispatch(api.getOrganizations());
  const data = (results && results.data && results.data.organizations) || [];
  dispatch(actions.setOrganizations(data));
  dispatch(actions.setSelectedOrganization(data.length > 0 ? data[0] : null));
};

export const createOrganization = (
  institutionName,
  reference,
  countryCode
) => async dispatch => {
  const result = await dispatch(
    api.createOrganization(institutionName, reference, countryCode)
  );
  if (result?.data) {
    const { name, id } = result.data;
    dispatch(actions.createOrganization(name, id, reference, countryCode));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_organization_created"),
        position: "tc"
      })
    );
    return true;
  }else if(result?.message === "Request failed with status code 412") {
    dispatch(
      error({
        title: i18n.t("error_title"),
        message: result?.response?.data?.message || i18n.t("error"),
        position: "tc"
      })
    );
  }
  return false;
};

export const adminUpdateOrganizationName = (
  institutionName,
  tenantId,
  reference,
  countryCode
) => async dispatch => {
  const result = await dispatch(
    api.adminUpdateOrganizationName(
      institutionName,
      tenantId,
      reference,
      countryCode
    )
  );
  if (result && result.status === 200) {
    dispatch(
      actions.adminUpdateOrganizationName(
        institutionName,
        tenantId,
        reference,
        countryCode
      )
    );
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_organization_updated"),
        position: "tc"
      })
    );
    return true;
  }else if(result?.message === "Request failed with status code 412") {
    dispatch(
      error({
        title: i18n.t("error_title"),
        message: result?.response?.data?.message || i18n.t("error"),
        position: "tc"
      })
    );
  }
  return false;
};

export const getOrganizationUsers = organizationId => async dispatch => {
  const response = await dispatch(api.getOrganizationUsers(organizationId));
  if (response && response.status === 200) {
    const data = (response.data || []).map(u => {
      //TODO: temporary fix, to be removed after BE fix
      const roles = [];
      u.roles.forEach(r => {
        if (!roles.find(rl => rl.roleId === r.roleId)) {
          roles.push({ roleId: r.roleId });
        }
      });
      return {
        ...u,
        roles
      };
    });
    dispatch(actions.setOrganizationUsers(data));
  }
  //BE replies 404 for empty users list
  else {
    dispatch(actions.setOrganizationUsers([]));
  }
};

export const updateOrganizationUser = (
  organizationId,
  firstName,
  lastName,
  oldRoles,
  newRoles,
  userId,
  email
) => async (dispatch, getState) => {
  const result = await dispatch(
    api.updateOrganizationUser(
      organizationId,
      firstName,
      lastName,
      newRoles,
      userId,
      email
    )
  );
  if (result && result.status === 200) {
    const state = getState();
    const idToken = getLoggedUserIdToken(state);

    if (idToken) {
      dispatch(
        await AwsApi.updateUserRoles(
          userId,
          idToken.jwtToken,
          oldRoles,
          newRoles
        )
      );
      dispatch(
        actions.updateOrganizationUser(
          firstName,
          lastName,
          newRoles,
          userId,
          email
        )
      );
      dispatch(actions.hideUserForm());
      dispatch(
        success({
          title: i18n.t("success"),
          message: i18n.t("success_user_updated"),
          position: "tc"
        })
      );
    }
  } else {
    dispatch(actions.hideUserForm());
  }
};

export const deleteOrganizationUser = (organizationId, userId) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const idToken = getLoggedUserIdToken(state);

  if (idToken) {
    await dispatch(AwsApi.deleteUser(userId, idToken.jwtToken));
    await dispatch(api.deleteOrganizationUser(organizationId, userId));
    dispatch(actions.deleteOrganizationUser(userId));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_user_deleted"),
        position: "tc"
      })
    );
  }
};

export const resetOrganizationUserPhoneNumber = (userId, phoneNumber) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const user = getSelectedOrganizationUser(state, userId);
  const idToken = getLoggedUserIdToken(state);

  if (idToken) {
    await dispatch(
      AwsApi.updatePhoneNumber(user.email, phoneNumber, idToken.jwtToken)
    );
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_phone_updated"),
        position: "tc"
      })
    );
  }
};

export const clearState = () => dispatch => {
  dispatch(actions.setOrganizationUsers([]));
};

export const createOrganizationUser = (
  organizationId,
  firstName,
  lastName,
  email,
  roles
) => async (dispatch, getState) => {
  const state = getState();

  const idToken = getLoggedUserIdToken(state);

  if (idToken) {
    const awsUser = await dispatch(
      AwsApi.createUser(email, roles, idToken.jwtToken)
    );

    if (awsUser) {
      if (awsUser instanceof Error) {
        return awsUser.code;
      } else {
        const result = await dispatch(
          api.createOrganizationUser(
            organizationId,
            firstName,
            lastName,
            email,
            roles,
            awsUser.Username
          )
        );

        if (!result || result.status !== 200) {
          await dispatch(AwsApi.deleteUser(awsUser.Username, idToken.jwtToken));
        } else {
          dispatch(
            actions.createOrganizationUser(
              firstName,
              lastName,
              email,
              roles,
              awsUser.Username
            )
          );
          dispatch(actions.hideUserForm());
          dispatch(
            success({
              title: i18n.t("success"),
              message: i18n.t("success_user_created"),
              position: "tc"
            })
          );
        }
      }
    } else {
      dispatch(error({ message: i18n.t("error_create_user") }));
    }
  }
};

export const showUserForm = () => dispatch => dispatch(actions.showUserForm());
export const hideUserForm = () => dispatch => dispatch(actions.hideUserForm());
