import React from "react";
import Button from "components/Button";
import FloatNavbar from "components/FloatNavbar";
import { useTranslation } from "react-i18next";

const HomeTestProfileButtonBar = ({
  onSave,
  onCancel,
  onPublish,
  saveDisabled,
  cancelDisabled,
  publishDisabled,
  saveLoading,
  homeTestPublishing
}) => {
  const { t } = useTranslation();

  return (
    <FloatNavbar>
      <Button
        variant="primary"
        className="header-add-button"
        onClick={onPublish}
        disabled={publishDisabled}
        loading={homeTestPublishing}
      >
        {t("publish")}
      </Button>
      <Button
        variant="primary"
        className="header-add-button"
        onClick={onSave}
        disabled={saveDisabled}
        loading={saveLoading}
      >
        {t("save_as_draft")}
      </Button>
      <Button
        variant="secondary"
        className="header-add-button "
        onClick={onCancel}
        disabled={cancelDisabled}
      >
        {t("cancel")}
      </Button>
    </FloatNavbar>
  );
};

export default HomeTestProfileButtonBar;
