import { SET_EXPORTS, CREATE_EXPORT } from "./types";

export const setExports = exportData => ({
  type: SET_EXPORTS,
  payload: {
    exportData
  }
});

export const createExport = ({
  organizationId,
  exportType,
  intervalStart,
  intervalEnd,
  profileId
}) => ({
  type: CREATE_EXPORT,
  payload: {
    organizationId,
    exportType,
    intervalStart,
    intervalEnd,
    profileId
  }
});
