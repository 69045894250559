import React from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Title from "components/Title";
import { InputPhoneNumber } from "components/FormComponents";
import { ModalActions } from "components/Modal";
import { useTranslation } from "react-i18next";
import "./resetUserPhoneNumberForm.scss";

function ResetUserPhoneNumberForm({ onSubmitAction, hide, loading, userId }) {
  const { t } = useTranslation();

  const myUseForm = useForm({ mode: "onChange" });
  const { handleSubmit, formState } = myUseForm;
  const onSubmit = ({ phone_number }) => {
    if (formState.isValid) {
      onSubmitAction(
        userId,
        `${phone_number?.countryCode?.replace("-", "")}${phone_number?.number}`
      );
    }
  };

  //magic fix for useForm' magic issue with validity status
  JSON.stringify(formState);

  return (
    <Modal visible={true}>
      <div className="sign-in-form reset-phonenumber-form">
        <Title>
          {t("health_care_organizations_users_actions_reset_phone")}
        </Title>
        <p className="user-tip">{t("phone_number_2FA_description")}</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputPhoneNumber
            name="phone_number"
            label="enter_new_phone_number"
            useForm={myUseForm}
          />
          <ModalActions>
            <Button
              variant="primary-full-width"
              className="margin-top--medium"
              type="submit"
              loading={loading}
              disabled={!formState.isValid || loading}
            >
              {t("save")}
            </Button>
            <Button variant="cancel-text" type="button" onClick={hide}>
              {t("cancel")}
            </Button>
          </ModalActions>
        </Form>
      </div>
    </Modal>
  );
}

export default ResetUserPhoneNumberForm;
