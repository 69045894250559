import React, { forwardRef } from "react";
import "./componentToPrint.scss";

const ComponentToPrint = forwardRef(({ children }, ref) => {
  return (
    <div className='componentToPrint'>
      <div ref={ref}>
        {children}
      </div>
    </div>
  );
});

export default ComponentToPrint;