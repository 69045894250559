export const SET_QR_CODE_DATA = "SET_QR_CODE_DATA";
export const CLEAR_QR_CODE_DATA = "CLEAR_QR_CODE_DATA";
export const UPDATE_TESTING_PROFILE = "UPDATE_TESTING_PROFILE";
export const UPDATE_TESTING_CONFIGURATION = "UPDATE_TESTING_CONFIGURATION";
export const SET_TEST_TYPE_FOR_EDIT = "SET_TEST_TYPE_FOR_EDIT";
export const CREATE_COPY_OF_METADATA = "CREATE_COPY_OF_METADATA";
export const DELETE_COPY_OF_METADATA = "DELETE_COPY_OF_METADATA";
export const CREATE_TEST_TYPE = "CREATE_TEST_TYPE";
export const DELETE_TEST_TYPE = "DELETE_TEST_TYPE";
export const SET_TESTING_PROFILE_DATA_FOR_EDIT =
  "SET_TESTING_PROFILE_DATA_FOR_EDIT";
export const SAVE_CONTACT_DATA = "SAVE_CONTACT_DATA";
export const SAVE_PROFILE_DATA = "SAVE_PROFILE_DATA";
export const SAVE_TEST_TYPE_DATA = "SAVE_TEST_TYPE_DATA";
export const UPDATE_TEST_CONFIG = "UPDATE_TEST_CONFIG";
export const FIELD_ERRORS = "FIELD_ERRORS";
export const SET_INFINITY_INTEGRATION = "SET_INFINITY_INTEGRATION";
export const SAVE_CHECKBOX_DATA = "SAVE_CHECKBOX_DATA";
export const RESET_PROVIDER_FIELDS = "RESET_PROVIDER_FIELDS";
