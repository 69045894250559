import { success } from "react-notification-system-redux";
import * as api from "api/accessProfiles";
import * as actions from "./actions";
import { clearAccessProfileData } from "../accessProfileData/actions";
import i18n from "../../../translations";

export const getAccessProfiles = tenantId => async (dispatch, getState) => {
  const state = getState();
  if (state.accessProfileData) {
    dispatch(clearAccessProfileData());
  }
  //use api.getMockedAccessProfile until BE is ready
  const result = await dispatch(
    api.getAccessProfiles(tenantId || state.user.tenantId)
  );
  dispatch(actions.setAccessProfiles(result));
  return result;
};

export const createAccessProfile = ({
  name,
  description,
  ...rest
}) => async dispatch => {
  //use api.createMockedAccessProfileDetails until BE is ready
  const result = await dispatch(
    api.createAccessProfile({ name, description, ...rest })
  );
  if (result && result.status === 200) {
    const { accessProfileId } = result.data;

    dispatch(
      actions.createAccessProfile({
        name,
        description,
        accessProfileId,
        ...rest
      })
    );
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_access_created"),
        position: "tc"
      })
    );
    return true;
  } else {
    return false;
  }
};

export const deleteAccessProfile = id => async (dispatch, getState) => {
  const state = getState();
  const { accessProfileId } = state.accessProfileData;
  //use api.deleteMockedAccessProfileDetails until BE is ready
  const result = await dispatch(api.deleteAccessProfile(id));
  if (result && result.status === 200) {
    dispatch(actions.deleteAccessProfile(id));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_access_deleted"),
        position: "tc"
      })
    );
    if (accessProfileId === id) {
      dispatch(clearAccessProfileData());
    }
  }
};
