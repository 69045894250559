import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import TestTypes from "components/TestTypes";
import {
  getTestingProfileDetails,
  deleteTestTypeUpdate,
  setTestTypeForEdit,
  createTestType,
  setTestingProfileDataForEdit,
  saveTestTypeData,
  cancelUpdate,
  setCheckboxForEdit,
  setFieldErrors
} from "store/reducers/qrCodeData/services";
import { getDefaultTestConfig } from "store/reducers/config/services";
import { updateTestingConfiguration } from "store/reducers/qrCodeData/actions";

const mapStateToProps = state => ({
  selectedTestProfile: state.qrCodeData,
  isCombo: state.qrCodeData.metaData.isComboTestProfile,
  testTypes: state.qrCodeData.metaData.testConfiguration,
  defaultTestTypeList: state.config.testTypes,
  testingProfileUpdating: isLoading(state, "updateTestingProfile"),
  testTypesLoading: isLoading(state, "getTestTypeList"),
  roles: state.user.roles,
  config: state.config
});

const mapDispatchToProps = {
  getTestingProfileDetails,
  deleteTestTypeUpdate,
  setTestTypeForEdit,
  createTestType,
  getDefaultTestConfig,
  updateTestingConfiguration,
  setTestingProfileDataForEdit,
  saveTestTypeData,
  cancelUpdate,
  setCheckboxForEdit,
  setFieldErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(TestTypes);
