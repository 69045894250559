import React from "react";
import HomeTestProfileContainer from "../../containers/HomeTestProfile.Container";

const HomeTestProfile = () => (
  <>
    <HomeTestProfileContainer />
  </>
);

export default HomeTestProfile;
