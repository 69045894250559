export const outcomeColors = {
  "OUTCOME 0": "#de2422",
  "OUTCOME 1": "#e02727",
  "OUTCOME 2": "#f18523",
  "OUTCOME 3": "#f1c421",
  "OUTCOME 4": "#fee500",
  "OUTCOME 5": "#24c09f",
  "OUTCOME 6": "#68d312",
  "OUTCOME 7": "#60fa8a"
};

export const initialBackgroundColor = "#ffffff";
export const secondaryBackgroundColor = "#e7f4ff";
