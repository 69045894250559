import React from "react";
import Button from "components/Button";
import Title from "components/Title";
import Modal, { ModalActions } from "components/Modal";
import NotificationWithIcon from "components/NotificationWithIcon";
import { useTranslation } from "react-i18next";

const SignOutModal = ({ visible, signOut, toggleModal, loading }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible}>
      <Title>{t("sign_out")}</Title>
      <NotificationWithIcon iconType="exit" type="informative">
        {t("sign_out_confirm")}
      </NotificationWithIcon>
      <ModalActions>
        <Button
          id="sign-out-popup_sign-out"
          disabled={loading}
          loading={loading}
          variant="primary-full-width"
          onClick={signOut}
        >
          {t("sign_out")}
        </Button>
        <Button
          id="sign-out-popup_cancel"
          disabled={loading}
          variant="cancel-text"
          onClick={toggleModal}
        >
          {t("cancel")}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default SignOutModal;
