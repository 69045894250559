import React from "react";
import AccessDataExportContainer from "../../containers/AccessDataExport.Container";

const AccessDataExport = () => (
  <>
    <AccessDataExportContainer />
  </>
);

export default AccessDataExport;
