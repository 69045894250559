import {
  SET_CONFIG,
  SET_ABOUT_CONFIG,
  SET_HTTP_ERROR,
  SET_DEFAULT_TEST_CONFIG,
  SET_TEST_TYPE_LIST,
  SET_COUNTRIES
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";
import { ROLE_SUPER_ADMIN } from "const/roles";

export const toOptionItem = (r, i) => ({
  label: r.name,
  value: r.id,
  position: i
});
export const toTestTypeOptionItem = (r, i) => ({ label: r.name, value: r.id });

export const toHash = (r, { value, label }) => {
  r[value] = label;
  return r;
};

const initialState = {};

const config = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CONFIG: {
      const { data } = payload;
      const mappedData = Object.keys(data).reduce((reducer, key) => {
        if (data.hasOwnProperty(key)) {
          return {
            ...reducer,
            [key]: data[key].map(toOptionItem)
          };
        }
        return reducer;
      }, {});

      return {
        ...mappedData,
        roles:
          mappedData.roles &&
          mappedData.roles.filter(r => r.value !== ROLE_SUPER_ADMIN)
      };
    }
    case SET_HTTP_ERROR:
      return {
        ...state,
        requestError: {
          ...payload
        }
      };
    case SET_ABOUT_CONFIG:
      return {
        ...state,
        aboutConfig: {
          ...payload
        }
      };
    case SET_DEFAULT_TEST_CONFIG:
      return {
        ...state,
        defaultTestConfig: {
          ...payload
        }
      };
    case SET_COUNTRIES:
      const { countries } = payload;
      return {
        ...state,
        countries
      };
    case SET_TEST_TYPE_LIST:
      return {
        ...state,
        testTypes: [...payload.map(toTestTypeOptionItem)]
      };
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default config;
