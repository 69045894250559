import React from "react";
import { useDispatch } from "react-redux";

import "./TimeInput.scss";

let defaultValues = { hours: [], mins: [], seconds: [] };

for (let i = 0; i < 60; i++) {
  if (String(i).length === 1) {
    defaultValues.mins.push(`0${i}`);
  } else {
    defaultValues.mins.push(i);
  }
}

for (let i = 0; i < 24; i++) {
  if (String(i).length === 1) {
    defaultValues.hours.push(`0${i}`);
  } else {
    defaultValues.hours.push(i);
  }
}

for (let i = 0; i < 60; i++) {
  if (String(i).length === 1) {
    defaultValues.seconds.push(`0${i}`);
  } else {
    defaultValues.seconds.push(i);
  }
}

const TimeInput = ({
  time,
  original,
  id,
  fromTime,
  toTime,
  setTimeError,
  label,
  setFieldErrors
}) => {
  const [hours, setHours] = React.useState(Math.floor(time / 3600));
  const [minutes, setMinutes] = React.useState(Math.floor((time % 3600) / 60));
  const [seconds, setSeconds] = React.useState(Math.floor((time % 3600) % 60));
  const [totalTime, setTotalTime] = React.useState(time);
  const [valueChanged, setValueChanged] = React.useState({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTotalTime(+hours * 60 * 60 + +minutes * 60 + +seconds);
    if (fromTime >= toTime) {
      setTimeError('"To" time should be after "From" time');
    } else {
      setTimeError("");
    }
  }, [hours, minutes, seconds, fromTime, toTime, setTimeError]);

  React.useEffect(() => {
    if (fromTime >= toTime) {
      dispatch(
        setFieldErrors({
          timeError: '"To" time should be after "From" time'
        })
      );
    } else {
      dispatch(
        setFieldErrors({
          timeError: undefined
        })
      );
    }
  }, [dispatch, setFieldErrors, fromTime, toTime]);

  const handleHoursChange = e => {
    setHours(Number(e.target.value));
    setValueChanged({ ...valueChanged, hours: true });
  };

  const handleMinutesChange = e => {
    setMinutes(Number(e.target.value));
    setValueChanged({ ...valueChanged, minutes: true });
  };

  const handleSecondsChange = e => {
    setSeconds(Number(e.target.value));
    setValueChanged({ ...valueChanged, seconds: true });
  };

  const onBlur = () => {
    original[id].updateFunction(totalTime, original[id]?.id);
  };

  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <p className="time-label">{label}</p>
      <div className="time-input-container">
        <select
          name="hours"
          id="hours"
          onChange={handleHoursChange}
          defaultValue={hours}
          onBlur={onBlur}
          style={valueChanged.hours ? { backgroundColor: "#fffcda" } : null}
        >
          {defaultValues.hours.map(item => (
            <option
              key={item}
              value={Number(item)}
              style={{ backgroundColor: "white" }}
            >
              {item}
            </option>
          ))}
        </select>
        :
        <select
          name="minutes"
          id="minutes"
          onChange={handleMinutesChange}
          defaultValue={minutes}
          onBlur={onBlur}
          style={valueChanged.minutes ? { backgroundColor: "#fffcda" } : null}
        >
          {defaultValues.mins.map(item => (
            <option
              key={item}
              value={Number(item)}
              style={{ backgroundColor: "white" }}
            >
              {item}
            </option>
          ))}
        </select>
        :
        <select
          name="seconds"
          id="seconds"
          onChange={handleSecondsChange}
          defaultValue={seconds}
          onBlur={onBlur}
          style={valueChanged.seconds ? { backgroundColor: "#fffcda" } : null}
        >
          {defaultValues.seconds.map(item => (
            <option
              key={item}
              value={Number(item)}
              style={{ backgroundColor: "white" }}
            >
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TimeInput;
