import { success } from "react-notification-system-redux";
import * as api from "api/export";
import * as actions from "./actions";
import i18n from "../../../translations";
import {
  STATUS_STARTED,
  STATUS_IN_PROGRESS,
  STATUS_NEW
} from "const/exportTypes";
import {EXPORT_TIMEOUT_LIMIT} from "const/app";
import moment from "moment";

export const getExports = () => async (dispatch, getState) => {
  const result = await dispatch(api.getExports());
  let currentTime = moment().valueOf();
  if(result && result.data) {
    result.data.map(item => {
      return ((item.statusCode === STATUS_IN_PROGRESS || item.statusCode ===STATUS_STARTED || item.statusCode ===STATUS_NEW) 
        && currentTime - item.updatedAt > EXPORT_TIMEOUT_LIMIT ) 
          ? item["statusCode"] = "STATUS_TIMED_OUT" : {...item}     
    })
    dispatch(actions.setExports(result.data));
    return result;
  }
  
};

export const createExport = ({
  organizationIdList,
  exportType,
  intervalStart,
  intervalEnd,
  profileIdList,
  testTypeList,
  userTimeZone,
  countryCode
}) => async dispatch => {
  const countryExport = exportType === "EXPORT_TEST_RESULTS_FOR_GOVERNMENT";
  let result;
  if (!countryExport) {
    result = await dispatch(
      api.createExport({
        organizationIdList,
        exportType,
        intervalStart,
        intervalEnd,
        profileIdList,
        userTimeZone
      })
    );
  } else {
    result = await dispatch(
      api.createCountryExport({
        organizationIdList,
        exportType,
        intervalStart,
        intervalEnd,
        testTypeList,
        userTimeZone,
        countryCode
      })
    );
  }

  if (result && result.status === 200) {
    await dispatch(getExports());
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_export_created"),
        position: "tc"
      })
    );
    return true;
  } else {
    return false;
  }
};

export const getDownloadUrl = exportID => async dispatch => {
  const result = await dispatch(api.getDownloadUrl(exportID));
  return result;
};
