import React from "react";
import classNames from "classnames";
import "./textarea.scss";

const Textarea = React.forwardRef(
  ({ className, disabled, error, ...props }, ref) => (
    <textarea
      disabled={disabled}
      className={classNames(
        "textarea",
        { "textarea--error": error },
        { "textarea--disabled": disabled },
        className
      )}
      {...props}
      ref={ref}
    />
  )
);

Textarea.displayName = "Textarea";

export default Textarea;
