import React from "react";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/useLanguage";
import Icon from "components/Icon";
import Table from "components/Table";
import Button from "components/Button";
import Spinner from "components/Spinner";
import PageTitle from "components/PageTitle";
import { sortIgnoreCase, sortByIsAdmin } from "utils/sortMethods";
import {
  ROLE_SUPER_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_DATA_ANALYST
} from "const/roles";
import "./CountryManagement.scss";
import AddAdminUserForm from "components/AddAdminUserForm";
import ConfirmPopup from "components/ConfirmPopup";
import ResetUserPhoneNumberForm from "components/ResetUserPhoneNumberForm";

const CountryManagement = ({
  adminsLoading,
  getAdminUsers,
  adminUsers,
  countries,
  createAdminUser,
  updateAdminUser,
  adminUserCreating,
  adminUserSaving,
  adminUserDeleting,
  adminUserForm,
  deleteAdminUser,
  resetAdminUserPhoneNumber,
  adminUserPhoneResetting,
  showAdminUserForm,
  hideAdminUserForm
}) => {
  const { t } = useTranslation();
  const { flippedNameOrder } = useLanguage();
  const [currentUser, setCurrentUser] = React.useState({});
  const [confirmConfig, setConfirmConfig] = React.useState({});
  const [showConfirm, setConfirmPopupStatus] = React.useState(false);
  const [userActionType, setUserActionType] = React.useState();

  const userActionTypes = React.useMemo(
    () => ({
      RESET_PHONE: "RESET_PHONE",
      CREATE_USER: "CREATE_USER",
      EDIT_USER: "EDIT_USER"
    }),
    []
  );

  const setUserForEdit = React.useCallback(
    (id, actionType) => {
      const user = adminUsers?.users.find(u => u.adminId === id);
      setCurrentUser(user);
      showAdminUserForm();
      setUserActionType(actionType);
    },
    [adminUsers, showAdminUserForm]
  );

  React.useEffect(() => {
    getAdminUsers();
  }, [getAdminUsers]);

  const formatCountry = code =>
    countries?.find(country => country.countryCode === code)?.countryName;

  const admins = adminUsers?.users?.map(admin => ({
    ...admin,
    isSuperAdmin: admin.roles.find(r => r.roleId === ROLE_SUPER_ADMIN),
    countryOfCountryAdmin: formatCountry(
      admin.roles.find(r => r.roleId === ROLE_COUNTRY_ADMIN)?.country
    ),
    countryOfDataAnalyst: formatCountry(
      admin.roles.find(r => r.roleId === ROLE_COUNTRY_DATA_ANALYST)?.country
    )
  }));

  const showAddUserForm = React.useCallback(() => {
    setCurrentUser({});
    setUserActionType(userActionTypes.CREATE_USER);
    showAdminUserForm();
  }, [showAdminUserForm, userActionTypes.CREATE_USER]);

  const hideModal = React.useCallback(() => {
    setCurrentUser({});
    hideAdminUserForm();
  }, [hideAdminUserForm]);

  const hideConfirm = () => {
    setCurrentUser({});
    setConfirmPopupStatus(false);
    setUserActionType();
  };

  const onConfirmResetPhoneNumber = React.useCallback(
    async (id, phoneNumber) => {
      await resetAdminUserPhoneNumber(id, phoneNumber);
      hideAdminUserForm();
    },
    [hideAdminUserForm, resetAdminUserPhoneNumber]
  );

  const onConfirmDeleteUser = React.useCallback(
    adminId => deleteAdminUser(adminId).then(() => hideConfirm()),
    [deleteAdminUser]
  );

  const deleteUser = React.useCallback(
    adminId => {
      const user = adminUsers?.users.find(u => u.adminId === adminId);
      setCurrentUser(user);
      setConfirmConfig({
        title: t("confirm_delete_action"),
        message: t(`confirm_delete_name`, {
          name: `${user.firstName} ${user.lastName}`
        }),
        onConfirm: () => onConfirmDeleteUser(adminId)
      });
      setConfirmPopupStatus(true);
    },
    [onConfirmDeleteUser, adminUsers, t]
  );

  const nameColumns = React.useMemo(
    () => [
      {
        Header: t("first_name"),
        accessor: "firstName",
        className: "name-column",
        sortType: sortIgnoreCase
      },
      {
        Header: t("last_name"),
        accessor: "lastName",
        className: "name-column",
        sortType: sortIgnoreCase
      }
    ],
    [t]
  );

  const nameColumnsFlipped = React.useMemo(
    () => [
      {
        Header: t("last_name"),
        accessor: "lastName",
        className: "name-column",
        sortType: sortIgnoreCase
      },
      {
        Header: t("first_name"),
        accessor: "firstName",
        className: "name-column",
        sortType: sortIgnoreCase
      }
    ],
    [t]
  );

  const columns = React.useMemo(
    () => [
      ...(flippedNameOrder ? nameColumnsFlipped : nameColumns),

      {
        Header: t("email"),
        accessor: "email",
        sortType: sortIgnoreCase
      },
      {
        Header: t("super_admin"),
        accessor: "isSuperAdmin",
        sortType: sortByIsAdmin,
        Cell: ({ value }) => {
          return value ? <Icon type="check" /> : null;
        }
      },
      {
        Header: t("country_admin"),
        accessor: "countryOfCountryAdmin",
        sortType: sortIgnoreCase
      },
      {
        Header: t("country_data_analyst"),
        accessor: "countryOfDataAnalyst",
        sortType: sortIgnoreCase
      },
      {
        Header: t("actions"),
        className: "actions-column",
        Cell: ({ row }) => {
          return (
            <React.Fragment>
              <Button
                data-testid="edit-button"
                variant="row-action"
                title={t("health_care_organizations_actions_edit")}
                onClick={() => {
                  return setUserForEdit(
                    row.original.adminId,
                    userActionTypes.EDIT_USER
                  );
                }}
              >
                <Icon type="edit" />
              </Button>
              <Button
                data-testid="reset-phone-button"
                variant="row-action"
                title={t("health_care_organizations_users_actions_reset_phone")}
                onClick={() =>
                  setUserForEdit(
                    row.original.adminId,
                    userActionTypes.RESET_PHONE
                  )
                }
              >
                <Icon type="reset" />
              </Button>
              <Button
                data-testid="delete-button"
                variant="row-action"
                title={t("health_care_organizations_users_actions_delete")}
                onClick={() => deleteUser(row.original.adminId)}
              >
                <Icon type="trash" />
              </Button>
            </React.Fragment>
          );
        }
      }
    ],
    [
      deleteUser,
      t,
      flippedNameOrder,
      nameColumns,
      nameColumnsFlipped,
      setUserForEdit,
      userActionTypes
    ]
  );

  const createAdminUserRequest = React.useCallback(
    async request =>
      !currentUser.adminId
        ? await createAdminUser(request)
        : await updateAdminUser(currentUser, request),
    [createAdminUser, currentUser, updateAdminUser]
  );

  return (
    <React.Fragment>
      {!adminsLoading && (
        <Button
          data-testid="add-button"
          variant="primary"
          className="header-add-button"
          onClick={() => showAddUserForm()}
        >
          {t("health_care_organizations_users_add")}
        </Button>
      )}
      <PageTitle title={t("countries_title")} />
      <br />
      {adminsLoading ? (
        <Spinner marginTop="2" />
      ) : (
        <Table
          columns={columns}
          data={admins}
          globalFilter
          pagination
          className={"adminUsers"}
          pageSize={10}
        />
      )}

      {adminUserForm &&
        (userActionType === userActionTypes.EDIT_USER ||
          userActionType === userActionTypes.CREATE_USER) && (
          <AddAdminUserForm
            hide={hideModal}
            onSubmit={createAdminUserRequest}
            loading={adminUserSaving || adminUserCreating}
            countries={countries}
            {...currentUser}
          />
        )}

      {showConfirm && (
        <ConfirmPopup
          title={confirmConfig.title}
          message={confirmConfig.message}
          loading={adminUserDeleting}
          visible={showConfirm}
          cancel={hideConfirm}
          confirm={confirmConfig.onConfirm}
        />
      )}
      {adminUserForm && userActionType === userActionTypes.RESET_PHONE && (
        <ResetUserPhoneNumberForm
          hide={hideModal}
          onSubmitAction={onConfirmResetPhoneNumber}
          loading={adminUserPhoneResetting}
          userId={currentUser.adminId}
        />
      )}
    </React.Fragment>
  );
};

export default CountryManagement;
