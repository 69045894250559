import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./link.scss";

const LinkTo = ({ children, to, className }) => (
  <Link to={to} className={classNames("link", className)}>
    {children}
  </Link>
);

export default LinkTo;
