import {
  SET_COHORTS,
  CREATE_COHORT,
  SET_SELECTED_COHORT,
  UPDATE_COHORT,
  UPDATE_COHORTS
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = {
  cohortProfiles: [],
  selectedCohortData: {}
};

const cohorts = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COHORTS: {
      const { cohortProfiles } = payload;
      return { ...state, cohortProfiles: [...cohortProfiles] };
    }
    case CREATE_COHORT: {
      const { cohortProfiles } = state;
      const { cohortProfile } = payload;
      return {
        ...state,
        cohortProfiles: [{ ...cohortProfile }, ...cohortProfiles]
      };
    }
    case SET_SELECTED_COHORT: {
      const { cohort } = payload;
      return {
        ...state,
        selectedCohortData: cohort
      };
    }
    case UPDATE_COHORTS: {
      const currentCohorts = state.cohortProfiles;
      let newCohorts = currentCohorts.map(c =>
        c.cohortId === payload.cohortId
          ? {
              cohortId: payload.cohortId,
              shortId: payload.shortId,
              name: payload.name,
              description: payload.description
            }
          : c
      );

      return { ...state, cohortProfiles: [...newCohorts] };
    }
    case UPDATE_COHORT: {
      const { selectedCohortData } = state;
      const { cohort } = payload;
      return {
        ...state,
        selectedCohortData: {
          ...selectedCohortData,
          ...cohort
        }
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default cohorts;
