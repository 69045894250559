import React from "react";
import classNames from "classnames";
import "./label.scss";

const Label = ({ children, className, htmlFor, error }) => (
  <label
    className={classNames("label", { "label--error": error }, className)}
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

export default Label;
