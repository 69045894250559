import {
  SET_QR_CODES,
  UPDATE_QR_CODE,
  SET_QR_FOR_EDIT,
  CREATE_TESTING_PROFILE,
  DELETE_TESTING_PROFILE
} from "./types";

export const setQrCodes = qrCodes => ({
  type: SET_QR_CODES,
  payload: {
    qrCodes
  }
});

export const updateQrCode = (testingProfileId, name, description) => {
  return {
    type: UPDATE_QR_CODE,
    payload: {
      testingProfileId,
      name,
      description
    }
  };
};

export const setTestingProfileForEdit = testProfileId => ({
  type: SET_QR_FOR_EDIT,
  payload: {
    testProfileId
  }
});

export const createTestingProfile = ({
  name,
  description,
  testingProfileId,
  ...rest
}) => ({
  type: CREATE_TESTING_PROFILE,
  payload: { name, description, testingProfileId, ...rest }
});

export const deleteTestingProfile = id => ({
  type: DELETE_TESTING_PROFILE,
  payload: {
    id
  }
});
