import { patientClient, default as axios } from "utils/Axios";
import { makePatients, makeTestResults } from "utils/mockData";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";

export const getPatients = (controller) => {
  return makeAxiosRequest("getPatients", {
    client: axios,
    method: "get",
    relativePath: "/patients",
    signal: controller?.signal || {}
  });
};

export const getMockedPatients = () => {
  return makeRequest("getPatients", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makePatients(100);
            resolve({ data: { data: mockData, status: 200 } });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const getMembers = (controller) => {
  return makeAxiosRequest("getMembers", {
    client: axios,
    method: "get",
    relativePath: "/members",
    signal: controller?.signal || {}
  });
};

export const getPatientData = id => {
  return makeAxiosRequest("getPatientData", {
    client: axios,
    method: "get",
    relativePath: `/patients/${id}`
  });
};

export const createPatient = patient => {
  return makeAxiosRequest("createPatient", {
    client: axios,
    method: "post",
    relativePath: "/provider-patients",
    data: patient
  });
};

export const searchSinglePatient = patient => {
  return makeAxiosRequest("getDiscoveredPatients", {
    client: axios,
    method: "post",
    relativePath: "/patients/search",
    data: patient
  });
};

export const linkPatient = (id, timeStamp) => {
  return makeAxiosRequest("createPatient", {
    client: axios,
    method: "post",
    relativePath: `patients/${id}/connections`,
    data: {
      consentAcceptedAt: timeStamp
    }
  });
};

export const updatePatient = (id, patient) => {
  return makeAxiosRequest("updatePatient", {
    client: axios,
    method: "put",
    relativePath: `/provider-patients/${id}`,
    data: {
      profile: patient.profile,
      configuration: patient.configuration,
      extendedProfile: patient.extendedProfile
    }
  });
};

export const getTestResults = (id, type) => {
  return makeAxiosRequest("getTestResults", {
    client: axios,
    method: "get",
    relativePath: `/patients/${id}/test-results?dataType=${type}`
  });
};

export const getFilteredPatientList = (filters, nextToken) => {
  return makeAxiosRequest("getFilteredPatientList", {
    client: axios,
    method: "post",
    relativePath: `test-result-registry`,
    data: {
      filters,
      nextToken
    }
  });
};

export const getSecret = id => {
  return makeAxiosRequest("getSignature", {
    client: patientClient,
    method: "get",
    relativePath: `/test-provider-secret-salt/${id}`
  });
};

export const getMockedTestResults = () => {
  return makeRequest("getTestResults", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeTestResults();
            resolve({ data: mockData });
          }, 3000);
        });
      }
    },
    query: ""
  });
};
