import phoneImage from "assets/images/accessProfilePhone.png";
import Button from "components/Button";
import ConfirmPopup from "components/ConfirmPopup";
import FloatNavbar from "components/FloatNavbar";
import Icon from "components/Icon";
import PageTitle from "components/PageTitle";
import AccessProfileDetails from "components/AccessProfileDetails";
import Spinner from "components/Spinner";
import Table from "components/Table";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import React from "react";
import useSticky from "../../utils/useSticky";
import { withRouter } from "react-router-dom";
import RouteLeavingGuard from "components/RouteLeavingGuard";
import { sortIgnoreCase } from "utils/sortMethods";
import TestProfileForm from "../TestProfileForm/TestProfileForm";
import { initPrintView } from "utils/print";
import "./AccessProfiles.scss";
import TestRequirements from "../TestRequirements";
import { useForm, FormContext, useFormContext } from "react-hook-form";
import Error from "components/Error";
import DecisionValidity from "components/DecisionValidity";
import { noWhiteSpacesForRequiredFields } from "utils/FieldValidationRules";
import { hoursToMilliseconds } from "utils/formatData";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import i18n from "../../translations";

export const EditingContext = React.createContext([]);

let validationRules = {
  name: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 128,
      message: "The field must contain at most 128 characters"
    }
  },
  description: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 512,
      message: "The field must contain at most 512 characters"
    }
  }
};

i18n.on("initialized", () => {
  validationRules = {
    name: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 128,
        message: i18n.t("field_max_length", { number_of_characters: 128 })
      }
    },
    description: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 512,
        message: i18n.t("field_max_length", { number_of_characters: 512 })
      }
    }
  };
});
const defaultColumn = {
  Cell: ({ row, value, column }) => {
    const { register, formState, errors } = useFormContext();
    const { dirtyFields } = formState;
    const context = React.useContext(EditingContext);

    if (
      context?.currentlyEditing?.profiles?.includes(
        row.original.accessProfileId
      )
    ) {
      return (
        <>
          <textarea
            rows={2}
            name={column.id}
            ref={register(validationRules[column.id])}
            style={{
              backgroundColor: dirtyFields.has(column.id) ? "#fffcda" : ""
            }}
            className="table-cell-input"
          />
          {errors?.[column?.id] && (
            <Error>{errors?.[column?.id]?.message}</Error>
          )}
        </>
      );
    }
    return <p>{value}</p>;
  }
};

const AccessProfiles = ({
  accessProfiles,
  getAccessProfiles,
  accessProfilesLoading,
  createAccessProfile,
  accessProfileSaving,
  accessProfileUpdating,
  deleteAccessProfile,
  accessProfileDeleting,
  duplicateAccessProfile,
  accessProfileDetails,
  accessProfileDetailsLoading,
  getAccessProfileDetails,
  updateMetaData,
  history,
  defaultTestTypeList,
  cancelUpdate
}) => {
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedAccessProfile, setSelectedAccessProfile] = React.useState({});
  const [showUnsavedChangesModal, setshowUnsavedChangesModal] = React.useState(
    false
  );
  const [confirmConfig, setConfirmConfig] = React.useState({});
  const [unsavedConfirmConfig, setUnsavedConfirmConfig] = React.useState({});
  const [checked, setChecked] = React.useState(
    accessProfileDetails?.metaData?.decisionTreeLogic?.nodeType ||
      "NODE_LOGIC_OR"
  );
  const [canLeavePage, setCanLeavePage] = React.useState(true);
  const intialEditingState = React.useMemo(
    () => ({
      profiles: [],
      sections: []
    }),
    []
  );

  const [currentlyEditing, setCurrentlyEditing] = React.useState(
    intialEditingState
  );
  const isEditing = accessProfileDetails?.isEditing?.length ? true : false;
  const { isSticky, element } = useSticky();

  const methods = useForm({
    mode: "onChange"
  });

  const { formState, reset, handleSubmit, setError } = methods;
  const onSubmit = async data => {
    let startEndTime = Number.isNaN(new Date(data.eventStartTime).getTime())
      ? new Date(accessProfileDetails.metaData.eventStartTime).getTime()
      : new Date(data.eventStartTime).getTime();
    let duration = hoursToMilliseconds(data.duration);
    let updatedData = {
      ...accessProfileDetails.metaData,
      ...data,
      duration: duration,
      decisionTreeLogic: {
        ...accessProfileDetails.metaData.decisionTreeLogic,
        ...data.decisionTreeLogic,
        nodeType: checked || "NODE_LOGIC_OR",
        children: data?.decisionTreeLogic?.children?.map(item => {
          return {
            nodeType: "NODE_RULE",
            ruleId: "RULE_CUSTOM",
            functionBlockId: "FB_LAST_RESULT_IS_GOOD",
            ruleConfig: {
              testType: item.ruleConfig["testType"],
              testResultValidity:
                +item.ruleConfig.days * 60 * 60 * 24 * 1000 +
                +item.ruleConfig.hours * 60 * 60 * 1000
            }
          };
        })
      },
      eventStartTime: startEndTime,
      eventEndTime: startEndTime
    };

    await updateMetaData(updatedData);
    setCurrentlyEditing(intialEditingState);
  };

  React.useEffect(() => {
    let data = {
      // ...accessProfileDetails?.metaData,
      name: selectedAccessProfile.name,
      description: selectedAccessProfile.description,
      eventStartTime:
        accessProfileDetails?.metaData?.eventStartTime &&
        new Date(accessProfileDetails.metaData.eventStartTime),
      contactDetails:
        { ...accessProfileDetails?.metaData?.contactDetails } || {},
      decisionTreeLogic: {
        ...accessProfileDetails?.metaData?.decisionTreeLogic
      }
    };
    reset(data);
    setChecked(accessProfileDetails?.metaData?.decisionTreeLogic?.nodeType);
  }, [accessProfileDetails, selectedAccessProfile, reset]);

  React.useEffect(() => {
    getAccessProfiles();
  }, [getAccessProfiles]);

  React.useEffect(initPrintView, []);

  React.useEffect(() => {
    setCanLeavePage(!formState.dirty);
  }, [formState]);

  React.useEffect(() => {
    setChecked(
      accessProfileDetails?.metaData?.decisionTreeLogic?.nodeType ||
        "NODE_LOGIC_OR"
    );
  }, [accessProfileDetails]);

  const resetForm = React.useCallback(() => {
    let data = {
      name: selectedAccessProfile.name,
      description: selectedAccessProfile.description,
      eventStartTime:
        accessProfileDetails?.metaData?.eventStartTime &&
        new Date(accessProfileDetails.metaData.eventStartTime),
      contactDetails:
        { ...accessProfileDetails?.metaData?.contactDetails } || {},
      decisionTreeLogic: {
        ...accessProfileDetails?.metaData?.decisionTreeLogic
      }
    };
    cancelUpdate();
    reset(data);
    if (_.isEmpty(data.decisionTreeLogic?.children[0])) {
      setError(
        `decisionTreeLogic.children[0].ruleConfig.testType`,
        "required",
        t("form_field_is_required")
      );
      setError(
        `decisionTreeLogic.children[0].ruleConfig.days`,
        "required",
        t("form_field_is_required")
      );
      setError(
        `decisionTreeLogic.children[0].ruleConfig.hours`,
        "required",
        t("form_field_is_required")
      );
    }

    setChecked(accessProfileDetails?.metaData?.decisionTreeLogic?.nodeType);

    setCurrentlyEditing(intialEditingState);
  }, [
    accessProfileDetails,
    intialEditingState,
    reset,
    selectedAccessProfile,
    setError,
    t,
    cancelUpdate
  ]);

  const addAccessProfile = React.useCallback(
    async ({ name, description, ...rest }) => {
      await createAccessProfile({ name, description, ...rest });
      hideAddAccessProfileForm();
    },
    [createAccessProfile]
  );

  const hideAddAccessProfileForm = () => {
    setShowAddModal(false);
  };

  const onConfirmDeleteAccessProfile = React.useCallback(
    async id => {
      await deleteAccessProfile(id);
      setShowDeleteModal(false);
    },
    [deleteAccessProfile]
  );

  const onConfirmUnsavedChanges = React.useCallback(async () => {
    resetForm();

    setshowUnsavedChangesModal(false);
  }, [resetForm]);

  const unsavedConfig = React.useCallback(() => {
    setUnsavedConfirmConfig({
      title: t("unsaved_changes_title"),
      message: t("unsaved_changes_description"),
      onConfirm: () => onConfirmUnsavedChanges()
    });
    setshowUnsavedChangesModal(true);
    return false;
  }, [onConfirmUnsavedChanges, t]);

  const deleteAccProfile = React.useCallback(
    async row => {
      const profile = accessProfiles.find(
        u => u.accessProfileId === row.accessProfileId
      );
      if (!formState.dirty) {
        setConfirmConfig({
          title: t("confirm_delete_action"),
          message: t("confirm_delete_name", { name: `${profile.name}` }),
          onConfirm: () => onConfirmDeleteAccessProfile(row.accessProfileId)
        });
        setShowDeleteModal(true);
      } else {
        if (row.accessProfileId === accessProfileDetails.accessProfileId) {
          setConfirmConfig({
            title: t("confirm_delete_action"),
            message: t("confirm_delete_name", { name: `${profile.name}` }),
            onConfirm: () => onConfirmDeleteAccessProfile(row.accessProfileId)
          });
          setShowDeleteModal(true);
          return;
        }
        unsavedConfig(row.accessProfileId);
      }
    },
    [
      onConfirmDeleteAccessProfile,
      formState,
      accessProfiles,
      unsavedConfig,
      accessProfileDetails,
      t
    ]
  );

  const onDuplicate = React.useCallback(
    async row => {
      if (!formState.dirty) {
        await duplicateAccessProfile(row.accessProfileId);
        setSelectedAccessProfile({});
      } else {
        unsavedConfig(row.accessProfileId);
      }
    },
    [duplicateAccessProfile, unsavedConfig, formState]
  );

  const onEdit = React.useCallback(
    async e => {
      if (formState.dirty) {
        if (e.accessProfileId !== accessProfileDetails.accessProfileId) {
          unsavedConfig(e.accessProfileId);
          return;
        } else {
          setSelectedAccessProfile(e);
          setCurrentlyEditing(prevState => ({
            ...prevState,
            profiles: [e.accessProfileId]
          }));
          await getAccessProfileDetails(e.accessProfileId);
        }
      } else {
        setSelectedAccessProfile(e);
        setCurrentlyEditing(prevState => ({
          ...prevState,
          profiles: [e.accessProfileId]
        }));
        await getAccessProfileDetails(e.accessProfileId);
      }
    },
    [accessProfileDetails, unsavedConfig, getAccessProfileDetails, formState]
  );

  const onRowClick = React.useCallback(
    async e => {
      if (!formState.dirty) {
        if (accessProfileDetails.accessProfileId !== e.accessProfileId) {
          setCurrentlyEditing(intialEditingState);
        }
        setSelectedAccessProfile(e);
        await getAccessProfileDetails(e.accessProfileId);
        return;
      }
      if (accessProfileDetails.accessProfileId !== e.accessProfileId) {
        unsavedConfig(e.accessProfileId);
        return;
      }
    },
    [
      getAccessProfileDetails,
      accessProfileDetails,
      setSelectedAccessProfile,
      unsavedConfig,
      formState,
      intialEditingState
    ]
  );

  const onCancel = React.useCallback(() => {
    resetForm();
  }, [resetForm]);

  const accessProfilesColumns = [
    {
      Header: () => (
        <div className="header-content">
          <div>{t("qr_code")}</div> <span className="badge">1</span>
        </div>
      ),
      accessor: "accessProfileId",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="qr-codes__code-cell">
            <div className="qr-code__image">
              <QRCode
                value={row.original.accessProfileId}
                size={30}
                renderAs={"svg"}
                level={"H"}
                includeMargin={true}
              />
            </div>
          </div>
        );
      }
    },
    {
      Header: () => (
        <div className="header-content">
          <div>{t("name")}</div> <span className="badge">2</span>
        </div>
      ),
      accessor: "name",
      style: {
        width: "25%"
      },
      className: "qr-codes__name",
      sortType: sortIgnoreCase
    },
    {
      Header: t("description"),
      accessor: "description",
      style: {
        width: "65%",
        minWidth: "80px"
      },
      className: "qr-codes__description",
      disableSortBy: true
    },
    {
      Header: t("test_data_organization_actions"),
      style: {
        minWidth: "60px"
      },
      className: "qr-codes__actions-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <React.Fragment>
            <Button
              variant="row-action"
              title={t("edit")}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onEdit(row.original);
              }}
            >
              <Icon type="edit" />
            </Button>
            <Button
              variant="row-action"
              title={t("duplicate")}
              onClick={e => {
                e.stopPropagation();
                onDuplicate(row.original);
              }}
            >
              <Icon type="duplicate" />
            </Button>
            <Button
              variant="row-action"
              title={t("health_care_organizations_users_actions_delete")}
              onClick={e => {
                e.stopPropagation();
                deleteAccProfile(row.original);
              }}
            >
              <Icon type="trash" />
            </Button>
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <>
      <EditingContext.Provider
        value={{ currentlyEditing, setCurrentlyEditing }}
      >
        <FormContext {...methods}>
          <form className="profiles">
            <div className="button-container">
              <Button
                variant="primary"
                className="button header-add-button"
                onClick={e => {
                  e.preventDefault();
                  setShowAddModal(true);
                }}
                disabled={formState.dirty}
                ref={element}
              >
                {t("create_access_profile")}
              </Button>
              <Button
                type="button"
                variant="secondary"
                className="button header-add-button"
                disabled={!formState.dirty && !isEditing}
                onClick={onCancel}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="primary"
                className="button header-add-button"
                disabled={!formState.isValid || !formState.dirty}
                onClick={handleSubmit(onSubmit)}
                loading={accessProfileUpdating}
              >
                {t("save")}
              </Button>
            </div>
            {isSticky && formState.dirty && (
              <FloatNavbar
                onSave={handleSubmit(onSubmit)}
                onCancel={e => {
                  e.preventDefault();
                  onCancel();
                }}
                loading={accessProfileUpdating}
                saveDisabled={!formState.isValid || !formState.dirty}
              />
            )}

            <PageTitle title={t("access_profiles")} />

            {accessProfilesLoading ? (
              <Spinner marginTop={15} />
            ) : (
              <div className="table-container">
                <img src={phoneImage} alt="phone" className="phone-image-1" />
                <Table
                  globalFilter
                  pagination
                  defaultColumn={defaultColumn}
                  pageSize={10}
                  columns={accessProfilesColumns}
                  data={accessProfiles}
                  selectedRowIndex={accessProfiles.findIndex((item, e) => {
                    return (
                      item.accessProfileId ===
                      selectedAccessProfile.accessProfileId
                    );
                  })}
                  className="access-profiles-table"
                  // updateMyData={update}
                  onRowClick={onRowClick}
                />
              </div>
            )}
            {showDeleteModal && (
              <ConfirmPopup
                title={confirmConfig.title}
                message={confirmConfig.message}
                visible={showDeleteModal}
                cancel={() => setShowDeleteModal(false)}
                confirm={e => {
                  e.preventDefault();
                  confirmConfig.onConfirm();
                }}
                loading={accessProfileDeleting}
              />
            )}
            {showUnsavedChangesModal && (
              <ConfirmPopup
                title={unsavedConfirmConfig.title}
                message={unsavedConfirmConfig.message}
                visible={showUnsavedChangesModal}
                cancel={() => setshowUnsavedChangesModal(false)}
                confirm={unsavedConfirmConfig.onConfirm}
                loading={accessProfileSaving}
              />
            )}
            {accessProfileDetailsLoading ? (
              <Spinner marginTop={5} />
            ) : (
              accessProfileDetails?.accessProfileId && (
                <>
                  <PageTitle
                    title={accessProfileDetails.metaData.name}
                    className="qr-details-header"
                  />
                  <AccessProfileDetails
                    {...accessProfileDetails}
                    selectedTestingProfile={accessProfileDetails}
                    testingProfileUpdating={accessProfileUpdating}
                  />
                  <div className="test-container">
                    <TestRequirements
                      testTypeList={defaultTestTypeList}
                      testRequirementsData={
                        accessProfileDetails?.metaData?.decisionTreeLogic
                          ?.children
                      }
                      nodeType={
                        accessProfileDetails?.metaData?.decisionTreeLogic
                          ?.nodeType
                      }
                      checked={checked}
                      setChecked={setChecked}
                    />
                    <DecisionValidity
                      duration={accessProfileDetails.metaData.duration}
                    ></DecisionValidity>
                  </div>
                </>
              )
            )}
          </form>
        </FormContext>
        {showAddModal && (
          <TestProfileForm
            onSubmit={addAccessProfile}
            loading={accessProfileSaving}
            hide={() => setShowAddModal(false)}
            type="accessProfiles"
          />
        )}
        <iframe
          title="ifmcontentstoprint"
          id="ifmcontentstoprint"
          style={{ visibility: "hidden", height: "10px" }}
        />
        <RouteLeavingGuard
          when={!canLeavePage}
          navigate={path => history.push(path)}
        />
      </EditingContext.Provider>
    </>
  );
};

AccessProfiles.propTypes = {
  accessProfiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  accessProfilesLoading: PropTypes.bool,
  accessProfileSaving: PropTypes.bool
};

export { AccessProfiles };

export default withRouter(AccessProfiles);
