import { useEffect, useState, useRef, useCallback } from "react";

function useSticky() {
  const [isSticky, setSticky] = useState(false);
  const element = useRef(null);

  const handleScroll = useCallback(() => {
    document.querySelector(".dashboardLayout_right").scrollTop >
    element.current.getBoundingClientRect().bottom
      ? setSticky(true)
      : setSticky(false);
  }, []);

  function throttle(fn, wait) {
    var time = Date.now();
    return function () {
      if (time + wait - Date.now() < 0) {
        fn();
        time = Date.now();
      }
    };
  }

  useEffect(() => {
    document
      .querySelector(".dashboardLayout_right")
      .addEventListener("scroll", throttle(handleScroll, 20));
  }, [handleScroll]);

  return { isSticky, element };
}

export default useSticky;
