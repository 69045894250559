import {
  SET_QR_CODES,
  UPDATE_QR_CODE,
  CREATE_TESTING_PROFILE,
  DELETE_TESTING_PROFILE
} from "./types";
import { SET_SELECTED_ORGANIZATION } from "store/reducers/organizations/types";
import { LOGOUT_USER } from "store/reducers/user/types";
import { SET_QR_FOR_EDIT } from "./types";

const initialState = [];

const qrCodes = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_QR_CODES: {
      const { qrCodes } = payload;
      return [...qrCodes];
    }
    case UPDATE_QR_CODE: {
      const { testingProfileId, name, description } = payload;
      return state.map(item =>
        item.testingProfileId === testingProfileId
          ? { ...item, name, description, isEditing: false }
          : item
      );
    }
    case SET_QR_FOR_EDIT: {
      const { testProfileId } = payload;
      return state.map(item =>
        item.testingProfileId === testProfileId
          ? { ...item, isEditing: true }
          : { ...item, isEditing: false }
      );
    }

    case CREATE_TESTING_PROFILE: {
      return [{ ...payload }, ...state];
    }

    case DELETE_TESTING_PROFILE: {
      const newTestingProfiles = state.filter(
        profile => profile.testingProfileId !== payload.id
      );
      return newTestingProfiles;
    }

    case LOGOUT_USER:
    case SET_SELECTED_ORGANIZATION:
      return initialState;
    default:
      return state;
  }
};

export default qrCodes;
