import {
  SET_ACCESS_PROFILES,
  UPDATE_ACCESS_PROFILES,
  CREATE_ACCESS_PROFILE,
  DELETE_ACCESS_PROFILE
} from "./types";

export const setAccessProfiles = accessProfiles => ({
  type: SET_ACCESS_PROFILES,
  payload: {
    accessProfiles
  }
});

export const updateAccessProfile = (accessProfileId, name, description) => {
  return {
    type: UPDATE_ACCESS_PROFILES,
    payload: {
      accessProfileId,
      name,
      description
    }
  };
};

export const createAccessProfile = ({
  name,
  description,
  accessProfileId,
  ...rest
}) => ({
  type: CREATE_ACCESS_PROFILE,
  payload: { name, description, accessProfileId, ...rest }
});

export const deleteAccessProfile = id => ({
  type: DELETE_ACCESS_PROFILE,
  payload: {
    id
  }
});
