export const fixture = {
    project : {
      name: "NAVIFY® Pass",
    },
    url : {
      helpCenter :  "https://www.navify.com/pass/help-center/web/en-us/portal/index.htm",
      support : "https://www.navify.com/pass/help-center/web/en-us/portal/Support.htm",
      privacyPolicy: "https://www.navify.com/pass/help-center/web/en-us/portal/legal/Privacy_Policy.htm"
    }
}
