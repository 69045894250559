import { SET_USERS, UPDATE_USERS, DELETE_USER } from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = [];

const users = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USERS: {
      const { users } = payload;
      return [...users];
    }
    case UPDATE_USERS: {
      const currentUsers = state;
      const newUser = payload.data;
      let newUsers;
      if (newUser.id) {
        newUsers = currentUsers.map(u =>
          u.id === newUser.id ? { ...newUser } : u
        );
      } else {
        newUsers = [
          ...currentUsers,
          { ...newUser, id: Math.floor(Math.random() * 100) + 1000 }
        ];
      }
      return newUsers;
    }
    case DELETE_USER: {
      const newUsers = state.filter(u => u.id !== payload.id);
      return newUsers;
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default users;
