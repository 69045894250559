import React from "react";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Error from "components/Error";
import { noWhiteSpacesForRequiredFields } from "utils/FieldValidationRules";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/useLanguage";

function InputFirstName({
  name = "firstName",
  useForm: { register, errors },
  label = "first_name",
  addLastName,
  required
}) {
  const { t } = useTranslation();
  const { flippedNameOrder } = useLanguage();

  return (
    <>
      {flippedNameOrder && addLastName && (
        <InputFirstName
          name={addLastName.name ?? "lastName"}
          label={addLastName.label ?? "last_name"}
          useForm={{ register, errors }}
          required={required}
        />
      )}
      <FormGroup>
        <Label htmlFor={name} error={errors[name]}>
          {t(label)}
          {required && "*"}
        </Label>
        <Input
          data-testid={`input-${name}`}
          type="text"
          name={name}
          error={errors[name]}
          ref={register({
            ...(required && { validate: noWhiteSpacesForRequiredFields }),
            ...(required && { required: t("form_field_is_required") }),
            maxLength: {
              value: 50,
              message: t("field_max_length", {
                number_of_characters: 50
              })
            }
          })}
        />
        {errors[name] && <Error>{errors[name].message}</Error>}
      </FormGroup>
      {!flippedNameOrder && addLastName && (
        <InputFirstName
          name={addLastName.name ?? "lastName"}
          label={addLastName.label ?? "last_name"}
          useForm={{ register, errors }}
          required={required}
        />
      )}
    </>
  );
}

export default InputFirstName;
