import { UPDATE_REQUEST_STATUS } from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = {};

const requests = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_REQUEST_STATUS: {
      const { syncName, status, message } = payload;

      return {
        ...state,
        [syncName]: {
          status,
          message
        }
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default requests;
