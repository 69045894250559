import React from "react";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { success } from "react-notification-system-redux";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import Button from "components/Button";
import Error from "components/Error";
import FormGroup from "components/FormGroup";
import Col from "components/Col";
import { useTranslation } from "react-i18next";
import { useValidation } from "utils/useValidation";

function UpdatePasswordForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const password = React.useRef({});
  const [loading, setLoadingStatus] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState,
    errors,
    watch,
    setError,
    reset
  } = useForm({
    mode: "onChange"
  });
  password.current = watch("password", "");
  const { noRomanCharacter } = useValidation();

  const onSubmit = ({ password, oldPassword }, event) => {
    if (formState.isValid) {
      setLoadingStatus(true);

      Auth.currentAuthenticatedUser().then(currentUser => {
        Auth.changePassword(currentUser, oldPassword, password)
          .then(() => {
            reset();
            dispatch(
              success({
                title: t("user_profile_update_password"),
                message: t("success_changes_applied"),
                position: "tc"
              })
            );
          })
          .catch(() =>
            setError(
              "oldPassword",
              "invalid",
              t("user_profile_old_password_invalid")
            )
          )
          .finally(() => setLoadingStatus(false));
      });
    }
  };

  return (
    <React.Fragment>
      <Col>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label htmlFor="oldPassword" error={errors.oldPassword}>
              {t("user_profile_old_password")}
            </Label>
            <Input
              type="password"
              name="oldPassword"
              error={errors.oldPassword}
              onChange={noRomanCharacter}
              ref={register({
                required: t("user_profile_old_password_invalid")
              })}
            />
            {errors.oldPassword && <Error>{errors.oldPassword.message}</Error>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password" error={errors.password}>
              {t("password")}
            </Label>
            <Input
              name="password"
              type="password"
              error={errors.password}
              onChange={noRomanCharacter}
              ref={register({
                required: t("password_error_required"),
                minLength: {
                  value: 8,
                  message: t("password_error_too_short", {
                    number_of_characters: 8
                  })
                },
                validate: {
                  hasLowerCase: value =>
                    RegExp(/[a-z]/g).test(value) ||
                    t("password_error_no_lowercase"),
                  hasUpperCase: value =>
                    RegExp(/[A-Z]/g).test(value) ||
                    t("password_error_no_uppercase"),
                  hasDigits: value =>
                    RegExp(/\d/g).test(value) || t("password_error_no_digits")
                }
              })}
            />
            {errors.password && <Error>{errors.password.message}</Error>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="confirmPassword" error={errors.confirmPassword}>
              {t("user_profile_confirm_password")}
            </Label>
            <Input
              type="password"
              name="confirmPassword"
              error={errors.confirmPassword}
              onChange={noRomanCharacter}
              ref={register({
                required: t("password_error_required"),
                validate: value =>
                  value === password.current || t("password_error_not_matching")
              })}
            />
            {errors.confirmPassword && (
              <Error>{errors.confirmPassword.message}</Error>
            )}
          </FormGroup>
          <Button
            type="submit"
            className="button--primary"
            loading={loading}
            disabled={loading || !formState.isValid}
          >
            {t("user_profile_update_password")}
          </Button>
        </Form>
      </Col>
    </React.Fragment>
  );
}

export default UpdatePasswordForm;
