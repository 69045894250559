import * as api from "api/accessProfiles";
import { error, success } from "react-notification-system-redux";
import { updateAccessProfile } from "../accessProfiles/actions";
import { createAccessProfile } from "../accessProfiles/services";
import * as actions from "./actions";
import { clearAccessProfileData } from "./actions";
import i18n from "../../../translations";

export const getAccessProfileDetails = id => async (dispatch, getState) => {
  const state = getState();
  const { accessProfileData } = state;
  if (
    !accessProfileData?.accessProfileId ||
    (accessProfileData?.accessProfileId &&
      accessProfileData.accessProfileId !== id)
  ) {
    //use api.getMockedAccessProfileDetails until BE is ready
    const result = await dispatch(api.getAccessProfileDetails(id));
    if (result && result.status === 200) {
      const accessProfileData = result.data;
      dispatch(actions.setAccessProfileData(accessProfileData, id));
      dispatch(actions.isEditingAccessProfile(true));

      return accessProfileData;
    }
  }
};

export const duplicateAccessProfile = id => async (dispatch, getState) => {
  //use api.getMockedAccessProfileDetails until BE is ready
  const result = await dispatch(api.getAccessProfileDetails(id));

  if (result && result.status === 200 && result.data) {
    delete result.data.accessProfileId;
    const duplicate = {
      ...result.data,
      name: `${result.data.name.substring(0, 123)}-copy`
    };

    dispatch(createAccessProfile(duplicate));
    dispatch(clearAccessProfileData());
  } else {
    dispatch(error({ message: i18n.t("error_generic") }));
    return false;
  }
};

export const updateMetaData = payload => async (dispatch, getState) => {
  //use api.updateMockedAccessProfileDetails until BE is ready
  const result = await dispatch(api.updateAccessProfile(payload));
  if (result && result.status === 200) {
    const { accessProfileId, name, description } = payload;
    dispatch(actions.updateAccessProfile(payload));
    dispatch(updateAccessProfile(accessProfileId, name, description));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_access_updated"),
        position: "tc"
      })
    );
  } else {
    dispatch(
      error({
        title: i18n.t("error_title"),
        message: i18n.t("error"),
        position: "tc"
      })
    );
  }
};

export const cancelUpdate = () => async dispatch => {
  dispatch(actions.isEditingAccessProfile(false));
};
