import Button from "components/Button";
import Error from "components/Error";
import FormGroup from "components/FormGroup";
import Input from "components/Input";
import Label from "components/Label";
import PageTitle from "components/PageTitle";
import React from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import SelectOption from "components/SelectOption";
import Icon from "components/Icon";
import { getDays, getHours } from "utils/formatData";

import "./TestRequirements.scss";
import { useTranslation } from "react-i18next";

const TestRequirements = props => {
  const { testTypeList, checked, setChecked } = props;
  const {
    register,
    control,
    errors,
    triggerValidation,
    setValue,
    formState,
    watch,
    getValues,
    setError
  } = useFormContext();
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "decisionTreeLogic.children"
  });

  React.useEffect(() => {
    register("decisionTreeLogic.nodeType");
  }, [register]);

  React.useEffect(() => {
    watch();
  }, [watch]);

  React.useEffect(() => {
    triggerValidation();
  }, [triggerValidation]);

  const onInputChange = (e, name, type) => {
    let field = name + "." + type;
    let { value } = e.target;
    let parsed = parseInt(value);
    if (isNaN(parsed)) {
      parsed = 0;
    }
    if (value === "") {
      parsed = null;
    }
    if (
      parsed === 0 &&
      (type === "hours"
        ? getValues(`${name}.days`)
        : getValues(`${name}.hours`)) === "0"
    ) {
      setError(
        `${name}.days`,
        "required",
        t("time_before_event", { amount: 1, unit_of_time: t("hour") })
      );
      setError(`${name}.hours`, "required", "");
    } else {
      triggerValidation();
    }
    setValue(field, parsed);
  };

  const handleClick = selected => {
    setValue("decisionTreeLogic.nodeType", selected);
    setChecked(selected);
  };

  return (
    <div className="test-requirements">
      <div className="title-top">
        <PageTitle title={t("test_requirements")}></PageTitle>

        <Button
          variant="primary"
          className="header-add-button"
          onClick={async e => {
            e.preventDefault();
            await append({});
            triggerValidation();
          }}
          disabled={errors.decisionTreeLogic}
        >
          {t("add_test_requirement")}
        </Button>
      </div>

      {fields.map((item, index) => (
        <div key={item.id} className="test-requirements-row-container">
          {index !== 0 && (
            <div className="and-or">
              <label
                data-testid="label-for-and"
                className={checked === "NODE_LOGIC_AND" ? "selected" : ""}
              >
                <input
                  type="radio"
                  name="decisionTreeLogic.nodeType"
                  value="NODE_LOGIC_AND"
                  defaultChecked={checked === "NODE_LOGIC_AND"}
                  onClick={() => handleClick("NODE_LOGIC_AND")}
                />
                {t("and")}
              </label>

              <label
                data-testid="label-for-or"
                className={checked === "NODE_LOGIC_OR" ? "selected" : ""}
              >
                <input
                  type="radio"
                  name="decisionTreeLogic.nodeType"
                  value="NODE_LOGIC_OR"
                  defaultChecked={checked === "NODE_LOGIC_OR"}
                  onClick={() => handleClick("NODE_LOGIC_OR")}
                />
                {t("or")}
              </label>
            </div>
          )}
          <div className="test-requirements-row">
            <FormGroup>
              <Label
                error={
                  errors?.decisionTreeLogic?.children?.[index]?.ruleConfig
                    ?.testType
                }
                htmlFor="testType"
              >
                {t("test_type")}
              </Label>
              <Controller
                as={
                  <SelectOption
                    isSearchable
                    placeholder={t("select")}
                    options={testTypeList}
                    error={
                      errors?.decisionTreeLogic?.children?.[index]?.ruleConfig
                        ?.testType
                    }
                    isChanged={
                      formState.dirtyFields.has(
                        `decisionTreeLogic.children[${index}].ruleConfig.testType`
                      ) ||
                      formState.dirtyFields.has(
                        `decisionTreeLogic.children[${index}].ruleConfig`
                      )
                    }
                  />
                }
                onChange={([selected]) => {
                  // React Select return object instead of value for selection
                  return selected.value;
                }}
                rules={{ required: t("form_field_is_required") }}
                name={`decisionTreeLogic.children[${index}].ruleConfig.testType`}
                control={control}
                defaultValue={item?.ruleConfig?.["testType"] || ""}
              />

              {errors?.decisionTreeLogic?.children?.[index]?.ruleConfig
                ?.testType && (
                <Error>
                  {
                    errors?.decisionTreeLogic?.children?.[index]?.ruleConfig
                      ?.testType.message
                  }
                </Error>
              )}
            </FormGroup>
            <FormGroup>
              <div className="days-hours-picker">
                <div className="day-picker-container">
                  <Label
                    error={
                      errors?.decisionTreeLogic?.children?.[index]?.ruleConfig
                        ?.days
                    }
                    htmlFor={`decisionTreeLogic.children[${index}].ruleConfig.days`}
                  >
                    {t("time_before_event_short")}({t("days")})
                  </Label>
                  <Input
                    type="number"
                    name={`decisionTreeLogic.children[${index}].ruleConfig.days`}
                    placeholder={t("days")}
                    style={
                      formState.dirtyFields.has(
                        `decisionTreeLogic.children[${index}].ruleConfig.days`
                      ) ||
                      formState.dirtyFields.has(
                        `decisionTreeLogic.children[${index}].ruleConfig`
                      )
                        ? { backgroundColor: "#fffcda" }
                        : null
                    }
                    min="0"
                    max="999"
                    onInput={e =>
                      onInputChange(
                        e,
                        `decisionTreeLogic.children[${index}].ruleConfig`,
                        "days"
                      )
                    }
                    ref={register({
                      required: t("form_field_is_required"),
                      min: {
                        value: 0,
                        message: t("time_before_validation", {
                          min: 0,
                          max: 999
                        })
                      },
                      max: {
                        value: 999,
                        message: t("time_before_validation", {
                          min: 0,
                          max: 999
                        })
                      }
                    })}
                    defaultValue={
                      Number.isNaN(
                        getDays(item?.ruleConfig?.["testResultValidity"])
                      )
                        ? ""
                        : getDays(
                            item?.ruleConfig?.["testResultValidity"] / 1000
                          )
                    }
                    error={
                      errors?.decisionTreeLogic?.children?.[index]?.ruleConfig
                        ?.days
                    }
                    className="day-time-input"
                  />
                  {`errors.decisionTreeLogic.children[${index}].ruleConfig.days` && (
                    <Error>
                      {
                        errors?.decisionTreeLogic?.children?.[index]?.ruleConfig
                          ?.days?.message
                      }
                    </Error>
                  )}
                </div>
                <div className="hour-picker-container">
                  <Label
                    error={
                      errors?.decisionTreeLogic?.children?.[index]?.ruleConfig
                        ?.hours
                    }
                    htmlFor={`decisionTreeLogic.children[${index}].ruleConfig.hours`}
                  >
                    {t("hours")}
                  </Label>
                  <Input
                    type="number"
                    name={`decisionTreeLogic.children[${index}].ruleConfig.hours`}
                    placeholder={t("hours")}
                    style={
                      formState.dirtyFields.has(
                        `decisionTreeLogic.children[${index}].ruleConfig.hours`
                      ) ||
                      formState.dirtyFields.has(
                        `decisionTreeLogic.children[${index}].ruleConfig`
                      )
                        ? { backgroundColor: "#fffcda" }
                        : null
                    }
                    min="0"
                    max="23"
                    onInput={e =>
                      onInputChange(
                        e,
                        `decisionTreeLogic.children[${index}].ruleConfig`,
                        "hours"
                      )
                    }
                    ref={register({
                      required: t("form_field_is_required"),
                      min: {
                        value: 0,
                        message: t("time_before_validation", {
                          min: 0,
                          max: 23
                        })
                      },
                      max: {
                        value: 23,
                        message: t("time_before_validation", {
                          min: 0,
                          max: 23
                        })
                      }
                    })}
                    defaultValue={
                      Number.isNaN(
                        getHours(item?.ruleConfig?.["testResultValidity"])
                      )
                        ? ""
                        : getHours(
                            item?.ruleConfig?.["testResultValidity"] / 1000
                          )
                    }
                    error={
                      errors?.decisionTreeLogic?.children?.[index]?.ruleConfig
                        ?.hours
                    }
                    className="hours-time-input"
                  />
                  {`errors?.decisionTreeLogic?.children[${index}].ruleConfig.hours` && (
                    <Error>
                      {
                        errors?.decisionTreeLogic?.children?.[index]?.ruleConfig
                          ?.hours?.message
                      }
                    </Error>
                  )}
                </div>
              </div>
            </FormGroup>
            <Button
              variant="row-action"
              title={t("health_care_organizations_users_actions_delete")}
              type="submit"
              className="delete-button"
              onClick={async e => {
                e.preventDefault();

                setValue(`decisionTreeLogic.children[${index}].ruleConfig`, {
                  testType: "SARS_COV_2_ANTIGEN",
                  days: 0,
                  hours: 0
                });
                await triggerValidation();
                remove(index);
              }}
            >
              {fields.length > 1 && <Icon type="trash" />}
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TestRequirements;
