import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  getTestResults,
  updatePatient
} from "store/reducers/patients/services";
import {
  getPatientProfileSurvey,
  getTestTypeSurvey,
  saveTestTypeSurveyAnswers
} from "store/reducers/surveys/services";

import PatientDetails from "components/PatientDetails";

const mapStateToProps = (state, ownProps) => ({
  patient: state.patients.selectedPatientData,
  countryPatientProfileSurvey: state.surveys.patientProfileSurveyData,
  selectedPatientProfileSurvey: state.patients.selectedPatientSurvey,
  testTypeSurvey: state.surveys.testTypeSurveyData,
  testTypeSurveyIsLoading: isLoading(state, "getTestTypeSurveyData"),
  testTypeSurveyIsSaving: isLoading(state, "saveTestTypeSurvey"),
  testResultsLoading: isLoading(state, "getTestResults"),
  patientUpdating: isLoading(state, "updatePatient"),
  testResults: state.patients.selectedPatientData.testResults || [],
  countries: state.config.countries || [],
  patientDeleted: ownProps.patientDeleted,
  deletedPatientData: ownProps.deletedPatientData,
  isMember: ownProps.isMember,
  cohorts: state.cohorts.cohortProfiles
});

const mapDispatchToProps = {
  getTestResults,
  getPatientProfileSurvey,
  getTestTypeSurvey,
  updatePatient,
  saveTestTypeSurveyAnswers
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
