import axios from "utils/Axios";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";
import { mockProfile } from "utils/mockData";

export const getUserData = () => {
  return makeAxiosRequest("me", {
    client: axios,
    method: "get",
    relativePath: "/hcp/me"
  });
};

export const getMockedUserData = () => {
  return makeRequest("whoAmI", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              data: { data: mockProfile, status: 200 }
            });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const updateProfile = (firstName, lastName, phoneNumber, id) => {
  return makeAxiosRequest("updateOwnProfile", {
    client: axios,
    method: "put",
    relativePath: `hcp/me`,
    data: {
      firstName,
      lastName,
      phoneNumber
    }
  });
};

export const updateContacts = (
  institutionPhoneNumber,
  institutionAddress,
  institutionWebLink,
  extendedProfile,
  institutionName
) =>
  makeAxiosRequest("updateContacts", {
    client: axios,
    method: "put",
    relativePath: "/organizations",
    data: {
      institutionPhoneNumber,
      institutionAddress,
      institutionWebLink,
      extendedProfile,
      institutionName
    }
  });
