import {
  SET_PATIENT_PROFILE_SURVEY_DATA,
  SET_ORGANIZATION_PROFILE_SURVEY_DATA,
  SET_TEST_TYPE_SURVEY_DATA,
  SET_COHORT_PROFILE_SURVEY_DATA,
  UPDATE_PATIENT_PROFILE_SURVEY,
  UPDATE_ORGANIZATION_PROFILE_SURVEY,
  UPDATE_TEST_TYPE_SURVEY,
  UPDATE_COHORT_PROFILE_SURVEY,
  DELETE_PATIENT_PROFILE_SURVEY,
  DELETE_ORGANIZATION_PROFILE_SURVEY,
  DELETE_TEST_TYPE_SURVEY,
  DELETE_COHORT_PROFILE_SURVEY
} from "./types";

export const setPatientProfileSurveyData = survey => ({
  type: SET_PATIENT_PROFILE_SURVEY_DATA,
  payload: {
    survey
  }
});

export const setOrganizationProfileSurveyData = survey => ({
  type: SET_ORGANIZATION_PROFILE_SURVEY_DATA,
  payload: {
    survey
  }
});

export const setTestTypeSurveyData = survey => ({
  type: SET_TEST_TYPE_SURVEY_DATA,
  payload: {
    survey
  }
});

export const setCohortProfileSurveyData = survey => ({
  type: SET_COHORT_PROFILE_SURVEY_DATA,
  payload: {
    survey
  }
});

export const updatePatientProfileSurvey = survey => ({
  type: UPDATE_PATIENT_PROFILE_SURVEY,
  payload: survey
});

export const updateOrganizationProfileSurvey = survey => ({
  type: UPDATE_ORGANIZATION_PROFILE_SURVEY,
  payload: survey
});

export const updateTestTypeSurvey = survey => ({
  type: UPDATE_TEST_TYPE_SURVEY,
  payload: survey
});

export const updateCohortProfileSurvey = survey => ({
  type: UPDATE_COHORT_PROFILE_SURVEY,
  payload: survey
});

export const deletePatientProfileSurvey = () => ({
  type: DELETE_PATIENT_PROFILE_SURVEY
});

export const deleteOrganizationProfileSurvey = () => ({
  type: DELETE_ORGANIZATION_PROFILE_SURVEY
});

export const deleteTestTypeSurvey = () => ({
  type: DELETE_TEST_TYPE_SURVEY
});

export const deleteCohortProfileSurvey = () => ({
  type: DELETE_COHORT_PROFILE_SURVEY
});
