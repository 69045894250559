import Button from "components/Button";
import { HomeProfileEditingContext } from "components/HomeTestProfile/HomeTestProfile";
import PageTitle from "components/PageTitle";
import Table from "components/Table";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import homeTest_3 from "assets/images/homeTest_3.png";
import "./HomeTestAdditional.scss";

const HomeTestAdditional = ({ testProfile }) => {
  const { t } = useTranslation();
  const { contactDetails } = testProfile;

  const context = React.useContext(HomeProfileEditingContext);

  const isEditing = context?.currentlyEditing.sections?.includes("additional");

  const handleClick = e => {
    e.preventDefault();
    context.setCurrentlyEditing({
      ...context.currentlyEditing,
      sections: [...context.currentlyEditing.sections, "additional"]
    });
  };

  let field = [
    {
      id: "additionalDetails",
      data: contactDetails?.additionalDetails,
      isEditing,
      formId: "contactDetails.additionalDetails"
    }
  ];

  const additionalColumns = React.useMemo(
    () => [
      {
        Header: t("header_title"),
        style: { width: "30%" },
        Cell: () => {
          return (
            <div className="title-container">
              <p>{t("additional_information")}</p>
              <span className="badge">1</span>
            </div>
            
          )
        }
      },
      {
        Header: t("description"),
        accessor: "additionalDetails",
        Cell: ({ row }) => {
          const { register, formState } = useFormContext();
          const { dirtyFields } = formState;
          if (row.original.isEditing) {
            return (
              <>
                <textarea
                  name={row.original.formId}
                  rows={2}
                  ref={register()}
                  style={{
                    backgroundColor:
                      dirtyFields.has(row.original.formId) && "#fffcda"
                  }}
                  className="table-cell-input"
                />
              </>
            );
          }

          return <p>{row.original.data}</p>;
        }
      }
    ],
    [t]
  );

  return (
    <>
      <div className="title-top">
        <PageTitle title={t("additional_information")}></PageTitle> 
        <Button
          variant="primary"
          className="header-add-button"
          data-testid="edit-button"
          onClick={handleClick}
          disabled={isEditing}
        >
          {t("edit")}
        </Button>
      </div>
      <hr className="devider" />
      <div className="home-test-additional">
        <img src={homeTest_3} alt="phone" className="phone-image-9" /> 
        <div className="table-container">
          <Table
            columns={additionalColumns}
            data={field}
            disabledSort={true}
          />{" "}
        </div>
      </div>
    </>
  );
};

export default HomeTestAdditional;
