export const EXPORT_TYPE_ACCESS = "EXPORT_TYPE_ACCESS";
export const EXPORT_TYPE_TEST_RESULTS = "EXPORT_TYPE_TEST_RESULTS";
export const EXPORT_TYPE_ACCESS_BILLING = "EXPORT_TYPE_ACCESS_BILLING";
export const EXPORT_TYPE_TEST_RESULTS_BILLING =
  "EXPORT_TYPE_TEST_RESULTS_BILLING";
export const EXPORT_TEST_RESULTS_FOR_GOVERNMENT =
  "EXPORT_TEST_RESULTS_FOR_GOVERNMENT";
export const EXPORT_TYPE_SHARED_TEST_RESULTS = "EXPORT_TYPE_SHARED_TEST_RESULTS";

export const STATUS_NEW = "STATUS_NEW";
export const STATUS_STARTED = "STATUS_STARTED";
export const STATUS_IN_PROGRESS = "STATUS_IN_PROGRESS";
export const STATUS_ERROR = "STATUS_ERROR";
export const STATUS_COMPLETED = "STATUS_COMPLETED";
export const STATUS_TIMED_OUT = "STATUS_TIMED_OUT";

//HOME TEST
export const STATUS_PUBLISHED = "STATUS_PUBLISHED";
