import { success, error } from "react-notification-system-redux";
import * as api from "api/surveys";
import * as actions from "./actions";
import _ from "lodash";
import { setSurveyAnswered } from "../patients/actions";
import i18n from "../../../translations";
import { getLoggedAdminUserCountry } from "selectors/loggedUser";
import {
  SURVEY_FOR_TEST_TYPE,
  SURVEY_FOR_EXTENDED_PATIENT_PROFILE,
  SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE,
  SURVEY_FOR_TEST_SHARING_WITH_COHORT
} from "const/surveyTypes"

export const getPatientProfileSurvey = country => async (
  dispatch,
  getState
) => {
  const surveyType = SURVEY_FOR_EXTENDED_PATIENT_PROFILE;
  const name = "PatientProfile";

  const state = getState();
  const countryCode = !country ? getLoggedAdminUserCountry(state) : country;

  const result = await dispatch(
    api.getSurveyData({ countryCode, surveyType, name })
  );
  dispatch(actions.setPatientProfileSurveyData(result.data?.[0] || {}));
};

export const getOrganizationProfileSurvey = () => async (
  dispatch,
  getState
) => {
  const surveyType = SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE;
  const name = "OrganizationProfile";

  const state = getState();
  const countryCode =
    (state.user.countries.length && getLoggedAdminUserCountry(state)) ||
    state.user.countryCode;

  const result = await dispatch(
    api.getSurveyData({ countryCode, surveyType, name })
  );
  dispatch(actions.setOrganizationProfileSurveyData(result.data?.[0] || {}));
};

export const getTestTypeSurvey = (scope, country) => async (
  dispatch,
  getState
) => {
  const surveyType = SURVEY_FOR_TEST_TYPE;
  const name = "TestType";

  const state = getState();
  const countryCode = !country ? getLoggedAdminUserCountry(state) : country;

  const result = await dispatch(
    api.getSurveyData({ countryCode, surveyType, scope, name })
  );
  if (result.data?.[0]) {
    await dispatch(actions.setTestTypeSurveyData(result.data?.[0]));
    const newState = getState();
    const { surveys } = newState;
    return surveys.testTypeSurveyData;
  } else {
    await dispatch(actions.setTestTypeSurveyData({}));
    return false;
  }
};

export const getCohortProfileSurvey = (cohortId) => async (
  dispatch,
  getState
) => {
  const surveyType = SURVEY_FOR_TEST_SHARING_WITH_COHORT;
  const name = "CohortProfile";

  const state = getState();
  const countryCode = state.user.countryCode;
  const scope = cohortId;
  const result = await dispatch(
    api.getSurveyData({ countryCode, surveyType, name, scope })
  );
  dispatch(actions.setCohortProfileSurveyData(result.data?.[0] || {}));
};

export const updateSurvey = data => async (dispatch, getState) => {
  const state = getState();
  const countryCode = getLoggedAdminUserCountry(state) || state.user.countryCode
  data.countryCode = countryCode;

  const { survey } = data;
  const name =
    data.surveyType === SURVEY_FOR_EXTENDED_PATIENT_PROFILE
      ? "PatientProfile"
      : data.surveyType === SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE
      ? "OrganizationProfile"
      : data.surveyType === SURVEY_FOR_TEST_SHARING_WITH_COHORT
      ? "CohortProfile"
      : "TestType";

  const result = await dispatch(api.updateSurvey(data));
  if (result && result.status === 200) {
    dispatch(
      actions[`update${name}Survey`]({
        survey
      })
    );
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("survey_updated"),
        position: "tc"
      })
    );
    return true;
  } else {
    return false;
  }
};

export const createSurvey = (data) => async (dispatch, getState) => {
  const state = getState();
  const countryCode = getLoggedAdminUserCountry(state) || state.user.countryCode;
  data.countryCode = countryCode;

  const name =
    data.surveyType === SURVEY_FOR_EXTENDED_PATIENT_PROFILE
      ? "PatientProfile"
      : data.surveyType === SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE
      ? "OrganizationProfile"
      : data.surveyType === SURVEY_FOR_TEST_SHARING_WITH_COHORT
      ? "CohortProfile"
      : "TestType";

  const result = await dispatch(api.createSurvey(data));
  if (result && result.status === 201) {
    dispatch(actions[`set${name}SurveyData`](result.data));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("survey_created"),
        position: "tc"
      })
    );
    return true;
  } else {
    return false;
  }
};

export const deleteSurvey = data => async (dispatch, getState) => {
  const name =
    data.surveyType === SURVEY_FOR_EXTENDED_PATIENT_PROFILE
      ? "PatientProfile"
      : data.surveyType === SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE
      ? "OrganizationProfile"
      : data.surveyType === SURVEY_FOR_TEST_SHARING_WITH_COHORT
      ? "CohortProfile"
      : "TestType";

  const state = getState();
  const countryCode = getLoggedAdminUserCountry(state) || state.user.countryCode;

  const result = await dispatch(api.deleteSurvey(data.surveyId, countryCode));
  if (result && result.status === 204) {
    dispatch(actions[`delete${name}Survey`]());
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("survey_deleted"),
        position: "tc"
      })
    );
  }
};

export const saveTestTypeSurveyAnswers = (id, data) => async dispatch => {
  const result = await dispatch(api.saveTestTypeSurveyAnswers(id, data));
  if (result && result.status === 200) {
    dispatch(setSurveyAnswered(id));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_save_information"),
        position: "tc"
      })
    );
    return true;
  } else {
    return false;
  }
};

export const uploadSurvey = (newSurvey, currentSurvey, surveyType, scopeId = null) => async (dispatch, getState) => {
  const state = getState();
  const countryCode = getLoggedAdminUserCountry(state) || state.user.countryCode;
  let surveyForValidation = {
    ...newSurvey,
    questions: newSurvey.questions.map(question => ({...question, answers: question.answers.map(answer => ({...answer,  ...(answer.hasOwnProperty("value") && {value: answer.value === null ? "" : answer?.value})}))})),
    countryCode: countryCode,
    id: "COV01",
    version: "1.0"
  }
  let validationResult = await dispatch(api.validateSurvey(surveyForValidation));
  if(validationResult && validationResult.status === 200){
    let originalSurveyIsEmpty = _.isEmpty(currentSurvey);
    let importedSurvey = originalSurveyIsEmpty ? {} : {...currentSurvey};
    importedSurvey["survey"] = {
      languageId: surveyForValidation.languageId,
      questions: surveyForValidation.questions
    };
    importedSurvey["countryCode"] = countryCode;
    importedSurvey["surveyType"] = surveyType;
    importedSurvey["scopeId"] = scopeId;

    if(originalSurveyIsEmpty) {
      dispatch(createSurvey(importedSurvey))
    } else {
      dispatch(updateSurvey(importedSurvey))
    }
    return true;
  } else if(validationResult && validationResult.response?.data?.status === 400) {
    dispatch(
      error({
        title: i18n.t("error_title"),
        message: validationResult.response?.data?.message || i18n.t("error"),
        position: "tc"
      })
    );
  }
  else {
    return false;
  }
};

export const  clearCohortSurvey = () => dispatch => {
  dispatch(actions.setCohortProfileSurveyData({}));
};
