import React from "react";
import PageTitle from "components/PageTitle";
import Text from "components/Text";
import Wrapper from "components/Wrapper";
import "./help.scss";
import { useTranslation } from "react-i18next";
import {fixture} from 'utils/fixture'

const Help = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle title={t("ask_for_help")} />
      <Wrapper>
        <Text as="p" className="text">
          {t("ask_for_help_help_center")}{" "}
          <a
            className="link"
            href={fixture.url.helpCenter}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("ask_for_help_help_center_link")}
          </a>
          .
        </Text>
        <Text as="p">
          {t("ask_for_help_contact")}{" "}
          <a
            className="link"
            href={fixture.url.support}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("ask_for_help_contact_link")}
          </a>
          .
        </Text>
      </Wrapper>
    </>
  );
};

export default Help;
