import React from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import Button from "components/Button";
import Title from "components/Title";
import { ModalActions } from "components/Modal";
import { useTranslation } from "react-i18next";
import "./confirmPopup.scss";

export default function ConfirmPopup(props) {
  const {
    visible,
    loading,
    title = "",
    message = "",
    confirm = () => {},
    cancel = () => {},
    buttonText = {}
  } = props;

  const { t } = useTranslation();
  return (
    <Modal visible={visible} className="confirm">
      {title && <Title>{title}</Title>}
      {message}
      <ModalActions className="confirm-popup-buttons">
        <Button variant="secondary-confirm" onClick={cancel}>
          {buttonText.cancel || t("cancel")}
        </Button>
        <Button variant="primary-confirm" loading={loading} onClick={confirm}>
          {buttonText.confirm || t("yes")}
        </Button>
      </ModalActions>
    </Modal>
  );
}

ConfirmPopup.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element
  ]).isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};
