import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import React from "react";
import { formatName } from "utils/formatData";
import { useTranslation } from "react-i18next";
import { fixture } from 'utils/fixture'
import "./QrCode.scss";

const QrCode = ({ code, type, data = {}, onlyQrCode = false}) => {
  let name = formatName(fixture.project.name);
  const { t } = useTranslation();

  return onlyQrCode ? (
    <div className="qrCode-image">
      <QRCode
        value={code}
        renderAs={"svg"}
        size={186}
        level={"H"}
        includeMargin={true}
        bgColor={"#e3f2ff"}
      />
    </div>
  ) : (
    <div className="qr-page_container">
      <div className="qr-page">
        {type === "testRecipient" ? (
          <h1 className="title title--test-recipient">
            {t("test_recipient_qr_code")}
          </h1>
        ) : type === "certificate" ? (
          <h1 className="title title--test-results">{t("certificate")}</h1>
        ) : (
          <h1 className="title title--profile">
            {name[0]}
            <span className="tm">{name[1]}</span>
            {name[2]}
            <span>{` ${data.title}`}</span>
          </h1>
        )}

        <div className="qr-page_qr-image">
          <QRCode
            value={code}
            renderAs={"svg"}
            size={240}
            level={"H"}
            includeMargin={true}
          />
        </div>

        {type === "testRecipient" ? (
          <div className="data-container--patient">
            <div className="data-container_row">
              <p className="data-key">{t("name")}</p>
              <p className="data-value name">{data.patientName}</p>
            </div>
            <div className="data-container_row">
              <p className="data-key">{t("date_of_birth_label")}</p>
              <p className="data-value">{data.patientDateOfBirth}</p>
            </div>
          </div>
        ) : type === "certificate" ? (
          <div className="data-container--test-results">
            <div className="data-container_row">
              <p className="data-key">{t("name")}</p>
              <p className="data-value name">{data.name}</p>
            </div>
            <div className="data-container_row">
              <p className="data-key">{t("date_of_birth_label")}</p>
              <p className="data-value">{data.dateOfBirth}</p>
            </div>
            <div className="data-container_row">
              <p className="data-key">{t("valid_until")}</p>
              <p className="data-value">{data.valid}</p>
            </div>
            <div className="data-container_row">
              <p className="data-key">{t("tested")}</p>
              <p className="data-value">{data.tested}</p>
            </div>
            <div className="data-container_row">
              <p className="data-key">{t("test_name")}</p>
              <p className="data-value">{data.testName}</p>
            </div>
            <div className="data-container_row">
              <p className="data-key">{t("test_type")}</p>
              <p className="data-value">{data.testType}</p>
            </div>
            <div className="data-container_row">
              <p className="data-key">{t("sample_id")}</p>
              <p className="data-value">{data.sampleId}</p>
            </div>
            <div className="data-container_row">
              <p className="data-key">{t("result")}</p>
              <p className="data-value">{data.result}</p>
            </div>
          </div>
        ) : (
          <div className="data-container--profiles">
            <h2>{data.institutionName}</h2>
            <p>{data.institutionAddress}</p>
            <p className="link">{data.institutionWebLink}</p>
            <p>{data.institutionPhoneNumber}</p>
            <br />
            {data.eventDate && <p>{data.eventDate}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

QrCode.propTypes = {
  code: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object
};

export default QrCode;
