import {
  SET_PATIENTS,
  SET_MEMBERS,
  SET_SELECTED_PATIENT,
  CREATE_PATIENT,
  UPDATE_PATIENTS,
  UPDATE_PATIENT,
  SET_TEST_RESULTS,
  SHOW_PATIENT_FORM,
  HIDE_PATIENT_FORM,
  SET_FILTERED_PATIENTS,
  SET_SELECTED_PATIENT_SURVEY,
  SET_SURVEY_ANSWERED,
  CLEAR_FILTERED_PATIENTS,
  CLEAR_PATIENTLIST
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";
import { formatDate, formatPhone, formatCountry } from "utils/formatData";

const initialState = {
  patientList: [],
  filteredPatientList: [],
  selectedPatientData: {},
  selectedPatientSurvey: {},
  isAddingPatient: false
};

const patients = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PATIENTS: {
      const { patients, countries } = payload;
      const mappedData = patients.map(item => {
        return {
          ...item,
          firstName: item.patientProfile.profile.firstName,
          lastName: item.patientProfile.profile.lastName,
          dateOfBirth: formatDate(item.patientProfile.profile.dateOfBirth),
          phoneNumber: formatPhone(item.patientProfile.profile.phoneNumber),
          zipCode: item.patientProfile.profile.zipCode,
          email: item.patientProfile.configuration.email || "",
          country: formatCountry(
            item.patientProfile.configuration.country,
            countries
          )
        };
      });
      return { ...state, patientList: [...mappedData] };
    }
    case SET_MEMBERS: {
      const { members, countries } = payload;
      const mappedData = members.map(item => {
        return {
          ...item,
          firstName: item.profile.firstName,
          lastName: item.profile.lastName,
          dateOfBirth: formatDate(item.profile.dateOfBirth),
          phoneNumber: formatPhone(item.profile.phoneNumber),
          zipCode: item.profile.zipCode,
          email: item.configuration.email || "",
          country: formatCountry(
            item.configuration.country,
            countries
          )
        };
      });
      return { ...state, patientList: [...mappedData] };
    }
    case CLEAR_PATIENTLIST: {
      return {
        ...state,
        patientList: []
      };
    }
    case SET_SELECTED_PATIENT: {
      const { patientData } = payload;
      return {
        ...state,
        selectedPatientData: patientData
      };
    }
    case CREATE_PATIENT: {
      const { patient, countries } = payload;
      let newPatient = {
        ...patient,
        dateOfBirth: formatDate(patient.dateOfBirth),
        phoneNumber: formatPhone(patient.phoneNumber),
        country: formatCountry(patient.country, countries)
      };
      return {
        ...state,
        patientList: [{ ...newPatient }, ...state.patientList]
      };
    }
    case SET_FILTERED_PATIENTS: {
      const { patientList } = payload;
      return {
        ...state,
        filteredPatientList: [...state.filteredPatientList, ...patientList]
      };
    }
    case CLEAR_FILTERED_PATIENTS: {
      return {
        ...state,
        filteredPatientList: []
      };
    }
    case UPDATE_PATIENTS: {
      const currentPatients = state.patientList;
      const { patient } = payload;
      let newPatients = currentPatients.map(p =>
        p.patientId === payload.id
          ? {
              ...p,
              firstName: patient.profile.firstName,
              lastName: patient.profile.lastName,
              dateOfBirth: formatDate(patient.profile.dateOfBirth),
              phoneNumber: formatPhone(patient.profile.phoneNumber),
              zipCode: patient.profile.zipCode,
              email: patient.configuration.email || "",
              patientProfile: { ...p.patientProfile, ...payload.patient }
            }
          : p
      );

      return { ...state, patientList: [...newPatients] };
    }
    case UPDATE_PATIENT: {
      const { selectedPatientData } = state;
      // const { serverTime, testResults } = selectedPatientData;
      const { patient } = payload;
      return {
        ...state,
        selectedPatientData: {
          ...selectedPatientData,
          ...patient
        }
      };
    }
    case SET_SELECTED_PATIENT_SURVEY: {
      const { survey } = payload;
      return {
        ...state,
        selectedPatientSurvey: survey
      };
    }
    case SET_TEST_RESULTS: {
      const { selectedPatientData } = state;
      const { serverTime, testResults } = payload;
      return {
        ...state,
        selectedPatientData: {
          ...selectedPatientData,
          serverTime: serverTime,
          testResults: testResults
        }
      };
    }
    case SET_SURVEY_ANSWERED: {
      const { selectedPatientData } = state;
      const { testResults } = selectedPatientData;
      return {
        ...state,
        selectedPatientData: {
          ...selectedPatientData,
          testResults: testResults.map(result =>
            result.testId === payload
              ? { ...result, isSurveyAnswered: true }
              : result
          )
        }
      };
    }
    case SHOW_PATIENT_FORM: {
      return {
        ...state,
        isAddingPatient: true
      };
    }
    case HIDE_PATIENT_FORM: {
      return {
        ...state,
        isAddingPatient: false
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default patients;
