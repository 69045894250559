export const SET_PATIENTS = "SET_PATIENTS";
export const UPDATE_PATIENTS = "UPDATE_PATIENTS";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const SET_FILTERED_PATIENTS = "SET_FILTERED_PATIENTS";
export const SET_SELECTED_PATIENT = "SET_SELECTED_PATIENT";
export const SET_TEST_RESULTS = "SET_TEST_RESULTS";
export const SHOW_PATIENT_FORM = "SHOW_PATIENT_FORM";
export const HIDE_PATIENT_FORM = "HIDE_PATIENT_FORM";
export const SET_SELECTED_PATIENT_SURVEY = "SET_SELECTED_PATIENT_SURVEY";
export const SET_SURVEY_ANSWERED = "SET_SURVEY_ANSWERED";
export const CLEAR_FILTERED_PATIENTS = "CLEAR_FILTERED_PATIENTS";
export const SET_MEMBERS = "SET_MEMBERS";
export const CLEAR_PATIENTLIST = "CLEAR_PATIENTLIST";
