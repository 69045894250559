import React from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import Error from "components/Error";
import { ModalActions } from "components/Modal";
import { noWhiteSpacesForRequiredFields } from "utils/FieldValidationRules";
import { useTranslation } from "react-i18next";
import SelectOption from "components/SelectOption";

export default function OrganizationForm(props) {
  const {
    hide = () => {},
    organizationId = null,
    institutionName = "",
    properties = {},
    loading,
    countries
  } = props;
  const { t } = useTranslation();

  const countryOptions = countries
    ?.filter(({ serverId }) => serverId === window.config.REACT_APP_REGION)
    .map(({ countryName, countryCode }) => ({
      value: countryCode,
      label: countryName
    }));

  const { register, handleSubmit, errors, formState, control } = useForm({
    mode: "onChange",
    defaultValues: {
      institutionName,
      reference: properties.sapNumber,
      countryCode: properties.countryCode
    }
  });

  const onSubmit = params => {
    props.onSubmit({ ...params, organizationId });
  };

  return (
    <Modal visible={true}>
      <Title>
        {organizationId !== null
          ? t("health_care_organizations_actions_edit")
          : t("health_care_organizations_add_modal_confirm")}{" "}
        {t("organization")}
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="institutionName" error={errors.institutionName}>
            {t("health_care_organizations_add_modal_name")}
          </Label>
          <Input
            type="text"
            name="institutionName"
            error={errors.institutionName}
            ref={register({
              validate: noWhiteSpacesForRequiredFields,
              required: t("form_field_is_required"),
              maxLength: {
                value: 255,
                message: t("field_max_length", { number_of_characters: 255 })
              }
            })}
          />
          {errors.institutionName && (
            <Error>{errors.institutionName.message}</Error>
          )}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="reference" error={errors.reference}>
            {t("health_care_organizations_add_modal_reference")}
          </Label>
          <Input
            type="text"
            name="reference"
            error={errors.reference}
            ref={register({
              maxLength: {
                value: 24,
                message: t("field_max_length", { number_of_characters: 24 })
              }
            })}
          />
          {errors.reference && <Error>{errors.reference.message}</Error>}
        </FormGroup>
        <FormGroup>
          <Label>{t("health_care_organizations_add_modal_country")}</Label>
          <Controller
            as={
              <SelectOption
                isSearchable
                options={countryOptions ?? []}
                error={errors.countryCode}
                placeholder={t("select")}
              />
            }
            rules={{
              required: "countryCode" && t("form_field_is_required")
            }}
            onChange={([selected]) => selected?.value}
            control={control}
            name="countryCode"
          />
          {errors["countryCode"] && (
            <Error>{errors["countryCode"].message}</Error>
          )}
        </FormGroup>
        <ModalActions>
          <Button
            variant="primary-full-width"
            type="submit"
            loading={loading}
            className="margin-top"
            disabled={!formState.isValid}
          >
            {organizationId !== null
              ? t("save")
              : t("health_care_organizations_add_modal_confirm")}
          </Button>
          <Button variant="cancel-text" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
