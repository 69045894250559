import axios from "utils/Axios";
import { makeExports, getExportDetails } from "utils/mockData";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";

export const getExports = () => {
  return makeAxiosRequest("getExports", {
    client: axios,
    method: "get",
    relativePath: `/export`
  });
};

export const getMockedExports = () => {
  return makeRequest("getExports", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeExports(20);
            resolve({ data: { accessProfiles: mockData } });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const createExport = ({
  organizationIdList,
  exportType,
  intervalStart,
  intervalEnd,
  profileIdList,
  userTimeZone
}) => {
  return makeAxiosRequest("createExport", {
    client: axios,
    method: "post",
    relativePath: `/export`,
    data: {
      organizationIdList,
      exportType,
      intervalStart,
      intervalEnd,
      profileIdList,
      userTimeZone
    }
  });
};

export const createCountryExport = ({
  organizationIdList,
  exportType,
  intervalStart,
  intervalEnd,
  testTypeList,
  userTimeZone,
  countryCode
}) => {
  return makeAxiosRequest("createCountryExport", {
    client: axios,
    method: "post",
    relativePath: `/export`,
    data: {
      organizationIdList,
      exportType,
      intervalStart,
      intervalEnd,
      testTypeList,
      userTimeZone,
      countryCode
    }
  });
};

export const createMockedExport = () => {
  return makeRequest("createExport", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = getExportDetails();
            resolve({
              data: { status: 200, data: mockData }
            });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const getDownloadUrl = id => {
  return makeAxiosRequest("getDownloadUrl", {
    client: axios,
    method: "get",
    relativePath: `/export/${id}/downloadUrl`
  });
};

export const getMockedDownloadUrl = () => {
  return makeRequest("getDownloadUrl", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              data: {
                status: 200,
                data: {
                  downloadUrl: "https://www.google.com/"
                }
              }
            });
          }, 1000);
        });
      }
    },
    query: ""
  });
};
