import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const SelectWrapper = ({
  options = [],
  value,
  defaultValue,
  isSearchable = false,
  isMulti = false,
  ...props
}) => {
  let selected;
  let defaultSelected;
  if (isMulti) {
    selected = value && value.map(v => options.find(o => o.value === v));
    defaultSelected =
      defaultValue && defaultValue.map(v => options.find(o => o.value === v));
  } else {
    selected = options.find(o => o.value === value);
    defaultSelected = options.find(o => o.value === defaultValue);
  }
  const styles =
    props.id !== "qrCodeSelector"
      ? {
          container: style => ({
            ...style,
            display: "inline-block",
            minWidth: "150px"
          }),
          control: style => ({
            ...style,
            border: "none",
            boxShadow: "none",
            minHeight: "auto"
          }),
          indicatorsContainer: style => ({
            ...style,
            flexDirection: "row-reverse"
          }),
          indicatorSeparator: style => ({ ...style, display: "none" }),
          singleValue: style => ({
            ...style,
            position: "static",
            maxWidth: "auto",
            transform: "none",
            marginLeft: 0
          }),
          dropdownIndicator: style => ({
            ...style,
            color: "#4a4a4a",
            padding: 0
          }),
          loadingIndicator: style => ({ ...style, color: "#4a4a4a" }),
          placeholder: style => ({
            ...style,
            position: "static",
            color: "#4a4a4a",
            maxWidth: "auto",
            transform: "none",
            marginLeft: 0
          }),
          valueContainer: style => ({
            ...style,
            paddingLeft: 0,
            minWidth: "170px"
          })
        }
      : {
          container: style => ({
            ...style,
            display: "inline-block",
            maxWidth: "100px"
          }),
          control: style => ({
            ...style,
            border: "none",
            boxShadow: "none",
            minHeight: "auto",
            backgroundColor: "#e3f2ff"
          }),
          indicatorsContainer: style => ({
            ...style,
            flexDirection: "row-reverse",
            color: "#161515",
            position: "absolute",
            right: "0"
          }),
          indicatorSeparator: style => ({ ...style, display: "none" }),
          singleValue: style => ({
            ...style,
            position: "static",
            maxWidth: "auto",
            transform: "none",
            marginLeft: 0,
            fontSize: "14px",
            color: "#000",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "80px"
          }),
          dropdownIndicator: style => ({
            ...style,
            color: "#000",
            padding: 0
          }),
          loadingIndicator: style => ({
            ...style,
            color: "#4a4a4a",
            position: "absolute",
            right: "-40px"
          }),
          placeholder: style => ({
            ...style,
            position: "static",
            color: "#4a4a4a",
            maxWidth: "auto",
            transform: "none",
            marginLeft: 0,
            fontSize: "14px"
          }),
          valueContainer: style => ({
            ...style,
            paddingLeft: 0,
            width: "100px"
          }),
          menu: style => ({
            ...style,
            width: "155px",
            right: "0px",
            color: "#636363"
          }),
          menuList: style => ({
            ...style,
            padding: "0"
          }),
          option: style => ({
            ...style,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          })
        };

  return (
    <Select
      isClearable={false}
      isSearchable={isSearchable}
      isMulti={isMulti}
      styles={styles}
      options={options}
      value={selected}
      defaultValue={defaultSelected}
      {...props}
    />
  );
};

SelectWrapper.displayName = "Select";

SelectWrapper.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool
};

export default SelectWrapper;
