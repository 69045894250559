import React from "react";
import classNames from "classnames";
import Wrapper from "components/Wrapper";
import "./modal.scss";

const Modal = ({ visible, id, className, children }) =>
  visible ? (
    <div id={id} className={classNames("modal", className)}>
      <Wrapper>{children}</Wrapper>
    </div>
  ) : null;

export default Modal;
