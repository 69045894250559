import Button from "components/Button";
import Error from "components/Error";
import { HomeProfileEditingContext } from "components/HomeTestProfile/HomeTestProfile";
import PageTitle from "components/PageTitle";
import Table from "components/Table";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { noWhiteSpacesForRequiredFields } from "utils/FieldValidationRules";

const HomeTestContacts = ({ testProfile }) => {
  const { t } = useTranslation();
  const { contactDetails } = testProfile;

  const context = React.useContext(HomeProfileEditingContext);

  const isEditing = context?.currentlyEditing.sections?.includes("contacts");

  const handleClick = e => {
    e.preventDefault();
    context.setCurrentlyEditing({
      ...context.currentlyEditing,
      sections: [...context.currentlyEditing.sections, "contacts"]
    });
  };

  let contacts = [
    {
      id: "institutionName",
      type: t("contact_channel_organization"),
      data: contactDetails?.institutionName,
      isEditing,
      table: "contacts",
      formId: "contactDetails.institutionName"
    },
    {
      id: "institutionAddress",
      type: t("contact_channel_address"),
      data: contactDetails?.institutionAddress,
      isEditing,
      table: "contacts",
      formId: "contactDetails.institutionAddress"
    },
    {
      id: "institutionWebLink",
      type: t("contact_channel_website"),
      data: contactDetails?.institutionWebLink,
      isEditing,
      table: "contacts",
      formId: "contactDetails.institutionWebLink"
    },
    {
      id: "institutionPhoneNumber",
      type: t("phone_number"),
      data: contactDetails?.institutionPhoneNumber,
      isEditing,
      table: "contacts",
      formId: "contactDetails.institutionPhoneNumber"
    }
  ];

  const contactColumns = React.useMemo(
    () => [
      {
        Header: t("admin_table_channel"),
        className: "channels",
        accessor: "type",
        style: { width: "20%" },
        Cell: ({ value }) => <p>{value}</p>
      },
      {
        Header: t("admin_table_contact"),
        accessor: "data",
        Cell: ({ row, value, column }) => {
          const { register, formState, errors } = useFormContext();
          const { dirtyFields } = formState;
          const maxLength =
            row.original.id === "institutionPhoneNumber" ? 30 : 128;

          if (row.original.isEditing) {
            return (
              <>
                <textarea
                  name={row.original.formId}
                  data-testid="text-row"
                  rows={2}
                  ref={register({
                    validate: noWhiteSpacesForRequiredFields,
                    required: t("form_field_is_required"),
                    maxLength: {
                      value: maxLength,
                      message: t("field_max_length", {
                        number_of_characters: maxLength
                      })
                    }
                  })}
                  style={{
                    backgroundColor:
                      dirtyFields.has(row.original.formId) && "#fffcda"
                  }}
                  className="table-cell-input"
                />
                {errors?.contactDetails?.[row.original.id] && (
                  <Error>
                    {errors?.contactDetails?.[row.original.id]?.message}
                  </Error>
                )}
              </>
            );
          }

          return <p>{value}</p>;
        }
      }
    ],
    [t]
  );

  return (
    <>
      <div className="title-top contact-title">
        <PageTitle title={t("contact_information_title")}></PageTitle>

        <Button
          variant="primary"
          className="header-add-button"
          data-testid="edit-button"
          onClick={handleClick}
          disabled={isEditing}
        >
          {t("edit")}
        </Button>
      </div>
      <Table
        columns={contactColumns}
        data={contacts}
        className={"home-test-profile home-test-contacts"}
        disabledSort={true}
      />{" "}
    </>
  );
};

export default HomeTestContacts;
