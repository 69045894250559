import React from "react";
import Label from "components/Label";
import { connect } from "react-redux";
import FormGroup from "components/FormGroup";
import { useTranslation } from "react-i18next";
import SelectOption from "components/SelectOption";
import { Controller } from "react-hook-form";
import Error from "components/Error";

function SelectCountry({
  name = "country",
  useForm: { errors, control, triggerValidation },
  label = "health_care_organizations_add_modal_country",
  placeHolder = "select",
  disabled,
  countries,
  required,
  selectProps
}) {
  const { t } = useTranslation();
  const countryAdminRef = React.useRef(null);
  const countryOptions = countries
    ?.filter(({ serverId }) => serverId === window.config.REACT_APP_REGION)
    .map(({ countryName, countryCode }) => ({
      value: countryCode,
      label: countryName
    }));

  return (
    <FormGroup>
      <Label htmlFor={name} error={errors[name]}>
        {t(label)}
        {required && "*"}
      </Label>
      <Controller
        control={control}
        name={name}
        as={
          <SelectOption
            ref={countryAdminRef}
            isSearchable
            options={countryOptions}
            error={errors[name]}
            placeholder={t(placeHolder)}
            disabled={disabled}
            {...selectProps}
          />
        }
        rules={required && { required: t("form_field_is_required") }}
        onChange={([selected]) => selected?.value}
      />
      {errors[name] && <Error>{errors[name].message}</Error>}
    </FormGroup>
  );
}

const mapStateToProps = state => ({
  countries: state.config.countries
});

export default connect(mapStateToProps)(SelectCountry);
