import React from "react";
import Button from "components/Button";
import EditableCell from "components/EditableCell";
import PageTitle from "components/PageTitle";
import Table from "components/Table";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { CohortEditingContext } from "components/CohortProfiles/CohortProfiles";
import Error from "components/Error";
import "./cohortProfileContacts.scss";
import { useTranslation } from "react-i18next";

const defaultColumn = {
  Cell: EditableCell
};

const CohortProfileContacts = ({ cohort, contactDetails }) => {
  const { t } = useTranslation();

  const context = React.useContext(CohortEditingContext);

  const contactsAreEditing = context?.currentlyEditing?.sections?.includes(
    "contacts"
  );
  let cohortContacts = [
    {
      id: "institutionName",
      type: t("enterprise_name"),
      data: contactDetails?.institutionName,
      isEditing: contactsAreEditing,
      table: "contacts",
      formId: "contactDetails.institutionName"
    },
    {
      id: "institutionAddress",
      type: t("contact_channel_address"),
      data: contactDetails?.institutionAddress,
      isEditing: contactsAreEditing,
      table: "contacts",
      formId: "contactDetails.institutionAddress"
    },
    {
      id: "institutionEmailAddress",
      type: t("contact_channel_website"),
      data: contactDetails?.institutionEmailAddress,
      isEditing: contactsAreEditing,
      table: "contacts",
      formId: "contactDetails.institutionEmailAddress"
    },
    {
      id: "institutionPhoneNumber",
      type: t("phone_number"),
      data: contactDetails?.institutionPhoneNumber,
      isEditing: contactsAreEditing,
      table: "contacts",
      formId: "contactDetails.institutionPhoneNumber"
    }
  ];

  const cohortProfilesColumns = React.useMemo(
    () => [
      {
        Header: t("admin_table_channel"),
        className: "channels",
        accessor: "type",
        Cell: ({ value }) => <p>{value}</p>
      },
      {
        Header: t("admin_table_contact"),
        accessor: "data",
        Cell: ({ row, value, column }) => {
          const { register, formState, errors } = useFormContext();
          const { dirtyFields } = formState;

          if (row.original.isEditing) {
            return (
              <>
                <textarea
                  name={row.original.formId}
                  rows={2}
                  defaultValue={value}
                  ref={register({
                    maxLength: {
                      value: 128,
                      message: t("field_max_length", {
                        number_of_characters: 128
                      })
                    }
                  })}
                  style={{
                    backgroundColor: dirtyFields.has(row.original.formId)
                      ? "#fffcda"
                      : ""
                  }}
                  className="table-cell-input"
                />
                {errors?.contactDetails?.[row.original.id] && (
                  <Error>
                    {errors?.contactDetails?.[row.original.id]?.message}
                  </Error>
                )}
              </>
            );
          }
          return <p>{value}</p>;
        }
      }
    ],
    [t]
  );
  return (
    <React.Fragment>
      <div className="title-top">
        <PageTitle title={t("contact_information_title")}></PageTitle>

        <Button
          variant="primary"
          className="header-add-button"
          onClick={e => {
            e.preventDefault();
            context.setCurrentlyEditing(prevState => {
              return {
                ...prevState,
                sections: [...prevState.sections, "contacts"]
              };
            });
          }}
          disabled={contactsAreEditing}
        >
          {t("edit")}
        </Button>
      </div>
      <Table
        columns={cohortProfilesColumns}
        data={cohortContacts}
        className={"test-profiles-contacts-table"}
        defaultColumn={defaultColumn}
        disabledSort={true}
      />
    </React.Fragment>
  );
};

CohortProfileContacts.propTypes = {
  selectedCohortProfile: PropTypes.object,
  saveContactData: PropTypes.func,
  contactUpdating: PropTypes.bool
};

export default CohortProfileContacts;
