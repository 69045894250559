import React from "react";
import "./patientAccordion.scss";
import { connect } from "react-redux";
import Icon from "components/Icon";
import { useTranslation } from "react-i18next";

const PatientAccordion = ({
  filteredPatients,
  expanded,
  setExpanded,
  selectPatient,
  countries
}) => {
  const menuSelected = index => expanded === index;
  const { t } = useTranslation();

  const getPatientSelected = (index, patientSelected) => {
    selectPatient(patientSelected);
    menuSelected(index) ? setExpanded(false) : setExpanded(index);
  };
  const findCountryName = code => {
    return countries?.find(item => item.countryCode === code)?.countryName || "";
  };

  const patients = filteredPatients;

  return (
    <div className="accordion-container" data-testid="patient-accordion">
      {patients?.map((patient, i) => {
        const { profile, configuration } = patient;
        return (
          <div
            key={i}
            className={`accordion-item ${menuSelected(i) ? "selected" : ""}`}
            onClick={() => getPatientSelected(i, patient)}
          >
            <div className={`content ${menuSelected(i) ? "expanded" : ""}`}>
              <p className="name">
                {" "}
                {t("name_record_colon")} {`${profile?.firstName} ${profile?.lastName}`}
              </p>
              <p> {t("DOB_record_colon")} {profile?.dateOfBirth}</p>
              <p> {t("country_record_colon")} {findCountryName(configuration.country)}</p>
              {menuSelected(i) && (
                <div>
                  <p> {t("ZipCode_record_colon")} {profile?.zipCode && profile?.zipCode}</p>
                  <p>
                    {" "}
                    {t("PhoneNumber_record_colon")} {" "}
                    {profile?.phoneNumber &&
                      `${profile?.phoneNumber?.countryCode} ${profile?.phoneNumber?.number}`}
                  </p>
                  <p> {t("EmailAddress_record_colon")} {configuration.email}</p>
                </div>
              )}
            </div>
            <div className="icon">
              <Icon
                type={`${menuSelected(i) ? "chevronDown" : "chevronRight"}`}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = state => ({
  countries: state.config.countries
});

export default connect(mapStateToProps)(PatientAccordion);
