import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import TestProfiles from "components/TestProfiles";
import {
  getTestingProfiles,
  setTestingProfileForEdit,
  createTestingProfile,
  deleteTestingProfile
} from "store/reducers/qrCodes/services";
import {
  getTestingProfileDetails,
  setTestingProfileDataForEdit,
  saveContactData,
  updateMetaData,
  cancelUpdate,
  saveProfileData,
  duplicateTestingProfile,
  createInfinityIntegration
} from "store/reducers/qrCodeData/services";

const mapStateToProps = state => ({
  qrCodes: state.qrCodes,
  testingProfilesLoading: isLoading(state, "getTestingProfiles"),
  testingProfileUpdating: isLoading(state, "updateTestingProfile"),
  qrCodeDetails: state.qrCodeData,
  testingProfileDetailsLoading: isLoading(state, "getTestingProfileDetails"),
  propertiesSaving: isLoading(state, "updateQrProperties"),
  roles: state.user.roles,
  testingProfileSaving: isLoading(state, "createTestingProfile"),
  testingProfileDeleting: isLoading(state, "deleteTestingProfile"),
  fieldErrors: state?.qrCodeData?.metaData?.fieldErrors || {}
});

const mapDispatchToProps = {
  getTestingProfiles,
  updateMetaData,
  getTestingProfileDetails,
  setTestingProfileForEdit,
  setTestingProfileDataForEdit,
  createTestingProfile,
  deleteTestingProfile,
  saveContactData,
  saveProfileData,
  cancelUpdate,
  duplicateTestingProfile,
  createInfinityIntegration
};

export default connect(mapStateToProps, mapDispatchToProps)(TestProfiles);
