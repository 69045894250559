import React from "react";
import CohortDataExportContainer from "../../containers/CohortDataExport.Container";

const CohortDataExport = () => (
  <>
    <CohortDataExportContainer />
  </>
);

export default CohortDataExport;
