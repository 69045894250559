import React from "react";
import { ModalActions } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import PatientAccordion from "components/PatientAccordion";
import Modal from "components/Modal";
import "./patientExistsModal.scss";

const PatientExistsModal = ({
  filteredPatients,
  onCreateNewAccount,
  onLinkAccount,
  onCancel
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const [selectedPatient, setSelectedPatient] = React.useState(null);

  return (
    <Modal
      visible={true}
      className="existing-user-modal"
      data-testid="existing-user-modal"
    >
      <Title>{t("discovered_accounts_title")}</Title>
      <p className="sub-title">{t("multiple_account_found")}</p>
      <PatientAccordion
        expanded={expanded}
        setExpanded={setExpanded}
        filteredPatients={filteredPatients}
        selectPatient={setSelectedPatient}
      />
      <ModalActions>
        <Button
          variant="primary-full-width"
          className="margin-top--medium"
          type="submit"
          onClick={() => onLinkAccount(selectedPatient)}
          // loading={loading}
          disabled={typeof expanded !== "number"}
        >
          {t("import_user")}
        </Button>
        <Button
          variant="outlined"
          type="button"
          className="margin-top"
          onClick={onCreateNewAccount}
          disabled={typeof expanded === "number"}
        >
          {t("create_new_account")}
        </Button>
        <Button
          variant="cancel-text"
          type="button"
          className="margin-top"
          onClick={onCancel}
        >
          {t("cancel")}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default PatientExistsModal;
