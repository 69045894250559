import i18n from "../translations";

export let noWhiteSpacesForRequiredFields = value =>
  !value.trim() ? "Field is required" : undefined;

export let validationRules = {
  name: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 128,
      message: "The field must contain at most 128 characters"
    }
  },
  description: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 255,
      message: "The field must contain at most 255 characters"
    }
  },
  institutionName: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 128,
      message: "The field must contain at most 128 characters"
    }
  },
  institutionAddress: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 128,
      message: "The field must contain at most 128 characters"
    }
  },
  institutionWebLink: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 128,
      message: "The field must contain at most 128 characters"
    }
  },
  institutionPhoneNumber: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 30,
      message: "The field must contain at most 30 characters"
    }
  },
  testTypeName: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 36,
      message: "The field must contain at most 36 characters"
    }
  },
  testTypeDescription: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 512,
      message: "The field must contain at most 512 characters"
    }
  },
  beforeValidHeaderText: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 20,
      message: "The field must contain at most 20 characters"
    }
  },
  beforeValidDetailText: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 70,
      message: "The field must contain at most 70 characters"
    }
  },
  duringValidHeaderText: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 20,
      message: "The field must contain at most 20 characters"
    }
  },
  duringValidDetailText: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 70,
      message: "The field must contain at most 70 characters"
    }
  },
  afterValidHeaderText: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 20,
      message: "The field must contain at most 20 characters"
    }
  },
  afterValidDetailText: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 70,
      message: "The field must contain at most 70 characters"
    }
  },
  testOutcomeValues_0: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 17,
      message: "The field must contain at most 17 characters"
    }
  },
  testOutcomeValues_1: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 17,
      message: "The field must contain at most 17 characters"
    }
  },
  testOutcomeValues_2: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 17,
      message: "The field must contain at most 17 characters"
    }
  },
  testOutcomeValues_0_activeValue: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 17,
      message: "The field must contain at most 17 characters"
    }
  },
  testOutcomeValues_0_activeValueDescription: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 120,
      message: "The field must contain at most 120 characters"
    }
  },
  testOutcomeValues_0_expiredValue: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 17,
      message: "The field must contain at most 17 characters"
    }
  },
  testOutcomeValues_0_expiredValueDescription: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 120,
      message: "The field must contain at most 120 characters"
    }
  },
  testOutcomeValues_1_activeValue: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 17,
      message: "The field must contain at most 17 characters"
    }
  },
  testOutcomeValues_1_activeValueDescription: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 120,
      message: "The field must contain at most 120 characters"
    }
  },
  testOutcomeValues_1_expiredValue: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 17,
      message: "The field must contain at most 17 characters"
    }
  },
  testOutcomeValues_1_expiredValueDescription: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 120,
      message: "The field must contain at most 120 characters"
    }
  },
  testOutcomeValues_2_activeValue: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 17,
      message: "The field must contain at most 17 characters"
    }
  },
  testOutcomeValues_2_activeValueDescription: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 120,
      message: "The field must contain at most 120 characters"
    }
  },
  testOutcomeValues_2_expiredValue: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 17,
      message: "The field must contain at most 17 characters"
    }
  },

  testOutcomeValues_2_expiredValueDescription: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 120,
      message: "The field must contain at most 120 characters"
    }
  },

  pendingStateActive: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 17,
      message: "The field must contain at most 17 characters"
    }
  },

  pendingStateActiveDescription: {
    validate: noWhiteSpacesForRequiredFields,
    required: "Field is required",
    maxLength: {
      value: 120,
      message: "The field must contain at most 120 characters"
    }
  }
};

i18n.on("initialized", () => {
  noWhiteSpacesForRequiredFields = value =>
    !value.trim() ? i18n.t("form_field_is_required") : undefined;
  validationRules = {
    name: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 128,
        message: i18n.t("field_max_length", { number_of_characters: 128 })
      }
    },
    description: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 255,
        message: i18n.t("field_max_length", { number_of_characters: 255 })
      }
    },
    institutionName: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 128,
        message: i18n.t("field_max_length", { number_of_characters: 128 })
      }
    },
    institutionAddress: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 128,
        message: i18n.t("field_max_length", { number_of_characters: 128 })
      }
    },
    institutionWebLink: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 128,
        message: i18n.t("field_max_length", { number_of_characters: 128 })
      }
    },
    institutionPhoneNumber: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 30,
        message: i18n.t("field_max_length", { number_of_characters: 30 })
      }
    },
    testTypeName: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 36,
        message: i18n.t("field_max_length", { number_of_characters: 36 })
      }
    },
    testTypeDescription: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 512,
        message: i18n.t("field_max_length", { number_of_characters: 512 })
      }
    },
    beforeValidHeaderText: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 20,
        message: i18n.t("field_max_length", { number_of_characters: 20 })
      }
    },
    beforeValidDetailText: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 70,
        message: i18n.t("field_max_length", { number_of_characters: 70 })
      }
    },
    duringValidHeaderText: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 20,
        message: i18n.t("field_max_length", { number_of_characters: 20 })
      }
    },
    duringValidDetailText: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 70,
        message: i18n.t("field_max_length", { number_of_characters: 70 })
      }
    },
    afterValidHeaderText: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 20,
        message: i18n.t("field_max_length", { number_of_characters: 20 })
      }
    },
    afterValidDetailText: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 70,
        message: i18n.t("field_max_length", { number_of_characters: 70 })
      }
    },
    testOutcomeValues_0: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 17,
        message: i18n.t("field_max_length", { number_of_characters: 17 })
      }
    },
    testOutcomeValues_1: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 17,
        message: i18n.t("field_max_length", { number_of_characters: 17 })
      }
    },
    testOutcomeValues_2: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 17,
        message: i18n.t("field_max_length", { number_of_characters: 17 })
      }
    },
    testOutcomeValues_0_activeValue: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 17,
        message: i18n.t("field_max_length", { number_of_characters: 17 })
      }
    },
    testOutcomeValues_0_activeValueDescription: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 1024,
        message: i18n.t("field_max_length", { number_of_characters: 1024 })
      }
    },
    testOutcomeValues_0_expiredValue: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 17,
        message: i18n.t("field_max_length", { number_of_characters: 17 })
      }
    },
    testOutcomeValues_0_expiredValueDescription: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 1024,
        message: i18n.t("field_max_length", { number_of_characters: 1024 })
      }
    },
    testOutcomeValues_1_activeValue: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 17,
        message: i18n.t("field_max_length", { number_of_characters: 17 })
      }
    },
    testOutcomeValues_1_activeValueDescription: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 1024,
        message: i18n.t("field_max_length", { number_of_characters: 1024 })
      }
    },
    testOutcomeValues_1_expiredValue: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 17,
        message: i18n.t("field_max_length", { number_of_characters: 17 })
      }
    },
    testOutcomeValues_1_expiredValueDescription: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 1024,
        message: i18n.t("field_max_length", { number_of_characters: 1024 })
      }
    },
    testOutcomeValues_2_activeValue: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 17,
        message: i18n.t("field_max_length", { number_of_characters: 17 })
      }
    },
    testOutcomeValues_2_activeValueDescription: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 1024,
        message: i18n.t("field_max_length", { number_of_characters: 1024 })
      }
    },
    testOutcomeValues_2_expiredValue: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 17,
        message: i18n.t("field_max_length", { number_of_characters: 17 })
      }
    },

    testOutcomeValues_2_expiredValueDescription: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 1024,
        message: i18n.t("field_max_length", { number_of_characters: 1024 })
      }
    },

    pendingStateActive: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 17,
        message: i18n.t("field_max_length", { number_of_characters: 17 })
      }
    },

    pendingStateActiveDescription: {
      validate: noWhiteSpacesForRequiredFields,
      required: i18n.t("form_field_is_required"),
      maxLength: {
        value: 1024,
        message: i18n.t("field_max_length", { number_of_characters: 1024 })
      }
    }
  };
});
