import React from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import PageTitle from "components/PageTitle";
import Spinner from "components/Spinner";
import Link from "components/Link";
import { clearAWSCache } from "utils/aws-tools";
import { useTranslation } from "react-i18next";

const SignOut = ({ removeLoggedUser, history }) => {
  const { t } = useTranslation();
  const [loading, setLoadingStatus] = React.useState(false);

  React.useEffect(() => {
    setLoadingStatus(true);
    clearAWSCache();
    Auth.signOut({ global: true })
      .then(() => {
        removeLoggedUser();
        history.push("/sign-in");
      })
      .catch(err => {
        if (err && err.code === "NotAuthorizedException") {
          Auth.signOut().then(() => {
            removeLoggedUser();
            history.push("/sign-in");
          });
        }
        setLoadingStatus(false);
      });
  }, [history, setLoadingStatus, removeLoggedUser]);

  return (
    <div className="page-not-found">
      {loading && <Spinner />}
      <PageTitle
        title={t("sign_out_in progress")}
        subtitle={t("sign_out_redirect")}
      />
      {!loading && (
        <Link className="p" to="/">
          {t("back_to_home")}
        </Link>
      )}
    </div>
  );
};

export default withRouter(SignOut);
