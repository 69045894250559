import React from "react";
import { useForm } from "react-hook-form";
import Error from "components/Error";
import TimeInput from "components/TimeInput";

import "./EditableCell.scss";
import { validationRules } from "../../utils/FieldValidationRules";
import { useDispatch } from "react-redux";
import { setFieldErrors } from "store/reducers/qrCodeData/actions";
import { useTranslation } from "react-i18next";

export default function EditableCell({
  value: initialValue,
  row: { index, original },
  column: { id },
  column,
  row,
  inputType,
  updateMyData,
  className,
  badgeValue
}) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(initialValue || "");
  const [valueChanged, setValueChanged] = React.useState(false);
  const [timeError, setTimeError] = React.useState("");
  const { register, errors, triggerValidation } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur"
  });

  const dispatch = useDispatch();
  const qrTableCell =
    className && className === "qr-codes-table" ? true : false;
  const contactsTableCell = original?.table === "contacts" ? true : false;
  const testTypeTableCell =
    className && className === "test-types-table" ? true : false;
  const accessProfilesTableCell =
    className && className === "access-profiles-table" ? true : false;

  const onChange = e => {
    setValue(e.target.value);
    triggerValidation();
  };

  const onChangeNumberInput = e => {
    let { value, min, max } = e.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setValue(parseInt(value));
  };

  const onBlur = () => {
    if (accessProfilesTableCell) {
      if (contactsTableCell) {
        if (inputType === "datePicker") {
          updateMyData(row.original.id, new Date(value).getTime());
          return;
        }
        updateMyData(row.original.id, value);
        return;
      }
      updateMyData(column.id, value);
      return;
    } else {
      dispatch(
        setFieldErrors({
          [contactsTableCell
            ? [original.id]
            : [original[id].validationName || id]]: errors[id]?.message
        })
      );
    }
    if (value !== initialValue && value !== undefined) setValueChanged(true);
    else setValueChanged(false);
    if (contactsTableCell) updateMyData(row.original.id, value);
    else if (qrTableCell) updateMyData(column.id, value);
    else if (testTypeTableCell)
      updateMyData(row.original.testTypeId, column.id, value);
    else original[id] && original[id].updateFunction(value, original[id]?.id);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
    setValueChanged(false);
  }, [initialValue]);

  if (inputType === "datePicker") {
    if (original.isEditing)
      return (
        <input
          type="date"
          onChange={onChange}
          onBlur={onBlur}
          style={valueChanged ? { backgroundColor: "#fffcda" } : null}
          className="date-picker"
          original={original}
          id={id}
          defaultValue={value && new Date(value).toISOString().slice(0, 10)}
        />
      );
    return <p>{value && new Date(value).toISOString().slice(0, 10)}</p>;
  }

  return (
    <>
      {original.isEditing ? (
        <>
          {inputType === "time" ? (
            <>
              <TimeInput
                label={
                  original[id]?.label === "from" ? (
                    <>
                      {t("from")}: <span>HH :</span>
                      <span>MM :</span>
                      <span>SS</span>
                    </>
                  ) : (
                    <>
                      {t("to")}: <span>HH :</span>
                      <span>MM :</span>
                      <span>SS</span>
                    </>
                  )
                }
                type="time"
                time={value}
                style={valueChanged ? { backgroundColor: "#fffcda" } : null}
                className="time-input"
                original={original}
                id={id}
                fromTime={original[id]?.fromTime}
                toTime={original[id]?.toTime}
                setTimeError={setTimeError}
                setFieldErrors={setFieldErrors}
              />
            </>
          ) : inputType === "days" ? (
            <input
              type="number"
              min={0}
              max={999}
              onChange={onChangeNumberInput}
              onBlur={onBlur}
              style={valueChanged ? { backgroundColor: "#fffcda" } : null}
              className="day-hours-input"
              original={original}
              id={id}
              value={value}
            />
          ) : inputType === "hours" ? (
            <input
              type="number"
              min={0}
              max={23}
              onChange={onChangeNumberInput}
              onBlur={onBlur}
              style={valueChanged ? { backgroundColor: "#fffcda" } : null}
              className="day-hours-input"
              original={original}
              id={id}
              value={value}
            />
          ) : (
            <div className="cell-badge">
              <textarea
                rows={2}
                name={id}
                className="table-cell-input"
                value={value || ""}
                onChange={onChange}
                onBlur={onBlur}
                onClick={e => e.stopPropagation()}
                style={valueChanged ? { backgroundColor: "#fffcda" } : null}
                ref={
                  contactsTableCell
                    ? register(validationRules[original.id])
                    : register(
                        validationRules[id] ||
                          validationRules[original[id].validationName]
                      )
                }
              />
              {badgeValue && <span className="badge">{badgeValue}</span>}
              {errors?.[id] && <Error>{errors?.[id]?.message}</Error>}
            </div>
          )}
        </>
      ) : (
        <div className="cell-badge">
          <p>{value}</p>
          {badgeValue && <span className="badge">{badgeValue}</span>}
        </div>
      )}
      {timeError && original[id].label === "from" && <Error>{timeError}</Error>}
    </>
  );
}
