import * as types from "./types";
import { detectUserRoles } from "utils/roleManager";

const initialState = {};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOGIN_SUCCESS: {
      const { username, ...user } = payload.user;
      return {
        ...user,
        username,
        id: username
      };
    }
    case types.LOGOUT_USER: {
      return initialState;
    }
    case types.UPDATE_CONTACTS: {
      return {
        ...state,
        ...payload
      };
    }
    case types.UPDATE_USER_ATTRIBUTES: {
      const { attributes } = payload;
      const stateAttributes = state.attributes;
      return {
        ...state,
        attributes: {
          ...stateAttributes,
          ...attributes
        }
      };
    }
    case types.UPDATE_USER_SESSION: {
      const { signInUserSession } = payload;
      return {
        ...state,
        signInUserSession
      };
    }
    case types.GET_SIGNUP_CODE: {
      const { signupCode } = payload;
      return { ...state, signupCode };
    }
    case types.SET_USER_DATA: {
      const { roles, hcpId, ...data } = payload;
      if (roles) {
        const detected = detectUserRoles(roles);
        data.roles = detected.roles;
        data.countries = detected.countries;
      }
      if (hcpId) {
        data.id = hcpId;
      }
      return {
        ...state,
        ...data
      };
    }
    default:
      return state;
  }
};

export default user;
