import * as _ from "lodash";
import {contacts} from "const/contactDetails";

export const getMultiAnswerValue = answers =>
  answers.map(({ answer }) => answer).join(", ");

export const checkIfSurveyIsAnswered = (patient, survey) => {
  if (!survey) {
    return true;
  }

  let missingAnswers = 0;
  let answers = [];
  if (patient) {
    answers =
      patient.extendedProfile?.surveyAnswers?.map(answer =>
        _.isEmpty(answer.answers?.[0]) || answer.answers?.[0].value === ""
          ? ""
          : answer.id
      ) || [];
  }
  survey.questions.forEach(item => {
    if (item.required && !answers.includes(item.id)) {
      missingAnswers++;
    }
  });
  return missingAnswers === 0 ? true : false;
};

export const checkIfTestTypeAlreadyUsed = (value, alreadyUsed) => {  
  if (alreadyUsed && alreadyUsed.some(s => s.testType === value)) {
    return true;
  } else {
    return false
  }
};

export const checkContactDetails = contactDetails => 
  !contacts.every(item => contactDetails?.hasOwnProperty(item)) || 
  contacts.some(k => {
    return (
      contactDetails[k] === "" ||
      contactDetails[k] === undefined ||
      contactDetails[k] === null
    );
  }) ? true : false;

export const checkTestConfiguration = config => {
  const shouldhave = ['testType', 'testTypeId', 'testTypeName', 'testTypeDescription']
  return !shouldhave.every(key => config.hasOwnProperty(key)) || 
  shouldhave.some(k => {
    return (
      config[k] === "" ||
      config[k] === undefined ||
      config[k] === null
    )
  })
}

