import Button from "components/Button";
import HomeTestTypes from "components/HomeTestTypes";
import PageTitle from "components/PageTitle";
import Switch from "components/Switch";
import _ from "lodash";
import React from "react";
import uuid from "uuid";
import { FormContext, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getDays, getHours } from "utils/formatData";
import HomeTestContacts from "../HomeTestContacts";
import HomeTestAdditional from "../HomeTestAdditional";
import HomeTestDetails from "../HomeTestDetails";
import ConfirmPopup from "components/ConfirmPopup";
import HomeTestProfileButtonBar from "components/HomeTestProfileButtonBar";
import "./HomeTestProfile.scss";
import { HOME_TEST_VALUE } from "const/app";
import { STATUS_PUBLISHED } from "const/exportTypes";
import useSticky from "utils/useSticky";
import { fixture } from 'utils/fixture'

export const HomeProfileEditingContext = React.createContext([]);

const HomeTestProfile = ({
  testProfile,
  testProfiles,
  getDefaultTestConfig,
  defaultTestProfile,
  getHomeTestProfilesList,
  createHomeTestProfile,
  updateHomeTestProfile,
  publishHomeTestProfile,
  activateDeactivateHomeTestProfile,
  countryCode,
  testTypes,
  homeTestSaving,
  homeTestPublishing
}) => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: "onChange"
  });
  const { reset, handleSubmit, formState, triggerValidation } = methods;
  const intialEditingState = {
    sections: []
  };
  const [currentlyEditing, setCurrentlyEditing] = React.useState(
    intialEditingState
  );
  const [value, setValue] = React.useState(false);
  const [defaultProfile, setDefaultProfile] = React.useState({});
  const [originalProfile, setOriginalProfile] = React.useState({});
  const [showPublishModal, setShowPublishModal] = React.useState(false);
  const [showOnOffModal, setShowOnOffModal] = React.useState(false);
  const [confirmConfig, setConfirmConfig] = React.useState({});
  const [hasConfigAndContact, setHasConfigAndContact] = React.useState(false);
  const { isSticky, element } = useSticky();

  const availableTestTypes = testTypes.filter(
    test => test.value === HOME_TEST_VALUE
  );
  const testTypeValue = availableTestTypes?.[0]?.value;
  async function onSubmit(data) {
    let validity = {
      testConfiguration: data?.testConfiguration?.map(test => {
        return {
          ...test,
          testOutcome: {
            ...test.testOutcome,
            values: test?.testOutcome?.values?.map(item => {
              const { days, hours, ...rest } = item;
              return {
                ...rest,
                validityIntervalInSeconds:
                  +item.days * 24 * 60 * 60 + +item.hours * 60 * 60
              };
            })
          }
        };
      })
    };
    let request = _.merge(defaultProfile, data, validity);
    if (!testProfiles?.length) {
      if (!request?.testConfiguration?.[0]?.testTypeDescription) {
        setCurrentlyEditing(prevState => {
          return {
            sections: [...prevState.sections, "testTypes"]
          };
        });
        triggerValidation();
        return;
      }

      const homeTestCreated = await createHomeTestProfile(request);

      if(!homeTestCreated) {
        let defaultData = {
          name: "home test",
          description: "home test description",
          contactDetails: {
            institutionName: "",
            institutionAddress: "",
            institutionWebLink: "",
            institutionPhoneNumber: "",
            additionalDetails: ""
          },
          testConfiguration: [
            {
              ...defaultTestProfile,
              testTypeDescription: "",
              testTypeName: "Roche SARS-CoV-2 Rapid Antigen Test",
              testType: testTypeValue,
              testTypeId: uuid(),
              isReportingEnable: false, 
              isSurveySkippable: false,
              testOutcome: {
                ...defaultTestProfile?.testOutcome,
                values: defaultTestProfile?.testOutcome?.values?.map(item => ({
                  ...item,
                  days: getDays(item.validityIntervalInSeconds),
                  hours: getHours(item.validityIntervalInSeconds)
                }))
              }
            }
          ]
        };
        setDefaultProfile(defaultData);
        reset(defaultData);
      }


      setCurrentlyEditing(intialEditingState);
    } else {
      let testData = _.merge(defaultProfile, data, validity, {
        name: testProfile?.testingProfileName,
        description: testProfile?.testingProfileDescription
      });

      if (!testData?.testConfiguration?.[0]?.testTypeDescription) {
        setCurrentlyEditing(prevState => {
          return {
            sections: [...prevState.sections, "testTypes"]
          };
        });
        triggerValidation();
        return;
      }

      const res = await updateHomeTestProfile(
        testProfile?.testingProfileId,
        testData
      );
      if (res) setCurrentlyEditing(intialEditingState);
    }
  }

  React.useEffect(() => {
    if(testProfile?.testConfiguration) {
      setHasConfigAndContact(true)
    }
    if (!testProfile || !testProfile?.testConfiguration?.[0]) {
      let data = {
        name: "home test",
        description: "home test description",
        contactDetails: {
          institutionName: "",
          institutionAddress: "",
          institutionWebLink: "",
          institutionPhoneNumber: "",
          additionalDetails: ""
        },
        testConfiguration: [
          {
            ...defaultTestProfile,
            testTypeDescription: "",
            testTypeName: "Roche SARS-CoV-2 Rapid Antigen Test",
            testType: testTypeValue,
            testTypeId: uuid(),
            isReportingEnable: false, 
            isSurveySkippable: false,
            testOutcome: {
              ...defaultTestProfile?.testOutcome,
              values: defaultTestProfile?.testOutcome?.values?.map(item => ({
                ...item,
                days: getDays(item.validityIntervalInSeconds),
                hours: getHours(item.validityIntervalInSeconds)
              }))
            }
          }
        ]
      };
      setDefaultProfile(data);
      setOriginalProfile(data);
      reset(data);
      return;
    }

    let data = {
      ...testProfile,
      name: testProfile?.testingProfileName,
      description: testProfile?.testingProfileDescription,
      testConfiguration: testProfile?.testConfiguration?.map(test => {
        return {
          ...test,
          testOutcome: {
            ...test.testOutcome,
            ...defaultTestProfile?.testOutcome,
            values: test.testOutcome.values.map(item => ({
              ...item,
              days: getDays(item.validityIntervalInSeconds),
              hours: getHours(item.validityIntervalInSeconds)
            }))
          }
        };
      })
    };
    setDefaultProfile(data);
    setValue(testProfile.active);

    reset(data);
  }, [testProfile, reset, defaultTestProfile, testTypeValue]);
  React.useEffect(() => {
    getDefaultTestConfig();
    getHomeTestProfilesList(countryCode);
  }, [getDefaultTestConfig, getHomeTestProfilesList, countryCode]);

  const onPublish = () => {
    setConfirmConfig({
      title: t("title"),
      message: t("ready_to_publish_description"),
      onConfirm: () =>
        publishHomeTestProfile(testProfile.testingProfileId, STATUS_PUBLISHED)
    });
    setShowPublishModal(true);
  };

  const onCancel = () => {
    setCurrentlyEditing(intialEditingState);
    if(!hasConfigAndContact) {
      const data = {...originalProfile, 
        contactDetails: null,
        testConfiguration: [
          {
            ...defaultTestProfile,
            testTypeDescription: "",
            testTypeName: "Roche SARS-CoV-2 Rapid Antigen Test",
            testType: testTypeValue,
            testTypeId: uuid(),
            isReportingEnable: false, 
            isSurveySkippable: false,
            testOutcome: {
              ...defaultTestProfile?.testOutcome,
              values: defaultTestProfile?.testOutcome?.values?.map(item => ({
                ...item,
                days: getDays(item.validityIntervalInSeconds),
                hours: getHours(item.validityIntervalInSeconds)
              }))
            }
          }
        ]
      }
      setDefaultProfile(data)
      reset(data);
    } else {
      setCurrentlyEditing(intialEditingState);
      reset(defaultProfile);
    }
  };

  return (
    <HomeProfileEditingContext.Provider
      value={{ currentlyEditing, setCurrentlyEditing }}
    >
      <FormContext {...methods}>
        <div className="home-test-profile">
          <div className="button-container">
            <Button
              variant="primary"
              className="button header-add-button"
              disabled={currentlyEditing.sections.length || !testProfile}
              onClick={onPublish}
              ref={element}
              loading={homeTestPublishing}
            >
              {t("publish")}
            </Button>
            <Button
              variant="primary"
              className="button header-add-button"
              disabled={!currentlyEditing.sections.length || !formState.isValid}
              onClick={handleSubmit(onSubmit)}
              loading={homeTestSaving}
            >
              {t("save_as_draft")}
            </Button>
            <Button
              variant="secondary"
              className="button header-add-button"
              onClick={onCancel}
            >
              {t("cancel")}
            </Button>
          </div>
          {(testProfile || defaultTestProfile) && (
            <>
              <PageTitle
                title={t("home_test_profile")}
                className="qr-details-header"
              />
              <HomeTestContacts
                testProfile={defaultProfile}
              />
              <HomeTestTypes testProfile={defaultProfile} />
              <HomeTestAdditional testProfile={defaultProfile} />
              <HomeTestDetails
                testProfile={
                  !testProfile
                    ? defaultTestProfile
                    : testProfile?.testConfiguration?.[0] || defaultTestProfile
                }
                institutionName={testProfile?.contactDetails?.institutionName}
              />
              <div className="enable-test-container">
                <div>
                  <p>{t("enable_home_test_fea")}</p>
                  <i>{t("this_will_enable_the", { name: fixture.project.name })}</i>
                </div>

                <Switch
                  isActive={value}
                  onColor="#e3f2ff"
                  disabled={
                    !testProfiles?.length ||
                    (testProfile?.status !== STATUS_PUBLISHED && !value)
                  }
                  handleToggle={() => {
                    setConfirmConfig({
                      title: t("toggle_confirmation"),
                      message: value
                        ? t("home_test_disabled_title")
                        : t("home_test_enabled_title"),
                      onConfirm: () => {
                        setValue(!value);
                        activateDeactivateHomeTestProfile(
                          testProfile.testingProfileId,
                          !value
                        );
                      }
                    });
                    setShowOnOffModal(true);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </FormContext>
      {isSticky && currentlyEditing.sections.length ? (
        <HomeTestProfileButtonBar
          onSave={handleSubmit(onSubmit)}
          onCancel={onCancel}
          publishDisabled={currentlyEditing.sections.length || !testProfile}
          saveDisabled={!currentlyEditing.sections.length || !formState.isValid}
          saveLoading={homeTestSaving}
          publishLoading={homeTestPublishing}
        />
      ) : null}
      {showPublishModal && (
        <ConfirmPopup
          title={confirmConfig.title}
          message={confirmConfig.message}
          visible={showPublishModal}
          cancel={() => setShowPublishModal(false)}
          buttonText={{ confirm: t("publish"), cancel: t("cancel") }}
          confirm={e => {
            e.preventDefault();
            confirmConfig.onConfirm();
            setShowPublishModal(false);
          }}
        />
      )}
      {showOnOffModal && (
        <ConfirmPopup
          title={confirmConfig.title}
          message={confirmConfig.message}
          visible={showOnOffModal}
          cancel={() => setShowOnOffModal(false)}
          buttonText={{ confirm: t("confirm"), cancel: t("cancel") }}
          confirm={e => {
            e.preventDefault();
            confirmConfig.onConfirm();
            setShowOnOffModal(false);
          }}
        />
      )}
    </HomeProfileEditingContext.Provider>
  );
};

export default HomeTestProfile;
