import React from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import PageTitle from "components/PageTitle";
import Spinner from "components/Spinner";
import Table from "components/Table";
import PropTypes from "prop-types";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import Label from "components/Label";
import SelectOption from "components/SelectOption";
import { useForm, Controller } from "react-hook-form";
import Error from "components/Error";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { subMonths, addMonths } from "date-fns";
import { LANGUAGE } from "const/app";
import moment from "moment-timezone";
import { sortIgnoreCase, sortTimestamp } from "utils/sortMethods";

import "react-datepicker/dist/react-datepicker.css";

import {
  EXPORT_TYPE_SHARED_TEST_RESULTS,
  STATUS_NEW,
  STATUS_STARTED,
  STATUS_COMPLETED,
  STATUS_ERROR,
  STATUS_IN_PROGRESS,
  STATUS_TIMED_OUT
} from "const/exportTypes";
import { getDateFormatString, languageMapping } from "utils/formatData";
import { useTranslation } from "react-i18next";

registerLocale(LANGUAGE, languageMapping?.[LANGUAGE]);

const SELECT_ALL_VALUE = "*";

const CohortDataExport = ({
  getCohortProfiles,
  cohortProfiles,
  cohortProfilesLoading,
  exportData,
  getExports,
  createExport,
  organizationId,
  getDownloadUrl
}) => {
  const toOptionItem = r => ({ label: r.name, value: r.cohortId });
  
  const { t } = useTranslation();

  const {
    handleSubmit,
    errors,
    setValue,
    getValues,
    reset,
    formState,
    control,
    watch,
    setError,
    clearError
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const [options, setOptions] = React.useState([]);
  const [allIsSelected, setAllIsSelected] = React.useState(false);
  
  let exportsInProgress = React.useMemo(
    () =>
      exportData
        .filter(data => {
          return data.jobType === EXPORT_TYPE_SHARED_TEST_RESULTS;
        })
        .filter(
          item =>
            item.statusCode === STATUS_IN_PROGRESS ||
            item.statusCode === STATUS_NEW ||
            item.statusCode === STATUS_STARTED
        ).length,
    [exportData]
  );
  React.useEffect(() => {
    if (!window.navigator.onLine) return;
    
    getCohortProfiles();
    
  }, [getCohortProfiles]);

  React.useEffect(() => {
    getExports();

    const interval = setInterval(() => {
      getExports();
    }, 10000);

    if (!exportsInProgress) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [getExports, exportsInProgress]);

  React.useEffect(() => {
    let options = cohortProfiles?.map(toOptionItem);
    setOptions(options);
    
  }, [cohortProfiles]);

  React.useEffect(() => {
    watch("cohortProfiles");
  }, [watch]);

  const compareDates = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d2 < d1;
  };

  const onSubmit = async formData => {
    let start = moment(formData.dateFrom)
      .startOf("day")
      .valueOf();
    let end = moment(formData.dateTo)
      .endOf("day")
      .valueOf();
    let userTimeZone = moment.tz.guess();

    if (formData?.cohortProfiles?.includes(SELECT_ALL_VALUE)) {
      formData.cohortProfiles = options.map(option => option.value);
    }
    let requestsArray = formData.cohortProfiles.map(profile => {
      let request = {
        organizationIdList: [organizationId],
        exportType: EXPORT_TYPE_SHARED_TEST_RESULTS,
        profileIdList: [profile],
        intervalStart: start,
        intervalEnd: end,
        userTimeZone
      };
      return createExport(request);
    });

    await Promise.all(requestsArray);

    setValue([
      { cohortProfiles: null },
      { dateFrom: undefined },
      { dateTo: undefined }
    ]);
    reset();
    setAllIsSelected(false);
  };

  const triggerDownload = React.useCallback( async exportId => {
    const file = await getDownloadUrl(exportId);
    window.open(file?.data?.downloadUrl, "_self");
  }, [getDownloadUrl]);

  const triggerRetry = React.useCallback( async rowData => {
    let userTimeZone = moment.tz.guess();

    const {
      organizationIdList,
      intervalStart,
      intervalEnd,
      profileIdList,
      organizationId,
      profileId
    } = rowData.jobConfig;
    const exportType = rowData.jobType;
    let response = {
      organizationIdList: organizationIdList || [organizationId],
      exportType,
      intervalStart,
      intervalEnd,
      profileIdList: profileIdList || [profileId],
      userTimeZone
    };
    await createExport(response);
    return;
  }, [createExport]);

  const formatDateRange = React.useCallback((dateFrom, dateTo) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const fromDate = new Date(dateFrom).toLocaleDateString(LANGUAGE, options);
    const toDate = new Date(dateTo).toLocaleDateString(LANGUAGE, options);
    return `${fromDate} - ${toDate}`;
  }, []);

  const formatStartDate = React.useCallback(date => {
    const dateTime = new Date(date);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: false };
    const startDate = dateTime.toLocaleDateString(LANGUAGE, options);
    const startTime = dateTime.toLocaleTimeString(LANGUAGE, timeOptions);

    return `${startDate} ${startTime}`;
  }, []);

  const data = exportData.filter(data => {
    return data.jobType === EXPORT_TYPE_SHARED_TEST_RESULTS;
  }).map((item) => ({
    ...item, 
    profileName: cohortProfiles?.find(
      p =>
        p.cohortId ===
        item.jobConfig?.profileIdList?.[0] 
    )?.name || t("information_not_available"),
    status: item.statusCode === STATUS_ERROR || !!item.error
      ? t("export_not_successful")
      : item.statusCode === STATUS_COMPLETED
      ? t("export_ready")
      : item.statusCode === STATUS_TIMED_OUT
      ? t("export_too_big")
      : t("export_in_progress"),
      createdAt: formatStartDate(item.createdAt)
  }));
  
  const cohortDataExportColumns = React.useMemo(
    () => [
      {
        Header: t("test_data_organization_date_range"),
        accessor: "dateRange",
        disableSortBy: true,
        className: "column-date-range",
        Cell: ({ row }) =>
          formatDateRange(
            row.original.jobConfig?.intervalStart,
            row.original.jobConfig?.intervalEnd
          )
      },
      {
        Header: t("cohort_profile_label"),
        accessor: "profileName",
        sortType: sortIgnoreCase    
      },
      {
        Header: t("test_data_organization_job_started"),
        accessor: "createdAt",
        sortType: sortTimestamp,
        className: "column-job-started" 
      },
      {
        Header: t("test_data_organization_processing_status"),
        accessor: "status",
        sortType: sortIgnoreCase,
        Cell: ({ row, value }) => {
          return (
            <div className="status-container">
              <p>{value}</p>
              {row.original.statusCode === STATUS_COMPLETED ? (
                <Icon type="readyToDownload" />
              ) : row.original.statusCode === STATUS_ERROR ||
                  row.original.statusCode === STATUS_TIMED_OUT ||
                  !!row.original.error ? (
                <Icon type="errorAlert" />
              ) : null}
            </div>
          );
        }
      },
      {
        Header: t("test_data_organization_actions"),
        className: "qr-codes__actions-column column-actions",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <React.Fragment>
              {row.original.statusCode === STATUS_COMPLETED ? (
                <Button
                  variant="row-action"
                  title={t("test_data_organization_actions_download")}
                  onClick={() => triggerDownload(row.original.exportId)}
                >
                  <Icon type="download" />
                </Button>
              ) : row.original.statusCode === STATUS_ERROR ||
                !!row.original.error ? (
                <Button
                  variant="row-action"
                  title={t("test_data_organization_actions_retry")}
                  onClick={() => triggerRetry(row.original)}
                >
                  <Icon type="refresh" />
                </Button>
              ) : null}
            </React.Fragment>
          );
        }
      }
    ], [formatDateRange, triggerRetry, triggerDownload, t]);


  return cohortProfilesLoading ? (
    <Spinner marginTop={15} />
  ) : (
    <React.Fragment>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="data-export__form-container"
      >
        <div className="button-container">
          <Button
            variant="primary"
            className="button header-add-button"
            type="submit"
            disabled={!formState.isValid}
          >
            {t("test_data_organization_export_data")}
          </Button>
        </div>
        <PageTitle title={t("cohort_data_export")} /> 
        <div className="data-export__form">
          <div className="dates-container">
            <FormGroup>
              <Label htmlFor="dateFrom" error={errors.dateFrom}>
                {t("test_data_date_from")}
              </Label>
              <Icon type="calendar" />

              <Controller
                as={ReactDatePicker}
                control={control}
                valueName="selected" // DateSelect value's name is selected
                onChange={([selected]) => {
                  return selected;
                }}
                minDate={subMonths(watch("dateTo"), 6)}
                name="dateFrom"
                className={
                  errors.dateFrom ? "date-picker--error" : "date-picker"
                }
                placeholderText={getDateFormatString(LANGUAGE)}
                autoComplete="off"
                locale={languageMapping[LANGUAGE] || LANGUAGE}
                dateFormat="P"
                rules={{
                  required: t("form_field_is_required"),
                  validate: value => {
                    return (
                      !compareDates(value, getValues("dateTo")) ||
                      t("test_data_date_from_less_than_to_date")
                    );
                  }
                }}
              />
              {errors.dateFrom && <Error>{errors.dateFrom.message}</Error>}
            </FormGroup>
            <div className="separator">-</div>
            <FormGroup>
              <Label htmlFor="dateTo" error={errors.dateTo}>
                {t("to")}
              </Label>
              <Icon type="calendar" />

              <Controller
                as={ReactDatePicker}
                control={control}
                valueName="selected" // DateSelect value's name is selected
                onChange={([selected]) => {
                  const { dateFrom } = getValues();
                  if (compareDates(dateFrom, selected)) {
                    setError(
                      "dateFrom",
                      "notMatch",
                      t("test_data_date_from_less_than_to_date")
                    );
                  } else {
                    clearError("dateFrom");
                    reset("dateFrom", dateFrom);
                  }
                  return selected;
                }}
                maxDate={addMonths(watch("dateFrom"), 6)}
                name="dateTo"
                className={errors.dateTo ? "date-picker--error" : "date-picker"}
                placeholderText={getDateFormatString(LANGUAGE)}
                autoComplete="off"
                locale={languageMapping[LANGUAGE] || LANGUAGE}
                dateFormat="P"
                rules={{
                  required: t("form_field_is_required")
                }}
              />
              {errors.dateTo && <Error>{errors.dateTo.message}</Error>}
            </FormGroup>
          </div>
          <FormGroup>
            <Label htmlFor="cohortProfiles" error={errors.cohortProfiles}>
              {t("cohort_profile_label")} 
            </Label>

            <Controller
              as={
                <SelectOption
                  isSearchable
                  isClearable
                  isMulti
                  allowSelectAll={true}
                  allIsSelected={allIsSelected}
                  options={options}
                  closeMenuOnSelect={false}
                  error={errors.cohortProfiles}
                  placeholder={t("select_cohort_profile")}
                />
              }
              control={control}
              onChange={([selected, meta]) => {
                // React Select return object instead of value for selection
                if (selected && selected[selected.length - 1]?.value === "*") {
                  setAllIsSelected(true);
                }
                if (allIsSelected && meta.action === "clear") {
                  setAllIsSelected(false);
                }      
                if (allIsSelected && meta.action === "remove-value") {
                  let data = selected
                    ?.filter(item => item.value !== meta.removedValue.value)
                    .map(item => item.value);
                  setAllIsSelected(false);

                  return data;
                }
                return selected.length ? selected?.map(item => item.value) : null;
              }}
              rules={{ required: t("form_field_is_required") }}
              name="cohortProfiles"
            />
            {errors.cohortProfiles && (
              <Error>{errors.cohortProfiles.message}</Error>
            )}
          </FormGroup>
        </div>
      </Form>
      <div className="data-export__table-container">
        <Table
          pagination
          globalFilter
          pageSize={10}
          columns={cohortDataExportColumns}
          data={data}
          withSortIcons={true}
          className={"data-export__table"}
        />
      </div>
    </React.Fragment>
  );
};

CohortDataExport.propTypes = {
  cohortProfiles: PropTypes.arrayOf(PropTypes.object),
  exportData: PropTypes.arrayOf(PropTypes.object)
};

export default CohortDataExport;
