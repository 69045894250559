import axios from "utils/Axios";
import { makeAdminUsers } from "utils/mockData";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";

export const getAdminUsers = () => {
  return makeAxiosRequest("getAdminUsers", {
    client: axios,
    method: "get",
    relativePath: `/admins`
  });
};

export const getMockedAdminUsers = () => {
  return makeRequest("getAdminUsers", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeAdminUsers(20);
            resolve({ data: mockData });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const createAdminUser = request => {
  return makeAxiosRequest("createAdminUser", {
    client: axios,
    method: "post",
    relativePath: "/admins",
    data: request
  });
};

export const updateAdminUser = (adminId, firstName, lastName, roles) => {
  return makeAxiosRequest("updateAdminUser", {
    client: axios,
    method: "put",
    relativePath: `/admins/${adminId}`,
    data: {
      firstName,
      lastName,
      roles
    }
  });
};

export const deleteAdminUser = adminId => {
  return makeAxiosRequest("deleteAdminUser", {
    client: axios,
    method: "delete",
    relativePath: `/admins/${adminId}`
  });
};
