import React from "react";
import CountryManagementContainer from "../../containers/CountryManagement.Container";

const CountryManagement = () => (
  <>
    <CountryManagementContainer />
  </>
);

export default CountryManagement;
