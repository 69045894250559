import React from "react";
import { useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { SignOutModal, Icon, Text } from "components";
import { hasAccessToRoute } from "utils/roleManager";
import "./NavBar.scss";
import { useTranslation } from "react-i18next";
import { fixture } from 'utils/fixture'

const NavBar = ({ history, isSuperAdmin }) => {
  const { t } = useTranslation();

  const [modalVisible, toggleModal] = React.useState(false);

  const toggleSignOut = e => {
    e.preventDefault();
    toggleModal(!modalVisible);
  };

  const signOut = e => {
    e.preventDefault();
    //Do we really need a ROUTE to logout?
    //TODO: refactor logging out: DashboardLayout, App, PrivateRoute, NavBar
    history.push("/sign-out");
  };

  const openPrivacy = e => {
    e.preventDefault();
    window.open(fixture.url.privacyPolicy);
  };

  const { roles } = useSelector(state => state.user);

  const topItems = [
    {
      path: "/users",
      id: "navbar-users",
      icon: "organization",
      text: t("organization")
    },
    {
      path: "/organizations",
      id: "navbar-organizations",
      icon: "organization",
      text: t("organizations")
    },
    {
      path: "/countries",
      id: "navbar-countries",
      icon: "globe",
      text: t("countries")
    },
    {
      path: "/test-profiles",
      id: "navbar-testProfile",
      icon: "qrCode",
      text: t("test_profiles")
    },
    {
      path: "/cohort-profiles",
      id: "navbar-cohortProfile",
      icon: "qrCode",
      text: t("cohort_profiles")
    },
    {
      path: "/access-profiles",
      id: "navbar-accessProfile",
      icon: "qrCode",
      text: t("access_profiles")
    },
    {
      path: "/test-data-export",
      id: "navbar-testDataExport",
      icon: "dataExport",
      text: isSuperAdmin ? t("billing_test") : t("test_data")
    },
    {
      path: "/access-data-export",
      id: "navbar-accessDataExport",
      icon: "dataExport",
      text: isSuperAdmin ? t("billing_access") : t("access_data")
    },
    {
      path: "/country-analyst-export",
      id: "navbar-countryAnalystDataExport",
      icon: "dataExport",
      text: t("data_export_nav")
    },
    {
      path: "/cohort-data-export",
      id: "navbar-cohortDataExport",
      icon: "cohortDataExport",
      text: t("cohort_data")
    },
    {
      path: "/data-elements",
      id: "navbar-dataElements",
      icon: "dataElements",
      text: t("data_elements")
    },
    {
      path: "/home-test-profile",
      id: "navbar-homeTestProfile",
      icon: "homeTestProfile",
      text: t("home_test_profile")
    },
    {
      path: "/test-recipients",
      id: "navbar-test-recipients",
      icon: "profile",
      text: t("test_recipients_title")
    },
    {
      path: "/search-test-recipients",
      id: "navbar-search",
      icon: "navSearch",
      text: t("search")
    },
    {
      path: "/members",
      id: "navbar-members",
      icon: "memberList",
      text: t("member_list")
    },
    {
      path: "/search-members",
      id: "navbar-member-search",
      icon: "memberSearch",
      text: t("member_search")
    },
  ];

  const bottomItems = [
    {
      path: "/profile",
      id: "navbar-profile",
      icon: "profile",
      text: t("user_profile")
    },
    {
      path: "/help",
      id: "navbar-help",
      icon: "help",
      text: t("help")
    },
    {
      click: openPrivacy,
      id: "navbar-privacy",
      icon: "privacy",
      text: t("privacy_notice")
    },
    {
      path: "/about",
      id: "navbar-about",
      icon: "about",
      text: t("about")
    },
    {
      click: toggleSignOut,
      id: "navbar-exit",
      icon: "exit",
      text: t("exit")
    }
  ];

  const renderItem = item => {
    if (item.path) {
      if (!hasAccessToRoute(item.path, roles)) {
        return null;
      }
      const linkProps = {
        id: item.id,
        className: "navBar_item",
        exact: true,
        to: item.path,
        activeClassName: "active"
      };
      return (
        <NavLink key={item.id} {...linkProps}>
          <Text as="p">
            <Icon type={item.icon} />
            {item.text}
          </Text>
        </NavLink>
      );
    } else {
      return (
        <button
          key={item.id}
          id={item.id}
          className="navBar_item"
          onClick={item.click}
        >
          <Text as="p">
            <Icon type={item.icon} />
            {item.text}
          </Text>
        </button>
      );
    }
  };

  return (
    <div className="navBar">
      <div className="navBar_group">{topItems.map(renderItem)}</div>
      <div className="navBar_group">{bottomItems.map(renderItem)}</div>
      <SignOutModal
        signOut={signOut}
        toggleModal={toggleSignOut}
        visible={modalVisible}
      />
    </div>
  );
};

export { NavBar };

export default withRouter(NavBar);
