import React from "react";
import Title from "components/Title";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

import "./cookieConsent.scss";

function CookieConsent({ cookieConsentAccept }) {
  const { t } = useTranslation();

  return (
    <div className="cookie-container">
      <div className="cookie-content">
        <Title>{t("cookie_title")}</Title>
        <p>
          {t("cookie_description")}
          <a
            className="link"
            href="https://www.roche.com/cookie-notice.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("cookie_privacy_notice")}
          </a>
          .
        </p>
        <Button variant="primary" type="submit" onClick={cookieConsentAccept}>
          {t("accept")}
        </Button>
      </div>
    </div>
  );
}

export default CookieConsent;
