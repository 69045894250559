import React from "react";
import TestProfilesContainer from "../../containers/TestProfiles.Container";

const TestProfiles = () => (
  <>
    <TestProfilesContainer />
  </>
);

export default TestProfiles;
