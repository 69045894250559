import axios from "axios";
import { setApiUrl } from "./setBaseUrls";

const baseUrl = setApiUrl();

export const createClient = () => {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      appId: "com.roche.covid"
    }
  });
};

export const createClientNoId = () => {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const createPatientClient = () => {
  const apiUrl = baseUrl?.replace("/portal", "");
  return axios.create({
    baseURL: apiUrl,
    headers: {
      "Content-Type": "application/json",
      appId: "com.roche.covid"
    }
  });
};

export const createSecretClient = () => {};

const client = createClient();

export const patientClient = createPatientClient();

export const clientNoId = createClientNoId();

export const updateAuthToken = token => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  clientNoId.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  patientClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export default client;
