import React from "react";
import phoneImage from "assets/images/testPhone.png";
import comboPhoneImage from "assets/images/comboTestPhone.png";
import Button from "components/Button";
import EditableCell from "components/EditableCell";
import PageTitle from "components/PageTitle";
import Table from "components/Table";
import Icon from "components/Icon";
import TestProvider from "components/TestProvider";
import TestRecipient from "components/TestRecipient";
import PropTypes from "prop-types";
import TestProfileForm from "../TestProfileForm/TestProfileForm";
import ConfirmPopup from "components/ConfirmPopup";
import { ROLE_TEST_PROVIDER_ADMIN } from "const/roles";
import * as _ from "lodash";
import { error } from "react-notification-system-redux";
import { useDispatch } from "react-redux";
import { checkTestConfiguration } from "utils/mappers";

import uuid from "uuid";
import "./TestTypes.scss";
import { useTranslation } from "react-i18next";

const defaultColumn = {
  Cell: EditableCell
};

const TestTypes = ({
  deleteTestTypeUpdate,
  testingProfileUpdating,
  setTestTypeForEdit,
  setCheckboxForEdit,
  testTypes,
  isCombo,
  getDefaultTestConfig,
  config,
  createTestType,
  roles,
  selectedTestProfile,
  updateTestingConfiguration,
  setTestingProfileDataForEdit,
  saveTestTypeData,
  cancelUpdate,
  defaultTestTypeList,
  setFieldErrors
}) => {
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showUnsavedChangesModal, setshowUnsavedChangesModal] = React.useState(
    false
  );
  const [unsavedConfirmConfig, setUnsavedConfirmConfig] = React.useState({});
  const [confirmConfig, setConfirmConfig] = React.useState({});
  const [selectedTestType, setSelectedTestType] = React.useState(null);
  const [checkedState, setCheckedState] = React.useState([]);
  const [enabledTestTypes, setEnabledTestTypes] = React.useState([]);
  const dispatch = useDispatch();
  //this needs to be refactored with the form
  const checkIfEqual = () => {
    return _.isEqual(
      selectedTestProfile.newMetaData?.testConfiguration,
      selectedTestProfile.originalMetaData?.testConfiguration
    );
  };

  const isTestConfigDataEqual = checkIfEqual();
  React.useEffect(() => {
    let enabled = testTypes.filter(item => item.markedForIntegration);
    setEnabledTestTypes(enabled);
  }, [testTypes]);

  React.useEffect(() => {
    const updatedState = testTypes.map(item => {
      return {
        checked: item.markedForIntegration,
        disabled: enabledTestTypes.find(
          el =>
            item.testType === el.testType && el.testTypeId !== item.testTypeId
        )
          ? true
          : false
      };
    });
    setCheckedState(updatedState);
  }, [testTypes, enabledTestTypes]);

  const onCreateTestType = React.useCallback(async () => {
    if (config && !config.defaultTestConfig) {
      const result = await getDefaultTestConfig();
      if (!result.data) {
        return;
      }
    }
    setShowAddModal(true);
  }, [getDefaultTestConfig, config]);

  const addTestType = React.useCallback(
    async ({ name, description, testType }) => {
      let newTestType = JSON.parse(JSON.stringify(config.defaultTestConfig));
      newTestType.testTypeId = uuid();
      newTestType.testTypeName = name;
      newTestType.testTypeDescription = description;
      newTestType.testType = testType;
      await createTestType(newTestType);
      setSelectedTestType(newTestType);
      hideTestProfileForm();
    },
    [createTestType, config]
  );

  const onConfirmUnsavedChanges = React.useCallback(() => {
    setSelectedTestType(null);
    cancelUpdate();
    setshowUnsavedChangesModal(false);
  }, [cancelUpdate]);

  const onConfirmDeleteTestType = React.useCallback(
    row => {
      setSelectedTestType(null);
      deleteTestTypeUpdate(row.testTypeId);
      hideDeleteModal();
    },
    [deleteTestTypeUpdate]
  );

  const unsavedConfig = React.useCallback(() => {
    setUnsavedConfirmConfig({
      title: t("unsaved_changes_title"),
      message: t("unsaved_changes_description"),
      onConfirm: () => onConfirmUnsavedChanges()
    });
    setshowUnsavedChangesModal(true);
  }, [onConfirmUnsavedChanges, t]);

  const displayErrormessage = React.useCallback( () => {
    dispatch(
      error({
        title: t("error_title"),
        message: t("error"),
        position: "tc"
      })
    );
  }, [dispatch, t]);

  const deleteTestType = React.useCallback(
    async row => {
      const notValidData = checkTestConfiguration(row);
      if (notValidData) {
        displayErrormessage();
        return;
      }
      if (
        (!isTestConfigDataEqual &&
          selectedTestType?.testTypeId !== row.testTypeId) ||
        selectedTestProfile.isEditing.includes("testTypeDeleted")
      ) {
        unsavedConfig();
        return;
      }
      setConfirmConfig({
        title: t("confirm_delete_action"),
        message: t("confirm_delete_name", { name: `${row.testTypeName}` }),
        onConfirm: () => onConfirmDeleteTestType(row)
      });
      setShowDeleteModal(true);
    },
    [
      isTestConfigDataEqual,
      onConfirmDeleteTestType,
      unsavedConfig,
      selectedTestType,
      selectedTestProfile,
      t,
      displayErrormessage
    ]
  );

  const duplicateTestType = React.useCallback(
    async data => {
      const notValidData = checkTestConfiguration(data);
      if (notValidData) {
        displayErrormessage();
        return;
      }
      let newTestType = { ...data };
      newTestType.testTypeId = uuid();
      newTestType.markedForIntegration = false;
      if (!isTestConfigDataEqual) {
        unsavedConfig();
        return;
      }
      await createTestType(newTestType);
      setSelectedTestType(newTestType);
      setTestTypeForEdit();
    },
    [isTestConfigDataEqual, createTestType, unsavedConfig, setTestTypeForEdit, displayErrormessage]
  );

  const hideTestProfileForm = () => {
    setShowAddModal(false);
  };

  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const onEdit = React.useCallback(
    async row => {
      const notValidData = checkTestConfiguration(row);
      if (notValidData) {
        displayErrormessage();
        return;
      }
      if (
        !isTestConfigDataEqual &&
        selectedTestType?.testTypeId !== row.testTypeId
      ) {
        unsavedConfig();
        return;
      }
      setTestTypeForEdit(row.testTypeId);
      setTestingProfileDataForEdit("ADD", "testType");
      setSelectedTestType(row);
    },
    [
      isTestConfigDataEqual,
      setSelectedTestType,
      setTestTypeForEdit,
      setTestingProfileDataForEdit,
      selectedTestType,
      unsavedConfig,
      displayErrormessage
    ]
  );

  const onRowClick = React.useCallback(
    async row => {
      if (
        selectedTestType === undefined &&
        selectedTestProfile.isEditing.includes("testTypeDeleted")
      ) {
        unsavedConfig();
        return;
      }
      if (selectedTestType?.testTypeId === row.testTypeId) {
        return;
      }
      if (!isTestConfigDataEqual) {
        unsavedConfig();
        return;
      }

      const notValidData = checkTestConfiguration(row);
      if (notValidData) {
        displayErrormessage();
        return;
      }
      setTestTypeForEdit(null);
      setSelectedTestType(row);
    },
    [
      isTestConfigDataEqual,
      setTestTypeForEdit,
      selectedTestType,
      selectedTestProfile,
      unsavedConfig,
      displayErrormessage
    ]
  );

  const update = React.useCallback(
    async (testTypeId, id, value) => {
      await saveTestTypeData(testTypeId, id, value);
    },
    [saveTestTypeData]
  );

  const handleCheckboxChange = React.useCallback(
    async row => {
      const notValidData = checkTestConfiguration(row.original);
      if (notValidData) {
        displayErrormessage();
        return;
      }
      if (
        !selectedTestType ||
        (selectedTestType &&
          selectedTestType.testTypeId === row.original.testTypeId) ||
        (selectedTestType &&
          selectedTestType.testTypeId !== row.original.testTypeId &&
          isTestConfigDataEqual)
      ) {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === row.index
            ? { ...item, checked: !item.checked }
            : { ...item }
        );
        setCheckedState(updatedCheckedState);
        let selected = {
          ...row.original,
          markedForIntegration: updatedCheckedState[row.index]?.checked
        };
        setSelectedTestType(selected);
        let name = "markedForIntegration";
        setCheckboxForEdit(
          row.original.testTypeId,
          name,
          updatedCheckedState[row.index]?.checked
        );
        if (
          selectedTestType?.testTypeId !== row.original.testTypeId &&
          isTestConfigDataEqual
        ) {
          setTestTypeForEdit(null);
        }
        // clear all the Test Provider errors when Infinity is enabled
        setFieldErrors({
          beforeValidHeaderText: undefined,
          duringValidHeaderText: undefined,
          beforeValidDetailText: undefined,
          duringValidDetailText: undefined,
          afterValidHeaderText: undefined,
          afterValidDetailText: undefined,
          testOutcomeValues_0: undefined,
          testOutcomeValues_1: undefined,
          testOutcomeValues_2: undefined,
          timeError: undefined
        });
      }
      if (
        selectedTestType &&
        selectedTestType.testTypeId !== row.original.testTypeId &&
        !isTestConfigDataEqual
      ) {
        unsavedConfig();
      }
    },
    [
      selectedTestType,
      checkedState,
      unsavedConfig,
      isTestConfigDataEqual,
      setCheckboxForEdit,
      setTestTypeForEdit,
      setFieldErrors,
      displayErrormessage
    ]
  );

  const integrationColumn = [
    {
      Header: t("external_data_source"),
      accessor: "markedForIntegration",
      style: {
        minWidth: "60px"
      },
      Cell: ({ row }) => {
        return (
          <>
            <input
              checked={checkedState[row.index]?.checked}
              name={`checkbox[${row.index}]`}
              onClick={e => e.stopPropagation()}
              onChange={() => {
                handleCheckboxChange(row);
              }}
              type="checkbox"
              disabled={checkedState[row.index]?.disabled}
            />
          </>
        );
      }
    }
  ];

  const testTypesColumns = [
    {
      Header: () => (
        <div className="header-content">
          <div>{t("test_name")}</div> <span className="badge">1</span>
        </div>
      ),
      accessor: "testTypeName",
      style: {
        width: "20%",
        minWidth: "80px"
      }
    },
    {
      Header: t("test_type"),
      style: {
        width: "20%",
        minWidth: "80px"
      },
      Cell: ({ row }) => {
        return (
          <p>
            {row.original.testType
              ? defaultTestTypeList?.find(
                  item => item.value === row.original.testType
                )?.label
              : ""}
          </p>
        );
      }
    },
    {
      Header: t("test_description"),
      accessor: "testTypeDescription",
      style: {
        width: "40%",
        minWidth: "80px"
      }
    },

    ...(selectedTestProfile.metaData.integrationId
      ? [...integrationColumn]
      : []),

    {
      Header: t("health_care_organizations_actions"),
      className: "actions-column",
      style: {
        width: "10%"
      },
      Cell: ({ row }) => {
        return (
          <React.Fragment>
            <Button
              variant="row-action"
              title={t("edit")}
              onClick={e => {
                e.stopPropagation();
                onEdit(row.original);
              }}
            >
              <Icon type="edit" />
            </Button>
            {roles && roles.includes(ROLE_TEST_PROVIDER_ADMIN) && (
              <Button
                variant="row-action"
                title={t("duplicate")}
                disabled={isCombo}
                onClick={e => {
                  e.stopPropagation();
                  let copy = { ...row.original };
                  copy.testTypeName = `${row.original.testTypeName.substring(
                    0,
                    31
                  )}-copy`;
                  duplicateTestType(copy, row.original);
                }}
              >
                <Icon type="duplicate" />
              </Button>
            )}
            <Button
              variant="row-action"
              title={t("health_care_organizations_users_actions_delete")}
              onClick={e => {
                e.stopPropagation();
                deleteTestType(row.original);
              }}
            >
              <Icon type="trash" />
            </Button>
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <div className="test-types-list">
        <img src={isCombo ? comboPhoneImage : phoneImage} alt="phone" className="phone-image-1" />
        <div className="test-types-table-container">
          <div className="title-top">
            <PageTitle title={t("tests")}></PageTitle>

            <Button
              variant="primary"
              className="header-add-button"
              disabled={
                selectedTestProfile.isEditing.includes("testProvider") ||
                selectedTestProfile.isEditing.includes("testType") ||
                selectedTestProfile.isEditing.includes("checkbox") ||
                selectedTestProfile.isEditing.includes("testTypeCreated") ||
                (selectedTestProfile?.isEditing.includes("testTypeDeleted") &&
                  !selectedTestType)
              }
              onClick={onCreateTestType}
            >
              {t("create_test")}
            </Button>
            {showAddModal && (
              <TestProfileForm
                onSubmit={addTestType}
                loading={testingProfileUpdating}
                hide={() => setShowAddModal(false)}
                type="testTypes"
                testTypeList={defaultTestTypeList}
                isCombo={isCombo}
                alreadyAddedTestTypes={testTypes}
              />
            )}
          </div>
          {testTypes && (
            <Table
              columns={testTypesColumns}
              data={testTypes}
              className="test-types-table"
              defaultColumn={defaultColumn}
              selectedRowIndex={testTypes.findIndex(item => {
                return item.testTypeId === selectedTestType?.testTypeId;
              })}
              pageSize={4}
              updateMyData={update}
              onRowClick={onRowClick}
              disabledSort={true}
            />
          )}
        </div>

        {showDeleteModal && (
          <ConfirmPopup
            title={confirmConfig.title}
            message={confirmConfig.message}
            visible={showDeleteModal}
            cancel={() => setShowDeleteModal(false)}
            loading={testingProfileUpdating}
            confirm={confirmConfig.onConfirm}
          />
        )}
        {showUnsavedChangesModal && (
          <ConfirmPopup
            title={unsavedConfirmConfig.title}
            message={unsavedConfirmConfig.message}
            visible={showUnsavedChangesModal}
            cancel={() => setshowUnsavedChangesModal(false)}
            confirm={unsavedConfirmConfig.onConfirm}
            loading={testingProfileUpdating}
          />
        )}
      </div>
      {testTypes && selectedTestType && (
        <div>
          <PageTitle
            title={selectedTestType.testTypeName}
            className="test-type-configuration-header"
          />
          {!selectedTestType.markedForIntegration && (
            <TestProvider
              metaData={selectedTestProfile.metaData.testConfiguration.find(
                t => t.testTypeId === selectedTestType.testTypeId
              )}
              newMetaData={selectedTestProfile?.newMetaData?.testConfiguration?.find(
                t => t.testTypeId === selectedTestType.testTypeId
              )}
              testId={selectedTestType.testTypeId}
              isEditing={selectedTestProfile.isEditing}
              setTestingProfileDataForEdit={setTestingProfileDataForEdit}
              updateTestingConfiguration={updateTestingConfiguration}
            />
          )}
          <TestRecipient
            metaData={selectedTestProfile.metaData.testConfiguration.find(
              t => t.testTypeId === selectedTestType.testTypeId
            )}
            newMetaData={selectedTestProfile?.newMetaData?.testConfiguration?.find(
              t => t.testTypeId === selectedTestType.testTypeId
            )}
            testId={selectedTestType.testTypeId}
            isEditing={selectedTestProfile.isEditing}
            updateTestingConfiguration={updateTestingConfiguration}
            institutionName={
              selectedTestProfile.metaData.contactDetails.institutionName
            }
            setTestingProfileDataForEdit={setTestingProfileDataForEdit}
            selectedTestType={selectedTestType}
          />
        </div>
      )}
    </React.Fragment>
  );
};

TestTypes.propTypes = {
  testTypes: PropTypes.array
};

export default TestTypes;
