import React from "react";
import { Icon } from "components";
import { useTranslation } from "react-i18next";

function Pagination({
  totalRecords,
  pageIndex,
  pageSize,
  pageCount,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  gotoPage,
  removeNumTotal
}) {
  const { t } = useTranslation();
  const firstRecordNumber = pageIndex * pageSize + 1;
  const lastRecordNumber = (pageIndex + 1) * pageSize;

  React.useEffect(() => {
    if (
      totalRecords <= pageSize * pageCount &&
      pageIndex * pageSize >= totalRecords
    ) {
      gotoPage(pageCount - 1);
    }
  }, [totalRecords, pageSize, pageCount, previousPage, pageIndex, gotoPage]);
  return (
    <div className="pagination-container">
      <span className="hint">
        {firstRecordNumber} -{" "}
        {t("access_pagination", {
          num:
            lastRecordNumber > totalRecords ? totalRecords : lastRecordNumber,
          numTotal: totalRecords
          // numTotal: removeNumTotal ? "..." : totalRecords
        })}
      </span>
      <button
        className="prev-page"
        onClick={e => {
          e.preventDefault();
          previousPage();
        }}
        disabled={!canPreviousPage}
      >
        <Icon type="chevron" variant="left" />
      </button>
      <button
        className="next-page"
        onClick={e => {
          e.preventDefault();
          nextPage();
        }}
        disabled={!canNextPage}
      >
        <Icon type="chevron" variant="right" />
      </button>
      <button
        className="first-page"
        onClick={e => {
          e.preventDefault();
          gotoPage(0);
        }}
        disabled={!canPreviousPage}
      >
        <Icon type="firstPage" />
      </button>
      <button
        className="last-page"
        onClick={e => {
          e.preventDefault();
          gotoPage(pageCount - 1);
        }}
        disabled={!canNextPage}
      >
        <Icon type="lastPage" variant="right" />
      </button>
    </div>
  );
}

export default Pagination;
