import phoneImage9 from "assets/images/qrPhone9.png";
import EditableCell from "components/EditableCell";
import Icon from "components/Icon";
import PageTitle from "components/PageTitle";
import Table from "components/Table";
import Button from "components/Button";
import React from "react";
import "./TestRecipient.scss";
import { getDays, getHours } from "utils/formatData";
import { useTranslation } from "react-i18next";

const TestRecipient = ({
  updateTestingConfiguration,
  isEditing,
  metaData,
  newMetaData,
  institutionName,
  setTestingProfileDataForEdit,
  selectedTestType
}) => {
  const { t } = useTranslation();
  const setIsEditing = isEditing.includes("testProvider");
  const contacts = [
    {
      id: "Row1_Recipient",
      type: "test_results",
      secondColumn: {
        value: metaData?.testOutcome?.values[0]?.activeValue,
        id: metaData?.testOutcome?.values[0]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId ? { ...val, activeValue: item } : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },

        validationName: "testOutcomeValues_0_activeValue"
      },
      thirdColumn: {
        value: metaData?.testOutcome?.values[0]?.activeValueDescription,
        id: metaData?.testOutcome?.values[0]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId
                  ? { ...val, activeValueDescription: item }
                  : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_0_activeValueDescription"
      },

      isEditing: setIsEditing,
      icon: "testResult_negative"
    },
    {
      id: "Row2_Recipient",
      type: "",
      secondColumn: {
        value: metaData?.testOutcome?.values[0]?.expiredValue,
        id: metaData?.testOutcome?.values[0]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId ? { ...val, expiredValue: item } : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_0_expiredValue"
      },
      thirdColumn: {
        value: metaData?.testOutcome?.values[0]?.expiredValueDescription,
        id: metaData?.testOutcome?.values[0]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId
                  ? { ...val, expiredValueDescription: item }
                  : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_0_expiredValueDescription"
      },
      isEditing: setIsEditing,
      icon: "testResult_negative_expired"
    },
    {
      id: "Row3_Recipient",
      type: "",
      secondColumn: {
        value: metaData?.testOutcome?.values[1]?.activeValue,
        id: metaData?.testOutcome?.values[1]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId ? { ...val, activeValue: item } : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_1_activeValue"
      },
      thirdColumn: {
        value: metaData?.testOutcome?.values[1]?.activeValueDescription,
        id: metaData?.testOutcome?.values[1]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId
                  ? { ...val, activeValueDescription: item }
                  : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },

        validationName: "testOutcomeValues_1_activeValueDescription"
      },
      isEditing: setIsEditing,
      icon: "testResult_positive"
    },
    {
      id: "Row4_Recipient",
      type: "",
      secondColumn: {
        value: metaData?.testOutcome?.values[1]?.expiredValue,
        id: metaData?.testOutcome?.values[1]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId ? { ...val, expiredValue: item } : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_1_expiredValue"
      },
      thirdColumn: {
        value: metaData?.testOutcome?.values[1]?.expiredValueDescription,
        id: metaData?.testOutcome?.values[1]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId
                  ? { ...val, expiredValueDescription: item }
                  : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_1_expiredValueDescription"
      },
      isEditing: setIsEditing,
      icon: "testResult_positive_expired"
    },
    {
      id: "Row5_Recipient",
      type: "",
      secondColumn: {
        value: metaData?.testOutcome?.values[2]?.activeValue,
        id: metaData?.testOutcome?.values[2]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId ? { ...val, activeValue: item } : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_2_activeValue"
      },
      thirdColumn: {
        value: metaData?.testOutcome?.values[2]?.activeValueDescription,
        id: metaData?.testOutcome?.values[2]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId
                  ? { ...val, activeValueDescription: item }
                  : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_2_activeValueDescription"
      },
      isEditing: setIsEditing,
      icon: "testResult_invalid"
    },
    {
      id: "Row6_Recipient",
      type: "",
      secondColumn: {
        value: metaData?.testOutcome?.values[2]?.expiredValue,
        id: metaData?.testOutcome?.values[2]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId ? { ...val, expiredValue: item } : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_2_expiredValue"
      },
      thirdColumn: {
        value: metaData?.testOutcome?.values[2]?.expiredValueDescription,
        id: metaData?.testOutcome?.values[2]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val =>
                val.id === testId
                  ? { ...val, expiredValueDescription: item }
                  : { ...val }
              )
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "testOutcomeValues_2_expiredValueDescription"
      },
      isEditing: setIsEditing,
      icon: "testResult_invalid_expired"
    },
    {
      id: "Row7_Recipient",
      type: "",
      secondColumn: {
        value: metaData?.pendingState?.activeValue,

        updateFunction: item => {
          let data = {
            ...newMetaData,
            pendingState: {
              ...newMetaData.pendingState,
              activeValue: item
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "pendingStateActive"
      },
      thirdColumn: {
        value: metaData?.pendingState?.activeValueDescription,
        updateFunction: item => {
          let data = {
            ...newMetaData,
            pendingState: {
              ...newMetaData.pendingState,
              activeValueDescription: item
            }
          };

          updateTestingConfiguration(data);
        },
        validationName: "pendingStateActiveDescription"
      },
      isEditing: setIsEditing,
      icon: "testResult_not_ready"
    },
    {
      id: "Row8_Recipient",
      type: "expiration",
      secondColumn: {
        value: getDays(
          metaData?.testOutcome?.values[0]?.validityIntervalInSeconds
        ),
        id: metaData?.testOutcome?.values[0]?.id,
        input: "days",

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val => {
                const hours = getHours(val.validityIntervalInSeconds) * 60 * 60;
                return val.id === testId
                  ? {
                      ...val,
                      validityIntervalInSeconds: hours + item * (60 * 60 * 24)
                    }
                  : { ...val };
              })
            }
          };

          updateTestingConfiguration(data);
        }
      },
      thirdColumn: {
        value: getHours(
          metaData?.testOutcome?.values[0]?.validityIntervalInSeconds
        ),
        id: metaData?.testOutcome?.values[0]?.id,
        input: "hours",

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val => {
                const days =
                  getDays(val.validityIntervalInSeconds) * 60 * 60 * 24;
                return val.id === testId
                  ? {
                      ...val,
                      validityIntervalInSeconds: days + item * (60 * 60)
                    }
                  : { ...val };
              })
            }
          };

          updateTestingConfiguration(data);
        }
      },
      isEditing: setIsEditing,
      icon: "testResult_negative"
    },
    {
      id: "Row9_Recipient",
      type: "",
      secondColumn: {
        value: getDays(
          metaData?.testOutcome?.values[1]?.validityIntervalInSeconds
        ),
        id: metaData?.testOutcome?.values[1]?.id,
        input: "days",

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val => {
                const hours = getHours(val.validityIntervalInSeconds) * 60 * 60;
                return val.id === testId
                  ? {
                      ...val,
                      validityIntervalInSeconds: hours + item * (60 * 60 * 24)
                    }
                  : { ...val };
              })
            }
          };

          updateTestingConfiguration(data);
        }
      },
      thirdColumn: {
        value: getHours(
          metaData?.testOutcome?.values[1]?.validityIntervalInSeconds
        ),
        id: metaData?.testOutcome?.values[1]?.id,
        input: "hours",

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val => {
                const days =
                  getDays(val.validityIntervalInSeconds) * 60 * 60 * 24;
                return val.id === testId
                  ? {
                      ...val,
                      validityIntervalInSeconds: days + item * (60 * 60)
                    }
                  : { ...val };
              })
            }
          };

          updateTestingConfiguration(data);
        }
      },
      isEditing: setIsEditing,
      icon: "testResult_positive"
    },
    {
      id: "Row10_Recipient",
      type: "",
      secondColumn: {
        value: getDays(
          metaData?.testOutcome?.values[2]?.validityIntervalInSeconds
        ),
        id: metaData?.testOutcome?.values[2]?.id,
        input: "days",

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val => {
                const hours = getHours(val.validityIntervalInSeconds) * 60 * 60;
                return val.id === testId
                  ? {
                      ...val,
                      validityIntervalInSeconds: hours + item * (60 * 60 * 24)
                    }
                  : { ...val };
              })
            }
          };

          updateTestingConfiguration(data);
        }
      },
      thirdColumn: {
        value: getHours(
          metaData?.testOutcome?.values[2]?.validityIntervalInSeconds
        ),
        input: "hours",

        id: metaData?.testOutcome?.values[2]?.id,

        updateFunction: (item, testId) => {
          let data = {
            ...newMetaData,
            testOutcome: {
              ...newMetaData.testOutcome,
              values: newMetaData.testOutcome.values.map(val => {
                const days =
                  getDays(val.validityIntervalInSeconds) * 60 * 60 * 24;
                return val.id === testId
                  ? {
                      ...val,
                      validityIntervalInSeconds: days + item * (60 * 60)
                    }
                  : { ...val };
              })
            }
          };

          updateTestingConfiguration(data);
        }
      },
      isEditing: setIsEditing,
      icon: "testResult_invalid"
    },
    {
      id: "Row11_Recipient",
      type: "test_org_facility_name",
      secondColumn: institutionName,
      thirdColumn: null,
      isEditing: setIsEditing
    }
  ];

  const contactColumns = React.useMemo(
    () => [
      {
        Header: () => null,
        accessor: "type",

        Cell: ({ value, row: { original } }) => (
          <div className="header-content-row">
            <div>{value && t(value)}</div>
            {original.type === "test_results" ? (
              <span className="badge">1 </span>
            ) : original.type === "expiration" ? (
              <span className="badge">2 </span>
            ) : original.id === "Row11_Recipient" ? (
              <span className="badge">4 </span>
            ) : null}
          </div>
        )
      },

      {
        Header: () => null,
        accessor: "secondColumn",
        className: "secondColumn",
        Cell: props => {
          if (props.value && props.value.input === "days")
            return (
              <div className="days-hours-input">
                <Icon type={props.row.original.icon} />
                <span>{t("days")}</span>
                <EditableCell
                  inputType="days"
                  {...props}
                  value={props?.value?.value ?? props.value}
                />
              </div>
            );

          if (
            props.row.original.id === "Test organization" ||
            props.row.original.id === "Row11_Recipient"
          )
            return (
              <div>
                <p>{props.value}</p>
                <p>
                  <i>({t("test_org_facility_name_description")})</i>
                </p>
              </div>
            );

          return (
            <div className="cell-icon">
              <Icon type={props.row.original.icon} />
              <EditableCell
                {...props}
                value={props?.value?.value ?? props.value}
              />
            </div>
          );
        }
      },
      {
        Header: () => null,
        accessor: "thirdColumn",
        className: "thirdColumn",
        Cell: props => {
          if (
            props.row.original.id === "Test organization" ||
            props.row.original.id === "Row11_Recipient"
          )
            return null;

          if (props.value.input === "hours")
            return (
              <div className="days-hours-input">
                <span>{t("hours")}</span>
                <EditableCell
                  inputType={"hours"}
                  {...props}
                  value={props?.value?.value ?? props.value}
                />
              </div>
            );

          return (
            <EditableCell
              {...props}
              value={props?.value?.value ?? props.value}
              badgeValue={3}
            />
          );
        }
      }
    ],
    [t]
  );

  return (
    <div
      className={
        selectedTestType?.markedForIntegration
          ? "recipient-container top-container"
          : "recipient-container"
      }
    >
      <div className="container-left">
        <img src={phoneImage9} alt="phone" className="phone-image-8" />
      </div>
      <div className="container-right">
        <div className="title-top">
          <PageTitle title={t("test_recipient")}></PageTitle>
          {selectedTestType?.markedForIntegration && (
            <Button
              variant="primary"
              className="header-add-button"
              disabled={setIsEditing}
              onClick={() => {
                setTestingProfileDataForEdit("ADD", "testProvider");
              }}
            >
              {t("edit")}
            </Button>
          )}
        </div>
        <Table
          columns={contactColumns}
          data={contacts}
          className="test-recipients-table"
          Header={() => null}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default TestRecipient;
