import React from "react";
import Notifications from "react-notification-system-redux";
import "./notifications.scss";

class Notifier extends React.Component {
  render() {
    const { notifications } = this.props;

    // eslint-disable-next-line
    return <Notifications notifications={notifications} style={false} />;
  }
}

export default Notifier;
