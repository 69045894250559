import { SET_SELECTED_ORGANIZATION } from "store/reducers/organizations/types";
import { LOGOUT_USER } from "store/reducers/user/types";
import { SET_EXPORTS, CREATE_EXPORT } from "./types";

const initialState = [];

const exportData = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EXPORTS: {
      return [
        ...(payload.exportData?.sort((a, b) => b.createdAt - a.createdAt) || [])
      ];
    }

    case CREATE_EXPORT: {
      return [{ ...payload }, ...state];
    }

    case LOGOUT_USER:
    case SET_SELECTED_ORGANIZATION:
      return initialState;
    default:
      return state;
  }
};

export default exportData;
