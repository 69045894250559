import { connect } from "react-redux";
import { Notifications } from "components";

const mapStateToProps = state => ({
  notifications: state.notifications
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
