import React from "react";
import PageTitle from "components/PageTitle";
import Link from "components/Link";
import "./pageNotFound.scss";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="page-not-found">
      <PageTitle
        title={t("page_not_found")}
        subtitle={t("page_not_found_navigate")}
      />
      <Link className="p" to="/">
        {t("back_to_home")}
      </Link>
    </div>
  );
};

export default PageNotFound;
