import {
  SET_ORGANIZATIONS,
  CREATE_ORGANIZATION,
  SET_ORGANIZATION_USERS,
  CREATE_ORGANIZATION_USER,
  UPDATE_ORGANIZATION_USER,
  DELETE_ORGANIZATION_USER,
  SET_SELECTED_ORGANIZATION,
  ADMIN_UPDATE_ORGANIZATION,
  SHOW_USER_FORM,
  HIDE_USER_FORM
} from "./types";

export const setSelectedOrganization = organization => ({
  type: SET_SELECTED_ORGANIZATION,
  payload: {
    organization
  }
});

export const setOrganizations = organizations => ({
  type: SET_ORGANIZATIONS,
  payload: {
    organizations
  }
});

export const adminUpdateOrganizationName = (
  institutionName,
  organizationId,
  reference,
  countryCode
) => ({
  type: ADMIN_UPDATE_ORGANIZATION,
  payload: {
    institutionName,
    organizationId,
    reference,
    countryCode
  }
});

export const createOrganization = (
  institutionName,
  organizationId,
  reference,
  countryCode
) => ({
  type: CREATE_ORGANIZATION,
  payload: {
    institutionName,
    organizationId,
    reference,
    countryCode
  }
});

export const setOrganizationUsers = users => ({
  type: SET_ORGANIZATION_USERS,
  payload: {
    users
  }
});

export const updateOrganizationUser = (
  firstName,
  lastName,
  roles,
  id,
  email
) => ({
  type: UPDATE_ORGANIZATION_USER,
  payload: {
    firstName,
    lastName,
    roles,
    id,
    email
  }
});

export const createOrganizationUser = (
  firstName,
  lastName,
  email,
  roles,
  id
) => ({
  type: CREATE_ORGANIZATION_USER,
  payload: {
    firstName,
    lastName,
    email,
    roles,
    id
  }
});

export const deleteOrganizationUser = id => ({
  type: DELETE_ORGANIZATION_USER,
  payload: {
    id
  }
});

export const showUserForm = () => ({ type: SHOW_USER_FORM });
export const hideUserForm = () => ({ type: HIDE_USER_FORM });
