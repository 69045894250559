import React from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import SelectOption from "components/SelectOption";
import Error from "components/Error";
import { ModalActions } from "components/Modal";
import { isRoleDisabled } from "utils/roleManager";
import "./EditUserForm.scss";
import { useValidation } from "utils/useValidation";
import { noWhiteSpacesForRequiredFields } from "utils/FieldValidationRules";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/useLanguage";

export default function EditUserForm(props) {
  const {
    hide = () => {},
    id = null,
    firstName = "",
    lastName = "",
    email = "",
    roles = [],
    loading,
    rolesList
  } = props;

  const { t } = useTranslation();
  const { flippedNameOrder } = useLanguage();

  const {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    getValues,
    setError
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName,
      lastName,
      email,
      roles
    }
  });
  const { emailValidation, noRomanCharacter } = useValidation();

  //magic fix for useForm' magic issue with validity status
  JSON.stringify(formState);

  React.useEffect(() => {
    register(
      { name: "roles" },
      {
        validate: v => {
          return (
            v.length > 0 ||
            t("health_care_organizations_users_add_modal_access_required")
          );
        }
      }
    );
  }, [register, t]);

  const onSubmit = async params => {
    if (formState.isValid) {
      const result = await props.onSubmit({
        ...params,
        roles: (params.roles || []).map(r => ({
          roleId: r.roleId || r
        })),
        id
      });
      if (result === "UsernameExistsException") {
        setError(
          "email",
          "required",
          t("health_care_organizations_users_add_modal_email_used")
        );
      } else if (result === "CredentialsError") {
        setError(
          "request",
          "required",
          t("health_care_organizations_users_add_modal_generic_error")
        );
      }
    }
  };

  const handleRoleChange = values => {
    setValue(
      "roles",
      values && values.length > 0
        ? values.map(({ value }) => ({ roleId: value }))
        : [],
      true
    );
  };

  return (
    <Modal visible={true}>
      <Title>
        {id !== null
          ? t("edit_user")
          : t("health_care_organizations_users_add")}
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)} className="edit-user-form">
        {flippedNameOrder ? (
          <>
            <FormGroup>
              <Label htmlFor="lastName" error={errors.lastName}>
                {t("last_name")}
              </Label>
              <Input
                type="text"
                name="lastName"
                error={errors.lastName}
                ref={register({
                  validate: noWhiteSpacesForRequiredFields,
                  required: t("form_field_is_required"),
                  maxLength: {
                    value: 255,
                    message: t("field_max_length", {
                      number_of_characters: 255
                    })
                  }
                })}
              />
              {errors.lastName && <Error>{errors.lastName.message}</Error>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="firstName" error={errors.firstName}>
                {t("first_name")}
              </Label>
              <Input
                type="text"
                name="firstName"
                error={errors.firstName}
                ref={register({
                  validate: noWhiteSpacesForRequiredFields,
                  required: t("form_field_is_required"),
                  maxLength: {
                    value: 255,
                    message: t("field_max_length", {
                      number_of_characters: 255
                    })
                  }
                })}
              />
              {errors.firstName && <Error>{errors.firstName.message}</Error>}
            </FormGroup>
          </>
        ) : (
          <>
            <FormGroup>
              <Label htmlFor="firstName" error={errors.firstName}>
                {t("first_name")}
              </Label>
              <Input
                type="text"
                name="firstName"
                error={errors.firstName}
                ref={register({
                  validate: noWhiteSpacesForRequiredFields,
                  required: t("form_field_is_required"),
                  maxLength: {
                    value: 255,
                    message: t("field_max_length", {
                      number_of_characters: 255
                    })
                  }
                })}
              />
              {errors.firstName && <Error>{errors.firstName.message}</Error>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="lastName" error={errors.lastName}>
                {t("last_name")}
              </Label>
              <Input
                type="text"
                name="lastName"
                error={errors.lastName}
                ref={register({
                  validate: noWhiteSpacesForRequiredFields,
                  required: t("form_field_is_required"),
                  maxLength: {
                    value: 255,
                    message: t("field_max_length", {
                      number_of_characters: 255
                    })
                  }
                })}
              />
              {errors.lastName && <Error>{errors.lastName.message}</Error>}
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Label htmlFor="email" error={errors.email}>
            {t("health_care_organizations_users_email")}
          </Label>
          <Input
            type="text"
            name="email"
            error={errors.email}
            disabled={id !== null}
            onChange={noRomanCharacter}
            ref={register({
              validate: {
                noWhiteSpacesForRequiredFields,
                emailValidation
              },
              required: t("form_field_is_required"),
              maxLength: {
                value: 128,
                message: t("field_max_length", { number_of_characters: 128 })
              }
            })}
          />
          {errors.email && <Error>{errors.email.message}</Error>}
        </FormGroup>
        <FormGroup>
          <Label error={errors.roles}>{t("roles")}</Label>
          <SelectOption
            isSearchable
            isMulti
            options={rolesList}
            placeholder={t("select")}
            defaultValue={roles.map(r => r.roleId)}
            onChange={handleRoleChange}
            error={errors.roles}
            isOptionDisabled={option => {
              const { roles } = getValues();
              return isRoleDisabled(option.value, roles);
            }}
          />
          {errors.roles && <Error>{errors.roles.message}</Error>}
          {errors.request && <Error>{errors.request.message}</Error>}
        </FormGroup>

        <ModalActions>
          <Button
            variant="primary-full-width"
            className="margin-top--medium"
            type="submit"
            loading={loading}
            disabled={!formState.isValid || loading}
          >
            {id !== null ? t("save") : t("health_care_organizations_users_add")}
          </Button>
          <Button variant="cancel-text" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
