import React from "react";
import classNames from "classnames";
import "./LargeInput.scss";

const LargeInput = React.forwardRef(
  ({ className, placeholder, disabled, error, ...props }, ref) => (
    <input
      disabled={disabled}
      className={classNames(
        "large-input",
        { "large-input--error": error },
        className
      )}
      placeholder={placeholder}
      {...props}
      ref={ref}
    />
  )
);
export default LargeInput;
