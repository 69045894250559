import * as actions from "./actions";
import * as api from "api/patients";
import { getPatientProfileSurvey } from "../surveys/services";
import { getSurveyById } from "api/surveys";
import { success, error } from "react-notification-system-redux";
import i18n from "../../../translations";

export const getPatients = (controller) => async (dispatch, getState) => {
  const state = getState();
  const { config } = state;
  const result = await dispatch(api.getPatients(controller));
  if(result === "request cancelled") {
    return;
  }
  if (result && result.status === 200) {
    const patientList = result.data;
    dispatch(actions.setPatients(patientList, config.countries));
  }
};

export const getMembers = (controller) => async (dispatch, getState) => {
  const state = getState();
  const { config } = state;
  const result = await dispatch(api.getMembers(controller));
  if(result === "request cancelled") {
    return;
  }
  if (result && result.status === 200) {
    const memberList = result.data;
    dispatch(actions.setMembers(memberList, config.countries));
  }
};

export const searchSinglePatient = patient => async dispatch => {
  dispatch(actions.clearFilteredPatient([]));
  const result = await dispatch(api.searchSinglePatient(patient));
  if (result && result.data && result.status === 200) {
    const patientList = result.data;
    dispatch(actions.setFilteredPatients(result.data));
    if (patientList.length) {
      return true;
    }
  }
  return false;
};

export const getSelectedPatientData = (
  country,
  id,
  surveyId,
  type
) => async dispatch => {
  const patientResult = dispatch(api.getPatientData(id));
  const secretResult = dispatch(api.getSecret(id));
  if(country) {
    dispatch(getPatientProfileSurvey(country));
  }
  if (surveyId) {
    dispatch(getSelectedPatientSurvey(surveyId));
  }

  const [patientData, secret] = await Promise.all([
    patientResult,
    secretResult
  ]);

  dispatch(getTestResults(id, type));

  if (patientData?.data.patientId === null) {
    dispatch(actions.setSelectedPatient({}));
    return false;
  } else if (patientData?.data) {
    const patient = secret.data
      ? { ...secret.data, ...patientData.data }
      : patientData.data;
    dispatch(actions.setSelectedPatient(patient));
    return true;
  } else {
    dispatch(
      error({
        title: i18n.t("error_title"),
        message: i18n.t("error"),
        position: "tc"
      })
    );
    return false;
  }
};

export const createPatient = patient => async (dispatch, getState) => {
  const state = getState();
  const { config } = state;
  const result = await dispatch(api.createPatient(patient));
  if (result?.message === "Request failed with status code 412") {
    dispatch(actions.showPatientForm());
    return "duplicate";
  }
  if (result && result.status === 201 && result.data) {
    const { data } = result;
    let newPatient = {
      patientId: data.patientId,
      firstName: data.profile.firstName,
      lastName: data.profile.lastName,
      dateOfBirth: data.profile.dateOfBirth,
      phoneNumber: data.profile.phoneNumber,
      zipCode: data.profile.zipCode,
      email: data.configuration.email || "",
      country: data.configuration.country,
      patientProfile: data
    };
    dispatch(actions.createPatient(newPatient, config.countries));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_user_added"),
        position: "tc"
      })
    );
    return true;
  }
  return false;
};

export const linkPatient = patient => async (dispatch, getState) => {
  let id = patient.patientId;
  let timeStamp = patient.consentAcceptedAt;
  const state = getState();
  const { patients, config } = state;
  const { patientList } = patients;
  let alreadyExists = patientList.find(patient => patient.patientId === id);
  if (alreadyExists) {
    dispatch(
      error({
        title: i18n.t("error_title"),
        message: i18n.t("user_already_exists"),
        position: "tc"
      })
    );
    return false;
  } else {
    const result = await dispatch(api.linkPatient(id, timeStamp));
    if (result && result.status === 200) {
      let newPatient = {
        patientId: patient.patientId,
        firstName: patient.profile.firstName,
        lastName: patient.profile.lastName,
        dateOfBirth: patient.profile.dateOfBirth,
        phoneNumber: patient.profile.phoneNumber,
        zipCode: patient.profile.zipCode,
        email: patient.configuration.email || "",
        country: patient.configuration.country,
        patientProfile: patient
      };
      dispatch(actions.createPatient(newPatient, config.countries));

      dispatch(
        success({
          title: i18n.t("success"),
          message: i18n.t("success_user_added"),
          position: "tc"
        })
      );
      return true;
    }
  }
  return false;
};

export const updatePatient = (id, patient) => async dispatch => {
  const result = await dispatch(api.updatePatient(id, patient));
  if (result?.message === "Request failed with status code 412")
    return "duplicatedEmail";
  if (result && result.status === 200) {
    dispatch(actions.updatePatients(id, patient));
    dispatch(actions.updatePatient(patient));
    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("success_user_updated"),
        position: "tc"
      })
    );
    dispatch(hidePatientForm);
    return true;
  } else {
    return false;
  }
};

export const getSelectedPatientSurvey = id => async dispatch => {
  const result = await dispatch(getSurveyById(id));
  dispatch(actions.setSelectedPatientSurvey(result.data));
};

export const getTestResults = (id, type) => async dispatch => {
  const result = await dispatch(api.getTestResults(id, type));
  dispatch(actions.setTestResults(result.data));
};

export const getFilteredPatientList = (params, nextToken) => async dispatch => {

  let countRequest = 0;

  const fetchMoreData = async (myToken) => {
    // fisrt call gonna verify the nextmyToken is null or not
    const { data: { nextToken: token, serverTime, results } } = await dispatch(api.getFilteredPatientList(params, myToken));

    const response = { token,  serverTime }

    if (token !== null) {
      if (results.length < 1 && countRequest < 5) {
        countRequest++
        return await fetchMoreData(token);
      }
    }

    await dispatch(actions.setFilteredPatients(results));
    return response
  }

  const data = await fetchMoreData(nextToken);

  return data
};

export const clearState = () => dispatch => {
  dispatch(actions.setSelectedPatient({}));
  dispatch(actions.setFilteredPatients([]));
  dispatch(actions.setSelectedPatientSurvey({}));
  dispatch(actions.setTestResults([]));
};

export const clearFilteredPatient = () => dispatch => {
  dispatch(actions.clearFilteredPatient([]));
};

export const clearPatientList = () => dispatch => {
  dispatch(actions.clearPatientList());
};

export const clearSelectedPatient = () => dispatch => {
  dispatch(actions.setSelectedPatient({}));
  dispatch(actions.setSelectedPatientSurvey({}));
  dispatch(actions.setTestResults([]));
};

export const showPatientForm = () => dispatch =>
  dispatch(actions.showPatientForm());

export const hidePatientForm = () => dispatch =>
  dispatch(actions.hidePatientForm());
