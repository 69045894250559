import axios from "utils/Axios";
import { makeSurveyData } from "utils/mockData";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";
import {SURVEY_FOR_TEST_TYPE, SURVEY_FOR_TEST_SHARING_WITH_COHORT} from "const/surveyTypes";

export const getSurveyData = ({ countryCode, surveyType, name, scope }) => {
  return makeAxiosRequest(`get${name}SurveyData`, {
    client: axios,
    method: "get",
    relativePath:
      surveyType !== SURVEY_FOR_TEST_TYPE && surveyType !== SURVEY_FOR_TEST_SHARING_WITH_COHORT
        ? `/surveys?country=${countryCode}&surveyType=${surveyType}`
        : `/surveys?country=${countryCode}&surveyType=${surveyType}&scope=${scope}`
  });
};

export const getMockedSurveyData = ({
  countryCode,
  surveyType,
  scope,
  name
}) => {
  return makeRequest(`get${name}SurveyData`, {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeSurveyData(countryCode, surveyType, scope);
            resolve({ data: { status: 200, data: mockData } });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const getSurveyById = id => {
  return makeAxiosRequest(`getSurveyById`, {
    client: axios,
    method: "get",
    relativePath: `/surveys/${id}`
  });
};

export const updateSurvey = data => {
  const { surveyId, survey, countryCode } = data;
  return makeAxiosRequest(`updateSurvey`, {
    client: axios,
    method: "put",
    relativePath: `/surveys/${surveyId}`,
    data: {
      survey,
      countryCode
    }
  });
};

export const updateMockedSurvey = data => {
  return makeRequest(`updateSurvey`, {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              data: { status: 200 }
            });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const createSurvey = data => {
  const { surveyType, scopeId, survey, countryCode } = data;
  return makeAxiosRequest(`createSurvey`, {
    client: axios,
    method: "post",
    relativePath: `/surveys`,
    data: {
      countryCode,
      surveyType,
      scopeId,
      survey
    }
  });
};

export const validateSurvey = (data) => {
  return makeAxiosRequest(`validateSurvey`, {
    client: axios,
    method: "post",
    relativePath: `/surveys/validate`,
    data: data
  });
};

export const createMockedSurvey = data => {
  return makeRequest(`createSurvey`, {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              data: { status: 200 }
            });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const deleteSurvey = (id, countryCode) => {
  return makeAxiosRequest("deleteSurvey", {
    client: axios,
    method: "delete",
    relativePath: `/surveys/${id}`,
    options: { data: { countryCode: countryCode } }
  });
};

export const saveTestTypeSurveyAnswers = (testId, data) => {
  const { surveyId, surveyVersion, surveyAnswers, surveyChecksum } = data;
  return makeAxiosRequest("saveTestTypeSurvey", {
    client: axios,
    method: "post",
    relativePath: `/tests/${testId}/survey-answers`,
    data: {
      surveyId,
      surveyVersion,
      surveyAnswers,
      surveyChecksum
    }
  });
};
