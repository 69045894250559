import Button from "components/Button";
import EditableCell from "components/EditableCell";
import PageTitle from "components/PageTitle";
import Table from "components/Table";
import Icon from "components/Icon";
import FormGroup from "components/FormGroup";
import PropTypes from "prop-types";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { EditingContext } from "components/AccessProfiles/AccessProfiles";
import Error from "components/Error";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { LANGUAGE } from "const/app";

import "react-datepicker/dist/react-datepicker.css";
import "./TestProfileContacts.scss";
import { languageMapping } from "utils/formatData";
import { useTranslation } from "react-i18next";

registerLocale(LANGUAGE, languageMapping?.[LANGUAGE]);

const defaultColumn = {
  Cell: EditableCell
};

const TestProfileContacts = ({
  testingProfileUpdating,
  selectedTestingProfile,
  saveContactData,
  setTestingProfileDataForEdit,
  isAccessProfile = false
}) => {
  const { t } = useTranslation();
  const {
    metaData: { contactDetails }
  } = selectedTestingProfile;
  const isEditing = selectedTestingProfile?.isEditing.includes("contacts")
    ? true
    : false;

  let contacts = [
    {
      id: "institutionName",
      type: t("contact_channel_organization"),
      data: contactDetails?.institutionName,
      isEditing: isEditing,
      table: "contacts"
    },
    {
      id: "institutionAddress",
      type: t("contact_channel_address"),
      data: contactDetails?.institutionAddress,
      isEditing: isEditing,
      table: "contacts"
    },
    {
      id: "institutionWebLink",
      type: t("contact_channel_website"),
      data: contactDetails?.institutionWebLink,
      isEditing: isEditing,
      table: "contacts"
    },
    {
      id: "institutionPhoneNumber",
      type: t("phone_number"),
      data: contactDetails?.institutionPhoneNumber,
      isEditing: isEditing,
      table: "contacts"
    }
  ];

  const context = React.useContext(EditingContext);

  if (isAccessProfile) {
    const accessEditing = context?.currentlyEditing?.sections?.includes(
      "contacts"
    );
    contacts = [
      {
        id: "institutionName",
        type: t("contact_channel_event"),
        data: contactDetails?.institutionName,
        isEditing: accessEditing,
        table: "contacts",
        formId: "contactDetails.institutionName"
      },
      {
        id: "institutionAddress",
        type: t("contact_channel_address"),
        data: contactDetails?.institutionAddress,
        isEditing: accessEditing,
        table: "contacts",
        formId: "contactDetails.institutionAddress"
      },
      {
        id: "institutionWebLink",
        type: t("contact_channel_website"),
        data: contactDetails?.institutionWebLink,
        isEditing: accessEditing,
        table: "contacts",
        formId: "contactDetails.institutionWebLink"
      },
      {
        id: "institutionPhoneNumber",
        type: t("phone_number"),
        data: contactDetails?.institutionPhoneNumber,
        isEditing: accessEditing,
        table: "contacts",
        formId: "contactDetails.institutionPhoneNumber"
      },
      {
        id: "institutionDate",
        type: t("event_date"),
        data: selectedTestingProfile?.metaData?.eventStartTime,
        isEditing: accessEditing,
        table: "contacts",
        formId: "eventStartTime"
      }
    ];
  }

  const onEdit = React.useCallback(async () => {
    setTestingProfileDataForEdit("ADD", "contacts");
  }, [setTestingProfileDataForEdit]);

  const update = React.useCallback(
    async (id, value) => {
      await saveContactData(id, value);
    },
    [saveContactData]
  );

  const accessProfilesColumns = React.useMemo(
    () => [
      {
        Header: t("admin_table_channel"),
        className: "channels",
        accessor: "type",
        Cell: ({ value }) => <p>{value}</p>
      },
      {
        Header: t("admin_table_contact"),
        accessor: "data",
        Cell: ({ row, value, column }) => {
          const { register, formState, errors, control } = useFormContext();
          const { dirtyFields } = formState;

          if (row.original.isEditing) {
            if (row.original.formId === "eventStartTime") {
              return (
                <div className="dates-container">
                  <FormGroup>
                    <Icon type="calendar" />
                    <Controller
                      as={ReactDatePicker}
                      control={control}
                      valueName="selected" // DateSelect value's name is selected
                      onChange={([selected]) => {
                        return selected;
                      }}
                      name={row.original.formId}
                      className="date-picker"
                      placeholderText={t("select")}
                      autoComplete="off"
                      locale={languageMapping[LANGUAGE] || LANGUAGE}
                      dateFormat="Pp"
                      showTimeSelect
                    />
                  </FormGroup>
                </div>
              );
            }
            return (
              <>
                <textarea
                  name={row.original.formId}
                  rows={2}
                  ref={register({
                    maxLength: {
                      value: 128,
                      message: t("field_max_length", {
                        number_of_characters: 128
                      })
                    }
                  })}
                  style={{
                    backgroundColor: dirtyFields.has(row.original.formId)
                      ? "#fffcda"
                      : ""
                  }}
                  className="table-cell-input"
                />
                {errors?.contactDetails?.[row.original.id] && (
                  <Error>
                    {errors?.contactDetails?.[row.original.id]?.message}
                  </Error>
                )}
              </>
            );
          }
          if (row.original.formId === "eventStartTime") {
            let options = {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit"
            };
            return (
              <p>
                {(value && new Date(value).toLocaleString(LANGUAGE, options)) ||
                  ""}
              </p>
            );
          }
          return <p>{value}</p>;
        }
      }
    ],
    [t]
  );
  const contactColumns = React.useMemo(
    () => [
      {
        Header: t("admin_table_channel"),
        className: "channels",
        accessor: "type",
        Cell: ({ value }) => <p>{value}</p>
      },
      {
        Header: t("admin_table_contact"),
        accessor: "data",
        Cell: props => {
          if (props.row.original.id === "institutionDate")
            return <EditableCell inputType="datePicker" {...props} />;
          return <EditableCell {...props} />;
        }
      }
    ],
    [t]
  );

  return (
    <React.Fragment>
      <div className="title-top">
        <PageTitle title={t("contact_information_title")}></PageTitle>

        <Button
          variant="primary"
          className="header-add-button"
          onClick={
            isAccessProfile
              ? e => {
                  e.preventDefault();
                  context.setCurrentlyEditing(prevState => {
                    return {
                      ...prevState,
                      sections: [...prevState.sections, "contacts"]
                    };
                  });
                }
              : onEdit
          }
          disabled={isEditing}
        >
          {t("edit")}
        </Button>
      </div>
      <Table
        columns={isAccessProfile ? accessProfilesColumns : contactColumns}
        data={contacts}
        className={
          isAccessProfile
            ? "access-profiles-contacts-table"
            : "test-profiles-contacts-table"
        }
        defaultColumn={defaultColumn}
        updateMyData={update}
        disabledSort={true}
      />
    </React.Fragment>
  );
};

TestProfileContacts.propTypes = {
  selectedTestingProfile: PropTypes.object,
  saveContactData: PropTypes.func,
  contactUpdating: PropTypes.bool
};

export default TestProfileContacts;
