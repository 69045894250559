import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import Form from "components/Form";
import Wrapper from "components/Wrapper";
import Title from "components/Title";
import Label from "components/Label";
import Input from "components/Input";
import Button from "components/Button";
import Link from "components/Link";
import Error from "components/Error";
import FormGroup from "components/FormGroup";
import { useValidation } from "utils/useValidation";
import { validateToken } from "utils/authHelper";
import { setLoggedUser } from "store/reducers/user/services";
import CreateProfileForm from "components/CreateProfileForm";
import ConfirmPhoneNumberForm from "components/ConfirmPhoneNumberForm";
import MFAForm from "components/MFAForm";
import "./signInForm.scss";
import { AUTH_USER_TOKEN_KEY } from "const/app";
import { CookieConsent } from "components";
import { useTranslation } from "react-i18next";
import {fixture} from 'utils/fixture'

function LoginForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [user, setUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isNewUser, setUserStatus] = React.useState(false);
  const [showConfirmation, setConfirmationStatus] = React.useState(false);
  const [showPhoneConfirmation, setPhoneConfirmationStatus] = React.useState(
    false
  );
  const [consent, setConsent] = React.useState(
    localStorage.getItem("cookie-consent") === "true"
  );

  const { register, handleSubmit, formState, errors, setError } = useForm({
    mode: "onChange"
  });

  const { emailValidation, noRomanCharacter } = useValidation();

  const { isValid, touched } = formState;

  const onSubmit = ({ email, password }) => {
    if (isValid) {
      setLoading(true);

      Auth.signIn(email, password)
        .then(async u => {
          if (u.challengeName === "NEW_PASSWORD_REQUIRED") {
            setUser(u);
            setUserStatus(true);
            setLoading(false);
            return;
          }

          if (u.challengeName === "SMS_MFA") {
            setUser(u);
            setConfirmationStatus(true);
            return;
          }

          await dispatch(setLoggedUser(user || u));
          history.push("/");
        })
        .catch(err => {
          if (err.message === "Incorrect username or password.") {
            setError("password", "invalid", t("email_password_not_match"));
          } else if (err.message === "Password attempts exceeded") {
            setError("password", "invalid", t("too_many_attempts"));
          } else {
            setError("password", "invalid", err.message);
          }
          setLoading(false);
        });
    }
  };

  const onProfileCreateSuccess = user => {
    setUserStatus(false);
    setPhoneConfirmationStatus(true);
  };

  const onProfileCreateError = user => {
    setUserStatus(false);
    setUser(false);
  };

  const cookieConsentAccept = () => {
    localStorage.setItem("cookie-consent", true);
    setConsent(true);
  };

  if (isNewUser) {
    return (
      <CreateProfileForm
        data-testid="is-new-user"
        userInProgress={user}
        onProfileCreateSuccess={onProfileCreateSuccess}
        onProfileCreateError={onProfileCreateError}
      />
    );
  }

  if (showConfirmation) {
    return <MFAForm user={user} />;
  }

  if (showPhoneConfirmation) {
    return <ConfirmPhoneNumberForm />;
  }

  if (validateToken(sessionStorage.getItem(AUTH_USER_TOKEN_KEY)) && !loading) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Wrapper className="sign-in-form">
      {!consent && <CookieConsent cookieConsentAccept={cookieConsentAccept} />}
      <Title>{t("sign_in")}</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="email" error={errors.email}>
            {t("email")}
          </Label>
          <Input
            data-testid="email-input"
            type="text"
            name="email"
            error={touched.email && errors.email}
            onChange={noRomanCharacter}
            ref={register({
              required: t("email_error_required"),
              validate: { emailValidation },
              maxLength: {
                value: 128,
                message: t("email_too_long")
              }
            })}
          />
          {touched.email && errors.email && (
            <Error>{errors.email.message}</Error>
          )}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password" error={errors.password}>
            {t("password")}
          </Label>
          <Input
            data-testid="password-input"
            type="password"
            name="password"
            error={touched.password && errors.password}
            onChange={noRomanCharacter}
            ref={register({
              required: t("password_error_required")
            })}
          />
          {touched.password && errors.password && (
            <Error>{errors.password.message}</Error>
          )}
        </FormGroup>
        <div className="links-container">
          <a
            className="link upper-case"
            href={fixture.url.helpCenter}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t("need_help")}
          </a>
          <Link className="upper-case" to="/forgot-password">
            {t("forgot_password")}
          </Link>
        </div>
        <Button
          data-testid="button-submit"
          variant="primary-full-width"
          loading={loading}
          type="submit"
        >
          {t("sign_in")}
        </Button>
      </Form>
    </Wrapper>
  );
}

export default LoginForm;
