import CohortProfileContacts from "components/CohortProfileContacts";
import Button from "components/Button";
import Icon from "components/Icon";
import QrCode from "components/QrCode";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import "./cohortDetails.scss";
import { useTranslation } from "react-i18next";
import ComponentToPrint from "components/ComponentToPrint";
import { useReactToPrint } from 'react-to-print';
import config from "config/aws-config";

const CohortDetails = ({ cohort, organizationId }) => {
  const { t } = useTranslation();
  const [copyText, setCopyText] = React.useState(t("copy"));
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const copyCodeToClipboard = (shortId, e) => {
    let code = `${shortId}`;
    e.preventDefault();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(code);
    }
    setCopyText(t("copied"));
  };

  const payload = JSON.stringify({
    cohortId: cohort.cohortId,
    shortId : cohort.shortId,
    organizationId: organizationId,
    serverId: config.Auth.region
  });

  return cohort ? (
    <div className="qr-code-details">
      <ComponentToPrint ref={componentRef}>
        <QrCode
          code={`{
            "qr-code-type": "cohort",
            "version": "1.0",
            "payload": ${payload}
          }`}
          type={"testProfile"}
          isPrint={true}
          data={{
            ...cohort,
            title: cohort.name
          }}
        ></QrCode>
      </ComponentToPrint>
      <div className="contact-information">
        <div className="qr-code-details__qr-code">
          <QrCode
           code={`{
            "qr-code-type": "cohort",
            "version": "1.0",
            "payload": ${payload}
            }`}
            onlyQrCode={true}
          />
          <div className="qr-actions">
            <Button
              data-testid="button-printer"
              variant="row-action"
              title={t("print")}
              onClick={e => {
                e.preventDefault();
                handlePrint();
              }}
            >
              <Icon type="printer" />
            </Button>
            <Button
              data-testid="button-copy"
              variant="row-action"
              title={copyText}
              onMouseLeave={() => setCopyText("Copy")}
              onClick={e => copyCodeToClipboard(cohort.shortId, e)}
            >
              <Icon type="copy" />
            </Button>
          </div>
        </div>
        <div className="qr-summary">
          <CohortProfileContacts
            cohort={cohort}
            contactDetails={cohort.contactDetails}
          />
        </div>
      </div>
    </div>
  ) : null;
};

CohortDetails.propTypes = {
  cohort: PropTypes.object
};

export default CohortDetails;
