import React from "react";
import Label from "components/Label";
import Input from "components/Input";
import InputGroup from "components/InputGroup";
import FormGroup from "components/FormGroup";
import Error from "components/Error";
import { useValidation } from "utils/useValidation";
import { useTranslation } from "react-i18next";

function InputZipCode({
  name = "zipCode",
  useForm: { register, errors },
  label = "zip_code"
}) {
  const { t } = useTranslation();
  const { noRomanCharacter } = useValidation();

  return (
    <FormGroup>
      <Label htmlFor={name} error={errors[name]}>
        {t(label)}
      </Label>
      <InputGroup>
        <Input
          data-testid="zip-code-input"
          type="text"
          name={name}
          error={errors[name]}
          onChange={noRomanCharacter}
          ref={register({
            maxLength: {
              value: 15,
              message: t("valid_zip_code")
            }
          })}
        />
      </InputGroup>
      {errors[name] && <Error>{errors[name].message}</Error>}
    </FormGroup>
  );
}

export default InputZipCode;
