import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  getPatients,
  getMembers,
  clearState,
  getTestResults,
  getSelectedPatientData,
  showPatientForm,
  hidePatientForm,
  searchSinglePatient,
  createPatient,
  linkPatient,
  clearPatientList
} from "store/reducers/patients/services";
import { getPatientProfileSurvey } from "store/reducers/surveys/services";
import { ROLE_ENTERPRISE_MANAGER, ROLE_TEST_RECIPIENT_MANAGER } from "const/roles";
import { getCohortProfiles } from "store/reducers/cohorts/services";

import Patients from "components/Patients";

const mapStateToProps = (state, ownProps) => ({
  patients: state.patients.patientList || [],
  patientsLoading: isLoading(state, "getPatients") || isLoading(state, "getMembers"),
  discoveredPatientsLoading:
    isLoading(state, "getDiscoveredPatients") ||
    isLoading(state, "getPatientProfileSurveyData"),
  patientIsCreating: isLoading(state, "createPatient"),
  selectedPatientData: state.patients.selectedPatientData,
  filteredPatients: state.patients.filteredPatientList,
  countries: state.config.countries,
  isAddingPatient: state.patients.isAddingPatient,
  patientProfileSurvey: state?.surveys?.patientProfileSurveyData,
  testResultSurvey: state?.surveys?.testTypeSurveyData,
  patientIsLoading: isLoading(state, "getPatientData"),
  surveyIsLoading:
    isLoading(state, "getPatientProfileSurveyData") ||
    isLoading(state, "getSurveyById") ||
    isLoading(state, "getSignature"),
  membersPage: ownProps.location === "members",
  cohortProfiles: state.cohorts.cohortProfiles,
  hasBothManagerRoles: state.user.roles.includes(ROLE_ENTERPRISE_MANAGER) && state.user.roles.includes(ROLE_TEST_RECIPIENT_MANAGER)
});

const mapDispatchToProps = {
  getPatients,
  clearState,
  getSelectedPatientData,
  getTestResults,
  showPatientForm,
  hidePatientForm,
  getPatientProfileSurvey,
  searchSinglePatient,
  createPatient,
  linkPatient,
  getCohortProfiles,
  getMembers,
  clearPatientList
};

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
