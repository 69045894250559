import React from "react";
import classNames from "classnames";
import Row from "components/Row";
import Col from "components/Col";
import Icon from "components/Icon";
import "./notificationWithIcon.scss";

const NotificationWithIcon = ({ className, iconType, type, children }) => (
  <div className={classNames("notification-wtih-icon", type, className)}>
    <Row>
      <Col>
        <Icon type={iconType} />
      </Col>
      <Col>{children}</Col>
    </Row>
  </div>
);

export default NotificationWithIcon;
