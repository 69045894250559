import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  ROLE_SUPER_ADMIN,
  ROLE_TEST_PROVIDER_ADMIN,
  ROLE_EVENT_ADMIN,
  ROLE_ENTERPRISE_ADMIN,
  ROLE_TEST_PROVIDER,
  ROLE_ACCESS_AGENT,
  ROLE_ACCESS_AGENT_SUPERVISOR,
  ROLE_DATA_EXPORT_TEST_RESULTS,
  ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII,
  ROLE_ENTERPRISE_DATA_ANALYST,
  ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII,
  ROLE_DATA_EXPORT_ACCESS_EVENTS,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_DATA_ANALYST,
  ROLE_TEST_RECIPIENT_MANAGER,
  ROLE_ENTERPRISE_MANAGER
} from "const/roles";

const Home = () => {
  const { roles = [] } = useSelector(state => state.user);
  if (
    roles.indexOf(ROLE_TEST_PROVIDER_ADMIN) > -1 ||
    roles.indexOf(ROLE_EVENT_ADMIN) > -1 ||
    roles.indexOf(ROLE_ENTERPRISE_ADMIN) > -1
  ) {
    return <Redirect to={{ pathname: "/users" }} />;
  }
  if (
    roles.indexOf(ROLE_DATA_EXPORT_TEST_RESULTS) > -1 ||
    roles.indexOf(ROLE_DATA_EXPORT_TEST_RESULTS_WITH_PII) > -1
  ) {
    return <Redirect to={{ pathname: "/test-data-export" }} />;
  }
  if (roles.indexOf(ROLE_DATA_EXPORT_ACCESS_EVENTS) > -1) {
    return <Redirect to={{ pathname: "/access-data-export" }} />;
  }
  if (
    roles.indexOf(ROLE_ENTERPRISE_DATA_ANALYST) > -1 ||
    roles.indexOf(ROLE_ENTERPRISE_DATA_ANALYST_WITH_PII) > -1
  ) {
    return <Redirect to={{ pathname: "/cohort-data-export" }} />;
  }
  if (roles.indexOf(ROLE_SUPER_ADMIN) > -1) {
    return <Redirect to={{ pathname: "/organizations" }} />;
  }
  if (roles.indexOf(ROLE_COUNTRY_ADMIN) > -1) {
    return <Redirect to={{ pathname: "/data-elements" }} />;
  }
  if (roles.indexOf(ROLE_COUNTRY_DATA_ANALYST) > -1) {
    return <Redirect to={{ pathname: "/country-analyst-export" }} />;
  }
  if (roles.indexOf(ROLE_TEST_RECIPIENT_MANAGER) > -1) {
    return <Redirect to={{ pathname: "/test-recipients" }} />;
  }
  if (roles.indexOf(ROLE_ENTERPRISE_MANAGER) > -1) {
    return <Redirect to={{ pathname: "/members" }} />;
  }
  if (
    roles.indexOf(ROLE_TEST_PROVIDER) > -1 ||
    roles.indexOf(ROLE_ACCESS_AGENT) > -1 ||
    roles.indexOf(ROLE_ACCESS_AGENT_SUPERVISOR) > -1
  ) {
    return <Redirect to={{ pathname: "/profile" }} />;
  }

  return <Redirect to={{ pathname: "/profile" }} />;
};

export default Home;
