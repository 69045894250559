import * as api from "api/qrCodes";
import * as actions from "./actions";
import { success, error } from "react-notification-system-redux";
import { getDefaultTestConfig } from "../config/services";
import { createTestingProfile } from "../qrCodes/services";
import { setTestingProfileForEdit, updateQrCode } from "../qrCodes/actions";
import i18n from "../../../translations";
import * as _ from "lodash";

export const getDefaultConfig = () => async dispatch => {
  return await dispatch(getDefaultTestConfig());
};

export const createTestType = data => async (dispatch, getState) => {
  const state = getState();
  const { qrCodeData } = state;
  if (!qrCodeData?.newMetaData) {
    await dispatch(actions.createCopyOfMetaData());
  }
  if (data.hasOwnProperty("isEditing")) {
    delete data.isEditing;
  }
  dispatch(actions.createTestType(data));
  dispatch(actions.setTestingProfileDataForEdit("ADD", "testTypeCreated"));
  const updatedState = getState();
  const { newMetaData } = updatedState.qrCodeData;
  const { testConfiguration } = newMetaData;
  dispatch(actions.updateTestConfig(testConfiguration));
};

export const deleteTestTypeUpdate = id => async (dispatch, getState) => {
  const state = getState();
  const { qrCodeData } = state;
  if (!qrCodeData?.newMetaData) {
    await dispatch(actions.createCopyOfMetaData());
  }
  await dispatch(actions.deleteTestType(id));
  if (qrCodeData?.isEditing.includes("testTypeCreated")) {
    dispatch(actions.setTestingProfileDataForEdit("REMOVE", "testTypeCreated"));
    dispatch(actions.setTestingProfileDataForEdit("REMOVE", "testType"));
    dispatch(actions.setTestingProfileDataForEdit("REMOVE", "testProvider"));
    dispatch(actions.setTestingProfileDataForEdit("REMOVE", "checkbox"));
  } else {
    dispatch(actions.setTestingProfileDataForEdit("ADD", "testTypeDeleted"));
  }
  const updatedState = getState();
  const { newMetaData } = updatedState.qrCodeData;
  const { testConfiguration } = newMetaData;
  dispatch(actions.updateTestConfig(testConfiguration));
};

export const getTestingProfileDetails = code => async (dispatch, getState) => {
  const state = getState();
  const { qrCodeData } = state;
  await dispatch(getDefaultTestConfig());

  if (
    !qrCodeData?.qrCode ||
    (qrCodeData?.qrCode && qrCodeData.qrCode !== code)
  ) {
    const result = await dispatch(api.getTestingProfileDetails(code));
    if (result && result.status === 200) {
      const qrCodeData = result.data;
      await dispatch(actions.setQrCodeData(qrCodeData, code));
      dispatch(actions.setFieldErrors({}));

      return qrCodeData;
    }
  }
};

export const duplicateTestingProfile = id => async (dispatch, getState) => {
  const result = await dispatch(api.getTestingProfileDetails(id));
  if (result && result.status === 200 && result.data) {
    let duplicate = {
      name: `${result.data.name.substring(0, 123)}-copy`,
      description: result.data.description,
      combo: result.data.isComboTestProfile,
      contactDetails: result.data.contactDetails || {},
      testConfiguration: result.data.testConfiguration || []
    };
    dispatch(createTestingProfile(duplicate));
  } else {
    dispatch(error({ message: i18n.t("error_generic") }));
    return false;
  }
};

export const setTestingProfileDataForEdit = (type, name) => (
  dispatch,
  getState
) => {
  const state = getState();
  if (!state.qrCodeData?.newMetaData) {
    dispatch(actions.createCopyOfMetaData());
  }
  dispatch(actions.setTestingProfileDataForEdit(type, name));
};

export const setTestTypeForEdit = id => (dispatch, getState) => {
  const state = getState();
  if (!state.qrCodeData?.newMetaData) {
    dispatch(actions.createCopyOfMetaData());
  }
  dispatch(actions.setTestTypeForEdit(id));
};

export const setCheckboxForEdit = (testTypeId, id, value) => async (
  dispatch,
  getState
) => {
  const state = getState();
  if (!state.qrCodeData?.newMetaData) {
    await dispatch(actions.createCopyOfMetaData());
  }
  dispatch(saveCheckboxData(testTypeId, id, value));
  dispatch(saveTestTypeData(testTypeId, id, value));
  const newState = getState();

  if (value === true) {
    await dispatch(resetProviderFields(testTypeId));
  }

  if (!state.qrCodeData.isEditing.includes("testTypeCreated")) {
    let equal = _.isEqual(
      newState.qrCodeData.newMetaData?.testConfiguration.find(
        item => item.testTypeId === testTypeId
      ).markedForIntegration,
      newState.qrCodeData.originalMetaData?.testConfiguration.find(
        item => item.testTypeId === testTypeId
      ).markedForIntegration
    );

    if (equal) {
      await dispatch(
        actions.setTestingProfileDataForEdit("REMOVE", "checkbox")
      );
    } else {
      await dispatch(actions.setTestingProfileDataForEdit("ADD", "checkbox"));
    }
  }
};

export const saveContactData = (id, value) => dispatch => {
  dispatch(actions.saveContactData(id, value));
};

export const saveProfileData = (id, value) => dispatch => {
  dispatch(actions.saveProfileData(id, value));
};

export const saveTestTypeData = (testTypeId, id, value) => dispatch => {
  dispatch(actions.saveTestTypeData(testTypeId, id, value));
};

export const saveCheckboxData = (testTypeId, id, value) => dispatch => {
  dispatch(actions.saveCheckboxData(testTypeId, id, value));
};
export const resetProviderFields = testTypeId => dispatch => {
  dispatch(actions.resetProviderFields(testTypeId));
};

export const cancelUpdate = () => async (dispatch, getState) => {
  const state = getState();
  const { originalMetaData } = state.qrCodeData;
  await dispatch(actions.updateTestingProfile());
  await dispatch(actions.updateTestingProfile(originalMetaData));
  dispatch(updateQrCode(originalMetaData.testingProfileId));
  dispatch(
    updateQrCode(
      originalMetaData.testingProfileId,
      originalMetaData.name,
      originalMetaData.description
    )
  );
  dispatch(actions.deleteCopyOfMetaData());
  dispatch(setTestingProfileForEdit());
};

export const updateMetaData = () => async (dispatch, getState) => {
  const state = getState();
  const { newMetaData } = state.qrCodeData;
  const { contactDetails } = newMetaData;

  var inValid =
    Object.keys(contactDetails).length < 4 ||
    Object.keys(contactDetails).some(k => {
      return (
        contactDetails[k] === "" ||
        contactDetails[k] === undefined ||
        contactDetails[k] === null
      );
    });

  if (inValid) {
    dispatch(
      error({
        title: i18n.t("error_title"),
        message: i18n.t("error_contact_info"),
        position: "tc"
      })
    );
    dispatch(actions.setTestingProfileDataForEdit("ADD", "contacts"));
  } else {
    const result = await dispatch(api.updateTestingProfile(newMetaData));
    if (result && result.status === 200) {
      const { testingProfileId, name, description } = newMetaData;
      dispatch(actions.updateTestingProfile(newMetaData));
      dispatch(updateQrCode(testingProfileId, name, description));
      dispatch(setTestingProfileForEdit());
      dispatch(
        success({
          title: i18n.t("success"),
          message: i18n.t("success_test_profile_updated"),
          position: "tc"
        })
      );
    } else {
      dispatch(cancelUpdate());
    }
    dispatch(actions.deleteCopyOfMetaData());
  }
};

export const createInfinityIntegration = testingProfileId => async dispatch => {
  const result = await dispatch(
    api.createInfinityIntegration(testingProfileId)
  );
  if (result && result.status === 200 && result.data) {
    const {
      data: { integrationId }
    } = result;
    await dispatch(actions.setInfinityIntegration(integrationId));

    dispatch(
      success({
        title: i18n.t("success"),
        message: i18n.t("ready_for_connection"),
        position: "tc"
      })
    );

    return true;
  } else {
    dispatch(error({ message: i18n.t("error_generic") }));
    return false;
  }
};

export const setFieldErrors = data => dispatch => {
  dispatch(actions.setFieldErrors(data));
};
