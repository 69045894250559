import React from "react";
import "./floatNavbar.scss";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

const FloatNavbar = ({
  children,
  onSave,
  onCancel,
  saveDisabled,
  cancelDisabled,
  loading
}) => {
  const { t } = useTranslation();

  return (
    <div className="float-navbar">
      {children ?? (
        <>
          <Button
            variant="primary"
            className="header-add-button"
            onClick={onSave}
            disabled={saveDisabled}
            loading={loading}
          >
            {t("save")}
          </Button>
          <Button
            variant="secondary"
            className="header-add-button "
            onClick={onCancel}
            disabled={cancelDisabled}
          >
            {t("cancel")}
          </Button>
        </>
      )}
    </div>
  );
};

export default FloatNavbar;
