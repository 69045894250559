import React from "react";
import { Icon } from "components";

function GlobalFilter({ value, setGlobalFilter }) {
  return (
    <div className="global-filter-container">
      <input
        className="global-filter-input"
        value={value || ""}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined);
        }}
      />
      <Icon type="search" />
    </div>
  );
}

export default GlobalFilter;
