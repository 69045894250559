import React from "react";
import SearchRecipientsContainer from "../../containers/SearchRecipients.Container";

const SearchMembers = () => (
  <>
    <SearchRecipientsContainer location={"members"}/>
  </>
);

export default SearchMembers;
