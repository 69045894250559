import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import AccessDataExport from "components/AccessDataExport";
import { getAccessProfiles } from "store/reducers/accessProfiles/services";
import { getOrganizations } from "store/reducers/organizations/services";
import { ROLE_SUPER_ADMIN } from "const/roles";
import {
  getExports,
  createExport,
  getDownloadUrl
} from "store/reducers/dataExport/services";

const mapStateToProps = state => ({
  accessProfiles: state.accessProfiles,
  accessProfilesLoading: isLoading(state, "getAccessProfiles"),
  exportData: state.dataExport,
  isSuperAdmin: state.user.roles.includes(ROLE_SUPER_ADMIN),
  organizations: state.organizations.organizations,
  organizationsLoading: isLoading(state, "getOrganizations"),
  organizationId: state.user.organizationId
});

const mapDispatchToProps = {
  getAccessProfiles,
  getOrganizations,
  getExports,
  createExport,
  getDownloadUrl
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessDataExport);
