import jwtDecode from "jwt-decode";

export const validateToken = token => {
  if (!token) {
    return false;
  }
  try {
    const decodedJwt = jwtDecode(token);
    const isValid = decodedJwt.exp >= Date.now() / 1000;
    return isValid;
  } catch (e) {
    return false;
  }
};
