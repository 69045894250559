import React from "react";
import { Button, PageTitle, Table } from "components";
import AddPatientForm from "components/AddPatientForm";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/useLanguage";
import {
  formatDate,
  formatDateTimeNumeric,
  formatPhone,
  formatCountry,
  getPhoneCountry,
  formatDateString
} from "utils/formatData";
import moment from "moment";
import "./patientContacts.scss";
import {
  SINGLE_ANSWER, 
  MULTI_ANSWERS,
  BUTTON,
  DATE, 
  DATETIME
} from "const/surveyTypes";

const PatientContacts = ({
  patient,
  countryPatientSurvey,
  updatePatient,
  patientUpdating,
  countries,
  patientDeleted,
  isMember
}) => {
  const { t } = useTranslation();
  const { flippedNameOrder } = useLanguage();
  const { extendedProfile, profile, configuration } = patient;
  const surveyAnswers = extendedProfile ? extendedProfile.surveyAnswers : [];
  const [editFormStatus, setEditFormStatus] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);

  const surveyInfo = surveyAnswers?.map(item => {
    return {
      id: item?.id,
      type: item?.title,
      data: item?.answers?.map(answer => (
        <p key={answer?.id || item?.id}>
          {
          answer.type === DATE 
            ? formatDate(answer.value) 
            : answer.type === DATETIME
            ? formatDateTimeNumeric(answer.value)
            : answer.value
          }
        </p>
      ))
    };
  });

  const nameColumns = [
    {
      id: "firstName",
      type: t("first_name_cap"),
      data: profile.firstName
    },
    {
      id: "lastName",
      type: t("last_name_cap"),
      data: profile.lastName
    }
  ];

  const nameColumnsFlipped = [
    {
      id: "lastName",
      type: t("last_name_cap"),
      data: profile.lastName
    },
    {
      id: "firstName",
      type: t("first_name_cap"),
      data: profile.firstName
    }
  ];

  const patientInformation = [
    ...(flippedNameOrder ? nameColumnsFlipped : nameColumns),
    {
      id: "dateOfBirth",
      type: t("date_of_birth_label"),
      data: patientDeleted
        ? formatDate(profile.dob)
        : formatDate(profile.dateOfBirth)
    },
    {
      id: "phoneNumber",
      type: t("phone_number"),
      data: patientDeleted
        ? profile.phoneNumber
        : formatPhone(profile.phoneNumber)
    },
    {
      id: "zipCode",
      type: t("zip_code"),
      data: profile.zipCode
    },
    {
      id: "country",
      type: t("health_care_organizations_add_modal_country"), //FIXME: Fix translation key!!! later
      data: formatCountry(configuration.country, countries)
    },
    {
      id: "email",
      type: t("email"),
      data: configuration.email
    },
    ...(!isMember && surveyAnswers?.length ? surveyInfo : [])
  ];

  const contactColumns = React.useMemo(
    () => [
      {
        Header: t("admin_table_channel"),
        className: "channels",
        accessor: "type"
      },
      {
        Header: t("admin_table_contact"),
        accessor: "data"
      }
    ],
    [t]
  );

  const update = React.useCallback(
    async ({
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
      zipCode,
      country,
      email,
      ...rest
    }) => {
      const dateFormat = "YYYY-MM-DD";
      setEmailError(false);
      let answers = Object.keys(rest).map(key => {
        let question = countryPatientSurvey.survey.questions.find(
          q => q.id === key
        );
        let answer;

        if (question?.type === SINGLE_ANSWER) {
          answer = question.answers.find(ans => ans.id === rest[key]);
        } else if (question?.type === MULTI_ANSWERS) {
          answer = question.answers.filter(ans => rest[key]?.includes(ans.id));
        } else answer = question?.answers[0];

        return {
          id: question.id,
          fieldName: question.fieldName,
          title: question.title,
          answers: Array.isArray(rest[key])
            ? rest[key].map(arr => answer.find(ans => ans.id === arr))
            : answer?.type === BUTTON
            ? [{ ...answer }]
            : [{ ...answer, value: answer?.type === DATE || answer?.type === DATETIME ? moment(rest[key]).format(formatDateString(answer.type)) : rest[key] }]
        };
      });

      let response = {
        ...extendedProfile,
        surveyAnswers: answers,
        surveyId: countryPatientSurvey.surveyId,
        surveyVersion: countryPatientSurvey.surveyVersion
      };

      //if there are no additional fields/request fails, send the param as null
      let updatedExtendedProfile = Object.keys(countryPatientSurvey).length
        ? response
        : null;

      let updatedPatient = {
        ...patient,
        profile: {
          firstName: firstName,
          lastName: lastName,
          dateOfBirth: moment(dateOfBirth).format(dateFormat),

          phoneNumber: phoneNumber.number.length
            ? {
                ...phoneNumber,
                country: getPhoneCountry(phoneNumber.countryCode)
              }
            : null,
          zipCode: zipCode.length ? zipCode : null
        },
        configuration: {
          country: country,
          email: email.length ? email : null
        },
        extendedProfile: updatedExtendedProfile
      };
      const updateStatus = await updatePatient(
        patient.patientId,
        updatedPatient
      );

      if (updateStatus === "duplicatedEmail") {
        setEmailError(true);
        return;
      }
      setEditFormStatus(false);
    },
    [updatePatient, patient, extendedProfile, countryPatientSurvey]
  );
 
  return (
    <div className="contact-information">
      {!isMember ? 
      <Button
        variant="primary"
        className="header-add-button"
        data-testid="edit-button"
        onClick={() => {
          setEditFormStatus(true);
        }}
        disabled={patientDeleted}
      >
        {t("edit")}
      </Button> :  null}
      <PageTitle title={t("personal_info_title")} />
      <Table
        columns={contactColumns}
        data={patientInformation}
        className="patient-contacts-table"
        disabledSort={true}
        pagination={false}
      />
      {editFormStatus && (
        <AddPatientForm
          hide={() => {
            setEditFormStatus(false);
            setEmailError(false);
          }}
          onSubmit={update}
          loading={patientUpdating}
          patient={patient}
          additionalFields={countryPatientSurvey}
          type={"UPDATE"}
          emailError={emailError}
        />
      )}
    </div>
  );
};

PatientContacts.propTypes = {};

export default PatientContacts;
