import { makeAxiosRequest, default as makeRequest } from "utils/requests";
import { roles, testTypes } from "utils/mockData";
import axios, { updateAuthToken as updateAxiosAuthToken } from "utils/Axios";
import { AUTH_USER_TOKEN_KEY } from "const/app";
import { setStaticContentUrl } from "utils/setBaseUrls";

export const getConfig = () =>
  makeAxiosRequest("getConfig", {
    client: axios,
    method: "get",
    relativePath: "/config/application"
  });

export const getMockedConfig = () => {
  return makeRequest("getConfig", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              data: {
                data: { roles, testTypes },
                status: 200
              }
            });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const getAboutConfig = () =>
  makeAxiosRequest("getAboutConfig", {
    client: axios,
    method: "get",
    options: { headers: { Authorization: "" } }, // The request fails if the Authorization header exists
    relativePath: setStaticContentUrl("configuration/about_page_config.json")
  });

export const getDefaultTestConfig = () =>
  makeAxiosRequest("getTestConfiguration", {
    client: axios,
    method: "get",
    options: { headers: { Authorization: "" } },
    relativePath: setStaticContentUrl("test_types/rapid_antiget_test.json")
  });

export const getMockedAboutConfig = () => {
  return makeRequest("getAboutConfig", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              data: {
                data: {},
                status: 200
              }
            });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const getTestTypeList = () => {
  const language = "en_us/"; //english is default
  const testTypeList = "test_types.json";
  return makeAxiosRequest("getTestTypeList", {
    client: axios,
    method: "get",
    options: { headers: { Authorization: "" } }, // The request fails if the Authorization header exists
    relativePath: `${setStaticContentUrl(
      "configuration/"
    )}${language}${testTypeList}`
  });
};

export const updateAuthToken = token => {
  if (token) {
    sessionStorage.setItem(AUTH_USER_TOKEN_KEY, token);
  } else {
    sessionStorage.removeItem(AUTH_USER_TOKEN_KEY);
  }
  updateAxiosAuthToken(token);
};

export const getCountries = () =>
  makeAxiosRequest("getCountries", {
    client: axios,
    method: "get",
    options: { headers: { Authorization: "" } },
    relativePath: setStaticContentUrl(window.config.REACT_APP_COUNTRIES)
  });
