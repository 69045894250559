import { LOGOUT_USER } from "store/reducers/user/types";
import { SET_DEFAULT_TEST_CONFIG } from "../config/types";
import {
  CLEAR_QR_CODE_DATA,
  CREATE_COPY_OF_METADATA,
  CREATE_TEST_TYPE,
  DELETE_COPY_OF_METADATA,
  DELETE_TEST_TYPE,
  SAVE_CONTACT_DATA,
  SET_QR_CODE_DATA,
  SET_TESTING_PROFILE_DATA_FOR_EDIT,
  SET_TEST_TYPE_FOR_EDIT,
  UPDATE_TESTING_CONFIGURATION,
  UPDATE_TESTING_PROFILE,
  SAVE_PROFILE_DATA,
  SAVE_TEST_TYPE_DATA,
  UPDATE_TEST_CONFIG,
  FIELD_ERRORS,
  SET_INFINITY_INTEGRATION,
  SAVE_CHECKBOX_DATA,
  RESET_PROVIDER_FIELDS
} from "./types";

const initialState = {};

const qrCodeData = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_QR_CODE_DATA: {
      const { qrCodeData, qrCode } = payload;

      qrCodeData.testConfiguration = qrCodeData.testConfiguration ?? [];

      return {
        ...state,
        qrCode,
        metaData: {
          ...qrCodeData,
          testConfiguration: qrCodeData.testConfiguration.map(
            configuration => ({
              ...configuration,
              markedForIntegration: configuration.markedForIntegration || false
            })
          )
        },
        isEditing: []
      };
    }
    case FIELD_ERRORS: {
      return {
        ...state,
        metaData: {
          ...state.metaData,
          fieldErrors: { ...state.metaData.fieldErrors, ...payload }
        }
      };
    }

    case SET_TEST_TYPE_FOR_EDIT: {
      const { testTypeId } = payload;
      const { metaData } = state;
      const { testConfiguration } = state.metaData;
      return {
        ...state,
        metaData: {
          ...metaData,
          testConfiguration: testConfiguration.map(item =>
            item.testTypeId === testTypeId
              ? { ...item, isEditing: true }
              : { ...item, isEditing: false }
          )
        }
      };
    }
    case SET_TESTING_PROFILE_DATA_FOR_EDIT: {
      let isEditing = [...state?.isEditing] || [];
      isEditing =
        payload.type === "ADD"
          ? [...isEditing, payload.name]
          : isEditing.filter(i => i !== payload.name);
      return {
        ...state,
        isEditing: [...new Set(isEditing)]
      };
    }

    case CREATE_COPY_OF_METADATA: {
      let newMetaData = { ...state.metaData };
      let originalMetaData = { ...state.metaData };
      return {
        ...state,
        newMetaData: newMetaData,
        originalMetaData: originalMetaData
      };
    }
    case DELETE_COPY_OF_METADATA: {
      const newState = Object.keys(state).reduce((object, key) => {
        if (key !== "newMetaData" && key !== "originalMetaData") {
          object[key] = state[key];
        }
        return object;
      }, {});
      return {
        ...newState,
        isEditing: []
      };
    }
    case SAVE_CONTACT_DATA: {
      let newMetaData = { ...state.newMetaData };
      let contactDetails = { ...newMetaData.contactDetails };
      return {
        ...state,
        newMetaData: {
          ...newMetaData,
          contactDetails: { ...contactDetails, [payload.id]: payload.value }
        }
      };
    }
    case SAVE_PROFILE_DATA: {
      let newMetaData = { ...state.newMetaData };
      return {
        ...state,
        newMetaData: {
          ...newMetaData,
          [payload.id]: payload.value
        }
      };
    }
    case SAVE_TEST_TYPE_DATA: {
      const { newMetaData } = state;
      const { testConfiguration } = state?.newMetaData;
      return {
        ...state,
        newMetaData: {
          ...newMetaData,
          testConfiguration: testConfiguration.map(t =>
            t.testTypeId === payload.testTypeId
              ? { ...t, [payload.id]: payload.value }
              : t
          )
        }
      };
    }
    case SAVE_CHECKBOX_DATA: {
      const { metaData } = state;
      const { testConfiguration } = state?.metaData;
      return {
        ...state,
        metaData: {
          ...metaData,
          testConfiguration: testConfiguration.map(t =>
            t.testTypeId === payload.testTypeId
              ? { ...t, [payload.id]: payload.value }
              : t
          )
        }
      };
    }
    case DELETE_TEST_TYPE: {
      const { newMetaData } = state;
      const { testConfiguration } = state.newMetaData;
      return {
        ...state,
        newMetaData: {
          ...newMetaData,
          testConfiguration: testConfiguration.filter(
            t => t.testTypeId !== payload
          )
        }
      };
    }
    case CREATE_TEST_TYPE: {
      const { newMetaData } = state;
      const { testConfiguration } = state.newMetaData;
      return {
        ...state,
        newMetaData: {
          ...newMetaData,
          testConfiguration: [payload, ...testConfiguration]
        }
      };
    }
    case UPDATE_TEST_CONFIG: {
      const { metaData } = state;
      return {
        ...state,
        metaData: {
          ...metaData,
          testConfiguration: payload
        }
      };
    }
    case UPDATE_TESTING_PROFILE: {
      return {
        ...state,
        metaData: { ...payload }
      };
    }

    case UPDATE_TESTING_CONFIGURATION: {
      const { testTypeId } = payload;
      const { testConfiguration } = state.newMetaData;
      return {
        ...state,
        newMetaData: {
          ...state.newMetaData,
          testConfiguration: testConfiguration.map(item =>
            item.testTypeId === testTypeId
              ? { ...item, ...payload }
              : { ...item }
          )
        }
      };
    }
    case CLEAR_QR_CODE_DATA: {
      return initialState;
    }
    case SET_INFINITY_INTEGRATION: {
      return {
        ...state,
        metaData: { ...state.metaData, integrationId: payload.integrationId }
      };
    }
    case RESET_PROVIDER_FIELDS: {
      const { newMetaData, originalMetaData, defaultTestConfig } = state;
      const { testConfiguration } = state.newMetaData;
      const originalResultTimer = originalMetaData.testConfiguration.find(
        item => item.testTypeId === payload.id
      )?.resultTimer;
      const originalOutcomeTitles = originalMetaData.testConfiguration.find(
        item => item.testTypeId === payload.id
      )?.testOutcome;
      return {
        ...state,
        newMetaData: {
          ...newMetaData,
          testConfiguration: testConfiguration.map(item =>
            item.testTypeId === payload.id
              ? {
                  ...item,
                  resultTimer:
                    originalResultTimer || defaultTestConfig.resultTimer,
                  testOutcome: {
                    ...item.testOutcome,
                    values: [
                      ...item.testOutcome?.values.map((value, index) => ({
                        ...value,
                        title:
                          originalOutcomeTitles?.values[index].title ||
                          defaultTestConfig?.testOutcome?.values[index].title
                      }))
                    ]
                  }
                }
              : { ...item }
          )
        }
      };
    }
    case SET_DEFAULT_TEST_CONFIG:
      return {
        ...state,
        defaultTestConfig: {
          ...payload
        }
      };
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default qrCodeData;
