import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  PageTitle,
  Form,
  Spinner,
  FormGroup,
  Label,
  SelectOption,
  Error
} from "components";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { subMonths } from "date-fns";
import { LANGUAGE } from "const/app";
import { getDateFormatString, languageMapping } from "utils/formatData";
import { useTranslation } from "react-i18next";
import DataTable from "./components/DataTable";
import PatientDetailsContainer from "../../containers/PatientDetails.Container";
import moment from "moment";
import {TEST_RESULTS, SHARED_TEST_RESULTS} from "const/testResults";

import "react-datepicker/dist/react-datepicker.css";
import "./SearchRecipients.scss";

registerLocale(LANGUAGE, languageMapping?.[LANGUAGE]);
const SELECT_ALL_VALUE = "*";

const SearchRecipients = ({
  getFilteredPatientList,
  filteredPatients,
  filteredPatientIsLoading,
  testTypes,
  testProfiles,
  clearFilteredPatient,
  getTestingProfiles,
  getSelectedPatientData,
  clearState,
  clearSelectedPatient,
  patientIsLoading,
  surveyIsLoading,
  membersSearch,
  cohortProfiles,
  getCohortProfiles
}) => {
  const { t } = useTranslation();
  const [allTestTypesSelected, setAllTestTypesIsSelected] = useState(false);
  const [allTestResultsSelected, setAllTestResultsIsSelected] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [serverTime, setServerTime] = useState(null);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [filters, setFilters] = useState();
  const [selectedPatient, setSelectedPatient] = React.useState({});
  const [patientDeleted, setPatientDeleted] = React.useState(false);
  const [cohortProfilesFetched, setCohortProfilesFetched] = React.useState(false);

  useEffect(() => {
    clearFilteredPatient();
    return () => {
      clearState();
    };
  }, [clearFilteredPatient, clearState]);

  const {
    handleSubmit,
    errors,
    getValues,
    reset,
    formState,
    control,
    watch
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const initialValues = {
    startDate: null,
    endDate: null,
    ...(membersSearch 
      ? { cohortProfileList: [] }
      : { testProfileList: [] }
    ),
    testTypeList: [],
    testResultList: []
  };

  const testResultList = React.useMemo(
    () => [
      {
        label: <Icon type="testResult_negative" />,
        value: "RESULT_GOOD"
      },
      {
        label: <Icon type="testResult_negative_expired" />,
        value: "RESULT_GOOD_EXPIRED"
      },
      {
        label: <Icon type="testResult_positive" />,
        value: "RESULT_BAD"
      },
      {
        label: <Icon type="testResult_positive_expired" />,
        value: "RESULT_BAD_EXPIRED"
      },
      {
        label: <Icon type="testResult_invalid" />,
        value: "RESULT_INVALID"
      },
      {
        label: <Icon type="testResult_invalid_expired" />,
        value: "RESULT_INVALID_EXPIRED"
      },
      {
        label: <Icon type="testResult_not_ready" />,
        value: "RESULT_PENDING"
      }
    ],
    []
  );

  const compareDates = (date1, date2) => {
    if (date1 && date2) {
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      return d2 < d1;
    }
  };

  const handleMaxDateIntoEndDate = () => {
    const { startDate } = getValues();
    const today = moment().toDate();
    const threeMonthsLater = moment(startDate)
      .add(3, "months")
      .toDate();

    if (startDate) {
      return threeMonthsLater > today ? today : threeMonthsLater;
    }

    return today;
  };

  const handleMinDateIntoEndDate = () => {
    const { startDate } = getValues();

    if (startDate) {
      return moment(startDate).toDate();
    }
  };

  const handleMaxDateIntoStartDate = () => {
    const { endDate } = getValues();
    const today = moment().toDate();

    if (endDate) {
      return moment(endDate).toDate();
    }
    return today;
  };

  const onSubmit = React.useCallback(
    async params => {
      setSelectedPatient({});
      await clearFilteredPatient();
      await clearSelectedPatient();

      params.startDate =
        moment(params.startDate)
          .startOf("day")
          .unix() * 1000;

      params.endDate =
        moment(params.endDate)
          .endOf("day")
          .unix() * 1000;

      params.dataType = membersSearch ? SHARED_TEST_RESULTS : TEST_RESULTS;

      if (params.testTypeList?.includes(SELECT_ALL_VALUE)) {
        params.testTypeList = testTypes.map(option => option.value);
      }
      if (params.testResultList?.includes(SELECT_ALL_VALUE)) {
        params.testResultList = testResultList.map(option => option.value);
      }

      setFilters(params);
      setLoadingMoreData(true)
      const {token, serverTime} = await getFilteredPatientList(params);
      setNextToken(token);
      setServerTime(serverTime);
      setLoadingMoreData(false)
    },
    [
      clearFilteredPatient,
      getFilteredPatientList,
      testResultList,
      testTypes,
      clearSelectedPatient,
      membersSearch
    ]
  );

  const handleReset = e => {
    e.preventDefault();
    reset({
      ...initialValues
    });
    clearFilteredPatient();
    clearState();
    setNextToken(null);
    setServerTime(null);
    setSelectedPatient({});
    setAllTestTypesIsSelected(false);
    setAllTestResultsIsSelected(false);
  };

  const handleShowMoreResults = React.useCallback(
    async e => {
      e.preventDefault();
      setLoadingMoreData(true)
      clearSelectedPatient();
      setSelectedPatient({});

      const {token, serverTime} = await getFilteredPatientList(filters, nextToken);

      setNextToken(token);
      setServerTime(serverTime);
      setLoadingMoreData(false)
    },
    [filters, nextToken, getFilteredPatientList, clearSelectedPatient]
  );

  React.useEffect(() => {
    if(!membersSearch) {
      getTestingProfiles();
    } else {
      if(!cohortProfiles.length && !cohortProfilesFetched) {
         getCohortProfiles();
         setCohortProfilesFetched(true)
      }
    }
  }, [getTestingProfiles, membersSearch, getCohortProfiles, cohortProfiles, cohortProfilesFetched]);

  const onRowClick = React.useCallback(
    async e => {
      setPatientDeleted(false);
      setSelectedPatient(e);
      
      let patientExist = await getSelectedPatientData(
        membersSearch ? null : e.patientCountryOfResidence,
        e.patientId,
        membersSearch ? null : e.patientExtendedProfile?.surveyId,
        membersSearch ? SHARED_TEST_RESULTS : TEST_RESULTS
      );

      if (!patientExist) {
        setPatientDeleted(true);
      } else {
        setPatientDeleted(false);
      }
    },
    [getSelectedPatientData, membersSearch]
  );
  return (
    <React.Fragment>
      <PageTitle title={membersSearch ? t("member_search") : t("search")} /> 
      <Form className="search" onSubmit={handleSubmit(onSubmit)}>
        <div className="search__form">
          <div className="search__form-row-1">
            <div className="dates-container">
              <FormGroup>
                <Label htmlFor="startDate">
                  {`${t("test_data_date_from")}*`}
                </Label>
                <Icon type="calendar" />

                <Controller
                  as={ReactDatePicker}
                  control={control}
                  valueName="selected" // DateSelect value's name is selected
                  onChange={([selected]) => selected}
                  minDate={subMonths(watch("endDate"), 3)}
                  maxDate={handleMaxDateIntoStartDate()}
                  name="startDate"
                  className={
                    errors.startDate ? "date-picker--error" : "date-picker"
                  }
                  placeholderText={getDateFormatString(LANGUAGE)}
                  autoComplete="off"
                  disabled={nextToken}
                  locale={languageMapping[LANGUAGE] || LANGUAGE}
                  dateFormat="P"
                  rules={{
                    required: t("form_field_is_required"),
                    validate: value => {
                      return (
                        !compareDates(value, getValues("endDate")) ||
                        t("test_data_date_from_less_than_to_date")
                      );
                    }
                  }}
                />
                {errors.startDate && <Error>{errors.startDate.message}</Error>}
              </FormGroup>
              <div className="separator">-</div>
              <FormGroup>
                <Label htmlFor="endDate" error={errors.endDate}>
                  {`${t("to")}*`}
                </Label>
                <Icon type="calendar" />

                <Controller
                  as={ReactDatePicker}
                  control={control}
                  valueName="selected" // DateSelect value's name is selected
                  onChange={([selected]) => selected}
                  minDate={handleMinDateIntoEndDate()}
                  maxDate={handleMaxDateIntoEndDate()}
                  name="endDate"
                  disabled={nextToken}
                  className={
                    errors.endDate ? "date-picker--error" : "date-picker"
                  }
                  placeholderText={getDateFormatString(LANGUAGE)}
                  autoComplete="off"
                  locale={languageMapping[LANGUAGE] || LANGUAGE}
                  dateFormat="P"
                  rules={{
                    required: t("form_field_is_required")
                  }}
                />
                {errors.endDate && <Error>{errors.endDate.message}</Error>}
              </FormGroup>
            </div>
            {membersSearch ? 
              <FormGroup>
              <Label htmlFor="cohortProfileList" error={errors.cohortProfileList}>
                {t("cohort_profile_label")}
              </Label>

              <Controller
                as={
                  <SelectOption
                    isSearchable
                    isClearable
                    isMulti
                    closeMenuOnSelect={false}
                    disabled={nextToken}
                    placeholder={t("select_cohort_profile")}
                    error={errors.cohortProfileList}
                    options={cohortProfiles.map(r => ({
                      label: r.name,
                      value: r.cohortId
                    }))}
                  />
                }
                control={control}
                onChange={([selected, meta]) => {
                  // React Select return object instead of value for selection
                  return selected?.map(item => item.value);
                }}
                name="cohortProfileList"
              />
              {errors.cohortProfileList && (
                <Error>{errors.cohortProfileList.message}</Error>
              )}
            </FormGroup>
            :
              <FormGroup>
                <Label htmlFor="testProfileList" error={errors.testProfileList}>
                  {t("test_profile")}
                </Label>

                <Controller
                  as={
                    <SelectOption
                      isSearchable
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      disabled={nextToken}
                      placeholder={t("select_test_profile")}
                      error={errors.testProfileList}
                      options={testProfiles.map(r => ({
                        label: r.name,
                        value: r.testingProfileId
                      }))}
                    />
                  }
                  control={control}
                  onChange={([selected, meta]) => {
                    // React Select return object instead of value for selection
                    return selected?.map(item => item.value);
                  }}
                  // rules={{ required: t("form_field_is_required") }}
                  name="testProfileList"
                />
                {errors.testProfileList && (
                  <Error>{errors.testProfileList.message}</Error>
                )}
              </FormGroup>
            }
            
          </div>
          <div className="search__form-row-2">
            <FormGroup>
              <Label htmlFor="testTypeList" error={errors.testTypeList}>
                {t("test_type")}
              </Label>

              <Controller
                as={
                  <SelectOption
                    isSearchable
                    isClearable
                    isMulti
                    allowSelectAll={true}
                    closeMenuOnSelect={false}
                    disabled={nextToken}
                    error={errors.testTypeList}
                    placeholder={t("select_test_type")}
                    allIsSelected={allTestTypesSelected}
                    options={testTypes}
                  />
                }
                control={control}
                onChange={([selected, meta]) => {
                  // React Select return object instead of value for selection
                  if (selected && selected[selected.length - 1]?.value === "*") {
                    setAllTestTypesIsSelected(true);
                  }
                  if (allTestTypesSelected && meta.action === "clear") {
                    setAllTestTypesIsSelected(false);
                  }
                  if (allTestTypesSelected && meta.action === "remove-value") {
                    let data = selected
                      ?.filter(item => item.value !== meta.removedValue.value)
                      .map(item => item.value);
                    setAllTestTypesIsSelected(false);

                    return data;
                  }
                  return selected?.map(item => item.value);
                }}
                // rules={{ required: t("form_field_is_required") }}
                name="testTypeList"
              />
              {errors.testTypeList && (
                <Error>{errors.testTypeList.message}</Error>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="testResultList" error={errors.testResultList}>
                {t("test_result")}
              </Label>

              <Controller
                as={
                  <SelectOption
                    isSearchable
                    isClearable
                    isMulti
                    allowSelectAll={true}
                    closeMenuOnSelect={false}
                    disabled={nextToken}
                    error={errors.testResultList}
                    placeholder={t("select_test_result")}
                    allIsSelected={allTestResultsSelected}
                    options={testResultList}
                  />
                }
                control={control}
                onChange={([selected, meta]) => {
                  // React Select return object instead of value for selection
                  if (selected && selected[selected.length - 1]?.value === "*") {
                    setAllTestResultsIsSelected(true);
                  }
                  if (allTestResultsSelected && meta.action === "clear") {
                    setAllTestResultsIsSelected(false);
                  }
                  if (
                    allTestResultsSelected &&
                    meta.action === "remove-value"
                  ) {
                    let data = selected
                      ?.filter(item => item.value !== meta.removedValue.value)
                      .map(item => item.value);
                    setAllTestResultsIsSelected(false);

                    return data;
                  }
                  return selected?.map(item => item.value);
                }}
                // rules={{ required: t("form_field_is_required") }}
                name="testResultList"
              />
              {errors.testResultList && (
                <Error>{errors.testResultList.message}</Error>
              )}
            </FormGroup>
          </div>
          <p>{`*(${t("required")})`}</p>
        </div>
        <div className="buttons-container">
          <Button
            variant="primary"
            className="button add-button"
            type="submit"
            loading={!nextToken && filteredPatientIsLoading}
            disabled={nextToken || !formState.isValid}
          >
            {t("search")}
          </Button>
          <Button
            variant="secondary"
            className="button button-cancel"
            onClick={e => handleReset(e)}
          >
            {t("reset")}
          </Button>
          {nextToken && (
            <Button
              variant="text"
              className="button"
              onClick={handleShowMoreResults}
            >
              {t("show_more")}
            </Button>
          )}
        </div>
      </Form>
      <div className="data-export__table-container">
        <DataTable
          loadingData={loadingMoreData}
          data={filteredPatients}
          onRowClick={onRowClick}
          selectedPatient={selectedPatient}
          nextToken={nextToken}
          serverTime={serverTime}
          membersSearch={membersSearch}
          cohortProfiles={cohortProfiles}
        />
      </div>
      <div>
        {patientIsLoading || surveyIsLoading ? (
          <Spinner marginTop={10} />
        ) : (
          selectedPatient.patientId && (
            <PatientDetailsContainer
              patientDeleted={patientDeleted}
              deletedPatientData={selectedPatient}
              isMember={membersSearch}
            />
          )
        )}
      </div>
    </React.Fragment>
  );
};

export default SearchRecipients;
