// Parse source url
const host = window.location.host;
const splitIndex = host.indexOf(".");
const base = host.substring(0, splitIndex);
let source = host.substring(splitIndex);

// Constructs api url for all stages/environments
export const setApiUrl = () => {
  const prefix = window.config.REACT_APP_API_PREFIX;
  const suffix = "passport/v1/portal";
  source = host === "localhost:3000" ? window.config.REACT_APP_LOCAL : source;

  if (base.indexOf("test") !== -1) return `${prefix}-test${source}/${suffix}`;
  return `${prefix}${source}/${suffix}`;
};

// Constructs static content url for all stages/environments
export const setStaticContentUrl = content => {
  const prefix = window.config.REACT_APP_SC_PREFIX;
  const suffix = "com.roche.nrm_passport";
  source = host === "localhost:3000" ? window.config.REACT_APP_LOCAL : source;

  if (base.indexOf("test") !== -1)
    return `${prefix}-test${source}/${suffix}/${content}`;
  return `${prefix}${source}/${suffix}/${content}`;
};
