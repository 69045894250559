import { connect } from "react-redux";
import {
  getCohortProfiles,
  createCohortProfile,
  getCohortProfileData,
  updateCohortProfile,
  clearSelectedCohort
} from "store/reducers/cohorts/services";
import {getCohortProfileSurvey, createSurvey, updateSurvey, deleteSurvey, clearCohortSurvey, uploadSurvey} from "store/reducers/surveys/services";
import { isLoading } from "selectors/request";

import CohortProfiles from "components/CohortProfiles";

const mapStateToProps = state => ({
  cohortProfiles: state.cohorts.cohortProfiles,
  cohortProfilesLoading: isLoading(state, "getCohortProfiles"),
  cohortProfileCreating: isLoading(state, "createCohortProfile"),
  cohortProfileDataLoading: isLoading(state, "getCohortProfileData"),
  cohortProfileUpdating: isLoading(state, "updateCohortProfile"),
  cohortProfileData: state.cohorts.selectedCohortData,
  organizationId: state.user.organizationId,
  cohortProfileSurvey: state.surveys.cohortProfileSurveyData,
  cohortSurveyIsLoading: isLoading(state, "getCohortProfileSurveyData"),
  surveyValidating: isLoading(state, "validateSurvey")
});

const mapDispatchToProps = {
  getCohortProfiles,
  createCohortProfile,
  getCohortProfileData,
  updateCohortProfile,
  clearSelectedCohort,
  getCohortProfileSurvey,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  clearCohortSurvey,
  uploadSurvey
};

export default connect(mapStateToProps, mapDispatchToProps)(CohortProfiles);
