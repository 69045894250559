import React from "react";
import Button from "components/Button";
import ConfirmPopup from "components/ConfirmPopup";
import Icon from "components/Icon";
import Table from "components/Table";
import PageTitle from "components/PageTitle";
import Spinner from "components/Spinner";
import { 
  QUESTIONTYPES, 
  SURVEY_FOR_TEST_TYPE, 
  SURVEY_FOR_TEST_SHARING_WITH_COHORT,
  SINGLE_ANSWER_TEXT
 } from "const/surveyTypes";
import { useTranslation } from "react-i18next";
import uuid from "uuid";
import fileDownload from "js-file-download";
import ReactFileReader from 'react-file-reader';
import { useDispatch } from "react-redux";
import { error } from "react-notification-system-redux";

import "./Survey.scss";

const types = [...QUESTIONTYPES];

const Survey = ({
  title,
  data,
  loading,
  selectSurvey,
  selectDataElement,
  setAction,
  duplicateDataElement,
  setShowAddModal,
  deleteDataElement,
  disabled = false,
  uploadImportedSurvey,
  surveyValidating,
  surveyType
}) => {
  const [showImportDataModal, setShowImportDataModal] = React.useState(false);
  const [confirmConfig, setConfirmConfig] = React.useState({}); 
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const tableData =
    data?.survey?.questions?.map(question => {
      return {
        ...question,
        type:
          question?.type !== SINGLE_ANSWER_TEXT
            ? types.find(t => t.value === question.type)
            : types.find(t => question.answers?.[0]?.type === t.type)
      };
    }) || [];

  const onConfirmUploadSurvey =  React.useCallback(
      async survey => {
        await uploadImportedSurvey(survey, data, surveyType);
        setShowImportDataModal(false);
      },
      [uploadImportedSurvey, surveyType, data]
    );

  const handleSurveyUpload = React.useCallback(
      async files => {
        const fr = new FileReader();
        fr.onload = (e) => { 
          try {
            const survey = JSON.parse(e.target.result);
            if(data?.survey) {
               setConfirmConfig({
                title: t("toggle_confirmation"),
                message: t("confirm_upload_survey"),
                onConfirm: () => onConfirmUploadSurvey(survey)
              });
              setShowImportDataModal(true);
            } else {
              onConfirmUploadSurvey(survey)
            }
          } catch (e) {
            dispatch(error({ 
              title: t("error_title"),
              position: "tc",
              message: t(e.message) 
            }));
          }
        }
  
        fr.readAsText(files[0]);
        selectSurvey(data);
    },[onConfirmUploadSurvey,t, data, selectSurvey, dispatch]);

  const onEdit = React.useCallback(
    row => {
      const dataElement = data.survey.questions.find(q => q.id === row.id);
      selectDataElement(dataElement);
      selectSurvey(data);
      setAction("EDIT");
      setShowAddModal(true);
    },
    [selectDataElement, selectSurvey, data, setAction, setShowAddModal]
  );
  
  const onDuplicate = React.useCallback(
    async row => {
      const dataElement = data.survey.questions.find(q => q.id === row.id);
      let newElement = {
        ...dataElement,
        id: uuid(),
        answers: dataElement.answers.map(answer => ({
          ...answer,
          id: uuid().slice(0, 32)
        }))
      };
      const updatedData = {
        ...data,
        survey: {
          ...data.survey,
          questions: [...data?.survey?.questions, { ...newElement }]
        }
      };
      duplicateDataElement(updatedData);
    },
    [data, duplicateDataElement]
  );

  const onDelete = React.useCallback(
    async row => {
      const dataElement = data.survey.questions.find(q => q.id === row.id);
      deleteDataElement(dataElement);
    },
    [data, deleteDataElement]
  );

  const columns = [
    {
      Header: t("data_elements_required"),
      accessor: "required",
      disableSortBy: true,
      className: "column-required",
      Cell: ({ row }) => {
        return row.original.required ? <p> {t("yes")}</p> : <p> {t("no")}</p>;
      }
    },
    {
      Header: t("data_elements_type"),
      accessor: d => d.type.label,
      disableSortBy: true,
      className: "column-type"
    },
    {
      Header: t("data_elements_question_fieldName"),
      accessor: "fieldName",
      disableSortBy: true,
      className: "column-fieldName"
    },
    {
      Header: () => (
        <div className="header-with-icon">
          <div>{t("data_elements_question_label")}</div>
          <span>
            <Icon type="info" title={`${t("data_elements_tooltip")}`}></Icon>
          </span>
        </div>
      ),
      accessor: "title",
      disableSortBy: true,
      className: "column-label"
    },
    {
      Header: () => (
        <div className="header-with-icon">
          <div>{t("data_elements_question_hint")}</div>
          <span>
            <Icon type="info" title={`${t("data_elements_tooltip")}`}></Icon>
          </span>
        </div>
      ),
      accessor: "hint",
      disableSortBy: true,
      className: "column-hint"
    },
    {
      Header: () => (
        <div className="header-container">
          <div className="header-content">
            <div className="header-code">
              <div className="header-code-content">
                {t("answer_code")}
              </div>
            </div>
          </div>
          <div className="header-content">
          <div className="header-label-content">
            <div className="header-with-icon">
              <div>{t("data_elements_answer_label")}</div>
                <span>
                  <Icon type="info" title={`${t("data_elements_tooltip")}`}></Icon>
                </span>
              </div>
            </div>
          </div>
        </div>
      ),
      accessor: "answers",
      disableSortBy: true,
      className: "column-answers",
      Cell: ({ row }) => (
        row.original.answers?.map((r, i) => {
          return (
            <div className="answer-container" key={i}>
              <span className="code" >{row.original.type.name !== SINGLE_ANSWER_TEXT ? r.id : ""}</span>
              <span className="label-text"  >{r.value}</span>
            </div>)
        })
      )
    },
    {
      Header: t("actions"),
      className: "survey__actions-column column-actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <React.Fragment>
            <Button
              variant="row-action"
              title={t("edit")}
              onClick={e => {
                e.stopPropagation();
                onEdit(row.original);
              }}
            >
              <Icon type="edit" />
            </Button>
            <Button
              variant="row-action"
              title={t("duplicate")}
              onClick={e => {
                e.stopPropagation();
                onDuplicate(row.original);
              }}
            >
              <Icon type="duplicate" />
            </Button>
            <Button
              variant="row-action"
              title={t("health_care_organizations_users_actions_delete")}
              onClick={e => {
                e.stopPropagation();
                onDelete(row.original);
              }}
            >
              <Icon type="trash" />
            </Button>
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <div className="survey-button-container">
        <ReactFileReader handleFiles={(files)=>handleSurveyUpload(files)} fileTypes={'.json'}>
          <Button
            variant="outlined-text"
            className="button header-add-button"
          >
            {t("upload_config_button")}
          </Button>
        </ReactFileReader>
        <Button
          variant="outlined-text"
          className="button header-add-button"
          disabled={!data?.survey || disabled}
          onClick={()=>fileDownload(JSON.stringify(data.survey), data.surveyId+".json")}
        >
          {t("download_config_button")}
        </Button>
        <Button
          variant="primary"
          className="button header-add-button"
          disabled={!data || disabled}
          onClick={() => {
            selectSurvey(data);
            setAction("ADD");
            setShowAddModal(true);
          }}
        >
          {t("add_data_element")}
        </Button>
      </div>
      <PageTitle title={title} />
      {loading ? (
        <div className="spinner-container">
          <Spinner marginTop={8}/>
        </div>
      ) : (
        <div className="data-export__table-container">
          <Table
            columns={columns}
            data={tableData}
            className={"survey__table"}
            disabledSort={true}
          />
        </div>
      )}
     {surveyType !== SURVEY_FOR_TEST_TYPE && surveyType !== SURVEY_FOR_TEST_SHARING_WITH_COHORT ? (
        <div className="data-elements-devider"></div>
      ) : null}
      {showImportDataModal && (
        <ConfirmPopup
          title={confirmConfig.title}
          message={confirmConfig.message}
          visible={showImportDataModal}
          cancel={() => setShowImportDataModal(false)}
          confirm={e => {
            e.preventDefault();
            confirmConfig.onConfirm()
          }}
          loading={surveyValidating}
          buttonText={{ confirm: t("confirm"), cancel: t("cancel") }}
        />
      )}
    </React.Fragment>
  );
};

export default Survey;
