import Button from "components/Button";
import Icon from "components/Icon";
import QrCode from "components/QrCode";
import TestProfileContacts from "components/TestProfileContacts";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import "./AccessProfileDetails.scss";
import { LANGUAGE } from "const/app";
import { useTranslation } from "react-i18next";
import ComponentToPrint from "components/ComponentToPrint";
import { useReactToPrint } from 'react-to-print';

const AccessProfileDetails = ({
  contactDetails,
  accessProfileId,
  testingProfileUpdating,
  setTestingProfileForEdit,
  setTestingProfileDataForEdit,
  saveContactData,
  selectedTestingProfile,
  ...metaData
}) => {
  const { t } = useTranslation();
  const [copyText, setCopyText] = React.useState(t("copy"));
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const copyCodeToClipboard = (code, e) => {
    e.preventDefault();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(code);
    }
    setCopyText(t("copied"));
  };

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit"
  };
  const formattedDate =
    (metaData.metaData.eventStartTime &&
      new Date(metaData.metaData.eventStartTime).toLocaleString(
        LANGUAGE,
        options
      )) ||
    "";

  return (
    accessProfileId && (
      <div className="qr-code-details">
        <ComponentToPrint ref={componentRef}>
          <QrCode
            code={`{"qr-code-type": "accessConfiguration","payload": "${accessProfileId}"}`}
            isPrint={true}
            type={"accessProfile"}
            data={{
              ...metaData.metaData.contactDetails,
              title: metaData.metaData.name,
              eventDate: formattedDate
            }}
          ></QrCode>
        </ComponentToPrint>
        <div className="contact-information">
          <div className="qr-code-details__qr-code">
            <QrCode
              code={`{"qr-code-type": "accessConfiguration","payload": "${accessProfileId}"}`}
              onlyQrCode={true}
            />
            <div className="qr-actions">
              <Button
                data-testid="button-printer"
                variant="row-action"
                title={t("print")}
                onClick={e => {
                  e.preventDefault();
                  handlePrint();
                }}
              >
                <Icon type="printer" />
              </Button>
              <Button
                data-testid="button-copy"
                variant="row-action"
                title={copyText}
                onMouseLeave={() => setCopyText("Copy")}
                onClick={e => copyCodeToClipboard(accessProfileId, e)}
              >
                <Icon type="copy" />
              </Button>
            </div>
          </div>
          <div className="qr-summary">
            <TestProfileContacts
              isAccessProfile
              selectedTestingProfile={{
                ...selectedTestingProfile
              }}
              setTestingProfileDataForEdit={setTestingProfileDataForEdit}
              testingProfileUpdating={testingProfileUpdating}
              saveContactData={saveContactData}
            />
          </div>
        </div>
      </div>
    )
  );
};

AccessProfileDetails.propTypes = {
  metaData: PropTypes.object,
  qrCode: PropTypes.string
};

export default AccessProfileDetails;
