import React from "react";
import EditDataElementForm from "components/EditDataElementForm";
import ConfirmPopup from "components/ConfirmPopup";
import Survey from "components/Survey";
import PropTypes from "prop-types";
import * as _ from "lodash";
import { formatI18String } from "utils/formatData";
import {SURVEY_FOR_TEST_SHARING_WITH_COHORT} from "const/surveyTypes"

import "react-datepicker/dist/react-datepicker.css";
import "./cohortProfileSurvey.scss";

import { useTranslation } from "react-i18next";

const CohortProfileSurvey = ({
  getCohortProfileSurvey,
  survey,
  loading,
  disabled,
  clearCohortSurvey,
  updateSurvey,
  surveyIsUpdating,
  createSurvey,
  deleteSurvey,
  cohortId,
  uploadImportedSurvey,
  surveyValidating
}) => {
  const { t } = useTranslation();
  const [selectedSurvey, setSelectedSurvey] = React.useState({});
  const [selectedDataElement, setSelectedDataElement] = React.useState(null);
  const [action, setAction] = React.useState("");
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [confirmConfig, setConfirmConfig] = React.useState({});

  React.useEffect(() => {
    getCohortProfileSurvey(cohortId);
    return () => {
      clearCohortSurvey();
    };
  }, [getCohortProfileSurvey, clearCohortSurvey, cohortId]);

  const addAnswersMap = data => {
    const { questions } = data.survey;
    const nrOfQuestions = questions.length - 1;
    return {
      ...data,
      survey: {
        ...data.survey,
        questions: questions.map((question, i) => {
          return {
            ...question,
            answersMap: Object.assign(
              {},
              ...question.answers.map(answer => ({
                [answer["id"]]: i === nrOfQuestions ? null : questions[i + 1].id
              }))
            )
          };
        })
      }
    };
  };

  const onSubmit = async submittedData => {
    if (_.isEmpty(selectedSurvey) && action === "ADD") {
      const newSurvey = {
        surveyType: SURVEY_FOR_TEST_SHARING_WITH_COHORT,
        scopeId: cohortId,
        survey: {
          //Im not sure where to get the languageId from
          languageId: "EN",
          questions: [{ ...submittedData }]
        }
      };
      let newSurveyWithAnswerMap = addAnswersMap(newSurvey);
      await createSurvey(newSurveyWithAnswerMap);
    } else {
      let updatedData = {
        ...selectedSurvey,
        survey: {
          ...selectedSurvey.survey,
          questions:
            action !== "ADD"
              ? selectedSurvey.survey.questions.map(question =>
                  question.id === submittedData.id
                    ? { ...submittedData }
                    : question
                )
              : [...selectedSurvey?.survey?.questions, { ...submittedData }]
        }
      };
      let updatedDataWithAnswerMap = addAnswersMap(updatedData);
      await updateSurvey(updatedDataWithAnswerMap);
    }
    setShowAddModal(false);

    setSelectedDataElement(null);
    setSelectedSurvey(null);
  };

  const onDuplicate = async data => {
    let updatedDataWithAnswerMap = addAnswersMap(data);
    await updateSurvey(updatedDataWithAnswerMap);
    setSelectedSurvey(null);
  };

  const onConfirmDeleteDataElement = React.useCallback(
    async (selectedSurvey, data) => {
      const updatedData = {
        ...selectedSurvey,
        survey: {
          ...selectedSurvey.survey,
          questions: selectedSurvey.survey.questions.filter(
            question => question.id !== data.id
          )
        }
      };
      if (!updatedData.survey.questions.length) {
        await deleteSurvey(updatedData);
      } else {
        if (updatedData.survey.questions[0].id !== "START") {
          updatedData.survey.questions[0].id = "START";
        }
        let updatedDataWithAnswerMap = addAnswersMap(updatedData);
        await updateSurvey(updatedDataWithAnswerMap);
      }
      setShowDeleteModal(false);
      setSelectedDataElement(null);
      setSelectedSurvey(null);
    },
    [updateSurvey, deleteSurvey]
  );

  const onDelete = React.useCallback(
    (survey, dataElement) => {
      setConfirmConfig({
        title: t("confirm_delete_action"),
        message: formatI18String(
          t("confirm_delete_name", { name: `'${dataElement.title}'` })
        ),
        onConfirm: () => onConfirmDeleteDataElement(survey, dataElement)
      });
      setShowDeleteModal(true);
    },
    [onConfirmDeleteDataElement, t]
  );

  return (
    <>
      <Survey
        title={t("cohort_survey_title")}
        data={survey}
        loading={loading}
        setAction={action => setAction(action)}
        setShowAddModal={() => setShowAddModal(true)}
        selectSurvey={data => {
          setSelectedSurvey(data)
        }}
        disabled={disabled}
        selectDataElement={data => {
          setSelectedDataElement(data);
        }}
        duplicateDataElement={onDuplicate}
        deleteDataElement={data => onDelete(survey, data)}
        uploadImportedSurvey={uploadImportedSurvey}
        surveyValidating = {surveyValidating}
        surveyType={SURVEY_FOR_TEST_SHARING_WITH_COHORT}
      />
      {showAddModal && (
        <EditDataElementForm
          onSubmit={onSubmit}
          loading={surveyIsUpdating}
          action={action}
          hide={() => {
            setShowAddModal(false);
            setSelectedDataElement(null);
          }}
          data={selectedSurvey}
          selectedDataElement={selectedDataElement}
        />
      )}
      {showDeleteModal && (
        <ConfirmPopup
          title={confirmConfig.title}
          message={confirmConfig.message}
          visible={showDeleteModal}
          cancel={() => setShowDeleteModal(false)}
          confirm={e => {
            e.preventDefault();
            confirmConfig.onConfirm();
          }}
          loading={surveyIsUpdating}
        />
      )}
    </>
  );
};

CohortProfileSurvey.propTypes = {
  survey: PropTypes.object,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  cohortId: PropTypes.string
};

export default CohortProfileSurvey;
