import {
  EMAIL,
  NUMBERS_ONLY,
  PHONE_NUMBER,
  STARTS_WITH_PLUS,
  HAS_NO_ROMAN_CHARACTERS,
  HAS_LOWER_CASE,
  HAS_UPPER_CASE,
  HAS_DIGITS
} from "utils/regex";
import { useTranslation } from "react-i18next";

/**
 * @brief To abstract all validations
 *
 * @returns
 */
export const useValidation = () => {
  const { t } = useTranslation();

  const emailValidation = value =>
    EMAIL.test(value) ||
    t("health_care_organizations_users_add_modal_email_invalid");

  const noRomanCharacter = event => {
    if (HAS_NO_ROMAN_CHARACTERS.test(event.target.value)) {
      event.target.value = event.target.value.replace(
        HAS_NO_ROMAN_CHARACTERS,
        ""
      );
    }
  };

  const numbersOnly = value =>
    NUMBERS_ONLY.test(value) || t("phone_number_only_digits");

  const numbersOnlyIgnoreFirstCharacter = value =>
    NUMBERS_ONLY.test(value.slice(1)) || t("phone_number_only_digits");

  const onlyNumbers = value =>
    NUMBERS_ONLY.test(value.slice(1)) || "Field only accepts numbers"; // FIXME: translation probably add a variable into to not repeat that numbers_only

  const phoneNumberValidation = value =>
    PHONE_NUMBER.test(value) || t("user_profile_phone_number_invalid_start");

  const startsWithPlus = value =>
    STARTS_WITH_PLUS.test(value) ||
    t("user_profile_phone_number_invalid_start");

  const hasLowerCase = value =>
    HAS_LOWER_CASE.test(value) || t("password_error_no_lowercase");

  const hasUpperCase = value =>
    HAS_UPPER_CASE.test(value) || t("password_error_no_uppercase");

  const hasDigits = value =>
    HAS_DIGITS.test(value) || t("password_error_no_digits");

  return {
    emailValidation,
    noRomanCharacter,
    numbersOnly,
    phoneNumberValidation,
    startsWithPlus,
    hasLowerCase,
    hasUpperCase,
    hasDigits,
    onlyNumbers,
    numbersOnlyIgnoreFirstCharacter
  };
};
