import React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Modal from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import Textarea from "components/Textarea";
import SelectOption from "components/SelectOption";
import Error from "components/Error";
import { ModalActions } from "components/Modal";
import * as _ from "lodash";
import uuid from "uuid";
import { useTranslation } from "react-i18next";
import {
  SURVEY_FOR_EXTENDED_PATIENT_PROFILE, 
  SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE,
  QUESTIONTYPES, 
  YES,
  NO,
  SINGLE_ANSWER_TEXT, 
  MULTI_ANSWERS
} from "const/surveyTypes";

import "./EditDataElementForm.scss";
const LENGTH_OF_ANSWERLIST = 100;

const types = [...QUESTIONTYPES];
const requiredOptions = [YES, NO];

export default function EditDataElementForm(props) {
  const { hide = () => {}, loading, data, selectedDataElement, action } = props;

  const { type, required, fieldName, title, hint, answers } =
    selectedDataElement || {};

  const { t } = useTranslation();

  const [selectedQuestionType, setSelectedQuestionType] = React.useState(
    selectedDataElement
      ? type !== SINGLE_ANSWER_TEXT
        ? types.find(t => t.name === type)
        : types.find(t => answers?.[0]?.type === t.type)
      : {}
  );
  const [selectedAnswerType, setSelectedAnswerType] = React.useState(
    selectedDataElement
      ? type !== SINGLE_ANSWER_TEXT
        ? types.find(t => t.name === type).type
        : types.find(t => answers?.[0]?.type === t.type).type
      : ""
  );
  const [selectedRequired, setSelectedRequired] = React.useState(
    selectedDataElement ? (required === true ? YES : NO) : { value: "" }
  );
  const [showAnswers, setShowAnswers] = React.useState(
    selectedDataElement ? (type !== SINGLE_ANSWER_TEXT ? true : false) : null
  );

  const answersTooLong = answers?.length > LENGTH_OF_ANSWERLIST;

  const [selectedFieldsForEdit, setFieldForEdit] = React.useState([])

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    errors,
    getValues,
    triggerValidation
  } = useForm({
    mode: "onChange",
    defaultValues: {
      type: selectedQuestionType.value,
      required: selectedRequired?.value,
      fieldName,
      title,
      hint,
      answers
    }
  });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: showAnswers ? "answers" : ""
  });

  const { dirty, dirtyFields } = formState;

  const onSubmit = params => {
    let question = {};
    let newSurvey = _.isEmpty(data);
    let surveyIsEmpty = !data?.survey?.questions?.length;
    if (formState.isValid) {
      if (action === "ADD") {
        question = {
          id: newSurvey || surveyIsEmpty ? "START" : uuid(),
          required: selectedRequired.booleanValue,
          fieldName: params.fieldName,
          title: params.title,
          type: selectedQuestionType.name,
          hint:
            selectedQuestionType.name !== MULTI_ANSWERS ? params.hint : "",
          answersMap: {},
          answers:
            selectedQuestionType?.name === SINGLE_ANSWER_TEXT
              ? [
                  {
                    id: uuid(),
                    value: null,
                    type: selectedAnswerType
                  }
                ]
              : [
                  ...params.answers.map(answer => ({
                    ...answer,
                    type: selectedAnswerType
                  }))
                ]
        };
      } else {
        let newAnswers = answersTooLong? selectedDataElement.answers.map((e, i) => params.answers[i] ? params.answers[i] : e) : params.answers;
        question = {
          ...selectedDataElement,
          ...params,
          hint:
            selectedQuestionType.name !== MULTI_ANSWERS ? params.hint : "",
          fieldName: params.fieldName,
          required: selectedRequired.booleanValue,
          title: params.title,
          type: selectedQuestionType.name,
          answers: newAnswers?.map(answer => ({
            id: answer.id,
            value: answer.value,
            type: selectedAnswerType
          })) || [
            {
              id: uuid(),
              value: null,
              type: selectedAnswerType
            }
          ]
        };  
      }
      props.onSubmit(question);
    }
  };

  const modalTitle =
    data?.surveyType === SURVEY_FOR_EXTENDED_PATIENT_PROFILE
      ? t("test_recipient_profile_data_elements")
      : data.surveyType === SURVEY_FOR_EXTENDED_ORGANIZATION_PROFILE
      ? t("organization_profile_data_elements")
      : t("test_recipient_survey_data_elements");

  const onInputChange = (e, name) => {
    let { value } = e.target;
    setValue(name, value);
  };

  const onChange = (selected, meta) => {
    if (meta.action === "remove-value") {
      let itemToReset = answers?.find(answer => answer.id === meta.removedValue.originalId);
      let index = answers?.findIndex(answer => answer.id === meta.removedValue.originalId);
      setValue(`answers[${index}].id`, itemToReset.id);
      setValue(`answers[${index}].value`, itemToReset.value);
      let newFieldList = selectedFieldsForEdit?.filter(item => item.originalId !== meta.removedValue.originalId)
      setFieldForEdit(newFieldList);
      return;
    }
    const newFieldListForEdit = selected.map(item => {
      let selectedField = fields.find(field => field.id === item.originalId)
      return selectedField ? {...selectedField, originalId: selectedField.id} : item
    })
    setFieldForEdit(newFieldListForEdit)
  }

  return (
    <Modal visible={true} className="edit-data-element-form">
      <Title>{modalTitle}</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label error={errors.type}>{t("data_elements_type")}</Label>
          <Controller
            as={
              <SelectOption
                isSearchable
                options={types}
                isDisabled={props.action === "EDIT"}
                placeholder={t("select")}
              />
            }
            onChange={([selected]) => {
              setSelectedQuestionType(selected);
              setSelectedAnswerType(selected.type);
              if (selected.name === SINGLE_ANSWER_TEXT) {
                setShowAnswers(false);
                setValue("answers", []);
                if (
                  dirtyFields.has("type") &&
                  dirtyFields.has("required") &&
                  dirtyFields.has("fieldName") &&
                  dirtyFields.has("title")
                ) {
                  setTimeout(() => {
                    triggerValidation();
                  }, 0);
                }
              } else {
                if (!fields.length) {
                  append([{}, {}]);
                }
                setShowAnswers(true);
              }
              // React Select return object instead of value for selection
              return selected.value;
            }}
            rules={{
              required: "type" && t("form_field_is_required")
            }}
            name="type"
            control={control}
          />
          {errors["type"] && <Error>{errors["type"].message}</Error>}
        </FormGroup>
        <FormGroup>
          <Label>{t("data_elements_required")}</Label>
          <Controller
            as={
              <SelectOption
                isSearchable
                options={requiredOptions}
                placeholder={t("select")}
              />
            }
            onChange={([selected]) => {
              setSelectedRequired(selected);
              // React Select return object instead of value for selection
              return selected?.value;
            }}
            rules={{ required: "required" && t("form_field_is_required") }}
            name="required"
            control={control}
          />
        </FormGroup>
        <FormGroup>
          <Label error={errors.fieldName}>
            {t("data_elements_question_fieldName")}
          </Label>
          <Input
            type="text"
            name="fieldName"
            error={errors.fieldName}
            ref={register({
              required: "fieldName" && t("error_question_field_name"),
              maxLength: {
                value: 32,
                message: t("error_question_field_name")
              }
            })}
            defaultValue={fieldName}
            onChange={e => onInputChange(e, "fieldName")}
          />
          {errors?.fieldName && <Error>{errors.fieldName.message}</Error>}
        </FormGroup>
        <FormGroup>
          <Label error={errors.title}>
            {t("data_elements_question_label")}
          </Label>
          <Input
            type="text"
            name="title"
            error={errors.title}
            ref={register({
              required: "title" && t("error_question_label"),
              maxLength: {
                value: 1024,
                message: t("error_question_label")
              }
            })}
            defaultValue={title}
            onChange={e => onInputChange(e, "title")}
          />
          {errors?.title && <Error>{errors.title.message}</Error>}
        </FormGroup>
        {selectedQuestionType.name !== MULTI_ANSWERS ? (
          <FormGroup>
            <Label error={errors.hint}>
              {t("data_elements_question_hint")}
            </Label>
            <Textarea
              name="hint"
              defaultValue={hint}
              className="hint-textarea"
              error={errors.hint}
              ref={register({
                maxLength: {
                  value: 40,
                  message: t("question_hint_error")
                }
              })}
              onChange={e => onInputChange(e, "hint")}
            />
            {errors?.hint && <Error>{errors.hint.message}</Error>}
          </FormGroup>
        ) : null}
        {showAnswers && !answersTooLong &&
          fields.map((item, index) => (
            <div key={item.id}>
              <FormGroup>
                <Label error={errors?.answers?.[index]?.id}>
                  {t("answer_code")}
                </Label>
                <Controller
                  as={
                    <Input type="text" error={errors?.answers?.[index]?.id} />
                  }
                  name={`answers[${index}].id`}
                  defaultValue={item.id || ""}
                  control={control}
                  rules={{
                    required:
                      `answers[${index}].id` && t("error_answer_code_length"),
                    minLength: {
                      value: 1,
                      message: t("error_answer_code_length")
                    },
                    maxLength: {
                      value: 128,
                      message: t("error_answer_code_length")
                    },
                    validate: () => {
                      const idList = getValues({ nest: true }).answers;
                      if (idList.length >= 2) {
                        const unique = new Set(idList.map(item => item.id));
                        return unique.size !== fields.length
                          ? t("error_answer_code")
                          : undefined;
                      }
                    }
                  }}
                />
                {errors?.answers?.[index]?.id && (
                  <Error>{errors?.answers?.[index].id.message}</Error>
                )}
              </FormGroup>
              <FormGroup>
                <Label error={errors?.answers?.[index]?.value}>
                  {t("data_elements_answer_label")}
                </Label>
                <Controller
                  as={
                    <Input
                      type="text"
                      error={errors?.answers?.[index]?.value}
                    />
                  }
                  name={`answers[${index}].value`}
                  defaultValue={item.value || ""}
                  control={control}
                  rules={{
                    required:
                      `answers[${index}].value` && t("error_question_label"),
                    minLength: {
                      value: 1,
                      message: t("error_question_label")
                    },
                    maxLength: {
                      value: 1024,
                      message: t("error_question_label")
                    }
                  }}
                />
                {errors?.answers?.[index]?.value && (
                  <Error>{errors?.answers?.[index].value.message}</Error>
                )}
                <span>
                  <div className="edit-data-element-form-button-container">
                    {index !== 0 && index !== 1 ? (
                      <button
                        type="button"
                        onClick={() => {
                          remove(index);
                          setTimeout(() => {
                            triggerValidation();
                          }, 0);
                        }}
                      >
                        -
                      </button>
                    ) : null}
                    {index !== 0 && index !== 1 ? "/" : null}
                    <button
                      type="button"
                      onClick={async () => {
                        insert(parseInt(index + 1), { value: "" });
                        setTimeout(() => {
                          triggerValidation();
                        }, 0);
                      }}
                    >
                      +
                    </button>
                  </div>
                </span>
              </FormGroup>
            </div>
          ))}
        {answersTooLong && 
          ( 
          <>
            <FormGroup>
              <Label >
                {t("answers")}
              </Label>
              <SelectOption
                name={"selectOption"}
                isSearchable
                className={"select-answers"}
                isMulti
                placeholder={t("select")}
                options={fields.map(item => ({
                  label: item.value,
                  value: item.id,
                  originalId: item.id
                }))}
                onChange={(selected, meta) => onChange(selected, meta)}
              />
            </FormGroup>
            {selectedFieldsForEdit.length ? selectedFieldsForEdit.map(item => {
              let index = answers.findIndex(answer => answer.id === item.originalId)
              return (
              
              <div key={index}>
                <FormGroup>
                  <Label error={errors?.answers?.[index]?.id}>
                    {t("answer_code")}
                  </Label>
                  <Controller
                    as={
                      <Input type="text" error={errors?.answers?.[index]?.id} />
                    }
                    name={`answers[${index}].id`}
                    defaultValue={item?.id || ""}
                    control={control}
                    rules={{
                      required:
                        `answers[${index}].id` && t("error_answer_code_length"),
                      minLength: {
                        value: 1,
                        message: t("error_answer_code_length")
                      },
                      maxLength: {
                        value: 128,
                        message: t("error_answer_code_length")
                      },
                      validate: () => {
                        const modifiedValues = getValues({ nest: true }).answers;
                        const idList = selectedDataElement.answers.map((e, i) => modifiedValues[i] ? modifiedValues[i] : e);
                        
                        if (idList.length >= 2) {
                          const unique = new Set(idList.map(item => item.id));
                          return unique.size !== fields.length
                            ? t("error_answer_code")
                            : undefined;
                        }
                      }
                    }}
                  />
                  {errors?.answers?.[index]?.id && (
                    <Error>{errors?.answers?.[index].id.message}</Error>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label error={errors?.answers?.[index]?.value}>
                    {t("data_elements_answer_label")}
                  </Label>
                  <Controller
                    as={
                      <Input
                        type="text"
                        error={errors?.answers?.[index]?.value}
                      />
                    }
                    name={`answers[${index}].value`}
                    defaultValue={item?.value || ""}
                    control={control}
                    rules={{
                      required:
                        `answers[${index}].value` && t("error_question_label"),
                      minLength: {
                        value: 1,
                        message: t("error_question_label")
                      },
                      maxLength: {
                        value: 1024,
                        message: t("error_question_label")
                      }
                    }}
                  />
                  {errors?.answers?.[index]?.value && (
                    <Error>{errors?.answers?.[index].value.message}</Error>
                  )}
                </FormGroup>
              </div>
            )}) : null}
          </>
          )
        }
        <ModalActions>
          <Button
            variant="primary-full-width"
            className="margin-top--medium"
            type="submit"
            loading={loading}
            disabled={!formState.isValid || loading || !dirty}
          >
            {t("save")}
          </Button>
          <Button variant="cancel-text" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
