import React from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Title from "components/Title";

import {
  InputBirthDay,
  InputPhoneNumber,
  InputFirstName,
  InputEmail,
  SelectCountry,
  InputZipCode
} from "components/FormComponents";
import { ModalActions } from "components/Modal";
import "./AddPatientForm.scss";
import { useTranslation } from "react-i18next";
import { mapFieldsToInputs } from "utils/mapFieldsToInput";

export default function AddPatientForm({
  hide = () => {},
  onSubmit,
  loading,
  patient,
  emailError,
  type,
  additionalFields = null
}) {
  const { t } = useTranslation();

  const {
    profile: { firstName, lastName, dateOfBirth, phoneNumber, zipCode },
    configuration: { email, country }
  } = patient;

  const myUseForm = useForm({
    mode: "onChange",
    defaultValues: {
      firstName,
      lastName,
      email,
      ...(dateOfBirth && {
        dateOfBirth: new Date(dateOfBirth)
      }),
      phoneNumber: {
        number: phoneNumber?.number,
        countryCode: phoneNumber?.countryCode
      },
      zipCode,
      country
    }
  });

  const { handleSubmit, formState, setError } = myUseForm;
  React.useEffect(() => {
    if (emailError) {
      setError("email", "invalid", t("email_exists"));
    }
  }, [emailError, setError, t]);

  return (
    <Modal
      visible={true}
      className="add-patient-form"
      data-testid="form-add-patient"
    >
      <Title>{t("patient_information_title")}</Title>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="add-patient-form">
        <InputFirstName required addLastName useForm={myUseForm} />
        <InputBirthDay required useForm={myUseForm} type />
        <InputPhoneNumber useForm={myUseForm} />
        <InputZipCode useForm={myUseForm} />
        <SelectCountry
          name="country"
          required
          disabled={type !== "ADD" && patient.configuration.country}
          useForm={myUseForm}
        />
        <InputEmail useForm={myUseForm} />
        <div className="reminder">{t("email_can_be_used")}</div>
        {type === "UPDATE" &&
          additionalFields?.survey?.questions?.map(field => mapFieldsToInputs(field, patient.extendedProfile, additionalFields, myUseForm))}
        <div className="fields-required">(*{t("required")})</div>
        <ModalActions>
          <Button
            variant="primary-full-width"
            className="margin-top--medium"
            type="submit"
            loading={loading}
            disabled={!formState.isValid}
          >
            {type === "ADD" ? t("next") : t("save")}
          </Button>
          <Button variant="cancel-text" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
