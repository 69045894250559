import axios from "utils/Axios";
import { makeAxiosRequest } from "utils/requests";

export const getHomeTestProfilesList = countryCode => {
  return makeAxiosRequest("getHomeTestProfilesList", {
    client: axios,
    method: "get",
    relativePath: `/home-testing-profiles/${countryCode}`
  });
};

export const createHomeTestProfile = (countryCode, payload) => {
  return makeAxiosRequest("createHomeTestProfile", {
    client: axios,
    method: "post",
    relativePath: `/home-testing-profiles/${countryCode}`,
    data: {
      ...payload
    }
  });
};

export const getHomeTestProfilesById = (countryCode, id) => {
  return makeAxiosRequest("getHomeTestProfilesById", {
    client: axios,
    method: "get",
    relativePath: `/home-testing-profiles/${countryCode}/${id}`
  });
};

export const updateHomeTestProfile = (id, payload) => {
  return makeAxiosRequest("updateHomeTestProfile", {
    client: axios,
    method: "put",
    relativePath: `/home-testing-profiles/${id}`,
    data: {
      ...payload
    }
  });
};

export const publishHomeTestProfile = (id, status) => {
  return makeAxiosRequest("publishHomeTestProfile", {
    client: axios,
    method: "patch",
    relativePath: `/home-testing-profiles/${id}`,
    data: {
      status
    }
  });
};

export const activateDeactivateHomeTestProfile = (id, active) => {
  return makeAxiosRequest("activateDeactivateHomeTestProfile", {
    client: axios,
    method: "patch",
    relativePath: `/home-testing-profiles/${id}`,
    data: {
      active
    }
  });
};
