import React from "react";
import { useHistory, Prompt } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import Form from "components/Form";
import Wrapper from "components/Wrapper";
import Title from "components/Title";
import Label from "components/Label";
import Input from "components/Input";
import Button from "components/Button";
import Error from "components/Error";
import FormGroup from "components/FormGroup";
import { setLoggedUser } from "store/reducers/user/services";
import { ColorContext } from "components/FlatLayout/FlatLayout";
import { secondaryBackgroundColor } from "const/colorCodes";
import { useTranslation } from "react-i18next";

function ConfirmPhoneNumberForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [phoneVerified, setPhoneVerified] = React.useState(false);
  const [loading, setLoadingStatus] = React.useState(false);
  const { register, handleSubmit, formState, errors, setError } = useForm({
    mode: "onChange"
  });

  const signOut = React.useCallback(() => {
    if (!phoneVerified) {
      Auth.signOut();
    }
  }, [phoneVerified]);

  React.useEffect(() => {
    window.addEventListener("beforeunload", signOut);

    return () => {
      window.removeEventListener("beforeunload", signOut);
    };
  }, [signOut]);

  const { changeBgColor } = React.useContext(ColorContext);

  React.useEffect(() => {
    changeBgColor(secondaryBackgroundColor);
  }, [changeBgColor]);

  const onSubmit = ({ confirmationCode }) => {
    if (formState.isValid) {
      setLoadingStatus(true);

      Auth.verifyCurrentUserAttributeSubmit("phone_number", confirmationCode)
        .then(() => {
          Auth.currentAuthenticatedUser().then(u => {
            setPhoneVerified(true);
            dispatch(setLoggedUser(u));
            history.push("/");
          });
        })
        .catch(err => {
          setError("confirmationCode", "invalid", err.message);
          setLoadingStatus(false);
        });
    }
  };

  return (
    <Wrapper className="sign-in-form">
      <Title className="no-margin">{t("confirm_phone_number")}</Title>
      <p className="user-tip">{t("confirm_phone_number_description")}</p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="confirmationCode" error={errors.confirmationCode}>
            {t("confirmation_code")}
          </Label>
          <Input
            type="text"
            name="confirmationCode"
            error={errors.confirmationCode}
            ref={register({
              minLength: {
                value: 6,
                message: t("confirmation_code_error")
              },
              required: t("confirmation_code_error_required")
            })}
          />
          {errors.confirmationCode && (
            <Error>{errors.confirmationCode.message}</Error>
          )}
        </FormGroup>
        <Button
          variant="primary-full-width"
          className="margin-top--large"
          disabled={loading}
          type="submit"
          loading={loading}
        >
          {t("confirm")}
        </Button>
      </Form>
      <Prompt
        message={() => {
          signOut();
          return true;
        }}
      />
    </Wrapper>
  );
}

export default ConfirmPhoneNumberForm;
