import React from "react";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Error from "components/Error";
import { useTranslation } from "react-i18next";
import { useValidation } from "utils/useValidation";

function InputEmail({
  name = "email",
  useForm: { register, errors, triggerValidation },
  label = "health_care_organizations_users_email",
  disabled,
  required
}) {
  const { t } = useTranslation();
  const { emailValidation, noRomanCharacter } = useValidation();

  return (
    <>
      <FormGroup>
        <Label htmlFor={name} error={errors[name]}>
          {t(label)}
          {required && "*"}
        </Label>
        <Input
          data-testid="email-input"
          type="text"
          name={name}
          error={errors[name]}
          disabled={disabled}
          onChange={e => {
            triggerValidation(name);
            noRomanCharacter(e);
          }}
          ref={register({
            validate: {
              emailValidation
            },
            ...(required && { required: t("form_field_is_required") }),
            maxLength: {
              value: 128,
              message: t("field_max_length", { number_of_characters: 128 })
            }
          })}
        />
        {errors[name] && <Error>{errors[name].message}</Error>}
      </FormGroup>
    </>
  );
}

export default InputEmail;
