import React from "react";
import classNames from "classnames";
import "./form.scss";

const Form = ({ children, className, onSubmit, ...restOfProps }) => (
  <form
    {...restOfProps}
    className={classNames("form", className)}
    onSubmit={onSubmit}
  >
    {children}
  </form>
);

export default Form;
