import React from "react";
import Row from "components/Row";
import Wrapper from "components/Wrapper";
import UpdatePasswordForm from "./UpdatePasswordForm";
import UpdatePersonalDataForm from "./UpdatePersonalDataForm";
import "./updateProfileForm.scss";

const UpdateProfileForm = props => (
  <Wrapper>
    {props.attributes && (
      <Row>
        <UpdatePersonalDataForm {...props} />
        <UpdatePasswordForm />
      </Row>
    )}
  </Wrapper>
);

export default UpdateProfileForm;
